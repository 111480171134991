import React, {useEffect, Fragment} from 'react';
import './OptionalsContent.scss'
import ItemGroup from "../../components/itemGroup/ItemGroup";
import {Preloader} from "../../components/preloader/Preloader";
import {PreloaderWrapper} from "../../components/preloader-wrapper/PreloaderWrapper";
import ContactBoxContainer from "../../components/contactbox/ContactBoxContainer";
import {ItemGroupHeader} from "../../components/itemGroup/itemGroupHeader/ItemGroupHeader";
import {ErrorMessage} from "../../components/error/ErrorMessage";
import {
	diffDateDays,
	getTravelPeriod,
	getUtcSafeDate,
	TRAVELPERIOD_CURRENT,
	TRAVELPERIOD_FUTURE,
	TRAVELPERIOD_PAST
} from "../../../vendor/utils/DateUtils";
import {connect} from "react-redux";
import {dispatchFetchData, FETCH_TYPE_OPTIONALS} from "../../redux/actions/fetchAction";
import PropTypes from "prop-types";
import OfferInfo from "../../components/offerInfo/OfferInfo";

function OptionalsContent({dispatchFetchData, ...props}) {

	useEffect(() => {

		dispatchFetchData();

	}, [dispatchFetchData, props.offerNumber]);

	function renderItems(items) {
		if (!items) {
			return null;
		}

		return (
			items.map((group, index) => {

				const groupLabel = group.type === 'insurance' ? group.label : "Unsere Ausflüge-Empfehlungen in " + group.label;

				return (
					<Fragment key={`group-${index}`}>
						<ItemGroupHeader label={groupLabel} withBackground={true} additionalClasses={'h3'}/>
						<ItemGroup group={group}
											 key={`group-${index}`}
											 id={`group-${index}`}
											 context={'optionals'}
											 readOnly={true}/>
					</Fragment>
				);
			})
		);
	}

	/**
	 * returns text matching to the optionalsOffer
	 *
	 * @param journeyStart
	 * @param journeyEnd
	 * @param diffDate
	 * @param maxDiffDays
	 * @param optionalItemslength
	 * @returns {*}
	 */
	function getOptionalsText(journeyStart, journeyEnd, diffDate, maxDiffDays, optionalItemslength) {

		const travelPeriod = getTravelPeriod(journeyStart, journeyEnd);


		if (travelPeriod === TRAVELPERIOD_PAST) {
			return optionalsTextAfterJourney;
		} else if (optionalItemslength === 0) {
			return noOptionalsText;
		} else if (travelPeriod === TRAVELPERIOD_CURRENT) {
			return optionalsTextOnJourney
		} else if (travelPeriod === TRAVELPERIOD_FUTURE) {
			if (diffDate < maxDiffDays) {
				return optionalsTextTimeframeExceeded
			} else {
				return optionalsText
			}
		}
	}

	/**
	 * renders Optional depending on journeyStartDate
	 * @returns {*}
	 */
	function renderOptionalsBlock() {

		const diffDate = diffDateDays(getUtcSafeDate(new Date().getTime()), getUtcSafeDate(props.journeyStart)) * -1;
		const maxDiffDays = 14;

		return (
			<div className={'optionals-content'}>
				<div className={'optionals-content__intro box'}>
					<div className={'optionals-content__intro-headline h2 ts-handwriting'}>Unsere Empfehlungen für Sie
					</div>

					{getOptionalsText(props.journeyStart, props.journeyEnd, diffDate, maxDiffDays, props.optionalItems.length)}
					{props.optionalItems?.length > 0 && <OfferInfo offerNumber={props.offerNumber}/>}
				</div>

				<ContactBoxContainer/>

				{(diffDate >= maxDiffDays && props.optionalItems.length > 0) &&
				renderItems(props.optionalItems)
				}
			</div>
		);
	}

	if (props.hasLoadingError) {
		return <ErrorMessage type={'loadingError'}/>
	} else if (props.isLoading || !props.loadingComplete) {
		return (
			<>
				<Preloader type={'text'} additionalClasses={'box'}/>
				<PreloaderWrapper classes={'optionals-content'}>
					<Preloader type={'logo'}/>
				</PreloaderWrapper>
			</>
		);
	}

	return renderOptionalsBlock();
}

/** ############################################
 *  optionals introTexts
 * ############################################
 */
const noOptionalsText = <>
	<p>Unsere Destinationen bieten eine unendliche Vielzahl an Erlebnissen und Ausflügen.</p>
	<p>Da wir die einzelnen Stationen auf Ihrer Route nicht kennen oder Ihnen die Ausflüge direkt über unsere Partner
		angeboten werden (z.B. bei Busreisen oder Kreuzfahrten), können wir Ihnen hier leider keine maßgeschneiderten
		Empfehlungen anbieten.</p>
	<p>Sprechen Sie gern Ihren Reiseberater an, wenn Sie für bestimmte Stationen auf Ihrer Reise Ausflüge wünschen - wir
		helfen Ihnen gerne weiter!</p></>;

const optionalsTextTimeframeExceeded = <><p>Ihre Reise rückt näher!</p>
	<p>Da Sie sich schon bald auf Ihrer Traumreise befinden, können wir Ihnen zum jetzigen Zeitpunkt leider keine
		Ausflüge mehr zum Buchen zur Verfügung stellen.</p>
	<p>Wir wünschen Ihnen einen schönen Urlaub!</p></>;


const optionalsTextOnJourney = <><p>Da Sie sich bereits auf Ihrer Traumreise befinden, können wir Ihnen zum jetzigen
	Zeitpunkt leider keine Ausflüge mehr zum Buchen zur Verfügung stellen.</p>
	<p>Wir wünschen Ihnen einen schönen Urlaub!</p></>;

const optionalsTextAfterJourney = <><p>Wir hoffen, Sie hatten einen fantastischen Urlaub?</p>
	<p>Gerne unterstützen wir Sie dabei auch Ihre nächste Traumreise zu realisieren. </p>
	<p>Sprechen Sie uns an, wir helfen Ihnen gern!</p></>;

const optionalsText = <p>Eine Reise in unsere Destinationen ist immer etwas ganz Besonderes! Ausflüge sind eine wunderbare
	Möglichkeit das Land noch besser kennenzulernen und ganz besondere Erinnerungen zu schaffen.<br/>
	Abgestimmt auf Ihre Reise haben wir Ihnen hier einige Ideen aufgeführt - da ist bestimmt etwas Passendes für Sie
	dabei.<br/>
	Der angegebene Preis ist der Gesamtpreis des Ausflugs für alle Reiseteilnehmer.<br/>
	Für das Buchen der Ausflüge kontaktieren Sie bitte bis vierzehn Tage vor Abreise Ihren Reiseberater, der Ihnen gern
	behilflich ist.</p>;


OptionalsContent.propTypes = {

	/**
	 * list of optionalItems
	 */
	optionalItems: PropTypes.array,
	/**
	 * show preloader
	 */
	isLoading: PropTypes.bool,

	/**
	 * flag in case of local error handling
	 */
	hasLoadingError: PropTypes.bool,

	offerNumber: PropTypes.string
};

const mapStateToProps = (state, ownProps) => {

	return {
		journeyStart: state.globalStateManagement.offer.journeyStart,
		journeyEnd: state.globalStateManagement.offer.journeyEnd,
		offerNumber: state.globalStateManagement.offer.offerNumber,
		...state.optionalsManagement
	}
};

export default connect(mapStateToProps, {dispatchFetchData: () => dispatchFetchData(FETCH_TYPE_OPTIONALS)})(OptionalsContent);
