import {DataProviderService} from "./DataProviderService";

class NoticeService extends DataProviderService {

	/**
	 * get currentNoteText from old endpoint
	 * @param groupIndex
	 * @param noticeId
	 * @param response
	 * @returns {object}
	 */

	setNoticeById(noticeId, noticeValue) {

		const data = {
			itemId: noticeId,
			itemNote: noticeValue,
			securityToken: ''
		}

		return this.setData(data)
			.then((response) => {
				if (response.isAxiosError) {
					return Promise.reject(response)
				}
				return response
			})

	}

}

export {
	NoticeService
}
