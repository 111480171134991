import React from 'react';
import {Checkbox} from "../checkbox/Checkbox";
import "./ConsentCheckbox.scss";
import classNames from "classnames";

function ConsentCheckBox(props) {

	const classes = classNames(
		'consent-checkbox',
		props.additionalClasses,
	);

	return (
		<div className={classes}>
			<div className={'consent-checkbox__wrapper'}>
				<Checkbox name={props.name} checked={props.checked} disabled={props.disabled} additionalClasses={'consent-checkbox__checkbox'} changeHandler={props.onChange}/>
				<div className={'consent-checkbox__label ts-bold'}>{props.label}</div>
			</div>
			<p>{props.description}</p>
		</div>
	);
}

export default ConsentCheckBox;
