import React, {Component} from 'react';
import './ItemHeader.scss'
import classNames from 'classnames';
import PropTypes from "prop-types";

class ItemHeader extends Component {

	render() {
		const classes = classNames(
			'item-header',
			`item-header-${this.props.context}`,
			{'item-header__extra': this.props.children.props.item.contentType === 'extra'}
		)

		return (
			<div className={classes}>
				{this.props.children}
			</div>
		);
	}
}


ItemHeader.defaultProps = {
	isCollapsible: false,
	readOnly: false,
};

ItemHeader.propTypes = {

	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string,

	/**
	 * defines if header has collapsible funtionality
	 *
	 * @default: false
	 */
	isCollapsible: PropTypes.bool,

	/**
	 * prevents changes in ItemHeader
	 *
	 * @default: false
	 */
	readOnly: PropTypes.bool,

	/**
	 * context of use has influence on itemHeaderTemplate
	 */
	context: PropTypes.oneOf(['documents', 'offer', 'optionals']),

	/**
	 *
	 * see **Item** for further information
	 */
	item: PropTypes.shape({

		/**
		 * template of ItemHeader
		 * @example: 'main'
		 *
		 */
		contentType: PropTypes.oneOf(['main', 'extra', 'optional']).isRequired,

		/**
		 * label used in ItemHeader
		 * @example: 'Mariner King Inn'
		 */
		label: PropTypes.string,

		/**
		 * location.label used in ItemHeader
		 * @example: {label:'Lighthouse Trail, NS', lat:34.2618964, lng:-116.9194283}
		 */
		location: PropTypes.shape({
				/**
				 * Location label
				 * @example: 'Lighthouse Trail, NS'
				 */
				label: PropTypes.string,

				/**
				 * Latitude of location
				 * @example: 34.2618964
				 */
				lat: PropTypes.number,

				/**
				 * Longitude of location
				 * @example: -116.9194283
				 */
				lng: PropTypes.number,
			}
		),

		/**
		 * OPTIONAL: an array with options inside
		 *
		 * @example:
		 *       [
		 *        {date: "2019-08-29"
		 *			times: [
		 *				{price: 118, time: "14:00-15:30"}
		 *				{price: 118, time: "15:00-16:30"}
		 * 				{price: 118, time: "16:30-18:00"}
		 *				{price: 118, time: "20:30-22:00"}]},
		 *            {date: "2019-08-30"
		 *			times: [
		 *				{price: 118, time: "14:00-15:30"}
		 *				{price: 118, time: "15:00-16:30"}
		 *				{price: 118, time: "16:30-18:00"}
		 *				{price: 118, time: "20:30-22:00"}]}
		 *        ]
		 *
		 */
		options: PropTypes.array,
	})
};

export {
	ItemHeader as default,
	ItemHeader
}
