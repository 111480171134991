import React, { Suspense, useState } from 'react';
import './DigitalOfferContent.scss'
import ItemGroup from "../../components/itemGroup/ItemGroup";
import { Preloader } from "../../components/preloader/Preloader";
import Participants from "../../components/participants/Participants";
import PropTypes from 'prop-types';
import { Badge } from "../../components/badge/Badge";

import { ButtonInternal } from "../../components/button/ButtonInternal";
import { LinkInternal } from "../../components/link/LinkInternal";
import { ErrorMessage } from "../../components/error/ErrorMessage";
import OfferInfo from "../../components/offerInfo/OfferInfo";
import {
  getTravelPeriod,
  TRAVELPERIOD_CURRENT,
  TRAVELPERIOD_FUTURE,
  TRAVELPERIOD_PAST
} from "../../../vendor/utils/DateUtils";
import ContactBoxContainer from "../../components/contactbox/ContactBoxContainer";
import { connect } from "react-redux";
import { PriceGuarantee } from "../../components/priceGuarantee/PriceGuarantee";
import { BookingBox } from "../../components/bookingBox/BookingBox";
import { ErrorBoundary } from "../../components/error/ErrorBoundary";
import CurrentOffer from "../../components/currentOffer/CurrentOffer";
import { getPriceOfActiveItems, getPriceOfGroups, getPriceOfOptionals } from "../../redux/selectors";
import { BUTTON_APPEARANCE, BUTTON_WIDTH } from "../../components/button/Button";
import RoutesConnector from "../../components/routesConnector/RoutesConnector";
import PortalConnector from "../../components/portalConnector/PortalConnector";
import Modal from '../../components/modal/Modal';
import Icon from '../../components/icon/Icon';

// lazy connect
const GMap = React.lazy(() =>
  import('../../components/gmap/GMap').then(module => ({default: module.GMapInDA}))
);

function DigitalOfferContent({...props}) {
  const urlParams = new URLSearchParams(window.location.search);

  const [selectedOfferItemId, setSelectedOfferItemId] = useState(null);
  const [selectedMapId, setSelectedMapId] = useState(null);
  const [errorMessage, setErrorMessage] = useState(urlParams.get('error'));

  /**
   * will be called from markerCallback by clicking a mapMarker.
   * @param itemId
   */
  function updateOfferItem(itemId) {
    setSelectedOfferItemId(itemId)
  }

  /**
   * will be called from offerItemCallback by clicking an offerItem
   * @param itemId
   */
  function updateMapContent(itemId) {
    setSelectedMapId(itemId)
  }

  /**
   * render offerItems in Itemgroup
   * @param offerItems
   * @returns {*}
   */
  function renderOfferItems(offerItems) {
    return (
      offerItems.map((group, index) => {
        let groupKey = group.type + '-' + group.label.split(' ').join('_').toLowerCase();
        return (
          <ItemGroup group={group}
                     itemMap={props.itemMap}
                     key={groupKey}
                     groupIndex={index}
                     id={groupKey}
                     context={'offer'}
                     readOnly={group.readOnly}
                     withBackground={true}
                     callbackHandler={updateMapContent}
                     selectedItemId={selectedOfferItemId}
                     hasAlternatives={false} // TODO: make as Default?
          />
        );
      })
    );
  }

  /**
   * render journeyInfoBadge
   * @param journeyStart
   * @param journeyEnd
   * @returns {*}
   */
  function renderJourneyInfo(journeyStart, journeyEnd) {

    let badge = null;
    switch (getTravelPeriod(journeyStart, journeyEnd)) {

      case TRAVELPERIOD_CURRENT:
        badge = <Badge label={'AKTUELLE REISE'}/>;
        break;
      case TRAVELPERIOD_FUTURE:
        badge = <Badge label={'ZUKÜNFTIGE REISE'}/>;
        break;
      case TRAVELPERIOD_PAST:
        badge = <Badge label={'ABGESCHLOSSENE REISE'}/>;
        break;
      default:
        badge = null;
    }

    return badge && <div className={'offer-content__intro-badge'}>{badge}</div>;
  }

  /**
   * replace placeholder ##LINK-OPTIONALS## with LinkInternal-Component
   * @param text
   * @returns {*}
   */
  function renderInternalLink(text) {

    if (text.indexOf("##LINK-OPTIONALS##") !== -1) {
      const internalLink = <LinkInternal linkPath={'tips'}
                                         label={'Buchbare Ausflüge'}/>;
      const introList = text.split('##LINK-OPTIONALS##');
      return (
        <span className={'offer-content__offer--display-inline'}>
                    <span dangerouslySetInnerHTML={{__html: introList[0]}}/>
                        „{internalLink}“
                    <span dangerouslySetInnerHTML={{__html: introList[1]}}/>
                </span>
      )
    } else {
      return <span dangerouslySetInnerHTML={{__html: text}}></span>
    }
  }

  /**
   * render IntroTextBlock
   * @param isLoading
   * @returns {*}
   */
  function renderIntroText(isLoading) {

    if (isLoading) {

      return <Preloader type={'text'} additionalClasses={'box'}/>;

    } else {

      return <div className={'offer-content__intro box'}>
        {/*Show JourneyInfoBadge*/}
        {renderJourneyInfo(props.journeyStart, props.journeyEnd)}

        {props.introductionHeadline &&
          <div className={'offer-content__intro-headline h2 ts-handwriting'}>
            {props.introductionHeadline}
          </div>
        }

        {props.introductionText &&
          <div className={'offer-content__intro-text'}>
            {renderInternalLink(props.introductionText)}
          </div>
        }

        {/*Show offerNumber & printOfferButton*/}
        <OfferInfo offerNumber={props.offerNumber}/>

        <PortalConnector/>

        <RoutesConnector/>

        {/*Show currentOfferButton */}
        <CurrentOffer additionalClasses={'offer-content__current-offer-btn'} showRevisionHint={true}/>

      </div>
    }
  }

  /**
   * return BookingBox Buttons (optionalsButton is conditional)
   * @param hasOptionals
   * @returns {*[]}
   */
  function renderBookingBoxButtons(hasOptionals) {
    const list = []
    hasOptionals && list.push(<ButtonInternal linkPath={'tips'}
                                              label={'Ausflüge ansehen'}
                                              appearance={BUTTON_APPEARANCE.SECONDARY}
                                              width={BUTTON_WIDTH.FULL_WIDTH}
                                              key={'optionals-button'}
    />);
    list.push(<CurrentOffer hasCheckoutButton={true} key={'offer-button'}/>);

    return list
  }

  /**
   * render OfferBlock
   * @param isLoading
   * @returns {*}
   */
  function renderOfferBlock(isLoading) {

    if (isLoading) {
      return <Preloader type={'logo'}/>;
    } else {
      return <>
        {/*Show OfferItems*/}
        {props.offerItems && renderOfferItems(props.offerItems)}

        {/*Show PriceGuarantee*/}
        <PriceGuarantee/>

        {/*Show bookingBox*/}
        <BookingBox totalPrice={props.totalPrice} optionalPrice={props.optionalPrice}>
          {renderBookingBoxButtons(props.hasOptionals)}
        </BookingBox>
      </>
    }
  }

  return (
    <>
      <div className={'offer-content'}>
        {errorMessage &&
          <Modal size='small' onClose={() => {
            setErrorMessage('')
          }}>
            <h2 className={'error-message__h2'}><Icon name={'warning'} additionalClasses={'error-message__icon'}/>Es ist
              ein Fehler aufgetreten</h2>
            <br/>
            <p>{errorMessage}</p>
          </Modal>
        }


        <div className='offer-content__main'>
          {/*Show IntrotextBlock*/}
          {renderIntroText(props.isLoading)}

          {/*Show ContactBox*/}
          <ContactBoxContainer/>

          {/*Show Participants*/}
          <Participants hasEditIcon={true}/>

          {/*Show offerItemsBlock*/}
          {props.hasLoadingError ?
            <ErrorMessage type={'loadingError'}/>
            :
            renderOfferBlock(props.isLoading)
          }

        </div>

        <div className='offer-content__side'>
          <ErrorBoundary>
            <Suspense fallback={<Preloader/>}>
              {/*Show Gmap*/}
              <GMap callbackHandler={updateOfferItem}
                    selectedItemId={selectedMapId}
                    mapType={'offerMap'}
              />
            </Suspense>
          </ErrorBoundary>
        </div>
      </div>
    </>
  )
}

DigitalOfferContent.propTypes = {

  /**
   * @example: '2019-06-29'
   */
  journeyStart: PropTypes.string,

  /**
   * @example: '2019-07-3'
   */
  journeyEnd: PropTypes.string,

  /**
   * @example: 'HAM-1339657-BSC'
   */
  offerNumber: PropTypes.string,

  /**
   * @example: 'MTRQTHAM-1339657.3-BSC'
   */
  offerToken: PropTypes.string,

  /**
   * list of offerItems
   */
  offerItems: PropTypes.array,

  /**
   * total amount of prices
   */
  totalPrice: PropTypes.number,

  /**
   * headline of introtext
   */
  introductionHeadline: PropTypes.string,

  /**
   * copy of introtext
   */
  introductionText: PropTypes.string,

  /**
   * is loading
   */
  isLoading: PropTypes.bool,

  /**
   * has loadingError
   */
  hasLoadingError: PropTypes.bool,

  /**
   * has offer route hints
   */
  hasRouteHints: PropTypes.bool,

};

const mapStateToProps = (state, ownProps) => {

  return {
    journeyStart: state.globalStateManagement.journeyStart,
    journeyEnd: state.globalStateManagement.journeyEnd,
    offerNumber: state.globalStateManagement.offer.offerNumber,
    offerToken: state.globalStateManagement.offer.offerToken,
    isAuthenticated: state.globalStateManagement.isAuthenticated,
    isMainClient: state.globalStateManagement.isMainClient,
    hasRouteHints: state.routeHintManagement.hasRouteHints,
    hasOptionals: state.optionalsManagement.optionalItems?.length > 0,
    itemMap: state.itemManagement.itemMap,
    offerItems: state.offerContentManagement.offerItems,
    totalPrice: getPriceOfGroups(state.offerContentManagement.offerItems) + getPriceOfActiveItems(state.itemManagement.itemMap),
    optionalPrice: getPriceOfOptionals(state.itemManagement.itemMap),

    introductionHeadline: state.offerContentManagement.introductionHeadline,
    introductionText: state.offerContentManagement.introductionText,

    bookable: state.globalStateManagement.permissions && state.globalStateManagement.permissions.bookable,
    bookableReason: state.globalStateManagement.permissions && state.globalStateManagement.permissions.bookableReason,

    ...state.offerContentManagement
  }
};

export default connect(mapStateToProps)(DigitalOfferContent)
