const convertScript = (callBack) => {
  let script = document.querySelector("script[data-convert]");
  if (!script) {
    script = document.createElement("script");
    script.src = '//cdn-4.convertexperiments.com/v1/js/10042735-10044781.js'; // whatever url you want here
    script.type = "text/javascript";
    script.setAttribute('data-convert', true);
    script.async = true;
    script.onload = () => {
      callBack()
    };
    script.onerror = () => {
      callBack()
    }
    document.head.appendChild(script);
  }
}
export {
  convertScript
};
