import React from 'react';
import './OfferInfo.scss'
import PropTypes from "prop-types";
import OfferPrintSelect from "../offerDownloadConfigurator/OfferPrintSelect";
import {Tooltip} from "../tooltip/Tooltip";

function OfferInfo(props) {
  if (!props.offerNumber) {
    return null;
  }
  const printable = typeof(window.print) === 'function';
  return (
    <div className={'offer-info'}>
      {!!props.offerNumber &&
      <div className={'offer-info__offer-number'}>
        <div className={'offer-info__offer-number-label ts-bold'}>Buchungsnummer</div>
        <div className={'offer-info__offer-number'}>{props.offerNumber}</div>
      </div>
      }

      {!props.hidePrintButton &&
      <div className='offer-info__offer-print-select'>
        <Tooltip content={'Die Druckfunktion ist für dieses Mobilgerät leider nicht verfügbar. Bitte benutzen Sie einen anderen Browser (z. B. Google Chrome).'}
                 direction={'top'}
                 toggle = {true}
                 inactive={printable}>
        <OfferPrintSelect buttonWidth={'fixed'} disableModal={!printable}/>
        </Tooltip>
      </div>
      }
    </div>
  );
}


OfferInfo.propTypes = {
  offerNumber: PropTypes.string,
  hidePrintButton: PropTypes.bool
};

export {
  OfferInfo as default,
  OfferInfo
}



