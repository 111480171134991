import React, {useEffect} from 'react';
import ContactBox from "./ContactBox";
import {connect} from "react-redux";
import {
	dispatchFetchData,
	FETCH_TYPE_CONTACT,
} from "../../redux/actions/fetchAction";

function ContactBoxContainer({dispatchFetchData,offerNumber, offerToken,isDigitalOfferLegacy, ...props}) {
	useEffect(() => {
		dispatchFetchData(isDigitalOfferLegacy);
	}, [dispatchFetchData, offerNumber, offerToken, isDigitalOfferLegacy]);

	return <ContactBox {...props}/>
}

const mapStateToProps = (state, ownProps) => {

	return {
		offerNumber: state.globalStateManagement.offer.offerNumber,
		offerToken: state.globalStateManagement.offer.offerToken,
		isDigitalOfferLegacy: state.globalStateManagement.environment === 'digitalOffer',
		...state.contactManagement
	}
};

export default connect(mapStateToProps, {dispatchFetchData: (isLegacy)=>dispatchFetchData(FETCH_TYPE_CONTACT, false, {}, isLegacy)})(ContactBoxContainer);
