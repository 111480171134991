import React from 'react';
import PropTypes from 'prop-types';
import './Badge.scss'
import classNames from "classnames";
import {decodeHTMLEntities} from "../../../vendor/utils/HTMLUtils";
import {Icon} from "../icon/Icon";

function Badge (props){

	const classes = classNames(
		'badge',
		{'badge--small': props.size === 'small',
		'badge--inverted': props.inverted},
		props.additionalClasses,
		props.type
	);

	const icon = props.icon && <Icon additionalClasses={'badge__icon'} name={props.icon}/>;

	return (
		<div className={classes}>
			{icon}
			{decodeHTMLEntities(props.label)}
		</div>
	)

}

Badge.propTypes = {

	/**
	 * Label of the badge
	 */
	label: PropTypes.string.isRequired,

	/**
	 * the type of the badge will define its color.
	 * @default: 'default'
	 */
	type: PropTypes.oneOf(['default','important', 'signal', 'white' ]),

	/**
	 * Icon
	 *
	 * Visit http://localhost:6060/#icon for more infos.
	 *
	 * @example: 'pdf'
	 */
	icon: PropTypes.string,

	/**
	 * set true if background should be filled
	 */
	inverted: PropTypes.bool,

	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string,


	size:PropTypes.oneOf(['small', 'default'])
};

Badge.defaultProps = {
	type: 'default',
	size: 'default'
};

export {
	Badge as default,
	Badge
}
