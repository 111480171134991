import React, {Component} from 'react';
import {BUTTON_APPEARANCE, Button} from "../components/button/Button";
import {storageManager} from "../services/StorageManager";

class DisableNotificationsReload extends Component {

  constructor(props) {
    super(props);
    this.onClick = this.onClick.bind(this);


    this.state = {
      disabled: storageManager.disableNotifications
    };
  }

  onClick() {

    this.setState({
      disabled: !this.state.disabled
    })

    storageManager.disableNotifications = !this.state.disabled;

    window.location.reload();
  }

  render() {

    const label = this.state.disabled ? 'Enable Notifications & reload' : 'Disable Notifications & reload';
    const appearance = this.state.disabled ? BUTTON_APPEARANCE.SECONDARY : BUTTON_APPEARANCE.PRIMARY;

    return (
      <>
        <Button onClick={this.onClick} label={label} width={"fit"} appearance={appearance}/>
      </>
    );
  }
}

export {
  DisableNotificationsReload as default,
  DisableNotificationsReload
}
