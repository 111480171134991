import React, {useEffect, useState, useRef} from 'react';
import './TotalPrice.scss';
import {connect} from "react-redux";
import {currencyFormat} from "../../../vendor/utils/Utils";
import classNames from "classnames";
import Badge from "../badge/Badge";

function PriceBar(props) {
  const classes = classNames(
    'price-bar h2',
    props.additionalClasses
  );
  return (
    <div className={classes}>
				<span className={'price-bar__label'}>
					<span className={'price-bar__mainlabel'}>{props.label}</span>
          {props.sublabel &&
          <span className={'price-bar__sublabel ts-copy-small'}>{props.sublabel}</span>}
					</span>
      {!!props.price &&
      <div className={'price-bar__price'}>{currencyFormat(props.price)}</div>
      }
    </div>
  )
}


function TotalPrice(props) {
  const [hideOnStart, setHideOnStart] = useState(true);
  const [showPriceBar, setShowPriceBar] = useState(false);

  const timerRef = useRef(null);

  useEffect(() => {

    if (!hideOnStart) {
      clearTimeout(timerRef.current);
      setShowPriceBar(true);
      timerRef.current = setTimeout(() => {
        setShowPriceBar(false);
      }, 5000);
    }
    setHideOnStart(false)

    return () => clearTimeout(timerRef.current);
  }, [props.price, hideOnStart]);

  const classes = classNames(
    {'price-bar--slide-in': showPriceBar},
    {'price-bar--border-top perforation-bottom': !!props.isDigitalOffer},
    props.additionalClasses
  );

  return (
    <div className={'total-price__container'}>
      {!!props.optionalPrice &&
      <PriceBar label={'Zugebuchte Leistungen'} price={props.optionalPrice} additionalClasses={'price-bar--optional'}/>}
      <PriceBar additionalClasses={classes} label={props.label} sublabel={props.sublabel} price={props.price}/>
      {(props.isDigitalOffer && props.isBookable) &&
      <div className={'total-price__last-call'}>
        <div className={'total-price__last-call-headline'}>
          <Badge label={'Wichtiger Hinweis'} type={'signal'} additionalClasses={'total-price__last-call-badge'}/>
          <b>Buchen Sie jetzt, um die Preise und Verfügbarkeiten zu
            sichern!</b>
        </div>
        <div className={'total-price__last-call-subline'}>Da sich die Verfügbarkeiten und Preise aller angebotenen
          Leistungen jederzeit ändern können, freuen wir
          uns
          über eine schnelle Rückmeldung.
        </div>
      </div>
      }

    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    isDesktop: state.globalStateManagement.isDesktop,
    isDigitalOffer: state.globalStateManagement.environment === "digitalOffer",
    isBookable: state.globalStateManagement.permissions?.bookable
  }
};

const ConnectedTotalPrice = connect(mapStateToProps)(TotalPrice);

export {
  ConnectedTotalPrice as default,
  TotalPrice,
  PriceBar
}

