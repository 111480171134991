import React, {Component} from 'react';
import './TravelTime.scss'
import PropTypes from "prop-types";

//HELPER
import classNames from "classnames";
import {getSmallPaddedFormatDate} from "../../../vendor/utils/DateUtils";

//COMPONENTS
import {Icon} from "../icon/Icon";

class TravelTime extends Component {

	render() {

		const classes = classNames(
			'travel-time',
			this.props.additionalClasses,
		);

		return (
			<div className={classes}>
				<div className='travel-time__headline h2 ts-handwriting'>Ihre persönliche Traumreise</div>
				<div className='travel-time__date'>
					<Icon additionalClasses={'travel-time__icon'} name={'calendar'}/>
					{getSmallPaddedFormatDate(this.props.journeyStart)} - {getSmallPaddedFormatDate(this.props.journeyEnd)}
				</div>
			</div>
		)
	}
}

TravelTime.propTypes = {

	/**
	 * REQUIRED: String needs to be in ISO-8601 standard
	 */
	journeyStart: PropTypes.string.isRequired,

	/**
	 * REQUIRED: String needs to be in ISO-8601 standard
	 */
	journeyEnd: PropTypes.string.isRequired
};

export {
	TravelTime as default,
	TravelTime
}
