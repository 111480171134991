import {DataProviderService} from "../../services/DataProviderService";
import {dispatchIsAuthenticated} from "./globalStateManagementAction";

export const OFFER_MAP = 'OFFER_MAP';
export const ROUTE_MAP = 'ROUTE_MAP';
export const FETCH_TYPE_MAP = 'FETCH_TYPE_MAP';
export const REQUEST_DA_MAP_DATA = 'REQUEST_DA_MAP_DATA';

export const dispatchOfferMap = (data) => ({
  type: OFFER_MAP,
  data,
});

export const dispatchRouteMap = (data) => ({
  type: ROUTE_MAP,
  data,
});

export const dispatchGetDAMapData = () => ({
  type: REQUEST_DA_MAP_DATA
});


/**
 * do something specific for different kinds of errors
 * @param errorStatus
 */
const handleError = (dispatch, errorStatus, fetchType) => {

  switch (errorStatus) {
    case 401:
      dispatch(dispatchIsAuthenticated(false));
      break;

    case "isCanceled":
      //do nothing here
      break;

    default:
      console.warn("error with code ", errorStatus);
      dispatch(dispatchOfferMap({hasLoadingError: true, errorMessage: errorStatus}, fetchType));
  }
};

const getMapData = (offerNumber) => {

  return dispatch => {
    const dataProviderConfig = {
      endpoint: 'portal/maps',
      useMock: false,
      useCache: false
    }

    const dataProvider = new DataProviderService(dataProviderConfig);
    dataProvider.getData({offerNumber}).then(response => {
      if (response.internalType) {
        handleError(dispatch, response.message, FETCH_TYPE_MAP);
      } else {
        dispatch(dispatchOfferMap({...response, loadingComplete: true}));
      }
    })
  }
};

export const dispatchGetPortalMapData = (offerNumber) => {
  return (dispatch) => {
    return dispatch(getMapData(offerNumber));
  }
};

