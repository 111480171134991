import React, {useEffect} from 'react';
import './DocumentsItems.scss'
import ItemGroup from "../../../components/itemGroup/ItemGroup";
import {Preloader} from "../../../components/preloader/Preloader";
import {connect} from "react-redux";
import {ErrorMessage} from "../../../components/error/ErrorMessage";
import {
	dispatchFetchData,
	FETCH_TYPE_DOCUMENTS_ITEMS
} from "../../../redux/actions/fetchAction";

function DocumentsItems ({dispatchFetchData, ...props}) {

	useEffect(() => {
		dispatchFetchData();
	}, [dispatchFetchData,props.offerNumber]);

	if (props.hasLoadingError) {
		return (
			<ErrorMessage type={'loadingError'}/>
		)
	}else if (!props.loadingComplete) {

		return <Preloader type={'logo'}/>;
	}

	return (
		<div className='documents-items'>
			{props.list.map((group, index) => {
				return (
					<ItemGroup
						group={group}
						key={`group-${index}`}
						id={`group-${index}`}
						context={'documents'}
					/>
				);
			})}
		</div>
	)
}


const mapStateToProps = state => {

	return {
		offerNumber: state.globalStateManagement.offer.offerNumber,
		...state.documentsItemsManagement
	};
};

export default connect(mapStateToProps,{dispatchFetchData: ()=>dispatchFetchData(FETCH_TYPE_DOCUMENTS_ITEMS)})(DocumentsItems);
