import {DataProviderService} from "../../services/DataProviderService";
import {
  dispatchIsAgencyOffer,
  dispatchIsAuthenticated,
  dispatchIsMainClient,
  dispatchPermissions,
  dispatchIsBooked,
  dispatchUpdateJourneyDates,
  dispatchUpdateOffer,
  dispatchUpdateStageImage,
  dispatchShowNotificationMessage,
} from "./globalStateManagementAction";
import {
  getParsedContact,
  getParsedGroupOfferItems,
  getParsedMap,
  getParsedOfferItems,
  getParsedParticipants,
  getParsedRevisionList,
  getParsedRouteItems
} from "../../../vendor/utils/DigitalOfferContentUtils";
import {dispatchHasRouteHints} from "./routeHintManagementAction";
import {storageManager} from "../../services/StorageManager";
import {
  dispatchAddItems,
  dispatchAddRouteItems,
  dispatchUpdateAllNotices,
} from "./itemManagementAction";
import {dispatchOfferMap, dispatchRouteMap} from "./mapManagementAction";
import {getErrorText} from "../../../vendor/utils/Mappings";

export const DATA_UPDATE = 'DATA_UPDATE';

export const FETCH_TYPE_INVOICE = 'FETCH_TYPE_INVOICE';
export const FETCH_TYPE_OFFER_CONTENT = 'FETCH_TYPE_OFFER_CONTENT';
export const FETCH_TYPE_CONTACT = 'FETCH_TYPE_CONTACT';
export const FETCH_TYPE_OPTIONALS = 'FETCH_TYPE_OPTIONALS';
export const FETCH_TYPE_DOCUMENTS_COMMON = 'FETCH_TYPE_DOCUMENTS_COMMON';
export const FETCH_TYPE_DOCUMENTS_ITEMS = 'FETCH_TYPE_DOCUMENTS_ITEMS';
export const FETCH_TYPE_PARTICIPANTS = 'FETCH_TYPE_PARTICIPANTS';
export const FETCH_TYPE_USER_PROFILE = 'FETCH_TYPE_USER_PROFILE';
export const FETCH_TYPE_FOOTER = 'FETCH_TYPE_FOOTER';
export const FETCH_TYPE_MAP = 'FETCH_TYPE_MAP';

export const FETCH_TYPE_FAQ = 'FETCH_TYPE_FAQ';

export const FETCH_TYPE_DIGITAL_OFFER_CONTENT = 'FETCH_TYPE_DIGITAL_OFFER_CONTENT';
export const FETCH_TYPE_DIGITAL_OFFER_PARTIAL = 'FETCH_TYPE_DIGITAL_OFFER_PARTIAL';
export const FETCH_TYPE_DIGITAL_OFFER_OPTIONALS = 'FETCH_TYPE_DIGITAL_OFFER_OPTIONALS';
export const FETCH_TYPE_DIGITAL_OFFER_ROUTES = 'FETCH_TYPE_DIGITAL_OFFER_ROUTES';
export const FETCH_TYPE_DIGITAL_OFFER_REVISION_LIST = 'FETCH_TYPE_DIGITAL_OFFER_REVISION_LIST';
export const FETCH_TYPE_DIGITAL_OFFER_RENTERS = 'FETCH_TYPE_DIGITAL_OFFER_RENTERS';

const IS_AGENCY_OFFER_MOCK = true;

const ENDPOINT_CONFIG_MAP = {
  FETCH_TYPE_INVOICE: {
    endpoint: 'portal/offer/invoice'
  },
  FETCH_TYPE_CONTACT: {
    useCache: true,
    useMock: false,
    endpoint: 'sales/contact'
  },
  FETCH_TYPE_OFFER_CONTENT: {
    endpoint: 'portal/offer/items'
  },
  FETCH_TYPE_OPTIONALS: {
    useMock: false,
    endpoint: 'portal/offer/optionals'
  },
  FETCH_TYPE_DOCUMENTS_COMMON: {
    useMock: false,
    endpoint: 'portal/offer/documents/common'
  },
  FETCH_TYPE_DOCUMENTS_ITEMS: {
    useMock: false,
    endpoint: 'portal/offer/documents/items'
  },
  FETCH_TYPE_PARTICIPANTS: {
    endpoint: 'portal/offer/participants',
    useMock: false
  },

  FETCH_TYPE_USER_PROFILE: {
    endpoint: 'portal/user',
    useCache: true
  },
  FETCH_TYPE_FOOTER: {
    useMock: false,
    useCache: true,
    endpoint: 'portal/footer'
  },
  FETCH_TYPE_FAQ: {
    useCache: true,
    endpoint: 'portal/faq'
  },
  FETCH_TYPE_MAP: {
    endpoint: 'portal/maps'
  },
  FETCH_TYPE_DIGITAL_OFFER_CONTENT: {
    useMock: false,
    useLegacyAPI: true, // POST REQUESTS
    endpoint: 'offer.php/portal-canusa/get'
  },
  FETCH_TYPE_DIGITAL_OFFER_PARTIAL: {
    useMock: false,
    useLegacyAPI: true, // POST REQUESTS
    endpoint: 'offer.php/portal-canusa/get'
  },
  FETCH_TYPE_DIGITAL_OFFER_ROUTES: {
    useMock: false,
    useLegacyAPI: true, // POST REQUESTS
    endpoint: 'offer.php/portal-canusa/getRouteHints'
  },
  FETCH_TYPE_DIGITAL_OFFER_RENTERS: {
    useMock: false,
    useLegacyAPI: true, // POST REQUESTS
    endpoint: 'offer.php/portal-canusa/getRenter'
  },
};

/**
 * creates DATA_UPDATE action which is received by generic data reducers
 *
 * @param data data which should be set
 * @param fetchType one of the defined fetchType Constants
 * @return {{data: *, fetchType: {string}, type: {string}}}
 */
export const dispatchUpdateData = (data, fetchType) => ({
  type: DATA_UPDATE,
  data: data,
  fetchType: fetchType
});

export const fetchData = (fetchType, silent, data, isLegacy) => {

  return dispatch => {

    if (!silent) {
      dispatch(dispatchUpdateData({isLoading: true}, fetchType));
    }

    if (isLegacy) {
      let responseData = {};
      responseData.isLoading = false;
      responseData.loadingComplete = true;
      dispatch(dispatchUpdateData(responseData, fetchType));
      return;
    }

    const dataProvider = new DataProviderService(ENDPOINT_CONFIG_MAP[fetchType]);

    return dataProvider.getData(data)
      .then((response) => {
        if(response.error) {
          handleError(dispatch, 'unknown', fetchType, response.error);
          throw new Error(response.error);
        }

        if (response.invalidate_session) {
          storageManager.authToken = null;
          dispatch(dispatchIsAuthenticated(false));
          dispatch(dispatchShowNotificationMessage(getErrorText('invalidate')));
        }

        if (!response.internalType) {

          let responseData = {};

          if (Array.isArray(response)) {
            responseData = {list: response}
          } else {
            responseData = {...response};
          }

          // TODO this is dirty, please find central solution
          if (response.offerToken) {
            storageManager.realOfferToken = response.offerToken;
          }

          // dispatch only if boolean is not undefined
          if (typeof responseData.hasRouteHints !== 'undefined') {
            dispatch(dispatchHasRouteHints(responseData.hasRouteHints));
          }

          if (fetchType === FETCH_TYPE_DIGITAL_OFFER_CONTENT) {
            const revisionList = response.revisions;
            let currentOffer = revisionList.find(revision => revision.offerToken === storageManager.offerToken) || revisionList[revisionList.length - 1]
            dispatch(dispatchUpdateStageImage(response.headerImages[0].replace(/^\/+/, '')));
            const isAuthenticatedMainClient = response.isMainClient && !!storageManager.authToken
            dispatch(dispatchIsAuthenticated(isAuthenticatedMainClient));
            dispatch(dispatchIsMainClient(isAuthenticatedMainClient));
            dispatch(dispatchIsAgencyOffer(responseData.isAgencyOffer ?? IS_AGENCY_OFFER_MOCK));
            dispatch(dispatchUpdateOffer(currentOffer));
            dispatchActionsWithLegacyData(dispatch, {...responseData});

            dispatch(dispatchOfferMap({...getParsedMap(responseData)}))

            let offerItems = getParsedOfferItems(responseData, true);
            dispatch(dispatchAddItems(offerItems));
            let content = getParsedGroupOfferItems(responseData, true);
            dispatch(dispatchUpdateData({
              loadingComplete: true,
              optionalItems: content.optionalItems,
              isLoading: false,
              hasLoadingError: false,
            }, FETCH_TYPE_DIGITAL_OFFER_OPTIONALS));

            dispatch(dispatchUpdateData({
              introductionHeadline: content.introductionHeadline,
              introductionText: content.introductionText,
              introductionRouteHintText: content.introductionRouteHintText,
              loadingComplete: true,
              offerItems: content.offerItems,
              isLoading: false,
              hasLoadingError: false,
            }, FETCH_TYPE_DIGITAL_OFFER_CONTENT));
            return;
          } else if (fetchType === FETCH_TYPE_DIGITAL_OFFER_PARTIAL) {
            const revisionList = response.revisions;
            let currentOffer = revisionList.find(revision => revision.offerToken === storageManager.offerToken) || revisionList[revisionList.length - 1]
            dispatch(dispatchPermissions(responseData.permissions));
            dispatch(dispatchIsMainClient(responseData.isMainClient));
            dispatch(dispatchIsAgencyOffer(responseData.isAgencyOffer ?? IS_AGENCY_OFFER_MOCK));
            dispatch(dispatchUpdateData(responseData.offerContact, FETCH_TYPE_USER_PROFILE));
            dispatch(dispatchUpdateData({...getParsedRevisionList(responseData)}, FETCH_TYPE_DIGITAL_OFFER_REVISION_LIST));
            dispatch(dispatchUpdateOffer(currentOffer));
            dispatch(dispatchUpdateData({
              ...getParsedParticipants(responseData)
            }, FETCH_TYPE_PARTICIPANTS));
            dispatch(dispatchUpdateAllNotices(getParsedOfferItems(responseData, true)));
					} else if (fetchType === FETCH_TYPE_DIGITAL_OFFER_ROUTES) {
            let offerItems = getParsedOfferItems(responseData, true);
            dispatch(dispatchAddRouteItems(offerItems));

            dispatch(dispatchRouteMap({...getParsedMap(responseData)}));
            responseData = getParsedRouteItems(responseData);
          }

          responseData.isLoading = false;
          responseData.loadingComplete = true;

          dispatch(dispatchUpdateData(responseData, fetchType));


        } else if (response.internalType) {
          handleError(dispatch, response.internalType, fetchType)
        }
      })
      .catch(error => {
        console.warn("Error on fetching data: ", error);
      })
  }
};

const dispatchActionsWithLegacyData = (dispatch, responseData) => {
  dispatch(dispatchUpdateJourneyDates(responseData.dateFrom, responseData.dateTo));
  dispatch(dispatchIsBooked(responseData.isBooked));
  dispatch(dispatchPermissions(responseData.permissions));
  dispatch(dispatchUpdateData(responseData.offerContact, FETCH_TYPE_USER_PROFILE));
  dispatch(dispatchUpdateData({...getParsedContact(responseData)}, FETCH_TYPE_CONTACT));
  dispatch(dispatchUpdateData({...getParsedRevisionList(responseData)}, FETCH_TYPE_DIGITAL_OFFER_REVISION_LIST));
  dispatch(dispatchUpdateData({
    ...getParsedParticipants(responseData)
  }, FETCH_TYPE_PARTICIPANTS));
}

/**
 * do something specific for different kinds of errors
 * @param errorStatus
 */
const handleError = (dispatch, errorStatus, fetchType, errorMessage) => {

  switch (errorStatus) {
    case 401:
      dispatch(dispatchIsAuthenticated(false));
      break;

    case "isCanceled":
      //do nothing here
      break;

    default:
      dispatch(dispatchUpdateData({hasLoadingError: true, errorMessage}, fetchType));
  }

  console.warn(`error with code ${errorStatus} | ${fetchType}`);
};

/**
 * generic fetchData action
 *
 * @param fetchType| one of the defined fetchType constants
 * @param silent | set to true if isLoading should not be set in the data while loading
 * @return {function(*): *}
 */
export const dispatchFetchData = (fetchType, silent = false, data = {}, isLegacy = false) => {
  return (dispatch) => {
    return dispatch(fetchData(fetchType, silent, data, isLegacy))
  }
};
