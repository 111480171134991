import React from 'react';
import './RoutesConector.scss';
import classNames from "classnames";
import {BUTTON_WIDTH} from "../button/Button";
import {connect} from "react-redux";
import ButtonInternal from "../button/ButtonInternal";

function RoutesConnector(props){

	const classes = classNames(
		'routes-connector',
		props.additionalClasses
	);

	return (
		<>
			{props.hasRouteHints &&
			<div className={classes}>
				<div className={'routes-connector__columns'}>
					<div className={'routes-connector__hint-text'}>
						<p>Wissen Sie schon, welche Stationen Sie während Ihrer Reise besuchen möchten? Falls nicht,
							schauen Sie sich doch mal unseren Routenvorschlag an, der an Ihre Reisedauer angelehnt ist.
							Aber auch, wenn Ihre Planung schon steht, finden Sie vielleicht noch das ein oder andere,
							das für Sie interessant sein könnte. Schauen Sie doch einfach mal nach!</p>

					</div>

					<ButtonInternal linkPath={`/digitaloffer/${props.offerToken}/routes`}
								  width={BUTTON_WIDTH.FIXED}
								  appearance={'secondary'}
								  label={'Zum Routenvorschlag'}/>
				</div>
			</div>
			}
		</>
	)
}

const mapStateToProps = (state, ownProps) => {
	return {
		offerToken: state.globalStateManagement.offer.offerToken,
		hasRouteHints: state.routeHintManagement.hasRouteHints,
	}
};

export default connect(mapStateToProps)(RoutesConnector)
