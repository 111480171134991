import React, {useEffect} from 'react';
import './Userprofile.scss'
import {Modal} from "../modal/Modal";
import {LinkInternal} from "../link/LinkInternal";
import classNames from "classnames";
import PropTypes from "prop-types";
import {ButtonInternal} from "../button/ButtonInternal";
import {connect} from "react-redux";
import {dispatchFetchData, FETCH_TYPE_USER_PROFILE} from "../../redux/actions/fetchAction";
import {BUTTON_APPEARANCE, BUTTON_WIDTH} from "../button/Button";
import ErrorMessage from "../error/ErrorMessage";

function UserProfile({dispatchFetchData, ...props}) {

  useEffect(() => {

    dispatchFetchData(props.isDigitalOffer);

  }, [dispatchFetchData, props.offerNumber, props.isAuthenticated, props.isDigitalOffer]);

  const classes = classNames(
    'user-profile',
    props.additionalClasses,
  );

  if(props.hasLoadingError) {
    return (<Modal size='small' onClose={props.onClose}><ErrorMessage type={'loadingError'}/></Modal>);
  }

  if (props.isLoading || !props.loadingComplete) {
    return null;
  }

  const redirectPath = props.isDigitalOffer ?  `/digitaloffer/${props.offerToken}/start` : '/';
  const redirectOfferToken = props.isDigitalOffer && props.offerToken;
  const redirectState = {from:redirectPath,offerToken:redirectOfferToken}
  return (
    <Modal size='small' onClose={props.onClose}>
      <div className={classes}>
        <h3
          className={'user-profile__name ts-handwriting'}>{props.title} {props.firstname} {props.middlenames} {props.lastname}</h3>

        <div className="user-profile__offer-headline dotted-headline-small">Ihre Buchungsnummer</div>
        <div className='user-profile__offer-number ts-copy-medium-bold'>{props.offerNumber}</div>

        <ButtonInternal
          label='Abmelden'
          uid={'logout'}
          linkPath={{pathname: '/logout', state: redirectState}}
          width={BUTTON_WIDTH.FIXED}
          appearance={BUTTON_APPEARANCE.SECONDARY}
          additionalClasses='user-profile__button'
        />

        <LinkInternal
          label='Passwort ändern?'
          linkPath={{pathname: '/new-password', state: redirectState}}
          additionalClasses='password-forgot'
        />
      </div>
    </Modal>
  )
}

UserProfile.propTypes = {

  /**
   * additional css class to alter view if needed
   */
  additionalClasses: PropTypes.string,
  /**
   * title of person. e.g. Dr
   */
  title: PropTypes.string,
  /**
   * first name of a person
   */
  firstname: PropTypes.string,

  /**
   * persons middle names
   */
  middlenames: PropTypes.string,

  /**
   * persons last name
   */
  lastname: PropTypes.string,

  /**
   * offernumber e.g. HAM-a1b2c3d4-ABC
   */
  offerNumber: PropTypes.string,

  /**
   * indicates if content is still loading if its used with DataProvider
   */
  isLoading: PropTypes.bool,

  /**
   * closeHandler which is passed through to modal
   */
  onClose: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {

  return {
    offerNumber: state.globalStateManagement.offer.offerNumber,
    offerToken: state.globalStateManagement.offer.offerToken,
    isDigitalOffer: state.globalStateManagement.environment === 'digitalOffer',
    ...state.userProfileManagement
  }
};

export default connect(mapStateToProps, {dispatchFetchData: (isLegacy) => dispatchFetchData(FETCH_TYPE_USER_PROFILE, false, {}, isLegacy)})(UserProfile);

