export const HAS_ROUTE_HINTS = 'HAS_ROUTE_HINTS';
export const ROUTE_HINT_ID = 'ROUTE_HINT_ID';

export const dispatchHasRouteHints = hasRouteHints => ({
	type: HAS_ROUTE_HINTS,
	hasRouteHints: hasRouteHints
});

export const dispatchHintId = hintId => ({
	type: ROUTE_HINT_ID,
	hintId: hintId
});
