import React, {useEffect} from 'react';
import './DocumentsCommon.scss';
import {Badge} from "../../../components/badge/Badge";
import {Preloader} from "../../../components/preloader/Preloader";
import classNames from 'classnames';
import LinkPdf from "../../../components/link/LinkPdf";
import {removeUnusedStyles} from "../../../../vendor/utils/HTMLUtils";
import {ErrorMessage} from "../../../components/error/ErrorMessage";
import {connect} from "react-redux";
import {getExtraLinks, getFlightLinks} from "../../../redux/selectors";
import {PreloaderWrapper} from "../../../components/preloader-wrapper/PreloaderWrapper";
import {BUTTON_APPEARANCE, Button} from '../../../components/button/Button'
import OfferInfo from "../../../components/offerInfo/OfferInfo";
import {dispatchFetchData, FETCH_TYPE_DOCUMENTS_COMMON} from "../../../redux/actions/fetchAction";

function DocumentsCommon({dispatchFetchData, ...props}) {

  useEffect(() => {
    dispatchFetchData();
  }, [dispatchFetchData, props.offerNumber]);

  /**
   * create link list
   * @param links
   * @return {*}
   */
  function createDocumentLinks(links, useDownLoadNameAsLabel, optionalType) {

    return links.map((link, index) => {
      return (
        <div className='documents-common__link' key={link.id + '_' + index || link.downloadName + '_' + index}>
          <LinkPdf
            label={useDownLoadNameAsLabel ? link.downloadName : link.label}
            type={link.type || optionalType || 'unknown'}
            download={link.downloadName}
            url={link.url}
            icon={'file-pdf'}
            optionalTooltipDirection={props.isDesktop ? 'right' : 'top'}
          />
          {link.state && <Badge type={link.state.level} label={link.state.label}
                                additionalClasses={'documents-common__link-badge'}/>}
        </div>
      )
    })
  }

  const classes = classNames('documents-common', 'box', props.additionalClasses);

  if (props.hasLoadingError) {
    return (
      <ErrorMessage type={'loadingError'}/>
    )
  } else if (!props.loadingComplete) {
    return (
      <Preloader type={'text'} additionalClasses={classes}/>
    );
  }

  const linksExist = props.links || props.extraLinks || props.flightLinks;

  return (
    <div className={classes}>
      <div className={'documents-common__text_wrapper'}>
        <div className={'documents-common__headline h2 ts-handwriting'}>Ihre Reiseunterlagen</div>
        <div className='documents-common__text'
             dangerouslySetInnerHTML={{__html: removeUnusedStyles(props.text)}}>
        </div>
        {props.disclaimer &&
        <div className='documents-common__disclaimer ts-bold'>
          <span><Badge inverted={true} label={'Wichtiger Hinweis'} type={'signal'}/>{props.disclaimer}</span>
        </div>}
      </div>
      <OfferInfo offerNumber={props.offerNumber}/>

      {(props.documentsError) &&
      <div className='documents-common__error-wrapper'>
        <Button additionalClasses={'documents-common__reload-button'}
                label={'Reiseunterlagen abrufen'}
                icon={'warning'}
                appearance={BUTTON_APPEARANCE.IMPORTANT}
                width={'fit'}
                onClick={() => {
                  window.location.reload()
                }}
        />
        <p className={'documents-common__reload-text ts-bold'}>Beim Anfordern der Unterlagen ist ein Fehler
          aufgetreten.<br/>Bitte versuchen Sie es in ein paar Minuten erneut.</p>
      </div>
      }

      {(linksExist && !props.documentsError) &&
      <div className='documents-common__link-list'>
        <PreloaderWrapper>
          {props.links && createDocumentLinks(props.links, false, 'common')}
          {props.flightLinks && createDocumentLinks(props.flightLinks, true)}
          {props.extraLinks && createDocumentLinks(props.extraLinks, true)}
        </PreloaderWrapper>
      </div>}

      {(props.dispatchLoadDocuments) &&
      <Preloader type={'line'}/>
      }

    </div>
  );
}

const mapStateToProps = state => {

  return {
    offerNumber: state.globalStateManagement.offer.offerNumber,
    isDesktop: state.globalStateManagement.isDesktop,

    extraLinks: getExtraLinks(state.documentsItemsManagement.list),
    flightLinks: getFlightLinks(state.documentsItemsManagement.list),

    dispatchLoadDocuments: state.documentsItemsManagement.isLoading,
    documentsError: state.documentsItemsManagement.hasLoadingError,
    ...state.documentsCommonManagement
  };
};

export default connect(mapStateToProps, {dispatchFetchData: () => dispatchFetchData(FETCH_TYPE_DOCUMENTS_COMMON)})(DocumentsCommon);
