import {getDayPadded, getMonthPadded, getUtcSafeDate, toISODateStr} from "./DateUtils";
import {reformatFromLegacyDataModel} from "../../portal/components/gmap/DataParser";
import {checkTypeObject, deepCopyPureObject} from "./Utils";
import {IMAGE_DB_PREFIX} from "../../portal/components/image/ImageDecoder";

/**
 * returns parsed offerItems
 * @param response
 * @returns {{}}
 */
export const getParsedOfferItems = (response, isLegacyParser) => {
  return offerItemParser(deepCopyPureObject(response), isLegacyParser);
};


const isOptionalGroup = (groupType) => {
  return groupType === 'location_options' || groupType === 'location-options' || groupType === 'insurance_options';
}

export const getParsedGroupOfferItems = (response, isLegacyParser) => {
  let groupObject = {}

  const groupItems = getGroupItems(deepCopyPureObject(response.offerItems), isLegacyParser);
  if (isLegacyParser) {
    groupObject.introductionHeadline = "Ihre persönliche Traumreise";
    groupObject.introductionText = response.personalContactText;
    groupObject.introductionRouteHintText = 'Wissen Sie schon, welche Stationen Sie während Ihrer Reise besuchen möchten? Falls nicht, schauen Sie sich doch mal unseren Routenvorschlag an, der an Ihre Reisedauer angelehnt ist. Aber auch, wenn Ihre Planung schon steht, finden Sie vielleicht noch das ein oder andere, das für Sie interessant sein könnte. Schauen Sie doch einfach mal nach!';
    groupObject.offerItems = groupItems.filter(groupItem => !isOptionalGroup(groupItem.type));
    groupObject.optionalItems = groupItems.filter(groupItem => isOptionalGroup(groupItem.type));
  } else {
    groupObject.offerItems = groupItems;
  }
  return groupObject;
}

export const getParsedGroupOptionalItems = (response) => {
  let groupObject = {}
  groupObject.optionalItems = getGroupItems(deepCopyPureObject(response.optionalItems), false);
  return groupObject;
}

/**
 * returns parsed offerItems
 * @param response
 * @returns {{}}
 */
export const getParsedRouteItems = (response) => {
  let newResponse = {};
  newResponse.offerItems = getGroupItems(deepCopyPureObject(response.offerItems), true);
  newResponse.headline = 'Unsere Routenvorschläge für Sie';
  newResponse.text = 'Anbei finden Sie eine mögliche Route für Ihre Wohnmobil-Reise, die Sie zu den Highlights der Region führt und an die Länge Ihrer Reise angelehnt ist. Die Anzahl der Nächte dient Ihnen als Orientierungshilfe dafür, wie viel es an dieser Station der Route zu sehen gibt - je mehr Nächte, desto mehr gibt es zu erleben.';
  newResponse.additionalText = 'Dies ist noch nicht der perfekte Vorschlag für Ihre Reise? Dann schauen Sie mal unten im Auswahlmenü - dort finden Sie weitere Routen, die für Sie interessant sein könnten. Durch einen Klick auf einen der Vorschläge öffnet sich dieser und Sie können ihn sich in Ruhe anschauen und die Routen miteinander vergleichen.';
  newResponse.hintList = response.hintList;
  return newResponse;
};

/**
 * returns parsed revisionList
 * @param response
 * @returns {*}
 */
export const getParsedRevisionList = (response) => {
  return {
    revisionList: response.revisions,
    revisionNumber: response.offerNumber
  };
};

/**
 * returns parsed participants
 * @param response
 * @returns {*}
 */
export const getParsedParticipants = (response) => {
  let newResponse = {};

  if (response.offerParticipants) {
    newResponse.list = Object.keys(response.offerParticipants).map((key) => {
      const obj = response.offerParticipants[key];
      const returnObj = {};
      returnObj.id = key;
      obj.firstname && (returnObj.firstname = obj.firstname);
      obj.middlenames && (returnObj.middlenames = obj.middlenames);
      obj.lastname && (returnObj.lastname = obj.lastname);
      obj.birthdate && (returnObj.birthdate = obj.birthdate);
      obj.gender && (returnObj.gender = obj.gender);
      obj.pending_change_by_customer && (returnObj.pending_change_by_customer = obj.pending_change_by_customer);

      return returnObj;
    });
  }

  return newResponse;
};

/**
 * returns parsed contact
 * @param response
 * @returns {*}
 */
export const getParsedContact = (response) => {
  if(!response?.salesperson) return null;
  let newResponse = {};
  const obj = response.salesperson;
  const re = /(?<phone>.+?\d+)\s+\((?<openHours>.+?)\)/;
  const phone = obj?.rufnummer.match(re) && obj.rufnummer.match(re).groups.phone;
  const openHours = obj?.rufnummer.match(re) && obj.rufnummer.match(re).groups.openHours;

  newResponse.fullName = obj.name;
  newResponse.email = obj.email;
  newResponse.phone = phone || obj.rufnummer;
  if (obj.foto) {
    const image = obj.foto.replace('.gray', '');
    newResponse.image = {
      url: IMAGE_DB_PREFIX + image,
      alt: ((obj.geschlecht === 'm') ? 'CANUSA Mitarbeiter ' : 'CANUSA Mitarbeiterin ') + obj.name
    };
  }

  newResponse.openHours = openHours;

  if( obj.geschlecht && obj.position) {
    newResponse.label = ((obj.geschlecht === 'm') ? 'Ihr persönlicher ' : 'Ihre persönliche ') + obj.position;
  }

  return newResponse;
};

/**
 * returns new GMap Datamodel from legacy data
 * @param response
 * @returns {any}
 */
export const getParsedMap = (response) => {

  let newResponse = {};
  if (response.offerMap) {
    newResponse = {
      path: response.offerMap.path,
      markers: response.offerMap.markers,
      mapCenter: response.offerMap.mapCenter
    };
    return reformatFromLegacyDataModel(newResponse);
  } else {
    newResponse.hasLoadingError = true;
    return newResponse;
  }
};

export const getConvertedItem = (itemMap, itemObject) => {
  let item = (itemMap && !checkTypeObject(itemObject)) ? itemMap[itemObject] : itemObject;
  let itemId = (itemMap && !checkTypeObject(itemObject)) ? itemObject : item.id;

  return {item, itemId}
}


export const checkIfCurrentRevision = (offerNumber, revisionList) => {

  let current = {offerNumber};
  if (revisionList) {
    current = revisionList.find((item) => {
      return item.currentVersion;
    })
  }
  return current.offerNumber === offerNumber;
}


export const getNumbersInLetters = (number) => {

  return numberMap[number] || number;
}


/**
 * #####################################################################
 *     __ __    __               ____              __  _
 *    / // /__ / /__  ___ ____  / __/_ _____  ____/ /_(_)__  ___  ___
 *   / _  / -_) / _ \/ -_) __/ / _// // / _ \/ __/ __/ / _ \/ _ \(_-<
 *  /_//_/\__/_/ .__/\__/_/   /_/  \_,_/_//_/\__/\__/_/\___/_//_/___/
 *            /_/
 * #####################################################################
 */

/**
 * because of itemmapping every item needs to have an id, if it doesn't have an id, this function generates it
 *
 * @param item
 * @param index
 * @return {string}
 */
const getItemId = (item, index, groupIndex) => {
  let validId;
  if (!!item.id && item.id !== 0) {
    validId = getValidIdSelector(item.id)
  } else {
    // item.id is missing on routes and return items
    const tempId = (item.name || item.date).toString() + '-' + index + '-' + groupIndex;
    validId = getValidIdSelector(tempId);
  }

  return validId;
}

/**
 * replace items array in group with an array containing just the ids of the original items
 *
 * @param groupItems
 * @param isLegacyParser
 * @return {*}
 */
const getGroupItems = (groupItems, isLegacyParser) => {

  groupItems.forEach((groupItem, groupIndex) => {
    if (isLegacyParser) {
      delete Object.assign(groupItem, {'label': groupItem['name']})['name'];
      groupItem.type = typeMap[groupItem.type];
    }
    // TODO: check for setting groupContentTypes in parser
    if (groupItem.type === typeMap.location_options) {
      groupItem.contentType = 'optional'
    }
    let groupItemIds = [];
    let pushedItemIndex = 0;
    groupItem.items.forEach((item) => {
      groupItemIds.push(isLegacyParser ? getItemId(item, pushedItemIndex, groupIndex) : item.id);
      // adding alternatives to create valid ids
      pushedItemIndex += (item.alternatives?.length) ? item.alternatives.length + 1 : 1;
      groupItem.items = groupItemIds;
    });
  });

  return groupItems;
}

/**
 * reformat offerItems to new dataFormat
 * @param offerItems
 * @returns {*}
 */
const offerItemParser = (response, isLegacyParser) => {
  let newOfferItems = [];
  const items = response.offerItems || response.optionalItems;
  if (items) {
    items.forEach((groupItem, groupIndex) => {

      const flatItems = extractAlternativesItemsToFlatList(groupItem);

      if (isLegacyParser) {
        newOfferItems = newOfferItems.concat(itemParser(deepCopyPureObject(flatItems), response, groupIndex));
      } else {
        newOfferItems = newOfferItems.concat(flatItems);
      }
    })

    newOfferItems.forEach(item => {
      if (item.parent_id) {
        item.active = newOfferItems.find(element => element.id === item.parent_id).active
      }
    })
  }
  return newOfferItems;
};

/**
 * pick alternatives an add to (flat) itemsList.
 * @param groupItem
 * @returns {*}
 */
const extractAlternativesItemsToFlatList = (groupItem) => {
  let newItems = [];

  groupItem.items.forEach((item) => {

    item.active = true;

    newItems.push(item);
    if (item.alternatives && item.contentType === 'main') {
      let itemAndAlternativeIds = [item.id].concat(item.alternatives.map(alternative => alternative.id))
      item.alternatives.forEach(alternative => {
        alternative.priceDiff = (!!alternative.alternative.priceDiff) ? alternative.alternative.priceDiff : null;
        alternative.contentType = 'main';
        alternative.alternatives = itemAndAlternativeIds;
      });
      newItems.push(...item.alternatives);
      item.alternatives = itemAndAlternativeIds;
    }
  });
  return newItems;
};


/**
 * remove illegal characters from id (':','.','%')
 * @param id
 * @returns {string}
 */
const getValidIdSelector = (id, length = 56) => {
  if (id) {
    id = id.split(':').join('_');
    id = id.split('.').join('_');
    id = id.split(',').join('_');
    id = id.split('%').join('_');
    id = id.split(' ').join('_');
    id = id.substr(0, length);
  }
  return id;
};

/**
 * reformat items to new dataFormat
 * @param itemList
 */
const itemParser = (itemList, response, groupIndex) => {
  let newItemList = itemList.map((item, index) => {
    delete item.alternative;

    if (!!item.id) {
      item.originId = item.id;
      item.showNoticeIcon = true;
    } else if (!item.id) {
      item.showNoticeIcon = false;
    }
    item.id = getItemId(item, index, groupIndex);

    // use defined item-types (english)
    item.type = typeMap[item.type] || 'default';

    // rename 'displayTitle' to 'label'
    delete Object.assign(item, {'label': item['displayTitle']})['displayTitle'];

    // rename originName to originLabel
    delete Object.assign(item, {'originLabel': item['originName']})['originName'];

    item.notice = item.note;
    delete item.note;

    //remove empty dates
    if (!item.date) {
      delete item.date
    }



    // format date
     if(item.date){
      const {date,time} = getDateTime(item.date, response.dateFrom, response.dateTo);
      item.date = date;
      item.time = time;
    }

    // format location
    const {lat, lng} = getLatLngByMapKey(item.mapKey, response.offerMap.markers);
    item.location = {label: item.location, lat: lat, lng: lng};

    // format tabs
    if (item.tabs) {
      item.tabs = formatTabList(item.tabs);
    }

    // format rating
    if (!item.rating) {
      delete item.rating
    }

    // remove empty teaserImages
    if (!item.teaserImage) {
      delete item.teaserImage
    }

    // reformat "stay" to "duration"
    if (item.stay && item.stay.num && item.stay.text) {
      item.duration = {
        num: parseInt(item.stay.num),
        label: item.stay.text
      };
      // remove unused attribute
      delete item.stay;
    }

    if (item.dateSelect) {
      delete Object.assign(item, {'options': item['dateSelect']})['dateSelect'];
      item.options = formatItemOptions(item.options, response.dateFrom, response.dateTo);
    }

    // reformat routeDescriptions
    if (item.contentType === 'text') {
      item.contentType = 'route-description';
      item.content = formatRouteDescription(item);
      delete Object.assign(item, {'label': item['name']})['name'];
    }
    return item

  });
  return newItemList
};

/**
 * reformat dateSelect to options
 * @param options
 * @returns {{date: string, times: *}[]}
 */
const formatItemOptions = (options, startDate, endDate) => {
  const dateMap = {};
  options.forEach((option) => {
    const {date, time} = getDateTime(option, startDate, endDate)

    if (!dateMap[date]) {
      dateMap[date] = [];
    }
    dateMap[date].push({time:time || 'unknown', price: option.value});
  });

  return Object.keys(dateMap).map((key) => {
    return {
      date: key,
      times: dateMap[key]
    }
  })
};

/**
 * format routeDescription to list of objects
 * @param item
 * @returns {*}
 */
const formatRouteDescription = (item) => {

  return item.texts.map((text) => {
    return {
      text: text.text,
      label: text.name
    }
  })
};

/**
 * reformat tabs
 * @param tabs
 * @returns {unknown[]}
 */
const formatTabList = (tabs) => {
  const tabList = Object.keys(tabs);

  return tabList.map((key) => {
    const tab = {...tabs[key]};

    if (key === 'itinerary') {
      tab.typ = 'itinerary';
    }

    // rename 'typ' to 'type'
    delete Object.assign(tab, {'type': tab['typ']})['typ'];

    // rename 'name' to 'label'
    delete Object.assign(tab, {'label': tab['name']})['name'];

    // reformat content of tabs
    const newTab = parseTabContent(tab);

    if (newTab) {
      Object.assign(tab, newTab);
      // remove unused elements
      delete tab.data;
    }

    return tab
  })
};

/**
 * parse specific dataFomrats from tabObject
 * @param tab
 * @returns {{content: [{label: string, text: *, type: string}]}|{images: {alt: string, url: string}[]}|{label: string, type: string, content: unknown[]}}
 */
const parseTabContent = (tab) => {
  const data = tab.data;
  switch (tab.type) {
    case 'html':
      return {
        content: [{
          type: 'leistung',
          label: 'Leistung',
          text: data
        }]
      };
    case 'itinerary':
      return {
        content: [{
          type: 'itinerary',
          label: 'Flugplan',
          text: data
        }]
      };
    case 'addons':
      return {
        type: 'addons',
        label: 'Zusatzleistungen',
        content: formatAddOnListFromObject(data)
      };
    case 'images':
      return {
        images: Object.keys(data).map((key) => {
          return {
            url: IMAGE_DB_PREFIX + data[key],
            alt: key
          }
        })
      };
    default:
      return null;
  }
};

/**
 * create Array from Object
 * @param data
 * @returns {unknown[]}
 */
const formatAddOnListFromObject = (data) => {
  return Object.keys(data).map((key) => {
    let addOnObject = data[key];
    addOnObject.id = key;
    return addOnObject;
  })
};

/**
 * get lat & lng from offerMap by mapKey
 * @param mapKey
 * @param markers
 * @returns {{lng: *, lat: number}}
 */
const getLatLngByMapKey = (mapKey, markers) => {
  const returnValue = markers.find((item) => {
    return item['leistung_id'] === mapKey
  });

  if (returnValue) {
    return {lat: parseFloat(returnValue.lat), lng: parseFloat(returnValue.lon)}
  } else {
    return {lat: 0, lng: 0}
  }
};

/**
 * Returns {date,time} Object for strings like "01.01. 10:00-11:00"
 *
 * @param dateString
 * @param startDate
 * @param endDate
 * @returns {{date: string, time: string}}
 */
const getDateTime = (dateString, startDate, endDate) => {
  let date ='';
  let time ='';

  if(typeof dateString === 'number') {
    date = toISODateStr(getUtcSafeDate(dateString));
  } else if(typeof dateString?.label === 'string') {
    let key = dateString.label.split('. ')[0] || dateString.label;
    date = formatToDateString(key, startDate, endDate);

    time = dateString.label.split('. ')[1];
  }
  return {date, time}
}

/**
 * reformat date to DateString: YYYY-MM-DD
 * @param date
 * @param startDate
 * @param endDate
 * @returns {string}
 */
const formatToDateString = (date, startDate, endDate) => {

  // dateformat is correct
  if (date.lastIndexOf('.') === 5) {
    // turn germanDateString into valid date
    const tempDay = getDayPadded(parseInt(date.split('.')[0]));
    const tempMonth = getMonthPadded(parseInt(date.split('.')[1]) - 1);
    const tempYear = date.split('.')[2];
    return tempYear + '-' + tempMonth + '-' + tempDay;
  } else {

    const tempDay = getDayPadded(parseInt(date.split('.')[0]));
    const tempMonth = getMonthPadded(parseInt(date.split('.')[1]) - 1);
    // default year is year of endDate
    let tempYear = endDate.split('-')[0];

    let tempDate = tempYear + '-' + tempMonth + '-' + tempDay;

    // get current optional time
    let tempTime = new Date(tempDate).getTime();
    // get endDate time
    let endTime = new Date(endDate).getTime();

    // if temptime is bigger as endTime set year to startDate year
    if (tempTime > endTime) {
      tempDate = startDate.split('-')[0] + '-' + tempMonth + '-' + tempDay
    }

    return tempDate
  }
};

/**
 *  typeMap legacyTypes to currentTypes
 * @type {{extras: string, Mietwagen: string, Kreuzfahrt: string, Rundflug: string, Eintrittskarte: string, FlugHawaii: string, default: string, Ausflug: string, Special: string, Versicherung: string, Flug: string, Sitzplatzreservierung: string, not_found: string, "F&auml;hre": string, Kleingruppenreise: string, location_options: string, Tierbeobachtung: string, Inselrundfahrt: string, Wohnmobil: string, Busreise: string, Motorrad: string, Bahnfahrt: string, "Unsere Routenempfehlung": string, Transfer: string, Infopaket: string, Sommercamp: string, fallback: string, Hotel: string, Sprachreise: string, Wertkarte: string}}
 */
const typeMap = {
  'fallback': 'fallback',
  'not_found': 'not-found',
  'default': 'default',
  'Special': 'special',
  'Ausflug': 'excursion',
  'Eintrittskarte': 'ticket',
  'extras': 'extras',
  'Flug': 'flight',
  'F&auml;hre': 'ferry',
  'Fähre': 'ferry',
  'Hotel': 'hotel',
  'Infopaket': 'info-package',
  'location_options': 'location-options',
  'Mietwagen': 'rental-car',
  'Rundflug': 'sightseeing-flight',
  'Versicherung': 'insurance',
  'Wertkarte': 'valuecard',
  'Wohnmobil': 'camper',
  'Kreuzfahrt': 'cruise',
  'Busreise': 'bustrip',
  'Bahnfahrt': 'ride',
  'Transfer': 'transfer',
  'Tierbeobachtung': 'wildlife-viewing',
  'Sprachreise': 'language-journey',
  'Sommercamp': 'summer-camp',
  'Motorrad': 'motorcycle',
  'Unsere Routenempfehlung': 'our-route-recommendation',
  'Sitzplatzreservierung': 'seat-reservation',
  'Kleingruppenreise': 'small-group-travel',
  'Inselrundfahrt': 'island-tour',
};

const numberMap = {
  1: 'eins',
  2: 'zwei',
  3: 'drei',
  4: 'vier',
  5: 'fünf',
  6: 'sechs',
  7: 'sieben',
  8: 'acht',
  9: 'neun',
  10: 'zehn',
  11: 'elf',
  12: 'zwölf'
};
