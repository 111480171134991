import React, {Component} from 'react';
import './Preloader.scss'
import classNames from "classnames";
import PropTypes from "prop-types";
import {Icon} from "../icon/Icon";

class Preloader extends Component {
	constructor(props) {
		super(props);
		this.state = {
			showPreloader: false
		};
		this.timeout = null;
	}

	componentDidMount() {
		clearTimeout(this.timeout);
		this.timeout = setTimeout(() => {
			this.setState({showPreloader: true})
		}, this.props.delay)
	}

	componentWillUnmount() {
		clearTimeout(this.timeout);
	}

	render() {

		const classes = classNames(
			'preloader',
			`preloader--${this.props.type}`,
			{'preloader--show': this.state.showPreloader},
			this.props.additionalClasses
		);

		if (this.props.type === 'text') {
			return (
				<div className={classes}>
					<span className="preloader__text">
						consetetur sadipscing,<br/>
						lorem ipsum dolor sit amet, consetetur sadipscing elitr. consetetur sadipscing elitr<br/>
						lorem ipsum dolor sit amet, consetetur sadipscing elitr.lorem ipsum dolor sit amet, consetetur sadipscing elitr.<br/>
						lorem ipsum dolor sit amet, consetetur sadipscing elitr.<br/>
						lorem ipsum dolor sit amet.
					</span>
				</div>
			)
		}

		if (this.props.type === 'line') {
			return (
				<div className={classes}>
					<Icon name={'loading'} additionalClasses={''}/>
					<span className="preloader__line">lorem ipsum dolor sit amet consetetur.</span>
				</div>
			)
		}

		if (this.props.type === 'contactbox') {
			return (
				<div className={classes}>

					<div className="preloader__contactbox">
						<div className={"preloader__contactbox-image"}/>
						<div className={'preloader__contactbox-text'}>
							<div>lorem ipsum dolor sit amet nse</div><br/>
							<div>lorem ipsum dolor sit amet.</div>
						</div>
						<div className={'preloader__contactbox-text'}>
							<div>lorem ipsum dolor sit amet.</div><br/>
							<div>lorem ipsum dolor sit amet, co </div>
						</div>
					</div>
				</div>
			)
		}

		if (this.props.type === 'full-screen') {
			return (
				<div className={classes} style={this.props.style}>
					<div className={'preloader__canusa'}></div>
				</div>
			)
		}

		if (this.props.type === 'logo') {
			return (
				<div className={classes} style={this.props.style}>
					<div className={'preloader__canusa'}></div>
				</div>
			)
		}

		if (this.props.type === 'block') {
			return (
				<div className={classes} style={this.props.style}>
					<div className={'preloader__block'}></div>
				</div>
			)
		}

		//TODO default loader is not defined
		return (
			<div className={classes}>
				<div>
				</div>
			</div>
		)
	}
}

Preloader.defaultProps = {
	delay: 200,
	type: 'text',
};

Preloader.propTypes = {
	/**
	 * show preloader after delay
	 */
	delay: PropTypes.number,
	/**
	 * preloader type
	 * @default: text
	 */
	type: PropTypes.oneOf(['text', 'line', 'full-screen', 'block', 'contactbox', 'logo']),
	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string

};

export {
	Preloader as default,
	Preloader
}
