import React from 'react';
import {connect} from "react-redux";
import ItemDetails from "./ItemDetails";
import PropTypes from "prop-types";

function ItemDetailsContainer(props) {
	return <ItemDetails item={props.item}
															id={props.itemId}
															parentId={props.parentId}
															isOpen={props.isOpen}
															isDesktop={props.isDesktop}
															closeHandler={props.closeHandler}
															hasAlternatives={(props.alternativeList && props.alternativeList.length > 0)}
															alternativeList={props.alternativeList}
															isDigitalOffer={props.isDigitalOffer}
															offerParticipants={props.offerParticipants}
	                            onAlternativeChange={props.onAlternativeChange}
															itemMap={props.itemMap}
	/>
}


ItemDetailsContainer.propTypes = {
	/**
	 * selector of the wrapping container: passthrough the selector to the tooltip
	 * reason: when set tooltip position is fixed that it can be displayed inside of an overflow hidden container.
	 */
	parentId: PropTypes.string.isRequired,
	/**
	 * toggles the visibility of the detail
	 */
	isOpen: PropTypes.bool.isRequired,
	/**
	 * switch between tabs & modal
	 */
	isDesktop: PropTypes.bool.isRequired,
	/**
	 * closehandler for modal (mobile Viewport only)
	 */
	closeHandler: PropTypes.func.isRequired,
	/**
	 * content of ItemDetail.
	 * see **Item** for further information
	 */
	item: PropTypes.object.isRequired,
	/**
	 * render detailInformations depending on type:
	 * the follwing types don't show detailInformationBoxes:
	 * 'seat-reservation', 'insurance'
	 */
	type: PropTypes.string
};


const mapStateToProps = (state, ownProps) => {
	return {
		isDesktop: state.globalStateManagement.isDesktop,
		isDigitalOffer: state.globalStateManagement.environment === 'digitalOffer',
		offerParticipants: state.participantManagement.list,
		itemMap: state.itemManagement.itemMap,
	}
}

export default connect(mapStateToProps, null)(ItemDetailsContainer)
