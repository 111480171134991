 import React, {Component} from 'react';
import './StaticMap.scss'
import {config} from "../../../../config/config";
import {typeToIconMap} from "../../../../../vendor/utils/Mappings";
 import {fallbackImage} from "../../../../../vendor/utils/Utils";
 import {connect} from "react-redux";
 import {
	 dispatchConsent
 } from "../../../../redux/actions/globalStateManagementAction";
 import {StorageManager} from "../../../../services/StorageManager";

class StaticMap extends Component {

	constructor(props) {
		super(props);
		this.state = {
			measuresByAspectRatio: this.calculateImageSizeByAspectRatio()
		};
		this.widthChange = this.widthChange.bind(this);
		this.updatePersonalStats = this.updatePersonalStats.bind(this);
	}

	componentDidMount() {
		this.widthChange();
		window.addEventListener('resize', this.widthChange);
	}

	componentWillUnmount() {
		window.removeEventListener('resize', this.widthChange);
	}

	/**
	 * create string for imageDimensions based on aspectRatio
	 * @param w
	 * @param h
	 * @returns {string}
	 */
	calculateImageSizeByAspectRatio(w = 16, h = 13) {
		return window.outerWidth + 'x' + Math.round((window.outerWidth / w) * h)
	}

	/**
	 * change imageSize by viewportType (portrait|landscape). square viewports will not be covered
	 */
	widthChange() {
		this.setState({
			measuresByAspectRatio: this.calculateImageSizeByAspectRatio(16, 6)
		})
	}

	addCustomPin() {

		let image = null;
		switch (typeToIconMap[this.props.item.type].icon) {
			case 'active':
			case 'biker':
			case 'camping':
			case 'car':
			case 'coach':
			case 'cruise':
			case 'ferry':
			case 'hawaii':
			case 'helicopter':
			case 'hotel':
			case 'motorcycle':
			case 'motorhome':
			case 'plane':
			case 'skier':
			case 'train':
			case 'transfer':
			case 'wedding':
				image = '/assets/map-icons/pin_' + typeToIconMap[this.props.item.type].icon + '.svg';
				break;
			default:
				image = '/assets/map-icons/pin_globe.svg';
		}

		return <img src={image} alt={'Icon ' + typeToIconMap[this.props.item.type].label}/>
	}
	setCookie(list) {
		let expiresAfter = 30;
		if (!list.stats || !list.personal) {
			expiresAfter = 1;
		}
		StorageManager.setCookie('acceptedCookies', JSON.stringify(list),
				expiresAfter);
		this.props.dispatchConsent(list);
	}

	updatePersonalStats() {
		const list = {
			necessary: true,
			stats: !!this.props.consent.stats,
			personal: true,
		};
		this.setCookie(list);
	}

	renderFallBack(){
		const aspectRatioStyle = this.state.measuresByAspectRatio.replace('x','/');
		return <div className={"static-map google-map-fallback"} style={{aspectRatio:aspectRatioStyle}}>
			<div className={'google-map-fallback__image'}>
				{fallbackImage()}
			</div>
			<div className={"google-map-fallback__wrapper"}>

				<div className={"google-map-fallback__text"}>
					Mit dem Laden von Google Maps akzeptieren Sie Marketing-Cookies.
				</div>

				<button className={"google-map-fallback__button cta-button cta-button--primary btn--small"}
								onClick={this.updatePersonalStats}>
					<span>Google Maps laden</span>
				</button>
			</div>
		</div>
	}


	render() {

		if (!this.props.consent?.personal) {
			return this.renderFallBack();
		}

		const paramString = 'https://maps.googleapis.com/maps/api/staticmap?' +
			'center=' + this.props.item.location.lat + ',' + this.props.item.location.lng +
			'&zoom=12' +
			'&scale=4' +
			'&size=' + this.state.measuresByAspectRatio +
			'&maptype=roadmap' +
			'&language=de' +
			'&region=DE' +
			'&visual_refresh=true' +
			'&key=' + config.GOOGLE_MAPS_API_KEY;
		const aspectRatioStyle = this.state.measuresByAspectRatio.replace('x','/');
		return (
			<div className={'static-map'} key={this.props.item.id} style={{aspectRatio:aspectRatioStyle}}>
				<img
					src={paramString}
					alt={'Karte von ' + this.props.item.location.label}
				/>
				<div className={'static-map__pin'}>
					{this.addCustomPin()}
				</div>
			</div>
		)
	}
}

StaticMap.defaultProps = {};
StaticMap.propTypes = {};

 const mapStateToProps = (state, ownProps) => {
	 return {
		 consent: state.globalStateManagement.consent,
	 }
 };

 export default connect(mapStateToProps, {dispatchConsent,})(StaticMap)
