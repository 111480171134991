import {applyMiddleware, compose, createStore} from "redux";
import thunkMiddleware from 'redux-thunk'
import rootReducer from "./reducers";

const envIsProduction = process.env.NODE_ENV === 'production';
const disableLogger = process.env.REACT_APP_DISABLE_LOGGER_MIDDLEWARE === 'true';

const composeEnhancers = ((!envIsProduction && window.__REDUX_DEVTOOLS_EXTENSION__) && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose;

const middleWare = (!envIsProduction && !disableLogger) ? applyMiddleware(
	thunkMiddleware,
	)
	:
	applyMiddleware(
		thunkMiddleware
	);

const store = createStore(rootReducer,/* preloadedState, */
	composeEnhancers(
		middleWare
	)
);

export default store;
