/**
 * compare two jsonStrings
 */
const jsonEqual = (a, b) => {
  return JSON.stringify(a) === JSON.stringify(b);
};

/**
 * deep copying of pure objects
 * use only with objects that don't have references to other instances!
 */
const deepCopyPureObject = (value) => {
  return JSON.parse(JSON.stringify(value));
};

/**
 * appending currency to price value
 * Format: € 122.334,-
 * @param value
 * @returns {string}
 */
const currencyFormat = (value) => {

  const formatter = new Intl.NumberFormat('de-DE', {
    minimumFractionDigits: 0
  });
  return formatter.format(value) + '\u2009€';
};

const getQueryVariable = (queryString, variable) => {

  let vars = (queryString[0] === '?' ? queryString.substr(1) : queryString).split('&');
  for (let i = 0; i < vars.length; i++) {
    let pair = vars[i].split('=');
    if (decodeURIComponent(pair[0]) === variable) {
      return decodeURIComponent(pair[1]);
    }
  }
};

const getFullName = (firstName, middleNames, lastName) => {
  if (middleNames) {
    return [firstName, middleNames, lastName].join(' ');
  }
  return [firstName, lastName].join(' ');
};

const checkTypeObject = (value) => {
  return (
    typeof value === 'object' &&
    !Array.isArray(value) &&
    value !== null
  );
};

const chunkArray = (arr, chunkLength) => {
  const chunks = [];
  let chunk = [];

  while (arr.length > 0) {
    const item = arr.shift();
    if (chunk.length < chunkLength - 1) {
      chunk.push(item);
    } else {
      chunk.push(item);
      chunks.push([...chunk]);
      chunk = []; // create nuw chunk
    }
    // make sure to push chunk if not complete
    if (arr.length === 0) {
      chunks.push([...chunk]);
    }
  }
  return chunks;
};

const transparentPNG_1x1 = 'data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII=';

const isEmpty = (object) => {
  return Object.keys(object).length === 0;
};

const mod = (n, m) => {
  return ((n % m) + m) % m;
};

const MOBILE_RATIO = {width: 16, height: 13};
const DESKTOP_RATIO = {width: 21, height: 8};

const tagManagerArgs = {
  gtmId: 'GTM-5TDVPWC'
};

const isDevEnvironment = () => (new RegExp('(localdev|lxdev|stage|test|dev|localhost)', 'gi').test(window.location.hostname));


const getUrlByEnvironment = () => {

  const subdomain = window.location.hostname.split('.')[0];
  const labelConfig = {
    portalLabel: 'mein.canusa.de',
    daLabel: 'angebot.canusa.de'
  };

  let config = {};
  switch (subdomain) {
    case 'meintest':
    case 'angebottest':
      config = {
        portalUrl: 'https://meintest.canusa.de',
        daUrl: 'https://angebottest.canusa.de'
      };
      break;
    case 'meindev':
    case 'angebotdev':
      config = {
        portalUrl: 'https://meindev.canusa.de',
        daUrl: 'https://angebotdev.canusa.de'
      };
      break;
    case 'meinstage':
    case 'angebotstage':
      config = {
        portalUrl: 'https://meinstage.canusa.de',
        daUrl: 'https://angebotstage.canusa.de'
      };
      break;
    case 'localhost':
      config = {
        portalUrl: '//localhost:3000',
        daUrl: '//localhost:3000'
      };
      break;
    default:
      config = {
        portalUrl: 'https://mein.canusa.de',
        daUrl: 'https://angebot.canusa.de'
      };
  }

  return Object.assign(config, labelConfig);
};

export const cleanOfferNumber = (offerNumber) => {
  return !!offerNumber ? offerNumber.match('(\\d+(\\.\\d+)?)(?=-)')[0] : 'notset';
};


const extractOfferIdAndRevisionFromOfferNumber = (offerNumber) => {
  const regex = /-([^-]*)-/
  const offerString = regex.exec(offerNumber)[1];

  const offerId = offerString.split('.')[0]
  const revisionNumber = offerString.split('.')[1]

  return {
    offerId,
    revisionNumber
  }
}

const getLabelForValue = (array, value) => {
  const foundObject = array.find(element => element.value === value);
  return foundObject ? foundObject.label : null;
}

const fallbackImage = () => <div className={'lazyloaded fallback-image'}>
    <svg x="0px" y="0px"
         viewBox="0 0 27.8 27.8">
      <path className="st0" d="M25,23.1h0.9v4.2H25c-0.2-0.6-0.5-1.2-1.4-1.2s-3.2,1.7-8.1,1.7c-8.1,0-14.8-5.7-14.8-14S7.5,0,15.5,0
c4.9,0,7.3,1.4,8.1,1.4S24.8,1,25,0.2h0.9v4.2H25c-2.3-1.7-5.1-2.6-7.9-2.7c-6.7,0-12.2,5.4-12.2,12.1c0.1,6.7,5.5,12.1,12.2,12.1
C20,25.9,22.8,24.9,25,23.1"/>
      <path className="st0"
            d="M26.2,14.6c-0.1,1.6-0.6,3.2-1.5,4.6l-0.1,0.2l-0.2-0.1c-0.7-0.4-1.4-0.7-2.1-1l-0.2-0.1V18c0.3-1.1,0.4-2.3,
          0.4-3.5v-0.2h3.7L26.2,14.6z M23.9,20.2c-0.9,1-2.1,1.8-3.3,2.4l-0.7,0.3l0.5-0.6c0.7-0.9,1.2-1.9,
          1.5-3l0.1-0.2l0.2,0.1c0.6,0.2,1.2,0.5,1.8,0.8L24,20L23.9,20.2z M17.8,23.1l-0.3,0.1v-4.8h0.2c1,
          0,2.1,0.2,3.1,0.4l0.2,0L21,19C20.2,21.2,19.1,22.7,17.8,23.1 M17.5,14.3h4.2v0.2c0,1.1-0.2,2.2-0.4,
          3.2v0.2h-0.2c-1.1-0.3-2.2-0.4-3.4-0.5h-0.2L17.5,14.3z M17.5,10.2h0.2c1.1,0,2.2-0.2,3.3-0.4h0.2V10c0.3,
          1.1,0.4,2.2,0.5,3.3v0.2h-4.2L17.5,10.2z M17.5,4.6l0.3,0.1c1.3,0.4,2.4,1.8,3.1,4L21,8.9L20.8,9c-1,0.2-2,
          0.4-3,0.4h-0.3L17.5,4.6z M20.5,5.3c1.2,0.5,2.4,1.3,3.3,2.3L24,7.7l-0.2,0.1c-0.6,0.3-1.1,0.5-1.7,0.7l-0.2,
          0.1l-0.1-0.2c-0.3-1-0.8-2-1.5-2.9L19.8,5L20.5,5.3z M24.3,8.5l0.2-0.1l0.1,0.2c1,1.4,1.5,3,1.6,
          4.7v0.2h-3.6v-0.2c0-1.2-0.2-2.4-0.5-3.6V9.5l0.2-0.1C23,9.2,23.7,8.9,24.3,8.5 M16.7,9.4h-0.2c-1,
          0-2-0.2-3-0.4l-0.2-0.1l0.1-0.2c0.7-2.1,1.9-3.6,3.1-4l0.2-0.1V9.4z M16.7,13.5h-4.3v-0.2c0-1.1,
          0.2-2.2,0.4-3.3V9.8h0.2c1.1,0.3,2.2,0.4,3.3,0.4h0.3V13.5z M16.7,17.5h-0.2c-1.1,0-2.3,0.2-3.4,
          0.4H13v-0.2c-0.2-1.1-0.4-2.1-0.4-3.2v-0.2h4.2V17.5z M16.7,
          23.2l-0.3-0.1c-1.3-0.4-2.4-1.9-3.2-4.1l-0.1-0.2l0.2,0c1-0.2,2-0.4,3.1-0.4h0.2V23.2z M13.7,
          22.6c-1.3-0.5-2.4-1.3-3.3-2.4L10.2,20l0.2-0.1c0.6-0.3,1.1-0.6,1.8-0.8l0.2-0.1l0.1,0.2c0.3,1.1,0.8,2.1,
          1.5,3l0.5,0.6L13.7,22.6z M9.8,19.2l-0.2,0.1l-0.1-0.2c-0.9-1.4-1.5-2.9-1.6-4.6v-0.2h3.7v0.2c0,
          1.2,0.2,2.3,0.4,3.5v0.2l-0.2,0.1C11.2,18.5,10.4,18.8,9.8,19.2 M8,13.3c0.1-1.7,0.7-3.3,1.6-4.7l0.1-0.2l0.2,
          0.1c0.7,0.4,1.4,0.7,2.1,0.9l0.2,0.1v0.2c-0.3,1.2-0.4,2.4-0.5,3.6v0.2H8L8,13.3z M10.4,7.6c0.9-1,2-1.8,
          3.3-2.3L14.4,5L14,5.6c-0.6,0.9-1.1,1.8-1.5,2.9l-0.1,0.2l-0.2-0.1c-0.6-0.2-1.2-0.4-1.7-0.7l-0.2-0.1L10.4,
          7.6z M17.1,3.9c-5.5,0-10,4.5-10,10s4.5,10,10,10s10-4.5,10-10S22.6,3.9,17.1,3.9"
      />
    </svg>
  </div>
;

export {
  jsonEqual,
  deepCopyPureObject,
  currencyFormat,
  getLabelForValue,
  transparentPNG_1x1,
  getQueryVariable,
  getFullName,
  isEmpty,
  mod,
  checkTypeObject,
  chunkArray,
  MOBILE_RATIO,
  DESKTOP_RATIO,
  tagManagerArgs,
  getUrlByEnvironment,
  isDevEnvironment,
  extractOfferIdAndRevisionFromOfferNumber,
  fallbackImage,
};
