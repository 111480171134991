import React from 'react';
import "./HtmlText.scss";
import PropTypes from "prop-types";

import {
	decodeHTMLEntities,
	removeRatingStarsImage,
	removeUnusedCharacters,
	removeUnusedStyles,
	trimHtmlBreaks
} from "../../../vendor/utils/HTMLUtils";

function HtmlText(props) {

	let textContent = removeUnusedStyles(props.children);
	textContent = decodeHTMLEntities(textContent);
	textContent = removeUnusedCharacters(textContent);
	textContent = removeRatingStarsImage(textContent);
	textContent = trimHtmlBreaks(textContent);
	textContent = convertHttpStringsToLinks(textContent);

	/**
	 * returns anchor-tag as a string
	 * @param value
	 * @returns {string}
	 */
	function linkAsAString(value, label, target) {
		if (!label) {
			return null;
		}
		label = label.split('>').join('')
		label = label.split('</').join('')
		if (target) {
			target = target.toString().split('target="').join('');
		}

		return (
			`<a href="${value}" target=" ${target || '_blank'}" style="display: inline-flex">
				<span class="link link--default">
					<span class="link__label dotted">${label}</span>
				</span>
			</a>`
		)
	}

	/**
	 * converting http-strings into links
	 * @param text
	 * @returns {*}
	 */
	function convertHttpStringsToLinks(text) {
		if (!text) return '';

		const linkHref = text.match(/(http|https):\/\/([\w_-]+(?:(?:\.[\w_-]+)+))([\w.,@?^=%&:/~+#-]*[\w@?^=%&/~+#-])?/gm)
		// detect anchor in text
		const anchorList = text.match(/(<a ).*?(<\/a>)/gm);
		// replace full urls in text with linkButton (url as label)
		if (linkHref && !anchorList) {
			for (let i = 0; i < linkHref.length; i++) {
				text = text.replace(linkHref[i], linkAsAString(linkHref[i], linkHref[i]));
			}
		}

		// replace anchors inside text with linkButton (anchorLabel as label)
		if (linkHref && anchorList) {

			const labelRegEx = new RegExp(/(>).*(?=<\/)/gm);
			const targetRegEx = new RegExp(/(target=")\w*()/gm);

			for (let i = 0; i < anchorList.length; i++) {
				const item = anchorList[i];
				const label = item.match(labelRegEx);
				const target = item.match(targetRegEx) || ['_blank'];
				text = text.replace(anchorList[i], linkAsAString(linkHref[i], label[0]), target[0]);
			}
		}

		return text
	}

	return (
		<span className={props.additionalClasses} dangerouslySetInnerHTML={{__html: textContent}}></span>
	)
}

HtmlText.propTypes = {
	/**
	 * OPTIONAL: add additional classes for this component here.
	 * *Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string,
	/**
	 * content of htmlText as a String
	 */
	children: PropTypes.string.isRequired

};

export {
	HtmlText as default,
	HtmlText
}
