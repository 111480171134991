import {
	NOTICE_CLEAR,
	NOTICE_FAILURE,
	NOTICE_SAVED,
	NOTICE_SAVING,
} from "../actions/noticeManagementAction";

const noticeManagementState = {};

const noticeManagementReducer = (state = noticeManagementState, action) => {
	switch (action.type) {
		case NOTICE_CLEAR:
			return {};

		case NOTICE_FAILURE:
			return {
				...state,
				notice: {isSaving: false, savingSuccessful: false, id: action.noticeId}
			};

		case NOTICE_SAVING:
			return {
				...state,
				notice: {isSaving: true, id: action.noticeId}
			};

		case NOTICE_SAVED:
			return {
				...state,
				notice: {isSaving: false, savingSuccessful: true, id: action.noticeId}
			};

		default:
			return state
	}
};


export default noticeManagementReducer;
