import React, {Component} from 'react';
import './Icon.scss'
import PropTypes from "prop-types";
import classNames from "classnames";

export const ICON_SIZE = {XS:'xs',S:'s',M:'m',L:'l',XL:'xl'};

class Icon extends Component {

	render() {

		const classes = classNames(
			'icon',
			this.props.additionalClasses,
			//`icon-${this.props.name}`,
			`icon--size-${this.props.size}`,
			{'icon--rotate': this.props.name === 'loading'}
		);

		return (
				<svg className={classes}>
					<use xlinkHref={`#${this.props.name}`}></use>
				</svg>
		)
	}
}

Icon.propTypes = {
	/**
	 * **REQUIRED**: name of icon
	 */
	name: PropTypes.string.isRequired,

	size:PropTypes.oneOf([ICON_SIZE.XS,ICON_SIZE.S,ICON_SIZE.M,ICON_SIZE.L,ICON_SIZE.XL]),

	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string
};

Icon.defaultProps = {
	size:'m'
};

export {
	Icon as default,
	Icon
}
