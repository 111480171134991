import {DataProviderService} from "./DataProviderService";
import {storageManager} from "./StorageManager";
import {getUtcSafeDate} from "../../vendor/utils/DateUtils";

class  OfferListService extends DataProviderService {

	/**
	 * get offer with closest start date and offerList
	 *
	 * @return {object}		{currentOffer{object},offerList:{array}}
	 */
	getOffer(initialDebugOfferNumber) {
		
		return this.getData().then(response => {
			if(response.isAxiosError) {
				return Promise.reject(response)
			}
			//sort response asc by journey end
			response.sort((a,b)=>{
				return Date.parse(a.journeyStart)- Date.parse(b.journeyStart);
			});
			const now = getUtcSafeDate(new Date().getTime()).getTime();
			//get offer in closest future to now
			let currentOffer = response.find(offer =>{
				return getUtcSafeDate(offer.journeyEnd).getTime() > now;
			});
			// if there is no offer in the future, take the latest one
			if(!currentOffer) {
				currentOffer = response[response.length -1];
			}
			if(initialDebugOfferNumber) {
				let initialOffer =  response.find( offer => {
					return offer.offerNumber === initialDebugOfferNumber
				});
				if(initialOffer) {
					currentOffer = initialOffer;
				}
			}
			//store offerNumber
			storageManager.offerNumber = currentOffer.offerNumber;
			storageManager.offerToken = currentOffer.offerToken;
			
			// return current offer and complete list
			return {currentOffer:currentOffer, offerList:response.reverse(), offerToken: currentOffer.offerToken};
		})
	}
}

export {
	OfferListService
}
