const passwordMinLength = 4;
const emailRegex = new RegExp(/^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/);
const telRegex = new RegExp(/([!^/+\-()\s]|(\d))/g);
const dateRegex = new RegExp(/([12]\d{3}-(0[1-9]|1[0-2])-(0[1-9]|[12]\d|3[01]))/i); // date format (yyyy-mm-dd)

const passwordValidationPattern = (value) => {
  return value.length >= passwordMinLength
};

const emailValidationPattern = (value) => {
  let emailChecked = (value.length > 0 && emailRegex.test(value));
  return emailChecked;
};

const telValidationPattern = (value) => {
  value = value.match(telRegex);
  return value;
};

const dateValidationPattern = (value) => {
  const retVal = dateRegex.test(value)
  return retVal;
};

const loginValidationPattern = (value) => {
  return value.length > 0
};

const formErrorMessages = {
  'minLength': 'Bitte geben Sie ein Passwort mit mindestens ' + passwordMinLength + ' Zeichen ein',
  'wrongEmail': 'Bitte geben Sie eine korrekte Email-Adresse ein',
  'oldAndNewPass': 'Bitte überprüfen Sie, ob Ihr altes Passwort korrekt ist und Ihre neuen Passwörter übereinstimmen',
  'comparePass': 'Bitte überprüfen Sie, ob Ihre neuen Passwörter übereinstimmen',
  'unknownError': 'Es ist ein unbekannter Fehler aufgetreten',
  'loginError': 'Die Felder müssen korrekt ausgefüllt werden.',
  'validationError': 'Die Felder müssen korrekt ausgefüllt werden.',
  'firstnameError': 'Bitte Vornamen eintragen',
  'birthdateError': 'Bitte geben Sie ein korrektes Geburtsdatum im Format TT.MM.JJJJ ein',
  'lastnameError': 'Bitte Nachnamen eintragen',
  'streetError': 'Bitte Straße & Hausnummer eintragen',
  'zipError': 'Bitte Postleitzahl eintragen',
  'cityError': 'Bitte Stadt eintragen',
  'countryError': 'Bitte Land eintragen',
  'genderError': 'Bitte Anrede eingeben',
};

const initialValues = {
  'email': null,
  'placeholder': 'Bitte ausfüllen'
};

const formConfigMap = {
  'loginMask': {
    email: {
      initialValue: initialValues['email'],
      toolTipText: formErrorMessages['loginError'],
      placeholder: initialValues['placeholder'],

      validationPattern: emailValidationPattern,
    },
    password: {
      toolTipText: formErrorMessages['loginError'],
      validationPattern: loginValidationPattern,
    },
  },
  'resetPasswordMask': {
    newPassword: {
      toolTipText: formErrorMessages['minLength'],
      validationPattern: passwordValidationPattern,
    },
    repeatPassword: {
      toolTipText: formErrorMessages['minLength'],
      validationPattern: passwordValidationPattern,
    },
  },
  'forgotMask': {
    email: {
      initialValue: initialValues['email'],
      toolTipText: formErrorMessages['wrongEmail'],
      placeholder: initialValues['placeholder'],
      validationPattern: emailValidationPattern,
    },
  },
  'newPasswordMask': {
    oldPassword: {
      toolTipText: formErrorMessages['minLength'],
      validationPattern: passwordValidationPattern,
    },
    newPassword: {
      toolTipText: formErrorMessages['minLength'],
      validationPattern: passwordValidationPattern,
    },
    repeatPassword: {
      toolTipText: formErrorMessages['minLength'],
      validationPattern: passwordValidationPattern,
    },
  },
  'verificationMask': {
    password: {
      toolTipText: formErrorMessages['minLength'],
      validationPattern: passwordValidationPattern,
    },
  },
};

export {
  formConfigMap,
  formErrorMessages,
  telValidationPattern,
  dateValidationPattern,
  passwordMinLength
}
