import React, {Component} from 'react';
import './Input.scss'
import classNames from "classnames";
import PropTypes from "prop-types";
import {telValidationPattern} from "../login/FormUtils";

class Input extends Component {

	/**
	 * constructor
	 * @param props
	 */
	constructor(props) {
		super(props);
		this.state = {
			value: this.props.initialValue || '',
			valid: true
		};
		this.changeHandler = this.changeHandler.bind(this);
	}

	/**
	 * changeHandler for controlled input
	 * @param event
	 * @param type
	 * @param name
	 */
	changeHandler(event, type, name) {
		let value = event.target.value;
		if (value) {
			switch (this.props.type) {
				case 'tel':
					// restrict to 0-9, +, -, /, (, ) and whitespace
					value = telValidationPattern(value);
					break;
				default:
					break;
			}
			value = Array.isArray(value) ? value.join('') : value;
		}

		this.checkState(value);
	};

	/**
	 * set validity and value into state
	 * publish to changeHandler
	 * @param value
	 */
	checkState(value) {
		const isValid = this.checkValidity(this.props.type, value, this.props.required);
		this.setState({value: value || '', valid: isValid});

		if (this.props.onChange) {
			this.props.onChange({
				valid: isValid,
				type: this.props.type,
				value: value,
				name: this.props.name,
				compareId: this.props.compareId
			});
		}
	}

	/**
	 * check if input has valid values
	 * @param type
	 * @param value
	 * @param isRequired
	 * @return {*|boolean}
	 */
	checkValidity(type, value, isRequired) {
		//just checking if it has value if its required at the moment
		let isValid = (value && value !== '') || !isRequired;
		if (this.props.validator) {
			isValid = this.props.validator(value);
		}
		if (type === 'password') {
			return false; // Backend takes care of validity when it comes to passwords
		}
		return isValid;
	}

	componentDidMount() {
		this.checkState(this.state.value);
	}

	setFocus() {
		if (!this.props.autoFocus) {
			return null;
		}
		return (input) => {
			input && input.focus()
		}
	}

	render() {
		const displayErrorState = this.props.showError && !this.state.valid;
		const classes = classNames(
			'input',
			this.props.additionalClasses,
			{'disabled': this.props.disabled},
			{'input--full-width': this.props.fullWidth},
			{'error': displayErrorState},
		);

		return (
			<div className={classes}>
				<label className="field-label ts-copy-small">{this.props.label}</label>
				<input type={this.props.type}
							 name={this.props.name}
							 placeholder={this.props.placeHolder}
							 autoComplete={this.props.autoComplete}
							 required={this.props.required}
							 disabled={this.props.disabled}
							 onChange={this.changeHandler}
							 value={this.state.value}
							 ref={this.setFocus()}
				/>
				{(this.props.errorMessage && displayErrorState) &&
				<div className={'input__error-message'}>{this.props.errorMessage}</div>}
			</div>
		)
	}
}

Input.defaultProps = {
	type: 'text',
	autoComplete: 'off',
	required: false,
	disabled: false,
	fullWidth: false,
	showError: false,
	autoFocus: false
};

Input.propTypes = {
	/**
	 * **REQUIRED**
	 * label of input
	 */
	label: PropTypes.string.isRequired,
	/**
	 * OPTIONAL
	 * placeholder text
	 */
	placeHolder: PropTypes.string,
	/**
	 * OPTIONAL
	 * input type default: "text"
	 */
	type: PropTypes.string,
	/**
	 * OPTIONAL
	 * input autocomplete default: "off"
	 */
	autoComplete: PropTypes.string,
	/**
	 * OPTIONAL
	 * input autofocus default: "off"
	 */
	autoFocus: PropTypes.bool,
	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string,
	/**
	 * OPTIONAL
	 * input required default: false
	 */
	required: PropTypes.bool,
	/**
	 * OPTIONAL
	 * input disabled default: false
	 */
	disabled: PropTypes.bool,
	/**
	 * OPTIONAL
	 * input value default: null
	 */
	value: PropTypes.string,
	/**
	 * OPTIONAL
	 * input fullwidth default: false
	 */
	fullWidth: PropTypes.bool,
	/**
	 * OPTIONAL
	 * input errorMessage default: null
	 */
	errorMessage: PropTypes.node,
	/**
	 * OPTIONAL
	 * Objects with the same value in this prop will be compared between each other
	 */
	compareId: PropTypes.string,
	/**
	 * OPTIONAL
	 * defines the status of the error-state of the input
	 * @default: false
	 */
	showError: PropTypes.bool,
};

export {
	Input as default,
	Input
}
