import {DataProviderService} from "../../services/DataProviderService";
import {storageManager} from "../../services/StorageManager";
import {dispatchIsAuthenticated} from "./globalStateManagementAction";

export const FAQ_DATA_UPDATE = 'FAQ_DATA_UPDATE';
const dispatchUpdateData = (data) => ({
	type: FAQ_DATA_UPDATE,
	data: data,
});

const fetchData = () => {

	// use different endpoint if not logged in
	let dataProviderConfig = {endpoint: 'portal/faq-public', useMock: false, useCache: false}
	if (storageManager.authToken) {
		dataProviderConfig = {endpoint: 'portal/faq', useMock: false, useCache: false}
	}

	const dataProvider = new DataProviderService(dataProviderConfig);

	return dispatch => {
		dispatch(dispatchUpdateData({isLoading: true}));
		return dataProvider.getData()
			.then((response) => {
				if (response.internalType) {
					handleError(dispatch, response.internalType)
				} else {
					let responseData = {list: response, loadingComplete: true, isLoading: false}
					dispatch(dispatchUpdateData(responseData));
				}
			})
			.catch(error => {
				console.warn("Error on fetching notice: ", error);
			})
	}
};
/**
 * do something specific for different kinds of errors
 * @param errorStatus
 */
const handleError = (dispatch, errorStatus) => {
	switch (errorStatus) {

		case 401:
			if(storageManager.authToken) {
				storageManager.authToken = null;
				dispatch(dispatchIsAuthenticated(false));
				dispatch(fetchData());
			}
			break;

		case "isCanceled":
			//do nothing here
			break;
		default:
			console.warn("error with code ", errorStatus);
			dispatch(dispatchUpdateData({hasLoadingError: true}));
	}
};


/**
 * generic fetchData action
 *
 * @param fetchType| one of the defined fetchType constants
 * @param silent | set to true if isLoading should not be set in the data while loading
 * @return {function(*): *}
 */
export const dispatchFetchFAQData = () => {
	return (dispatch) => {
		return dispatch(fetchData())
	}
};
