import React, {Component} from 'react';
import './LoginWrapper.scss';
import {LoginMask} from "./LoginMask";
import {LoginService} from "../../services/LoginService";
import {ResetPasswordMask} from "./ResetPasswordMask";
import {ForgotPasswordMask} from "./ForgotPasswordMask";
import {getQueryVariable} from "../../../vendor/utils/Utils";
import {NewPasswordMask} from "./NewPasswordMask";
import {VerificationMask} from "./VerificationMask";
import LogoutMask from "../../components/login/LogoutMask";
import PropTypes from 'prop-types';
import {trackPageView} from "../../../vendor/utils/TrackingManager";


const AUTH_TYPES = {
	LOGIN: 'login',
	LOGOUT: 'logout',
	RESET: 'reset',
	FORGOT: 'forgot',
	NEW_PASSWORD: 'new-password',
	VERIFICATION: 'verification'

}

class LoginWrapper extends Component {

	componentDidMount() {
		trackPageView({
			tabId:this.props.type, // generic
		});
	}

	componentDidUpdate(prevProps, prevState, snapshot) {
		if(this.props.type !== prevProps.type){
				trackPageView({
					tabId:this.props.type, // generic
				})
		}
	}

	/**
	 * create different content depending on type
	 * @param type
	 * @return {*}
	 */
	loginMaskFactory(type, isDesktop) {

		let component = null;
		let {from} = this.props.location?.state || {from: {pathname: "/"}};

		switch (type) {
			case AUTH_TYPES.LOGIN:
				component =
					<LoginMask redirectTo={from} isDesktop={isDesktop}
										 dataProvider={new LoginService({endpoint: 'login'})}/>;
				break;

			case AUTH_TYPES.RESET:
				const resetId = getQueryVariable(this.props.location.search, 'resetid');
				const offerToken = getQueryVariable(this.props.location.search, 'offer');
				from = offerToken ? `/digitaloffer/${offerToken}/start`:'/'
				component =
					<ResetPasswordMask resetId={resetId} redirectTo={from} isDesktop={isDesktop}
														 dataProvider={new LoginService({endpoint: 'resetpassword'})}/>;
				break;

			case AUTH_TYPES.FORGOT:
				component =
					<ForgotPasswordMask isDesktop={isDesktop}
															offerToken={this.props.location?.state?.offerToken}
															dataProvider={new LoginService({endpoint: 'resetpassword'})}/>;
				break;

			case AUTH_TYPES.NEW_PASSWORD :
				component =
					<NewPasswordMask isDesktop={isDesktop}
													 offerToken={this.props.location?.state?.offerToken}
													 redirectTo={from}
													 dataProvider={new LoginService({endpoint: 'change_password'})}/>;
				break;

			case AUTH_TYPES.VERIFICATION:
				component =
					<VerificationMask redirectTo={from} isDesktop={isDesktop}
														dataProvider={new LoginService({endpoint: 'getSecurityToken'})}/>;
				break;

			case AUTH_TYPES.LOGOUT:
				component =
					<LogoutMask redirectTo={from} isDesktop={isDesktop} dataProvider={new LoginService({endpoint: 'logout'})}/>;
				break;

			default:
				break;
		}

		return component;
	}

	render() {

		let type = this.props.type;

		return (
			<div className="login_wrapper">
				<div className='box box--large round-border login_wrapper__box'>
					{this.loginMaskFactory(type, this.props.isDesktop)}
				</div>

			</div>
		)
	}
}

LoginWrapper.propTypes = {

	/**
	 * string for valid login masks
	 */
	type: PropTypes.oneOf([
		AUTH_TYPES.LOGIN,
		AUTH_TYPES.RESET,
		AUTH_TYPES.FORGOT,
		AUTH_TYPES.NEW_PASSWORD,
		AUTH_TYPES.VERIFICATION,
		AUTH_TYPES.LOGOUT
	])
};

export {
	LoginWrapper as default,
	LoginWrapper,
	AUTH_TYPES
}
