import {
  CLOSE_MODAL,
  ENVIRONMENT,
  IS_AUTHENTICATED,
  IS_DESKTOP,
  IS_MAIN_CLIENT,
  IS_AGENCY_OFFER,
  JOURNEY_DATES,
  NOTIFICATION_MESSAGE_HIDDEN,
  NOTIFICATION_MESSAGE_SHOW,
  OFFER_LIST,
  STAGE_IMAGE,
  OFFER_NUMBER,
  OPEN_MODAL,
  PERMISSIONS,
  REVISION_NUMBER,
  STORE_CLEAR,
  CONSENT, IS_BOOKED,
} from "../actions/globalStateManagementAction";
import {IMAGE_DB_PREFIX} from "../../components/image/ImageDecoder";

const globalManagementState = {
  isDesktop: false,
  offer: {},
  offerList: [],
  revision: {},
  isAuthenticated: false,
  journeyStart: null,
  journeyEnd: null,
  permissions: null,
  hintId: null,
  modalId: null,
  notificationMessage: {
    visible: false
  },
};

const globalStateManagementReducer = (state = globalManagementState, action) => {

  switch (action.type) {
    case STORE_CLEAR:
      return {
        ...state,
        ...globalManagementState
      };

    case IS_DESKTOP:
      return {
        ...state,
        isDesktop: action.isDesktop,
      };

    case IS_AUTHENTICATED:
      return {
        ...state,
        isAuthenticated: action.isAuthenticated,
      };

    case JOURNEY_DATES:
      return {
        ...state,
        journeyStart: action.journeyStart,
        journeyEnd: action.journeyEnd
      };

    case IS_BOOKED:
      return {
        ...state,
        isBooked: action.isBooked,
      };

    case OFFER_LIST:
      return {
        ...state,
        offerList: action.offerList,
      };

    case STAGE_IMAGE:
      return {
        ...state,
        stageImage: {url: IMAGE_DB_PREFIX + action.stageImage, alt: ''},
      };

    case OFFER_NUMBER:
      return {
        ...state,
        offer: action.offer,
      };

    case REVISION_NUMBER:
      return {
        ...state,
        revision: action.revision,
      };

    case PERMISSIONS:
      return {
        ...state,
        permissions: action.permissions,
      };

    case IS_MAIN_CLIENT:
      return {
        ...state,
        isMainClient: action.isMainClient,
      };

    case IS_AGENCY_OFFER:
      return {
        ...state,
        isAgencyOffer: action.isAgencyOffer,
      };

    case ENVIRONMENT:
      return {
        ...state,
        environment: action.environment,
      };

    case OPEN_MODAL:
      return {
        ...state,
        modalId: action.modalId,
      };

    case CLOSE_MODAL:
      return {
        ...state,
        modalId: null,
      };

    case NOTIFICATION_MESSAGE_SHOW:
      return {
        ...state,
        notificationMessage: {...action.notificationMessage, visible: true},
      };

    case NOTIFICATION_MESSAGE_HIDDEN:
      return {
        ...state,
        notificationMessage: {...state.notificationMessage, visible: false}
      };

    case CONSENT:
      return {
        ...state,
        consent:{...action.consent}
      }

    default:
      return state
  }
};

export default globalStateManagementReducer;
