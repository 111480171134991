import React from 'react';
import './Select.scss'
import classNames from "classnames";
import {Icon} from "../icon/Icon";
import PropTypes from 'prop-types';
import {Tooltip} from "../tooltip/Tooltip";


function ControlledSelect(props) {

	const displayErrorState = props.showError;
	const classes = classNames(
		'select',
		{
			[`select--theme-${props.theme}`]: props.theme,
			'select--small': props.smallSize,
			'select--full-width': props.fullWidth,
		},
		{'field-error': displayErrorState},
		props.additionalClasses,
	);

	let inputJSX =
		<div className={classes}>
			{props.label &&
			<label className="select__label ts-copy-small">{props.label}</label>}
			<select
				value={props.value}
				name={props.name}
				disabled={props.disabled}
				onChange={(e) => props.onChange(e.target.value)}>
				{props.placeholder && <option disabled={true} value={'none'}>{props.placeholder}</option>}
				{props.options.map((option, i) => {
					return <option disabled={option.disabled || props.readOnly}
												 key={i}
												 value={option.value}>
						{(option.value !== props.value && option.extendedLabel) ? option.extendedLabel : option.label}
					</option>;
				})}
			</select>
			<div className="select__icon-wrapper">
				<Icon additionalClasses={'select__icon'} name={'arrow-down'}/>
			</div>
		</div>;

	let renderContent = null;

	if (props.errorMessage) {
		renderContent = <Tooltip
			content={props.errorMessage}
			showDefault={false}
			inactive={!displayErrorState}
			color={'important'}
			direction={props.tooltipDirection}
			parentContainerSelector={props.parentContainerSelector}
		>

			{inputJSX}
		</Tooltip>
	} else {
		renderContent = inputJSX;
	}

	return (
		<>
			{renderContent}
		</>
	);
}

ControlledSelect.propTypes = {
	/**
	 * sets all options to disabled
	 */
	readOnly: PropTypes.bool,

	/**
	 * disables select
	 */
	disabled: PropTypes.bool,

	/**
	 * sets css to have smaller top and bottom padding
	 */
	smallSize: PropTypes.bool,

	/**
	 * sets css to have 100% width
	 */
	fullWidth: PropTypes.bool,

	/**
	 * sets css to use a different theme
	 */
	theme: PropTypes.oneOf(['dark']),

	/**
	 * Defines a name for the drop-down list
	 */
	name: PropTypes.string,

	/**
	 * sets label
	 */
	label: PropTypes.string.isRequired,

	/**
	 * if placeholder text is set a default option will be added with value 'none'
	 */
	placeholder: PropTypes.string,

	/**
	 * add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.
	 */
	additionalClasses: PropTypes.string,

	/**
	 * array of options-objects
	 */
	options: PropTypes.arrayOf(
		PropTypes.shape({
			value: PropTypes.string.isRequired,
			label: PropTypes.string.isRequired,
			disabled: PropTypes.bool,
			extendedLabel: PropTypes.string
		})
	).isRequired,

	/**
	 * changeHandler to register on value changes of select
	 */
	onChange: PropTypes.func,

};

ControlledSelect.defaultProps = {
	readOnly: false,
	disabled: false,
};

export {
	ControlledSelect as default,
	ControlledSelect
}
