import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './AlternativeTeaserList.scss'
import AlternativeTeaserItem from "./AlternativeTeaserItem";
import classNames from "classnames";
import {connect} from "react-redux";

class AlternativeTeaserList extends Component {
	/**
	 * returns activeTeaserItem
	 * @param alternativeTeaserList
	 * @returns {*}
	 */
	getCurrentActiveItem(alternativeTeaserList) {
		alternativeTeaserList.forEach((itemId) => {
			const item = this.props.itemMap[itemId];
			if (item.active) {
				return item;
			}
		})
		return null;
	}

	/**
	 * returns active Teaser Item with counter of remainiung Items
	 * @param alternativeTeaserList
	 * @param sliderId
	 * @returns {*}
	 */
	renderAlternativesList(alternativeTeaserList, sliderId) {
		const item = this.getCurrentActiveItem(alternativeTeaserList);
		return item && <>
			<AlternativeTeaserItem item={item}
														 sliderId={sliderId}
														 key={item.id}
														 appearance={'minimal'}
														 isActive={true}
			/>
			{alternativeTeaserList.length > 1 &&
			<div className={'alternative-teaser-list__remaining-items'}>
				+ {alternativeTeaserList.length - 1}
			</div>
			}
		</>
	}


	render() {
		const classes = classNames(
			'alternative-teaser-list',
			this.props.additionalClasses
		);
		return (
			<div className={classes}>
				{this.renderAlternativesList(this.props.alternativeTeaser, this.props.sliderId)}
			</div>
		)
	}
}

AlternativeTeaserList.propTypes = {
	/**
	 * id of parent Item
	 */
	sliderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	/**
	 * required data for alternativesTeaser
	 */
	alternativeList: PropTypes.arrayOf(
		PropTypes.string
	),

	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string,
};

const mapStateToProps = (state, ownProps) => {
	return {
		itemMap: state.itemManagement.itemMap,
	}
};

export default connect(mapStateToProps)(AlternativeTeaserList)
