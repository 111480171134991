import React, {Component} from 'react';
import './VerificationMask.scss';
import {Input} from "../input/Input";
import {Button} from "../button/Button";
import {LinkInternal} from "../link/LinkInternal";
import {FormValidator} from "../form/FormValidator";
import {Tooltip} from "../tooltip/Tooltip";
import {Redirect} from "react-router-dom";
import {storageManager} from "../../services/StorageManager";
import PropTypes from 'prop-types';
import {formConfigMap, formErrorMessages} from "./FormUtils";

class VerificationMask extends Component {

	constructor(props) {
		super(props);

		this.state = {
			firstTimeError: false,
			errorMessage: null,
			gotSecurityToken: false,
			isLoading: false,
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.dataProvider = this.props.dataProvider;
		this.errorMessage = null;
		this.verificationHeadline = "Passwort bestätigen";

	}

	/**
	 * submit handler of Form
	 * send Form and set state depending on response
	 * if form was send for the first time then errors will be shown. not before
	 * @param form
	 * @param isValid
	 */
	handleSubmit({form, isValid}) {
		//the isValid prop is overwritten because is not used in this mask
		isValid = true;
		this.setState({
			isLoading: true
		});

		if (isValid) {

			this.dataProvider.setData(form).then(response => {

				if (response.err) {
					this.setState({
						gotSecurityToken: false,
						errorMessage: response.err,
						isLoading: false
					})

				} else if (response.auth_ok) {
					storageManager.securityToken = response.securityToken;
					this.setState({
						gotSecurityToken: true,
						errorMessage: null,
						isLoading: false
					})
				} else {
					this.setState({
						gotSecurityToken: false,
						errorMessage: formErrorMessages['unknownError'],
						isLoading: false
					})
				}
			});
		} else {
			this.setState({firstTimeError: true});
		}
	}

	render() {
		let redirectTo = this.props.redirectTo;

		if (this.state.gotSecurityToken) return <Redirect to={redirectTo}/>;

		return (
			<>
				<div className={"login_wrapper__mask-content"}>
					<h2 className='login_wrapper__headline'>{this.verificationHeadline}</h2>

					<FormValidator additionalClasses="login_wrapper__form"
								   onSubmit={this.handleSubmit}
								   render={({changeHandler, formIsValid}) => (
									   <>
										   <div className="login_wrapper__inputs">

											   <Input name="password"
													  label="Passwort"
													  type="password"
													  required={true}
													  onChange={changeHandler}
													  showError={this.state.firstTimeError}
													  fullWidth={true}
													  errorMessage={formConfigMap['verificationMask'].password.toolTipText}
											   />
										   </div>
										   <Tooltip
											   content={this.state.errorMessage}
											   showDefault={false}
											   inactive={!this.state.errorMessage}
											   direction={this.props.isDesktop ? 'left' : 'top'}
											   color={'important'}>
											   <Button type="submit"
													   label="Absenden"
													   additionalClasses="important login_wrapper__submit"
													   disabled={this.state.firstTimeError && !formIsValid}/>
										   </Tooltip>
									   </>
								   )}/>
					<div className={'login_wrapper__system-links'}>
						<LinkInternal label='Passwort vergessen'
									  linkPath='/forgot'
									  additionalClasses="login_wrapper__link"
						/>
						<LinkInternal label='Benutzer wechseln'
									  linkPath='/logout'
									  additionalClasses="login_wrapper__link"
						/>
					</div>
				</div>
				<div className={'login_wrapper__divider dotted'}/>
				<div className='login_wrapper__text'>
					<div
						className='h2 login_wrapper__headline login_wrapper__headline--visible-on-desktop'>{this.verificationHeadline}</div>
					Bitte bestätigen Sie Ihr Passwort, damit wir sicher sein können, dass Sie der rechtmäßige Inhaber
					des Kontos sind und wir den möglichen Missbrauch ausschließen können.
				</div>
			</>
		)
	}
}

VerificationMask.propTypes = {
	/**
	 * instance of LoginService
	 */
	dataProvider: PropTypes.object,

	/**
	 * Object with path to redirect after verification
	 */
	redirectTo: PropTypes.oneOfType([
		PropTypes.shape({
			pathname: PropTypes.string
		}),
		PropTypes.string,
	])
};

VerificationMask.defaultProps = {
	redirectTo: {pathname: "/"}
};

export {
	VerificationMask
}
