import React, {Component} from 'react';
import './Link.scss'
import {Link as RouterLink} from "react-router-dom";
import PropTypes from "prop-types";
import {Link} from "./Link";

class LinkInternal extends Component {

	render() {

		let {linkPath, withoutAnchor, ...passThroughProps} = this.props;

		return (
				<RouterLink to={linkPath}
							className={this.props.disabled && 'disabled'}
				>
					<Link {...passThroughProps} withoutAnchor={true}/>
				</RouterLink>
		);
	}
}

LinkInternal.propTypes = {
	/**
	 * **REQUIRED**: for type: **internal**
	 */
	linkPath: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,

	/**
	 * Link Label
	 *
	 * @example: Link Label
	 */
	label: PropTypes.string,

	/**
	 * Link Icon
	 *
	 * Visit http://localhost:6060/#icon for more infos.
	 *
	 * @example: 'pdf'
	 */
	icon: PropTypes.string,

	/**
	 * Link Title (leads to browser specific tooltip)
	 */
	title: PropTypes.string,

	/**
	 * Link with (dotted) underline
	 *
	 * @default: true
	 */
	underline: PropTypes.bool,

	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.
	 */
	additionalClasses: PropTypes.string,

	/**
	 * disabled
	 * @default: false
	 */
	disabled: PropTypes.bool,
};

export {
	LinkInternal as default,
	LinkInternal
}
