import React, {useEffect} from 'react';
import './Footer.scss'
import {Link} from "../link/Link";
import classNames from "classnames";
import PropTypes from "prop-types";
import {
  dispatchFetchData,
  FETCH_TYPE_FOOTER
} from "../../redux/actions/fetchAction";
import {connect} from "react-redux";
import Icon from "../icon/Icon";
import SocialMediaBlock from "../socialMediaBlock/SocialMediaBlock";
import {chunkArray} from "../../../vendor/utils/Utils";

function Footer({dispatchFetchData, ...props}) {

  useEffect(() => {

    dispatchFetchData();

  }, [dispatchFetchData]);

  const hasCompanyLink = !!props.companyLink && !!props.companyLink.url && !!props.companyLink.label;

  const classes = classNames(
    'footer',
    props.additionalClasses,
  );

  function createMetaLinks(links, chunkLength = 3) {
    if (!links) {
      return null;
    }

    // Kontakt should be removed if offer is agency offer
    const footerLinks = props.isAgencyOffer ? links.slice(0, links.length-1):links;

    const linkList = footerLinks.map((link, index) => {
      return (
        <Link
          href={link.url}
          target={link.target}
          label={link.label}
          additionalClasses={'footer__link'}
          underline={false}
          key={`link-${index}`}
        />
      )
    })

    if (linkList.length > chunkLength) {
      return chunkArray(linkList, chunkLength).map((chunk, index) =>
        <div className={'footer-lower-nav__meta-chunk'} key={index}>{chunk}</div>
      )
    }
    return linkList;
  }

  return (
    <footer className={classes}>
      <nav className={"footer-lower-nav"}>
        <div className={"footer-lower-nav__logo-wrapper"}>
          <div className={"footer-lower-nav__logo"}>
            <Icon name={'favicon'}/>
          </div>
        </div>
        <div className={"footer-lower-nav__wrapper"}>
          <SocialMediaBlock/>

          <div className={"footer-lower-nav__claim"}>
            <div className={"footer-lower-nav__claim-headline ts-handwriting"}>CANUSA - Touristik seit 1983</div>
            <div className={"footer-lower-nav__claim-copy"}>Wir beraten Sie persönlich und gestalten Ihre
              individuelle&nbsp;Traumreise ganz nach Ihren Wünschen.
            </div>

            {hasCompanyLink &&
            <div className={'footer__company-link'}>
              <Link
                href={props.companyLink.url}
                target={props.companyLink.target}
                label={props.companyLink.label}
                additionalClasses={'footer__link'}
                underline={false}
              />
            </div>
            }
          </div>
          <div className={"footer-lower-nav__meta"}>
            {createMetaLinks(props.links)}
          </div>
        </div>
      </nav>
    </footer>
  )
}

Footer.propTypes = {
  /**
   * OPTIONAL: add additional classes for this component here.
   * Use this prop for special css-classes, which are not defined by default.*
   */
  additionalClasses: PropTypes.string,

  /**
   * JSON-formatted link object
   */
  companyLink: PropTypes.shape({
    /**
     * link label
     */
    label: PropTypes.string,
    /**
     * @args: '_blank', '_self', '_top'
     */
    target: PropTypes.oneOf(['_blank', '_self', '_top']),
    /**
     * link url
     */
    url: PropTypes.string
  }),

  /**
   * JSON-formatted Array of link objects
   */
  links: PropTypes.arrayOf(PropTypes.shape({
    /**
     * link label
     */
    label: PropTypes.string,
    /**
     * @args: '_blank', '_self', '_top'
     */
    target: PropTypes.oneOf(['_blank', '_self', '_top']),
    /**
     * link url
     */
    url: PropTypes.string
  }))
};

const mapStateToProps = (state, ownProps) => {
  return {
    ...state.footerManagement,
    isAgencyOffer: state.globalStateManagement.isAgencyOffer,
  }
};

export default connect(mapStateToProps, {dispatchFetchData: () => dispatchFetchData(FETCH_TYPE_FOOTER)})(Footer)
