import React, {Component} from 'react';
import './Gallery.scss'
import {Image} from "../image/Image";
import {Modal} from "../modal/Modal";
import {GallerySlider} from "./gallery-slider/GallerySlider";
import {BREAKPOINTS} from "../../../vendor/utils/BreakpointUtils";
import PropTypes from "prop-types";

//IMAGE PROPOTION
const IMAGE_FULL = 1;
const IMAGE_HALF = 0.50;
const IMAGE_TWO_THIRD = 0.66;
const IMAGE_ONE_THIRD = 0.33;

//GALLERY LAYOUTS
const LAYOUTS = {
  1: {
    1: IMAGE_FULL
  },
  2: {
    1: IMAGE_HALF,
    2: IMAGE_HALF
  },
  3: {
    1: IMAGE_TWO_THIRD,
    2: IMAGE_ONE_THIRD,
    3: IMAGE_ONE_THIRD
  },
  4: {
    1: IMAGE_HALF,
    2: IMAGE_HALF,
    3: IMAGE_HALF,
    4: IMAGE_HALF
  },
  5: {
    1: IMAGE_HALF,
    2: IMAGE_HALF,
    3: IMAGE_TWO_THIRD,
    4: IMAGE_ONE_THIRD,
    5: IMAGE_ONE_THIRD
  },
  6: {
    1: IMAGE_TWO_THIRD,
    2: IMAGE_ONE_THIRD,
    3: IMAGE_ONE_THIRD,
    4: IMAGE_ONE_THIRD,
    5: IMAGE_ONE_THIRD,
    6: IMAGE_TWO_THIRD
  },
  7: {
    1: IMAGE_HALF,
    2: IMAGE_HALF,
    3: IMAGE_ONE_THIRD,
    4: IMAGE_ONE_THIRD,
    5: IMAGE_ONE_THIRD,
    6: IMAGE_HALF,
    7: IMAGE_HALF
  },
  8: {
    1: IMAGE_TWO_THIRD,
    2: IMAGE_ONE_THIRD,
    3: IMAGE_ONE_THIRD,
    4: IMAGE_HALF,
    5: IMAGE_HALF,
    6: IMAGE_ONE_THIRD,
    7: IMAGE_ONE_THIRD,
    8: IMAGE_TWO_THIRD
  },
  9: {
    1: IMAGE_ONE_THIRD,
    2: IMAGE_ONE_THIRD,
    3: IMAGE_ONE_THIRD,
    4: IMAGE_ONE_THIRD,
    5: IMAGE_ONE_THIRD,
    6: IMAGE_ONE_THIRD,
    7: IMAGE_ONE_THIRD,
    8: IMAGE_ONE_THIRD,
    9: IMAGE_ONE_THIRD
  }
};

class Gallery extends Component {

  constructor(props) {
    super(props);

    this.widthChange = this.widthChange.bind(this);
    this.closeGallerySlider = this.closeGallerySlider.bind(this);
    this.renderStackedGallery = this.renderStackedGallery.bind(this);
    this.renderPrintGallery = this.renderPrintGallery.bind(this);

    this.mediaQuery = window.matchMedia(`(min-width: ${BREAKPOINTS.tablet}px)`);
    this.mediaQuery.addListener(this.widthChange);

    this.state = {
      showGallerySlider: false,
      sliderStartAt: 0,
      isDesktop: this.mediaQuery.matches
    };
  }

  widthChange() {
    this.setState({
      isDesktop: this.mediaQuery.matches
    });
  }

  /**
   * @typedef  {Object}        Sizes
   *
   * @property {String}        [ultraWideDesktop]    - size for ultra wide desktop breakpoint
   * @property {String}        [wideDesktop]        - size for wide desktop breakpoint
   * @property {String}        [desktop]            - size for desktop breakpoint
   * @property {String}        [tablet]            - size for tablet breakpoint
   * @property {String}        [default]            - default size
   */

  /**
   * Concat gallery size and image propotion with css calc function and build sizes object for image component
   *
   * @example
   *        Gallery.getSizes(33, {
   * 			ultraWideDesktop:'1170px',
   *			desktop: '62vw',
   *			tablet: '100vw',
   *			default: '100vw'
   * 		})
   *
   *        => return {
   * 			ultraWideDesktop: "calc(1170px * 0.33)",
   * 			desktop: "calc(62vw * 0.33)",
   * 			tablet: "calc(100vw * 0.33)",
   * 			default: "100vw"
   * 		}
   *
   * @param {Number} imageProportion
   * @param {Sizes} gallerySizes
   *
   * @return {Sizes} sizes
   *
   */
  getSizes(imageProportion, gallerySizes) {
    let sizes = {};

    for (let viewportName of Object.keys(gallerySizes)) {
      const gallerySize = gallerySizes[viewportName];

      if (viewportName === 'default') {
        sizes[viewportName] = gallerySize;
      } else {
        sizes[viewportName] = gallerySize && `calc(${gallerySize} * ${imageProportion})`;
      }
    }
    return sizes;
  }

  /**
   * event handler for click on thumbnail
   * set start index for gallery slider and trigger modal
   *
   * @param  {number}  index
   */
  onThumbnailClick(index) {
    this.setState({
      showGallerySlider: true,
      sliderStartAt: index
    })
  }

  /**
   * modal close handler
   */
  closeGallerySlider() {
    this.setState({
      showGallerySlider: false
    })
  }

  componentWillUnmount() {
    if (this.mediaQuery) {
      this.mediaQuery.removeListener(this.widthChange);
    }
  }

  renderStackedGallery() {
    const componentCSSClass = 'gallery';
    return <div className={`${componentCSSClass} ${componentCSSClass}--imgs-${this.props.images.length}`}>
      {this.props.images.map(function (image, index) {
        const imageProportion = LAYOUTS[this.props.images.length][index + 1];
        return (
          <div className={`${componentCSSClass}__thumb`} key={`${componentCSSClass}-thumb-${index}`}
               onClick={() => {
                 if (this.state.isDesktop) this.onThumbnailClick(index)
               }}>
            <Image url={image.url} alt={image.alt} ratio={{width: 4, height: 3}}
                   sizes={this.getSizes(imageProportion, this.props.sizes)}
                   additionalClasses={`${componentCSSClass}__thumb-img`}/>
          </div>)
      }.bind(this))}
      {this.state.showGallerySlider &&
      <Modal onClose={this.closeGallerySlider} theme={'dark'} size={'fullscreen'}
             additionalClasses={'gallery__modal'}>
        <GallerySlider images={this.props.images} startAt={this.state.sliderStartAt}/>
      </Modal>}
    </div>
  }

  renderPrintGallery() {
    return <div className={'print-image'}>
      {this.props.images.map(function (image, index) {
        return <Image url={image.url} alt={image.alt} additionalClasses={'print-image__image'} key={index}/>
      })}
    </div>
  }

  render() {
    return (
      this.props.isPrintView ?
        this.renderPrintGallery()
        :
        this.renderStackedGallery()
    )
  }
}

Gallery.defaultProps = {
  images: [],
  sizes: {
    ultraWideDesktop: '1170px',
    desktop: '62vw',
    tablet: '100vw',
    default: '100vw'
  }
};

Gallery.propTypes = {
  /**
   * JSON-formatted array of imageObjects
   */
  images: PropTypes.arrayOf(PropTypes.shape(
    {
      /**
       * image url
       * @example: 'https://images.canusa.de/img/regionen/bahamas/nassau/allgemein/17katalog-bahamas-nassau-haengematte.jpg'
       */
      url: PropTypes.string,
      /**
       * alt text
       * @example: 'Ihre Traumreise'
       */
      alt: PropTypes.string
    }
  )).isRequired,

  /**
   * overwrite breakpoints definitions if necessary:
   *
   * @example: {
   * 	ultraWideDesktop: '1170px',
   * 	desktop: '62vw',
   * 	tablet: '100vw',
   * 	default: '100vw'
   * }
   */
  sizes: PropTypes.shape({
    /**
     * @default: '1170px'
     */
    ultraWideDesktop: PropTypes.string,
    /**
     * @default: '62vw'
     */
    desktop: PropTypes.string,
    /**
     * @default: '100vw'
     */
    tablet: PropTypes.string,
    /**
     * @default: '100vw'
     */
    default: PropTypes.string
  }),
};

export {
  Gallery as default,
  Gallery
}
