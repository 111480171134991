import React, {Component} from 'react';
import {Button} from "../components/button/Button";

class SwitchOfferListButton extends Component {

	constructor(props) {
		super(props);
		this.clickHandler = this.clickHandler.bind(this);
	}

	clickHandler() {

		let url = new URL(window.location.href);
		url.searchParams.append('useMockOffers', 'true');
		window.location.href = url.href;
	}

	render() {

		return (
			<>
				<Button label={"Beispiel-Angebote laden"} onClick={this.clickHandler} width={'fit'}/>
			</>
		);
	}
}
export {
	SwitchOfferListButton as default,
	SwitchOfferListButton
}
