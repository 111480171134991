// types from API
const typeToIconMap = {

	// old Mappings used in Mocks at the Moment TODO: update mocks with data from live service when ready and delete list till END LIST
	//START LIST
	"trip": {icon: "active", label: "trip"},
	"wildlife-observation": {icon: "active", label: "wildlife-observation"},
	"Ausflug": {icon: "active", label: "Ausflug"},
	"Rundflug": {icon: "active", label: "Rundflug"},
	"Aktivreisen": {icon: "active", label: "Aktivreise"},
	"Fähre": {icon: "ferry", label: "Fähre"},
	"Aktivreisen Sommer": {icon: "active", label: "Aktivreise Sommer"},
	"Aktivreisen Winter": {icon: "active", label: "Aktivreise Winter"},
	"Inselrundfahrt": {icon: "active", label: "Inselrundfahrt"},
	"Kleingruppenreise": {icon: "active", label: "Kleingruppenreise"},
	"Sommercamp": {icon: "camping", label: "Sommercamp"},
	"Sprachreise": {icon: "active", label: "Sprachreise"},
	"Tierbeobachtung": {icon: "active", label: "Tierbeobachtung"},
	"FlugHawaii": {icon: "plane", label: "Flug"},
	"car": {icon: "car", label: "Mietwagen"},
	"Motorrad": {icon: "biker", label: "Motorrad"},
	"Transfer": {icon: "transfer", label: "Transfer"},
	"Wohnmobil": {icon: "motorhome", label: "Wohnmobil"},
	"Kreuzfahrt": {icon: "cruise", label: "Kreuzfahrt"},
	"Busreise": {icon: "coach", label: "Busreise"},
	"Bahnfahrt": {icon: "train", label: "Bahnfahrt"},
	"Versicherung": {icon: "insurance", label: "Versicherung"},
	"Extras": {icon: "thumb", label: "Unsere Empfehlung"},
	"Unsere Routenempfehlung": {icon: "pin", label: "Unsere Routenempfehlung"},
	"flights": {icon: "plane", label: "Flug"},
	"Infopaket": {icon: "info-package", label: "Reisepaket"},
	// END LIST

	// new Mappings from Portal CPV
	"active-journey": {icon: "active", label: "Aktivreise"},
	"active-journey-winter": {icon: "active", label: "Aktivreise Winter"},
	"active-journey-summer": {icon: "active", label: "Aktivreise Sommer"},
	"excursion": {icon: "active", label: "Ausflug"},
	"ticket": {icon: "ticket", label: "Eintrittskarte"},
	"extras": {icon: "thumb", label: "Unsere Empfehlung"},
	"flight": {icon: "plane", label: "Flug"},
	"ferry": {icon: "ferry", label: "Fähre"},
	"hotel": {icon: "hotel", label: "Hotel"},
	"info-package": {icon: "info-package", label: "Reisepaket"},
	"marry": {icon: "wedding", label: "Heiraten"},
	"rental-car": {icon: "car", label: "Mietwagen"},
	"sightseeing-flight": {icon: "active", label: "Rundflug"}, // maybe helicopter? label:"sightseeing-flight"},
	"insurance": {icon: "insurance", label: "Versicherung"},
	"valuecard": {icon: "ticket", label: "Wertkarte"},
	"voucher": {icon: "ticket", label: "Gutschein"},
	"camper": {icon: "motorhome", label: "Wohnmobil"},
	"cruise": {icon: "cruise", label: "Kreuzfahrt"},
	"bustrip": {icon: "coach", label: "Busreise"},
	"ride": {icon: "train", label: "Bahnfahrt"},
	"ski-pass": {icon: "skier", label: "Ski-Pass oder Ski-Verleih"},
	"ski-rental": {icon: "skier", label: "Ski-Pass oder Ski-Verleih"},
	"transfer": {icon: "transfer", label: "Transfer"},
	"wildlife-viewing": {icon: "active", label: "Tierbeobachtung"},
	"summer-camp": {icon: "camping", label: "Sommercamp"},
	"motorcycle": {icon: "biker", label: "Motorrad"},
	"our-route-recommendation": {icon: "pin", label: "Unsere Routenempfehlung"},
	"seat-reservation": {icon: "ticket", label: "Sitzplatzreservierung"},
	"small-group-travel": {icon: "group", label: "Gruppenreise"},
	"island-tour": {icon: "active", label: "Inselrundfahrt"},
	"stay-under-your-own-management": {icon: "globe", label: "Unter Eigenregie"},

	// icons from legacy endpoint
	"home": {icon: "hotel", label: "Hotel"},
	"bus":{icon: "motorhome", label: "Wohnmobil"},
	"train": {icon: "train", label: "Bahnfahrt"},
	"airport": {icon: "plane", label: "Flug"},
	"car-dealer": {icon: "car", label: "Mietwagen"},
	//"location-options": 				{icon:"location-options", label://"location-options"},
	//"language-journey": 				{icon:"language-journey", label://"language-journey"},
	"default": 						{icon:"globe", label:"default"},
	//"special": 						{icon:"special", label://"special"},
};


const routeMap = {
	'offer': 'reise',
	'invoice': 'rechnung',
	'documents': 'dokumente',
	'optionals': 'ausfluege',
};


const offerStatusMap = {
	'verschickt': {label: 'Verschickt', type: 'important'},
	'angenommen': {label: 'Angenommen', type: 'signal'},
	'ueberarbeitet': {label: 'Überarbeitet', type: 'default'},
	'abgelehnt': {label: 'Abgelehnt', type: 'important'},
	'kunde': {label: 'Kunde', type: 'important'},
}


const errorMap = {
	'5xx' : {message: 'Ihre Änderungen konnten nicht gespeichert werden!', type: 'error'},
	'502' : {message: 'Ein Serverfehler ist aufgetreten. Bitte versuchen sie es in ein paar Minuten erneut.', type: 'error'},
	'invalidate' : {message: 'Sie wurden abgemeldet.', type: 'error'},
}


// new icons with english names
/*
{
	'ausfluege': 'active',
	'alaska-yukon': 'bear',
	'aktivreisen': 'hiker',
	'autoreisen': 'car',
	'rentalcar': 'car',
	'mietwagen': 'car',
	'busreisen': 'coach',
	'bahnreisen': 'train',
	'cat-ski': 'snowcat',
	'dog-sled': 'sled',
	'daumen': 'thumb',
	'fahrrad': 'cyclist',
	'faehren': 'ferry',
	'flights': 'plane',
	'gruppenreise': 'group',
	'heiraten': 'wedding',
	'heli': 'helicopter',
	'kreuzfahrten': 'cruise',
	'motorraeder': 'biker',
	'sommercamps': 'camping',
	'skireisen': 'skier',
	'snowmobil': 'snowjet',
	'versicherungen': 'insurance',
	'voucher': 'ticket',
	'wohnmobile': 'motorhome',
	'hawaii':'hawaii',
	'hotel':'hotel',
	'pension':'pension',
	'sports':'sports',
	'transfer':'transfer',
	'trolley':'trolley',
}*/

// Mapping in CPV to english values

/*
	'fallback'                => 'fallback',
	'not_found'               => 'not-found',
	'default'                 => 'default',
	'Special'                 => 'special',
	'Ausflug'                 => 'excursion',
	'Eintrittskarte'          => 'ticket',
	'extras'                  => 'extras',
	'Flug'                    => 'flight',
	'FlugHawaii'              => 'flight-hawaii',
	'F&auml;hre'              => 'ferry',
	'Hotel'                   => 'hotel',
	'Infopaket'               => 'info-package',
	'location_options'        => 'location-options',
	'Mietwagen'               => 'rental-car',
	'Rundflug'                => 'sightseeing-flight',
	'Versicherung'            => 'insurance',
	'Wertkarte'               => 'valuecard',
	'Wohnmobil'               => 'camper',
	'Kreuzfahrt'              => 'cruise',
	'Busreise'                => 'bustrip',
	'Bahnfahrt'               => 'ride',
	'Transfer'                => 'transfer',
	'Tierbeobachtung'         => 'wildlife-viewing',
	'Sprachreise'             => 'language-journey',
	'Sommercamp'              => 'summer-camp',
	'Motorrad'                => 'motorcycle',
	'Unsere Routenempfehlung' => 'our-route-recommendation',
	'Sitzplatzreservierung'   => 'seat-reservation',
	'Kleingruppenreise'       => 'small-group-travel',
	'Inselrundfahrt'          => 'island-tour',
 */


const contextToRouteMap = {
	"documents": "dokumente",
	"offer": "reise",
	"invoice": "rechnung"
};


function getErrorText(internalType) {
	return (errorMap[internalType] || errorMap['5xx']);
}


/**
 * returns english name of the icon
 * TODO: can be removed after new api-implementation ist done
 * @param name
 * @returns {*|string}
 */
function mapTypeToIcon(name) {
	return (typeToIconMap[name] && typeToIconMap[name].icon) || 'globe';
}


function mapLabelType(type) {
	return (typeToIconMap[type] && typeToIconMap[type].label) || null;
}

function mapOfferStatus(status) {
	return {label: offerStatusMap[status].label, type: offerStatusMap[status].type}
}


export {
	typeToIconMap,
	routeMap,
	errorMap,
	getErrorText,
	mapLabelType,
	mapTypeToIcon,
	mapOfferStatus,
	contextToRouteMap
}
