import {cleanOfferNumber} from './Utils'

if (!window.dataLayer) {
    window.dataLayer = [];
}

let hasBeenInitialyTracked = false;

const clearTrackingProps= (propsToClear, processObject) => {
    propsToClear.forEach(prop => {
        if(processObject.hasOwnProperty(prop)) {
            window.dataLayer.push({[prop]:null});
        }
    })
}

export const trackEvent = (processObject) => {
    clearTrackingProps(['ecommerce','submitAttempt', 'documentDownload'], processObject);
    window.dataLayer.push(processObject);
};

export const trackPageView = (processObject) => {
    const pageViewObject = {
        event: 'Virtual Page View',
        offerDetails: processObject,
        initialTracking : !hasBeenInitialyTracked,
    }
    if(!hasBeenInitialyTracked) {
        hasBeenInitialyTracked= true;
    }
    trackEvent(pageViewObject);
}

export const trackCheckoutParticipants = (totalPrice) => {
    trackEvent({
        event: 'begin_checkout',
        ecommerce: {
            currency: 'EUR',
            value: totalPrice
        }
    });
}

export const trackCheckoutContact = () => {
    trackEvent({
        event: 'add_address_info'
    });
}

export const trackCheckoutSummary = () => {
    trackEvent({
        event: 'checkout_summary'
    });
}

export const trackCheckoutPurchase = (totalPrice, offerNumber) => {
    trackEvent({
        event: 'purchase',
        ecommerce: {
            currency: 'EUR',
            value: totalPrice,
            transaction_id: cleanOfferNumber(offerNumber),
        }
    });
}

export const trackSubmitAttempt = (type, success, reason='') => {

    const submitAttempt =  {type, success};
    if(!!reason) {
        submitAttempt.reasonForFailure = reason;
    }
    trackEvent({event: 'submit_attempt', submitAttempt});
}

export const trackDocumentDownload = (label, type, success) => {
    trackEvent({
        event: 'document_download',
        documentDownload: {
            label: label,
            type: type,
            success: success
        }
    })
}
