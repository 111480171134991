import React, {Component} from 'react';
import './RevisionSelect.scss'
import {Icon} from "../icon/Icon";
import {BUTTON_APPEARANCE, BUTTON_WIDTH, Button} from "../button/Button";
import {Modal} from "../modal/Modal";
import classNames from "classnames";
import {getSmallPaddedFormatDate} from "../../../vendor/utils/DateUtils";
import {Tooltip} from "../tooltip/Tooltip";
import PropTypes from "prop-types";
import {connect} from "react-redux";
import {dispatchFetchData, FETCH_TYPE_DIGITAL_OFFER_REVISION_LIST} from "../../redux/actions/fetchAction";
import {Badge} from "../badge/Badge";
import {mapOfferStatus} from "../../../vendor/utils/Mappings";
import {dispatchRevisionChange} from "../../redux/actions/globalStateManagementAction";
import {dispatchHasRouteHints} from "../../redux/actions/routeHintManagementAction";

class RevisionSelect extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showOfferModal: false
    };
    this.selectChangeHandler = this.selectChangeHandler.bind(this);
    this.showOfferModal = this.showOfferModal.bind(this);
  }

  componentDidMount() {
    this.props.dispatchFetchData();
  }

  /**
   * change handler on offer-select change
   * @param value {string}
   */
  selectChangeHandler(value) {
    const selectedOffer = this.getOfferByNumber(this.props.revisionList, value);
    this.props.dispatchRevisionChange(selectedOffer);
  }

  /**
   * toggle modal visibility
   * @param show
   */
  showOfferModal(show) {
    this.setState({
      showOfferModal: show
    });
  }

  /**
   * filters offer from revisionList by offerNumber
   *
   * @param revisionList
   * @param offerNumber
   * @return {object}
   */
  getOfferByNumber(revisionList, offerNumber) {
    return revisionList.find((offer) => {
      return offer.offerNumber === offerNumber;
    });
  }

  /**
   * parse revisionList to option objects
   * @param offerList {array}
   * @return {array}
   */
  parseOptions(revisionList) {
    return revisionList.map((revisionData) => {
      return {
        value: revisionData.offerNumber,
        label: `${revisionData.offerNumber}`,
        creationDate: revisionData.created,
        status: revisionData.status,
        isCurrentVersion: revisionData.currentVersion

      }
    })
  }

  /**
   *
   * @param optionIten (value, label, creationDate, status, isCurrentVersion)
   * @param isSelected
   * @param modalCloseHandler
   * @returns {*}
   */
  renderRevisionSelectItem(optionItem, isSelected, modalCloseHandler) {
    const classes = classNames(
      'revision-selection__item',
      {'revision-selection__item--selected': isSelected}
    );

    const {label, type} = mapOfferStatus(optionItem.status);

    let itemContent =
      <>
        <div className={'revision-selection__radio-button'}>
          <Icon name={isSelected ? 'radio-check' : 'radio'}/>
        </div>

        <div className={'revision-selection__content'}>

          <div className={'revision-selection__revision-number'}>
            <div className={'revision-selection__revision-number-column'}>{optionItem.value} <Badge additionalClasses={'revision-selection__badge'}
                                          label={label}
                                          type={type}
                                           size={'small'}/></div>
            <div className={'revision-selection__small-subline ts-copy-small'}>Angebotsversion</div>
          </div>

          <div className={'revision-selection__date'}>
            <div>{getSmallPaddedFormatDate(optionItem.creationDate)}</div>
            <div className={'revision-selection__small-subline ts-copy-small'}>Bearbeitungsdatum</div>
          </div>
        </div>

        <div className={'revision-selection__info-icon'}>
          {optionItem.journeyDescription &&
          <Tooltip content={optionItem.journeyDescription}
                   direction={'left'}
          >
            <Icon name={'circle-info'}/>
          </Tooltip>
          }

        </div>
      </>;

    return (
      <div key={optionItem.value} className={classes} onClick={() => {
        this.selectChangeHandler(optionItem.value);
        modalCloseHandler();
      }}>
        {itemContent}
      </div>
    )
  }

  /**
   * get latest OfferNumber
   * if no match available, use the revisionNumber from Service
   * @param revisionList
   * @param offerNumber
   * @param revisionNumber
   * @returns {*}
   */
  getLatestOfferNumber(revisionList, offerNumber, revisionNumber) {

    let returnValue = revisionNumber;
    revisionList.forEach((item) => {
      if (item.offerNumber === offerNumber) {
        returnValue = item.offerNumber;
      }
    });

    return returnValue;
  }

  /**
   * render content of the modal
   * @param revisionList
   * @param offerNumber
   * @param revisionNumber
   * @param modalCloseHandler
   * @returns {*}
   */
  renderModalContent(revisionList, offerNumber, revisionNumber, modalCloseHandler) {
    const list = this.parseOptions(revisionList);

    list.sort(function (a, b) {
      return a.created - b.created
    }).reverse()

    const latestOfferNumber = this.getLatestOfferNumber(revisionList, offerNumber, revisionNumber);

    const itemList = list.map((optionItem) => {
      const isSelected = latestOfferNumber === optionItem.value;

      return this.renderRevisionSelectItem(optionItem, isSelected, modalCloseHandler);
    });

    return (
      <div className={'revision-selection'}>
        {itemList}
      </div>
    )
  }

  render() {

    if (!this.props.revisionList || !this.props.offerNumber) return null;

    const travelCount = (this.props.revisionList.length > 1) ?
      this.props.revisionList.length + ' Versionen'
      :
      this.props.revisionList.length + ' Version';

    return (<>
        <Button label={'Angebotsversion'}
                icon={'globe'}
                appearance={BUTTON_APPEARANCE.GHOST}
                width={BUTTON_WIDTH.FIT}
                additionalClasses={'header__button'}
                onClick={() => this.showOfferModal(true)}
        />

        {this.state.showOfferModal &&
        <Modal onClose={() => this.showOfferModal(false)}
               size={"medium"}

               render={({modalCloseHandler}) => (
                 <div className={'revision-selection'}>
                   <div className={'revision-selection__headcontent'}>
                     <div className={'revision-selection__headline h2 tt-none'}>
                       Ihr Angebotsverlauf
                     </div>
                     <div className={'revision-selection__subline h5'}>{travelCount}</div>
                   </div>

                   {(this.props.revisionList && this.props.offerNumber) &&
                   this.renderModalContent(this.props.revisionList, this.props.offerNumber, this.props.revisionNumber, modalCloseHandler)
                   }
                 </div>
               )}
        />
        }
      </>
    )
  }
}

RevisionSelect.defaultProps = {};

RevisionSelect.propTypes = {

  /**
   * @example: 'HAM-1339657-BSC'
   */
  offerNumber: PropTypes.string.isRequired,

  revisionList: PropTypes.arrayOf(PropTypes.shape({

    /**
     * @example: 'HAM-1339657-BSC'
     */
    offerNumber: PropTypes.string,

    /**
     * @example: '2019-06-26'
     */
    bookingDate: PropTypes.string,

    /**
     * @example: '2019-06-29'
     */
    journeyStart: PropTypes.string,

    /**
     * @example: '2019-07-3'
     */
    journeyEnd: PropTypes.string,

  })),

};

const mapStateToProps = (state, ownProps) => {
  return {
    offerNumber: state.globalStateManagement.offer.offerNumber,
    offerToken: state.globalStateManagement.offer.offerToken,
    hasRouteHints: state.globalStateManagement.hasRouteHints,
    revisionList: state.revisionListManagement.revisionList,
    revisionNumber: state.revisionListManagement.revisionNumber
  }
};

export default connect(mapStateToProps, {
  dispatchFetchData: () => dispatchFetchData(FETCH_TYPE_DIGITAL_OFFER_REVISION_LIST, false, {}, true),
  dispatchRevisionChange: dispatchRevisionChange,
  dispatchHasRouteHints: dispatchHasRouteHints,
})(RevisionSelect)
