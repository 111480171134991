import React, {Component} from 'react';
import './FullWidthImage.scss'
import {Image} from "../image/Image";
import PropTypes from "prop-types";
import classNames from "classnames";

class FullWidthImage extends Component {

	render() {
		const classes = classNames(
			'full-w-img',
			{
				'full-w-img--joined': this.props.join,
				'full-w-img--fullwidth': this.props.fullwidth
			},
			this.props.additionalClasses
		);
		return (
			<div className={classes}>
				{this.props.images && this.props.images.map(function (image, index) {

					return (
						<Image url={image.url} ratio={image.ratio} srcSet={image.srcSet} media={image.media} keepCropValue={true} imageOptions={image.imageOptions} sizes={image.sizes}
							   key={index}/>
					)
				})}
			</div>
		)
	}
}

FullWidthImage.propTypes = {

	/**
	 * array of ImageVOs
	 */
	images: PropTypes.arrayOf(
		PropTypes.shape({
			/**
			 * image url @example: 'https://images.canusa.de/img/regionen/bahamas/nassau/allgemein/17katalog-bahamas-nassau-haengematte.jpg'
			 */
			url: PropTypes.string,
			/**
			 * alt text @example: 'Ihre Traumreise'
			 */
			alt: PropTypes.string,
			/**
			 * image ratio @example: {width: 16, height: 13}
			 */
			ratio: PropTypes.shape({
				width:PropTypes.number,
				height:PropTypes.number,
			}),
			/**
			 * media query: @example: '(max-width: 767px)'
			 */
			media: PropTypes.string,
			/**
			 * @typedef  {Object}        ImageOptions
			 *
			 * @property {String}         srcPrefix         -
			 * @property {String}         basePath          -
			 * @property {String}        [direction]		- valid values: 'NorthWest' | 'North' | 'NorthEast' | 'NorthEast' | 'Center' | 'East' | 'SouthWest' | 'South' | 'SouthEast'
			 * @property {SizeOption}    [size]            	- see SizeOption
			 * @property {CropOption}    [crop]            	- see CropOption
			 * @property {String}        [background]    	- hex value
			 * @property {String}        [colorSpace]    	- valid values: 'srgb' | 'rgb' | 'gray' | 'cmyk'
			 * @property {String}        [gamma]            - 3 digits value between 001 - 999
			 * @property {Boolean}       [negate]           - negate image
			 * @property {String}        [quality]          - value between 1 - 99
			 * @property {Boolean}       [trim]             - remove unnecessary white space
			 * @property {String}        [fileFormat]    	- file format of the image
			 */
			imageOptions: PropTypes.shape({
				direction: PropTypes.oneOf(['NorthWest', 'North', 'NorthEast', 'NorthEast', 'Center', 'East', 'SouthWest', 'South', 'SouthEast']),
				size: PropTypes.shape({
					width: PropTypes.number,
					height: PropTypes.number,
					type: PropTypes.oneOf(['cut', 'max', 'fit'])
				}),
				crop: PropTypes.shape({
					width: PropTypes.number,
					height: PropTypes.number,
					x: PropTypes.number,
					y: PropTypes.number,
				}),
				background: PropTypes.string,
				colorSpace: PropTypes.oneOf(['srgb', 'rgb', 'gray', 'cmyk']),
				gamma: PropTypes.string,
				negate: PropTypes.bool,
				quality: PropTypes.string,
				trim: PropTypes.bool,
				fileFormat: PropTypes.oneOf(['jpg', 'png'])
			}),

	})).isRequired,
	/**
	 * adding negative margin to next element
	 */
	join: PropTypes.bool,
	/**
	 * add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string

};

export {
	FullWidthImage as default,
	FullWidthImage
}
