import React, {Suspense, useEffect, useState} from 'react';
import './OfferContent.scss'
import ItemGroup from "../../components/itemGroup/ItemGroup";
import {Preloader} from "../../components/preloader/Preloader";
import Participants from "../../components/participants/Participants";
import PropTypes from 'prop-types';
import {Badge} from "../../components/badge/Badge";
import {LinkInternal} from "../../components/link/LinkInternal";
import {ErrorMessage} from "../../components/error/ErrorMessage";
import OfferInfo from "../../components/offerInfo/OfferInfo";
import Teaser from "../../components/teaser/Teaser";
import {
  diffDateDays,
  getTravelPeriod,
  getUtcSafeDate,
  TRAVELPERIOD_CURRENT,
  TRAVELPERIOD_FUTURE,
  TRAVELPERIOD_PAST
} from "../../../vendor/utils/DateUtils";
import ContactBoxContainer from "../../components/contactbox/ContactBoxContainer";
import {connect} from "react-redux";
import {dispatchFetchData, FETCH_TYPE_OFFER_CONTENT} from "../../redux/actions/fetchAction";
import TotalPrice from "../../components/totalPrice/TotalPrice";
import ButtonAnchor from "../../components/button/ButtonAnchor";
import {BUTTON_WIDTH} from "../../components/button/Button";
import {getUrlByEnvironment} from "../../../vendor/utils/Utils";

// lazy connect
const GMap = React.lazy(() =>
  import('../../components/gmap/GMap').then(module => ({default: module.GMapInPortal}))
);

function OfferContent({dispatchFetchData, ...props}) {

  const [selectedOfferItemId, setSelectedOfferItemId] = useState(null);
  const [selectedMapId, setSelectedMapId] = useState(null);

  useEffect(() => {
    dispatchFetchData();
  }, [dispatchFetchData, props.offerNumber]);

  /**
   * will be called from markerCallback by clicking a mapMarker.
   * @param itemId
   */
  function updateOfferItem(itemId) {
    setSelectedOfferItemId(itemId)
  }

  /**
   * will be called from offerItemCallback by clicking an offerItem
   * @param itemId
   */
  function updateMapContent(itemId) {
    setSelectedMapId(itemId)
  }

  /**
   * render offerItems in Itemgroup
   * @param offerItems
   * @returns {*}
   */
  function renderOfferItems(offerItems) {
    return (
      offerItems.map((group, index) => {
        let groupKey = group.type + '-' + group.label.split(' ').join('_').toLowerCase();

        return (
          <ItemGroup group={group}
                     key={groupKey}
                     groupIndex={index}
                     id={groupKey}
                     context={'offer'}
                     readOnly={true}
                     withBackground={true}
                     callbackHandler={updateMapContent}
                     selectedItemId={selectedOfferItemId}
                     hasAlternatives={false} // TODO: make as Default?
          />
        );
      })
    );
  }

  function renderJourneyInfo(journeyStart, journeyEnd) {

    let badge = null;
    switch (getTravelPeriod(journeyStart, journeyEnd)) {

      case TRAVELPERIOD_CURRENT:
        badge = <Badge label={'AKTUELLE REISE'}/>;
        break;
      case TRAVELPERIOD_FUTURE:
        badge = <Badge label={'ZUKÜNFTIGE REISE'}/>;
        break;
      case TRAVELPERIOD_PAST:
        badge = <Badge label={'ABGESCHLOSSENE REISE'}/>;
        break;
      default:
        badge = null;
    }

    return badge && <div className={'offer-content__intro-badge'}>{badge}</div>;
  }

  /**
   * replace placeholder ##LINK-OPTIONALS## with LinkInternal-Component
   * @param text
   * @returns {*}
   */
  function renderInternalLink(text) {

    if (text.indexOf("##LINK-OPTIONALS##") !== -1) {
      const internalLink = <LinkInternal linkPath={'ausfluege'}
                                         label={'Buchbare Ausflüge'}/>;
      const introList = text.split('##LINK-OPTIONALS##');
      return (
        <span className={'offer-content__offer--display-inline'}>
					<span dangerouslySetInnerHTML={{__html: introList[0]}}/>
 					„{internalLink}“
					<span dangerouslySetInnerHTML={{__html: introList[1]}}/>
				</span>
      )
    } else {
      return <span dangerouslySetInnerHTML={{__html: text}}></span>
    }
  }

  function renderIntroText(isLoading) {

    if (isLoading) {

      return <Preloader type={'text'} additionalClasses={'box'}/>;

    } else {

      return <div className={'offer-content__intro box'}>

        {renderJourneyInfo(props.journeyStart, props.journeyEnd)}

        {props.introductionHeadline &&
        <div className={'offer-content__intro-headline h2 ts-handwriting'}>
          {props.introductionHeadline}
        </div>
        }

        {props.introductionText &&
        <div className={'offer-content__intro-text'}>
          {renderInternalLink(props.introductionText)}
        </div>
        }

        <OfferInfo offerNumber={props.offerNumber}/>

        {props.isLatestOffer === false &&
        <div className={'offer-content__da-link-box'}>
          <div className={'offer-content__da-link-box-text'}>
            <b>Wir haben Ihre Reise angepasst.</b>
            <p>Diese wurde aber noch nicht von Ihnen bestätigt. Bitte klicken Sie hier um Ihr Angebot zu
              überarbeiten.</p>
          </div>
          <ButtonAnchor
            href={`${getUrlByEnvironment().daUrl}/digitaloffer/${props.globalOfferToken}/start`}
            target={'_blank'}
            width={BUTTON_WIDTH.FIXED}
            label={'Zum Angebot'}
          />
        </div>
        }
      </div>
    }
  }

  function renderOfferBlock(isLoading) {

    const diffDate = diffDateDays(getUtcSafeDate(new Date().getTime()), getUtcSafeDate(props.journeyStart)) * -1;
    const maxDiffDays = 14;

    if (isLoading) {
      return <Preloader type={'logo'}/>;
    } else {

      return <>
        {props.offerItems && renderOfferItems(props.offerItems)}

        <TotalPrice label={'Gesamtpreis'}
                    sublabel={'(für alle Reiseteilnehmer)'}
                    price={props.totalPrice}
        />

        {(diffDate > maxDiffDays && props.hasOptionals !== false) &&
        <Teaser/>
        }
      </>
    }
  }

  return (
    <>
      <div className={'offer-content'}>

        <div className='offer-content__main'>

          {renderIntroText(props.isLoading)}

          <ContactBoxContainer/>

          <Participants/>

          {!props.hasLoadingError ?
            renderOfferBlock(props.isLoading)
            :
            <ErrorMessage type={'loadingError'}/>
          }
        </div>

        <div className='offer-content__side'>
          <Suspense fallback={<Preloader/>}>
            <GMap callbackHandler={updateOfferItem}
                  selectedItemId={selectedMapId}
                  mapType={'offerMap'}
            />
          </Suspense>
        </div>
      </div>
    </>
  )
}

OfferContent.propTypes = {
  offerItems: PropTypes.array,
  totalPrice: PropTypes.number,
  /**
   * headline of introtext
   */
  introductionHeadline: PropTypes.string,
  introductionText: PropTypes.string,
  isLoading: PropTypes.bool,
  hasRouteHints: PropTypes.bool,
  offerToken: PropTypes.string,
  hasOptionals: PropTypes.bool
};

const mapStateToProps = (state, ownProps) => {
  return {
    journeyStart: state.globalStateManagement.offer.journeyStart,
    journeyEnd: state.globalStateManagement.offer.journeyEnd,
    offerNumber: state.globalStateManagement.offer.offerNumber,
    isLatestOffer: state.globalStateManagement.offer.isLatestOffer,
    globalOfferToken: state.globalStateManagement.offer.offerToken,
    ...state.offerContentManagement
  }
};

export default connect(mapStateToProps, {dispatchFetchData: () => dispatchFetchData(FETCH_TYPE_OFFER_CONTENT)})(OfferContent)
