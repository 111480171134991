import React, { Component } from 'react';
import logoPortal from '../../../assets/static/CANUSA_logo_reiseportal.svg';
import logoDA from '../../../assets/static/CANUSA_logo_digital-offer.svg';
import logo from '../../../assets/static/CANUSA_logo_claim.svg';
import mobileLogo from '../../../assets/static/CANUSA_logo--half.svg';
import { Image } from "../image/Image";
import './Header.scss';
import classNames from "classnames";
import PropTypes from "prop-types";

const LOGO_MAP = {
  portal: logoPortal,
  digitalOffer: logoDA,
}

class Header extends Component {
  
  getLogoVariant(logoVariant) {
    return LOGO_MAP[logoVariant] || logo;
  }
  
  render() {
    const classes = classNames(
      'header',
      {'header--stick-to-top': this.props.stickToTop},
      this.props.additionalClasses
    );
    
    return (
      <header className={classes}>
        <div className={'header__max-vp-wrapper'}>
          <div className={'header__logo'}>
            <Image url={this.getLogoVariant(this.props.logoVariant)} alt={'Canusa Logo'}
                   additionalClasses={'header__logo-image'}/>
            <Image url={mobileLogo} alt={'Canusa Logo'} additionalClasses={'header__logo-icon'}/>
            <Image url={this.getLogoVariant(this.props.logoVariant)} alt={'Canusa Logo'}
                   additionalClasses={'header__print-logo-image'}/>
          </div>
          {this.props.children}
        </div>
      </header>
    )
  }
}

Header.propTypes = {
  
  /**
   * set Header sticky
   *
   * @default: true
   */
  stickToTop: PropTypes.bool,
  
  /**
   * set Logo version
   */
  logoVariant: PropTypes.oneOf(['portal', 'digitalOffer']),
  
  /**
   * OPTIONAL: add additional classes for this component here.
   * Use this prop for special css-classes, which are not defined by default.*
   */
  additionalClasses: PropTypes.string
};

Header.defaultProps = {
  stickToTop: true
};

export {
  Header as default,
  Header
}
