import React, {Component} from 'react';
import './OfferSelect.scss'
import {Icon, ICON_SIZE} from "../icon/Icon";
import {BUTTON_APPEARANCE, Button} from "../button/Button";
import {Modal} from "../modal/Modal";
import classNames from "classnames";
import {
  diffDateDays,
  getSmallPaddedFormatDate,
  getUtcSafeDate,
  unixSecondsFromISODateStr
} from "../../../vendor/utils/DateUtils";
import {Tooltip} from "../tooltip/Tooltip";
import PropTypes from "prop-types";
import {connect} from "react-redux";

class OfferSelect extends Component {

  constructor(props) {
    super(props);
    this.state = {
      showOfferModal: false
    };
    this.selectChangeHandler = this.selectChangeHandler.bind(this);
    this.showOfferModal = this.showOfferModal.bind(this);
  }


  /**
   * change handler on offer-select change
   * @param value {string}
   */
  selectChangeHandler(value) {
    const selectedOffer = this.getOfferByNumber(this.props.offerList, value);
    this.props.onOfferChange({offer: selectedOffer});
  }


  showOfferModal(show) {
    this.setState({
      showOfferModal: show
    });
  }

  /**
   * filters offer from offerList by offerNumber
   *
   * @param offerList
   * @param offerNumber
   * @return {object}
   */
  getOfferByNumber(offerList, offerNumber) {
    return offerList.find((offer) => {
      return offer.offerNumber === offerNumber;
    });
  }

  /**
   * parse offerList to option objects
   * @param offerList {array}
   * @return {array}
   */
  parseOptions(offerList) {
    return offerList.map((offerData) => {
      return {
        value: offerData.offerNumber,
        label: `${offerData.offerNumber}`,
        startDate: offerData.journeyStart,
        endDate: offerData.journeyEnd,
        journeyDescription: offerData.journeyDescription
      }
    })
  }

  renderOfferSelectItem(item, isSelected, modalCloseHandler) {

    const classes = classNames(
      'offer-selection__item',
      {'offer-selection__item--selected': isSelected}
    );
    let maxLegacyOffersDate = '2018-01-01';
    let itemContent =
      <>
        <div className={'offer-selection__radio-button'}>
          <Icon name={isSelected ? 'radio-check' : 'radio'}/>
        </div>

        <div className={'offer-selection__content'}>
          <div className={'offer-selection__date'}>
            <div>{getSmallPaddedFormatDate(item.startDate)} - {getSmallPaddedFormatDate(item.endDate)}</div>
            <div className={'offer-selection__small-subline ts-copy-small'}>
              {'(' + (diffDateDays(getUtcSafeDate(item.startDate), getUtcSafeDate(item.endDate)) * -1) + ' Tage)'}
            </div>
          </div>

          <div className={'offer-selection__offer-number'}>
            <div>{item.value}</div>
            <div className={'offer-selection__small-subline ts-copy-small'}>Buchungsnummer</div>
          </div>
        </div>

        <div className={'offer-selection__info-icon'}>
          {item.journeyDescription &&
          <Tooltip content={item.journeyDescription}
                   direction={'left'}
          >
            <Icon name={'circle-info'} size={ICON_SIZE.S}/>
          </Tooltip>

          }

        </div>

      </>;

    if (unixSecondsFromISODateStr(getUtcSafeDate(item.startDate)) < unixSecondsFromISODateStr(getUtcSafeDate(maxLegacyOffersDate))) {
      itemContent = <div className={'offer-selection__inactive_offer'} key={item.value}>
        <Tooltip
          direction={'top'}
          content={"Für dieses Angebot liegen keine Angebotsdaten in diesem Portal vor. Bitte wenden Sie sich an Ihren Kundenberater."}
        >
          <div className={'offer-selection__item offer-selection__item--no-hover'}>
            {itemContent}
          </div>

        </Tooltip>
      </div>
    } else {
      itemContent = <div key={item.value} className={classes} onClick={() => {
        this.selectChangeHandler(item.value);
        modalCloseHandler();
      }}>
        {itemContent}
      </div>
    }

    return (itemContent)
  }

  renderModalContent(offerList, offerNumber, modalCloseHandler) {
    const list = this.parseOptions(offerList);

    let forthcomingTrips = [];
    let pastTrips = [];

    const currentDate = unixSecondsFromISODateStr(getUtcSafeDate(new Date().getTime()));

    list.map((item) => {
      const isSelected = offerNumber === item.value;

      if (unixSecondsFromISODateStr(item.startDate) > currentDate) {

        forthcomingTrips.push(this.renderOfferSelectItem(item, isSelected, modalCloseHandler));

      } else {
        pastTrips.push(item);
      }
      return null;
    });

    return (
      <div className={'offer-selection'}>
        {!!forthcomingTrips.length && <div
            className={'offer-selection__year-divider h4 tt-none'}>Bevorstehend
        </div>}
        {forthcomingTrips}
        {this.sortTripsByDate(pastTrips, offerNumber, modalCloseHandler)}
      </div>
    )
  }

  sortTripsByDate(pastTrips, offerNumber, modalCloseHandler) {

    const yearMap = {};
    // sort pastTrips by year into object "dateMap"
    pastTrips.map((item) => {

      const year = item.startDate.split('-')[0];

      if (!yearMap[year]) {
        yearMap[year] = [];
      }
      yearMap[year].push(item);
      return null;
    });

    const yearList = Object.keys(yearMap).reverse();
    const sortedList = yearList.map((item) => {

      const itemListByYear = yearMap[item];
      return itemListByYear.map((itemByYear, index) => {
        const returnItem = [];

        if (index === 0) {
          returnItem.push(<div className={'offer-selection__year-divider h4 tt-none'}
                               key={itemByYear.value + index}>{item}</div>);
        }

        const isSelected = offerNumber === itemByYear.value;
        returnItem.push(this.renderOfferSelectItem(itemByYear, isSelected, modalCloseHandler));

        return returnItem;
      })
    });

    return sortedList;
  }

  render() {

    if (!this.props.offerList || !this.props.offerNumber) return null;

    const travelCount = (this.props.offerList.length > 1) ?
      this.props.offerList.length + ' Reisen'
      :
      this.props.offerList.length + ' Reise';

    return (<>
        <Button label={'Meine Reisen'}
                icon={'globe'}
                appearance={BUTTON_APPEARANCE.GHOST}
                width={'fit'}
                additionalClasses={'header__button'}
                onClick={() => this.showOfferModal(true)}
        />

        {this.state.showOfferModal &&
        <Modal onClose={() => this.showOfferModal(false)}
               size={"medium"}

               render={({modalCloseHandler}) => (
                 <div className={'offer-selection'}>
                   <div className={'offer-selection__headcontent'}>
                     <div className={'offer-selection__headline h2 tt-none'}>Meine
                       Reisen
                     </div>
                     <div className={'offer-selection__subline h5'}>{travelCount}</div>
                   </div>
                   {(this.props.offerList && this.props.offerNumber) &&
                   this.renderModalContent(this.props.offerList, this.props.offerNumber, modalCloseHandler)
                   }
                 </div>
               )}
        />
        }
      </>
    )
  }
}

OfferSelect.defaultProps = {};

OfferSelect.propTypes = {

  /**
   * @example: 'HAM-1339657-BSC'
   */
  offerNumber: PropTypes.string.isRequired,

  offerList: PropTypes.arrayOf(PropTypes.shape({

    /**
     * @example: 'HAM-1339657-BSC'
     */
    offerNumber: PropTypes.string,

    /**
     * @example: '2019-06-26'
     */
    bookingDate: PropTypes.string,

    /**
     * @example: '2019-06-29'
     */
    journeyStart: PropTypes.string,

    /**
     * @example: '2019-07-3'
     */
    journeyEnd: PropTypes.string,

  })).isRequired,

  /**
   * Method to store new offerNumber in localStorage
   * located in MainPage.js
   *
   */
  onOfferChange: PropTypes.func.isRequired

};

const mapStateToProps = (state, ownProps) => {
  return {
    offerNumber: state.globalStateManagement.offer.offerNumber,
    offerList: state.globalStateManagement.offerList
  }
};

const OfferSelectConnected = connect(mapStateToProps)(OfferSelect);

export {
  OfferSelect as default,
  OfferSelectConnected as OfferSelect
}
