import React, {Component} from 'react';
import './NewPasswordMask.scss';
import {Input} from "../input/Input";
import {Button} from "../button/Button";
import {LinkInternal} from "../link/LinkInternal";
import {FormValidator} from "../form/FormValidator";
import {Tooltip} from "../tooltip/Tooltip";
import PropTypes from 'prop-types';
import {Redirect} from "react-router-dom";
import {formErrorMessages} from "./FormUtils";
import {trackSubmitAttempt} from "../../../vendor/utils/TrackingManager";

class NewPasswordMask extends Component {

    constructor(props) {
        super(props);

        this.state = {
            errorFromResponse: {},
            errorMessage: null,
            loggedIn: false,
            requireSecurityToken: false,
            isLoading: false
        };
        this.handleSubmit = this.handleSubmit.bind(this);
        this.dataProvider = this.props.dataProvider;
        this.newPasswordHeadline = "Wählen Sie Ihr neues Passwort";
    }

    /**
     * submit handler of Form
     * send Form and set state depending on response
     * if form was send for the first time then errors will be shown. not before
     * @param form
     */
	handleSubmit({form}) {
        this.setState({
            isLoading: true
        });

            this.dataProvider.setData(form).then(response => {
                if (response.err) {
                    this.setState({
                        errorFromResponse: response.err,
                        loggedIn: false,
                        errorMessage: response.err,
                        requireSecurityToken: response.require_security_session,
                        isLoading: false
                    })
                    trackSubmitAttempt('change_password', false, response.err);
                } else if (response.success === 1) {
                    this.setState({
                        loggedIn: true,
                        errorMessage: null,
                        isLoading: false,
                        errorFromResponse: {}
                    });
                    trackSubmitAttempt('change_password', true);
                } else {
                    this.setState({
                        loggedIn: false,
                        errorMessage: formErrorMessages['unknownError'],
                        isLoading: false
                    })
                    trackSubmitAttempt('change_password', false, formErrorMessages['unknownError']);
                }
            });

    }


	/**
	 * Gets the error message according to the property "name" of the input field
	 * @param inputName
	 * @returns {null|*}
	 */
	getErrorMessage(inputName) {
		return this.state.errorFromResponse[inputName] && this.state.errorFromResponse[inputName][0]
	}

    render() {
        if (this.state.loggedIn) return <Redirect to={this.props.redirectTo}/>;
        return (
            <>
                <div className={"login_wrapper__mask-content"}>
                    <h2 className='login_wrapper__headline'>{this.newPasswordHeadline}</h2>
                    <FormValidator additionalClasses="login_wrapper__form"
                                   onSubmit={this.handleSubmit}
								   render={({changeHandler}) =>{
										 const isString = typeof this.state.errorMessage === 'string'
										   return(
											   <>
												   <div className="login_wrapper__inputs">

													   <Input name="password_old"
															  label="altes Passwort"
															  type="password"
															  required={true}
															  showError={isString}
															  fullWidth={true}
															  errorMessage={isString ? this.state.errorMessage : ''}
													   />

													   <Input name="password_new"
															  label="neues Passwort"
															  type="password"
																required={true}
															  showError={!!this.getErrorMessage("password_new")}
															  fullWidth={true}
															  compareId={'new-password'}
															  errorMessage={this.getErrorMessage("password_new") }
													   />

													   <Input name="password_new2"
															  label="neues Passwort wiederholen"
															  type="password"
																required={true}
															  showError={!!this.getErrorMessage("password_new2")}
															  fullWidth={true}
															  compareId={'new-password'}
															  errorMessage={this.getErrorMessage("password_new2")}
													   />
												   </div>

												   <Tooltip
													   content={isString ? this.state.errorMessage : ''}
													   showDefault={false}
													   inactive={typeof this.state.errorMessage !== 'string'}
													   direction={this.props.isDesktop ? 'left' : 'top'}
													   color={'important'}>
													   <Button type="submit"
															   label="Passwort bestätigen"
															   additionalClasses="important login_wrapper__submit"
															   isLoading={this.state.isLoading}
															   disabled={false}/>
												   </Tooltip>
											   </>
										   )
}}/>
					<LinkInternal label='Passwort vergessen'
								  linkPath={{pathname: '/forgot', state: {offerToken: this.props.offerToken}}}
								  additionalClasses="login_wrapper__link"
					/>
				</div>
				<div className={'login_wrapper__divider dotted'}/>
				<div className='login_wrapper__text'>
					<div
						className='h2 login_wrapper__headline login_wrapper__headline--visible-on-desktop'>{this.newPasswordHeadline}</div>
					Tragen Sie zunächst Ihr aktuelles Passwort in das Feld „altes Passwort“ ein.
                    Wählen Sie dann ein sicheres Passwort, welches Sie sich gut merken können. Tragen Sie dieses
                    zunächst in das Feld „neues Passwort“ ein. Zur Verifizierung des Passworts wiederholen Sie das neue
                    Passwort bitte im unteren Feld.
                    Nach dem erfolgreichen Ändern des Passworts erhalten Sie eine entsprechende Meldung.
                    Sollten Sie diese Meldung nicht erhalten, versuchen Sie es bitte erneut. Vielleicht hat sich beim
                    ersten Versuch ein Tippfehler eingeschlichen.
                </div>
            </>
        )
    }

}

NewPasswordMask.propTypes = {
    /**
     * instance of LoginService
     */
    dataProvider: PropTypes.object
};

export {
    NewPasswordMask
}
