
export const CHECKOUT_FORWARD = 'CHECKOUT_FORWARD';
export const CHECKOUT_BACKWARD = 'CHECKOUT_BACKWARD';
export const CHECKOUT_GO_TO_INDEX = 'CHECKOUT_GO_TO_INDEX';
export const CHECKOUT_RESET = 'CHECKOUT_RESET';
export const IS_BOOKING = 'IS_BOOKING';
export const IS_SAVING = 'IS_SAVING';
export const BOOKING_ERROR = 'BOOKING_ERROR';
export const BOOKING_SUCCESS = 'BOOKING_SUCCESS';

export const dispatchCheckoutForward = () => ({
  type: CHECKOUT_FORWARD,
});

export const dispatchCheckoutBackward = () => ({
  type: CHECKOUT_BACKWARD,
});

export const dispatchCheckoutGotoIndex = (index) => ({
  type: CHECKOUT_GO_TO_INDEX,
  index: index
});

export const dispatchCheckoutReset = () => ({
  type: CHECKOUT_RESET,
});

export const dispatchIsBooking = () => ({type:IS_BOOKING});
export const dispatchIsSaving = (isSaving) => ({type:IS_SAVING,isSaving});

export const dispatchBookingError = (bookingError) => ({type:BOOKING_ERROR, bookingError});

export const dispatchBookingSuccess = () => ({type:BOOKING_SUCCESS});


