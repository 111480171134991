import React from 'react';
import './DocumentsContent.scss'
import DocumentsCommon from "./documentsCommon/DocumentsCommon";
import Participants from "../../components/participants/Participants";
import DocumentsItems from "./documentsItems/DocumentsItems";
import ContactBoxContainer from "../../components/contactbox/ContactBoxContainer";

function DocumentsContent(props) {


	return (

		<div className={'documents-content'}>
			<DocumentsCommon/>
			<ContactBoxContainer/>
			<Participants/>
			<DocumentsItems/>
		</div>
	)

}

export default DocumentsContent;

