import React, {useState} from 'react';
import {Button, BUTTON_APPEARANCE, BUTTON_WIDTH} from "../../button/Button";
import Userprofile from "../../userprofile/Userprofile";
import './HeaderPortal.scss';
import PropTypes from "prop-types";
import {Header} from "../Header";
import {OfferSelect} from "../../offerSelect/OfferSelect";
import ButtonAnchor from "../../button/ButtonAnchor";
import {Tooltip} from "../../tooltip/Tooltip";

function HeaderPortal(props) {

  const [profileIsVisible, showUserProfile] = useState(false);

  return (
    <Header stickToTop={props.stickToTop} additionalClasses={props.additionalClasses} logoVariant={'portal'}>
      <div className="header__interaction">
        <div className='header__offer'>

          <Tooltip
              showDefault={true}
              content='Antworten auf Ihre Fragen'
              direction={'bottom'}
          >
            <ButtonAnchor label='FAQ'
                          href='/faq'
                          target='_blank'
                          width={BUTTON_WIDTH.FIT}
                  appearance={BUTTON_APPEARANCE.QUATERNARY}
                          icon='circle-question'
                          additionalClasses={'header__button'}
            />
          </Tooltip>
          <OfferSelect onOfferChange={props.onOfferChange}/>

        </div>
        <Button label='Mein Profil'
                width={BUTTON_WIDTH.FIT}
                appearance={BUTTON_APPEARANCE.SECONDARY}
                icon='circle-user'
                additionalClasses={'header__button'}
                onClick={() => showUserProfile(true)}/>
      </div>
      {profileIsVisible &&
      <Userprofile onClose={() => showUserProfile(false)}/>}
    </Header>
  )
}

HeaderPortal.propTypes = {
  /**
   * set Header sticky
   * @default: true
   */
  stickToTop: PropTypes.bool,
  /**
   * OPTIONAL: add additional classes for this component here.
   * Use this prop for special css-classes, which are not defined by default.*
   */
  additionalClasses: PropTypes.string,
  /**
   * Method to store new offerNumber in localStorage
   * located in MainPage.js
   */
  onOfferChange: PropTypes.func.isRequired

};

export {
  HeaderPortal as default,
  HeaderPortal
}
