import React, {Component} from 'react';
import './TravelDetails.scss'
import {Icon} from "../../../icon/Icon";
import {mapTypeToIcon} from "../../../../../vendor/utils/Mappings";
import {toGermanDateStr} from "../../../../../vendor/utils/DateUtils";
import {decodeHTMLEntities} from "../../../../../vendor/utils/HTMLUtils";

class TravelDetails extends Component {
	/**
	 * creates a list of TravelDetailItems
	 * @returns {[]|null}
	 */
	travelDetailFactory() {
		let retVal = [];
		retVal.push(this.travelDetailItemFactory(this.props.item.type));
		if (this.props.item.duration) {
			retVal.push(this.travelDetailItemFactory('duration'))
		}

		if (retVal[0] !== null) {
			return retVal;
		} else {
			return null;
		}
	}

	/**
	 * Create TravelDetailItems as ConfigObjects
	 * @param type
	 * @returns {null|*}
	 * TODO: Some views from the layout (e.g.:booking-class) are currently not available and need to be added later.
	 */
	travelDetailItemFactory(type) {

		const configObj = {};

		switch (type) {

			case 'hotel':
				configObj.label = 'Anreise:';
				configObj.text = 'Am ' + toGermanDateStr(this.props.item.date);
				configObj.icon = 'calendar';
				break;

			case 'car':
				configObj.label = 'Abholung:';
				configObj.text = 'Am ' + toGermanDateStr(this.props.item.date);
				configObj.icon = 'calendar';
				break;

			case 'flight':
				configObj.label = 'Abflug:';
				configObj.text = 'Am ' + toGermanDateStr(this.props.item.date);
				configObj.icon = mapTypeToIcon(type);
				break;

			case 'duration':
				configObj.label = 'Dauer:';
				configObj.text = this.props.item.duration.num + ' ' + this.props.item.duration.label;
				configObj.icon = 'time';
				break;

			default:
				return null
		}
		
		return (
			<div className='travel-detail' key={type}>
				<div className={'travel-detail__textwrapper'}>
					<div className={'travel-detail__textwrapper__label'}>{configObj.label}</div>
					<div className={'travel-detail__textwrapper__text'}>{decodeHTMLEntities(configObj.text)}</div>
				</div>
				<div className={'travel-detail__icon'}><Icon name={configObj.icon}/></div>
			</div>
		)
	}

	render() {

		const travelDetails = this.travelDetailFactory();

		return (
			travelDetails &&
			<div className={'travel-detail__wrapper'}>
				{travelDetails}
			</div>

		)
	}
}

TravelDetails.defaultProps = {};
TravelDetails.propTypes = {};

export {
	TravelDetails as default,
	TravelDetails
}
