import React from "react";
import {
  BrowserRouter as Router,
} from "react-router-dom";

import "./App.scss";
import Provider from "react-redux/es/components/Provider";
import store from "./portal/redux/store";
import {ErrorHandling} from "./report/ErrorHandling"
import {TimingHandling} from "./report/TimingHandling";
import InnerRouter from "./InnerRouter";

function App() {
    return (
        <Provider store={store}>
            <Router>
                <InnerRouter/>
            </Router>
        </Provider>
    );
}

let siteBrand = 'portal-canusa';

if (window.config) {
    siteBrand = window.config.site + '-' + window.config.brand;
}

// Initialize error handling
window.errorHandling = new ErrorHandling({
    reportUrl: '/api/report.php/' + siteBrand + '/error'
});

window.timingHandling = new TimingHandling({
    reportUrl: '/api/report.php/' + siteBrand + '/timing'
});

export default App;
