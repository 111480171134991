import React, { Fragment } from 'react';
import './CheckoutProgress.scss';
import classNames from "classnames";
import PropTypes from "prop-types";
import Icon from "../icon/Icon";

function CheckoutProgress(props) {

  const classes = classNames(
    'checkout-progress',
    props.additionalClasses
  );

  function renderSteps(steps, currentStep) {
    let i = 1;
    let stepList = [];
    for (i; i <= steps; i++) {
      if (i > currentStep) {
        stepList.push(
          <Fragment key={i}>
            <span className="checkout-progress__step checkout-progress__step--coming">{i}</span>
            {i !== steps && <span className="checkout-progress__dotted checkout-progress__dotted--coming"></span>}
          </Fragment>
        );
      } else if (i === currentStep) {
        stepList.push(
          <Fragment key={i}>
            <span className="checkout-progress__step checkout-progress__step--highlight">{i}</span>
            {i !== steps && <span className="checkout-progress__dotted checkout-progress__dotted--coming"></span>}
          </Fragment>
        );
      } else {
        stepList.push(
          <Fragment key={i}>
					<span className="checkout-progress__step checkout-progress__step--passed">
						<Icon name={'radio-check'}/>
					</span>
            <span className="checkout-progress__dotted checkout-progress__solid--passed"></span>
          </Fragment>
        );
      }
    }
    return stepList
  }

  return (
    <div className={classes}>
      {renderSteps(props.steps, props.currentStep)}
    </div>
  )
}

CheckoutProgress.propTypes = {
  /**
   * amount of possible steps
   */
  steps: PropTypes.number,
  /**
   * progress
   */
  currentStep: PropTypes.number,
};

CheckoutProgress.defaultProps = {
  steps: 3,
  currentStep: 1
};

export default CheckoutProgress;
