import React, {useEffect, useRef, useState} from "react";
import "./CheckoutContact.scss";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {FormValidator} from "../../form/FormValidator";
import {
	createRenter,
} from "../../../../vendor/utils/ParticipantsUtils";
import CheckoutNavigation from "../CheckoutNavigation";
import Icon from "../../icon/Icon";
import Select from "../../select/Select";
import {formErrorMessages} from "../../login/FormUtils";
import {
	dispatchFetchData, dispatchUpdateData,
	FETCH_TYPE_DIGITAL_OFFER_RENTERS, FETCH_TYPE_USER_PROFILE,
} from "../../../redux/actions/fetchAction";
import {typeToIconMap} from "../../../../vendor/utils/Mappings";
import {toGermanDateStr} from "../../../../vendor/utils/DateUtils";
import {
	dispatchUpdateUserProfile,
} from "../../../redux/actions/userProfileAction";
import {viewportUtils} from "../../../../vendor/utils/ViewportUtils";
import ErrorMessage from "../../error/ErrorMessage";
import ContactBoxContainer from "../../contactbox/ContactBoxContainer";
import {CountryListService} from "../../../services/CountryListService";
import Input from "../../input/Input";
import {getLabelForValue} from "../../../../vendor/utils/Utils";

function CheckoutContact(props) {
	const dataProvider = useRef(new CountryListService());
	const [isDirectInkasso, setDirectInkasso] = useState(false);
	const [showFormErrors, setShowFormErrors] = useState(false);
	const [listOfCountries, setListOfCountries] = useState([
		{value: "Deutschland", label: "Deutschland"},
		{value: "Schweiz", label: "Schweiz"},
		{value: "Österreich", label: "Österreich"},
	]);

	const rsbCollectModeSelectValues = [
		{value: "rsb", label: "Agenturinkasso"},
		{value: "direct", label: "Direktinkasso"},
	];

	const {dispatchFetchData} = props;
	useEffect(() => {
		dispatchFetchData();
	}, [dispatchFetchData]);

	useEffect(() => {
		dataProvider.current.getData().then(countries => {
			setListOfCountries(countries);
		}).catch(error => console.warn(error));
		return dataProvider.current.cancelRequest;
	}, []);

	function handleSubmit({form, isValid, firstErrorSelector}) {
		if (isValid) {

			if (props.isAgencyOffer || true) {
				let debtCollectionData = {
					iban: form.get("iban"),
					ibanaccountname: form.get("ibanaccountname"),
					rsbCollectMode: form.get("rsbCollectMode"),
					rsbCollectModeLabel: getLabelForValue(rsbCollectModeSelectValues,
							form.get("rsbCollectMode")),
				};
				props.dispatchDebtCollectionData(debtCollectionData);
				form.delete("iban");
				form.delete("ibanaccountname");
				form.delete("rsbCollectMode");
			}
			props.dispatchUpdateUserProfile(form);
		} else {
			viewportUtils.scrollIntoView(firstErrorSelector,
					".checkout-contact-wrapper__form", "#checkout-modal");
			setShowFormErrors(true);
		}
	}

	function formatRenterslistForSelect(list) {
		return list.map((item) => {
			return {value: item.id, label: item.firstname + " " + item.lastname};
		});
	}

	function renderRentersItem(
			key, icon, date, label, rentersList, selectedRenterId) {
		return (
				<div className="renters__item" key={key}>

					<div className="renters__title">
						<div className="renters__date-wrapper">
							<Icon name={"car"}/>
							<div className="renters__date">{date}</div>
						</div>
						<div className="renters__label">{label}</div>
					</div>

					<div className="renters__select">
          <Select label={'Fahrer'}
						        options={formatRenterslistForSelect(rentersList)}
						        name={`offerContact[rented-item-${key}]`}
						        fullWidth={true}
						        required={true}
						        showError={showFormErrors}
						        initialValue={selectedRenterId}
                  onChange={() => {
                  }}
                  errorMessage={formErrorMessages['genderError']}
                  tooltipDirection={'top'}
                  parentContainerSelector={'#' + props.parentId}
                  additionalClasses={'renters__select'}
						/>
					</div>
				</div>
		);
	}

	function createRentersList(rentersList) {
		return (
      <div className={'renters__box'}>
					{rentersList.map((element, index) => {
						return renderRentersItem(
								element.item.id,
								typeToIconMap[element.item.type],
								toGermanDateStr(element.item.date),
								element.item.displayTitle,
								element.participants,
								element.renter.id,
						);
					})}
				</div>
		);
	}

	function createRenterContent(rentersList) {
		if (!!rentersList?.length) {
			return (<div className="renters">
				<div className="renters__header">
					<div className="h4">Zuweisung Mieter/Kreditkarteninhaber</div>
					<p>Bitte wählen Sie hier den Mieter- und Kreditkarteninhaber aus, der
						das Fahrzeug übernehmen wird.</p>
				</div>
				{createRentersList(rentersList)}
				<p>Der Mieter des Fahrzeugs muss eine gültige Kreditkarte für die
					Hinterlegung der Kaution besitzen. Eine
					Debitkarte ist <strong>nicht</strong> ausreichend, da diese von den
					Vermietern nicht akzeptiert wird.</p>
			</div>);
		}
		return null;
	}

	function createAgencyDebtCollection(changeHandler) {
		return (<div className="debt-collection">
			<div className="debt-collection__header">
				<div className="h4">Gewünschte Inkasso-Variante</div>
				<p>Bitte wählen Sie hier Ihre favorisierte Inkasso-Variante aus. In
					beiden Varianten versenden wir alle Unterlagen immer direkt an Ihr
					Reisebüro.</p>
			</div>
			<div>
				<div>
					<Select label={"Inkassoart *"}
					        options={rsbCollectModeSelectValues}
					        name={"rsbCollectMode"}
					        fullWidth={true}
					        required={true}
					        showError={showFormErrors}
					        onChange={({value}) => {
						        const isDirect = value === "direct";
						        if (!isDirect) {
							        changeHandler({valid: true, name: "iban"});
							        changeHandler({valid: true, name: "ibanaccountname"});
						        }
						        setDirectInkasso(isDirect);
					        }}
					        tooltipDirection={"top"}
					        initialValue={props.offerContact.rsbCollectMode}
					        additionalClasses={"participants-form__gender"}
					/>
				</div>

				{isDirectInkasso &&
						<div className={"debt-collection__iban"}>
							<Input label={"IBAN *"}
							       fullWidth={true}
							       required={isDirectInkasso}
							       onChange={changeHandler}
							       showError={showFormErrors}
							       additionalClasses={"checkout__input"}
							       name={"iban"}
							       initialValue={props.offerContact.iban}
							       errorMessage={"Bitte IBAN eingeben"}
							/>
							<Input label={"Kontoinhaber *"}
							       fullWidth={true}
							       required={isDirectInkasso}
							       onChange={changeHandler}
							       showError={showFormErrors}
							       additionalClasses={"checkout__input"}
							       initialValue={props.offerContact.ibanaccountname}
							       name={"ibanaccountname"}
							       errorMessage={"Bitte Kontoinhaber eingeben"}
							/>
						</div>
				}
			</div>
		</div>);
	}

	return (
			<>
				<h1 className="tt-none ts-bold">Adressdaten des {props.isAgencyOffer ?
						"Reisebüros" :
						"Buchenden"}</h1>
				{props.isAgencyOffer ?
						<p>Bitte geben Sie hier die Adressdaten des Reisebüros an, damit wir
							auf der Rechnung Ihre korrekte
							Rechnungsadresse angeben können.</p>
						:
						<p>Bitte geben Sie hier die Adressdaten der buchenden Person an.</p>
				}
				<FormValidator additionalClasses="checkout-contact-wrapper__form"
				               onSubmit={handleSubmit}
				               render={({changeHandler, formIsValid}) => (
						               <>
							               <div
									               className={"participants-form__content participants-form--alone"}>
								               {props.participantsList &&
										               createRenter(props.offerContact,
												               changeHandler, props.parentId,
												               showFormErrors, props.isAgencyOffer,
												               listOfCountries)}
							               </div>
							               {props.isAgencyOffer &&
									               createAgencyDebtCollection(changeHandler)}

							               {createRenterContent(props.rentersList)}

							               {props.hasLoadingError && <ErrorMessage>
								               <h2 className={"error-message__h2"}><Icon
										               name={"warning"}
										               additionalClasses={"error-message__icon"}/>Es
									               ist
									               ein Fehler aufgetreten</h2>
								               <div className={"error-message__text-block"}>
									               <p>Leider haben wir gerade mit technischen
										               Schwierigkeiten zu kämpfen und können Ihnen
										               unseren Service derzeit nicht zur Verfügung
										               stellen.</p>
									               <p>Wir arbeiten bereits an einer Lösung.
										               Sollte das Problem weiterhin bestehen, rufen
										               Sie mich bitte an.</p>
									               <ContactBoxContainer appearance={"modal"}/>
								               </div>
							               </ErrorMessage>}

							               <CheckoutNavigation
									               checkoutStep={props.checkoutStep}
									               isLoading={props.isLoading}/>
						               </>
				               )}
				/>
			</>
	);
}

CheckoutContact.propTypes = {
	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string,
};

CheckoutContact.defaultProps = {};

const mapStateToProps = (state, ownProps) => {
	return {
		participantsList: state.participantManagement.list,
		offerContact: state.userProfileManagement,
		isLoading: state.userProfileManagement.isLoading,
		hasLoadingError: state.userProfileManagement.hasLoadingError,
		rentersList: state.rentersManagement.list,
		isAgencyOffer: state.globalStateManagement.isAgencyOffer,
	};
};

export default connect(mapStateToProps, {
	dispatchFetchData: () => dispatchFetchData(FETCH_TYPE_DIGITAL_OFFER_RENTERS),
	dispatchDebtCollectionData: (debtCollectionData) => dispatchUpdateData(
			debtCollectionData, FETCH_TYPE_USER_PROFILE),
	dispatchUpdateUserProfile: (form, isCheckout) => dispatchUpdateUserProfile(
			form, true),
})(CheckoutContact);
