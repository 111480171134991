import React, {Component} from 'react';
import './Checkbox.scss'
import PropTypes from "prop-types";
import classNames from "classnames";
import {Icon} from "../icon/Icon";

class Checkbox extends Component {

	/**
	 * @param {string} [name]
	 * @param {function} [changeHandler]
	 * @param {string} [additionalClasses]
	 * @param {boolean} [checked=false]
	 * @param {boolean} [required=false]
	 * @param {boolean} [disabled=false]
	 */
	constructor(props) {
		super(props);
		this.state = {
			checked: props.checked
		};
		this.changeHandler = this.changeHandler.bind(this);
	}

	/**
	 * toggles state and call the injected callbackMethod (if set)
	 */
	changeHandler(e) {
		this.setState({checked: e.nativeEvent.target.checked});
		this.props.changeHandler && this.props.changeHandler(e.nativeEvent.target.checked);
	}

	render() {

		const classes = classNames(
			'checkbox',
			this.props.additionalClasses
		);

		return (
			<div className={classes}>
				<input type="checkbox"
					   id={this.props.uid}
					   name={this.props.name}
					   checked={this.state.checked}
					   required={this.props.required}
					   disabled={this.props.disabled}
					   onChange={this.changeHandler}
				/>
				<label htmlFor={this.props.uid}>
					<Icon name={'box-check'}/>
					<Icon name={'box'}/>
				</label>
			</div>
		)
	}
}

Checkbox.propTypes = {

	/**
	 * **REQUIRED**: set name of the component
	 */
	name: PropTypes.string.isRequired,
	/**
	 * OPTIONAL:  set checked state by default
	 */
	checked: PropTypes.bool,
	/**
	 * OPTIONAL: set required
	 */
	required: PropTypes.bool,
	/**
	 * OPTIONAL: set disabled by default
	 */
	disabled: PropTypes.bool,
	/**
	 * OPTIONAL: changeHandler returns checkbox-state
	 */
	changeHandler: PropTypes.func,
	/**
	 * OPTIONAL: add additional classes for this component here.
	 * *Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string
};

export {
	Checkbox as default,
	Checkbox
}
