import React, { Component } from 'react';
import './FormValidator.scss';
import PropTypes from 'prop-types';
import { passwordMinLength } from '../login/FormUtils';

class FormValidator extends Component {

  /**
   * constructor
   * @param props
   */
  constructor(props) {
    super(props);
    this.state = {formIsValid: false, firstErrorSelector: null};
    this.inputMap = {};
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeHandler = this.changeHandler.bind(this);
    this.formRef = React.createRef()
  }

  /**
   * handle form submit
   * add every key value pair into FormData
   * give formData and validState to its onSubmit
   * @param event
   */
  handleSubmit(event) {
    event.preventDefault();

    const formData = new FormData(this.formRef.current);

    if (this.props.onSubmit) {
      this.props.onSubmit({
        form: formData,
        isValid: this.state.formIsValid,
        firstErrorSelector: this.state.firstErrorSelector,
      })
    }
  }

  /**
   * changeHandler for inputs.
   * checks if every input is valid
   * @param valid
   * @param type
   * @param value
   * @param name
   * @param compareId
   */
  changeHandler({valid, type, value, name, compareId}) {

    this.inputMap[name] = {name, valid, type, value, compareId};

    let formIsValid = true;
    let firstErrorSelector = null;
    //check if every input is valid
    for (let inputIdentifier in this.inputMap) {
      formIsValid = this.inputMap[inputIdentifier].valid && formIsValid;

      if (!formIsValid) {
        firstErrorSelector = `[name="${this.inputMap[inputIdentifier].name}"]`
        break;
      }
    }

    if (compareId) {
      if (value.length >= passwordMinLength) {
        formIsValid = this.compareInputs(this.inputMap);
      }
    }

    this.setState({
      firstErrorSelector: formIsValid ? null : firstErrorSelector,
      formIsValid: formIsValid,
    });
    this.props.onChange?.(formIsValid);
  }

  /**
   * groups the objects with the same value of the key 'compareId'
   * @param inputMap
   * @returns {boolean}
   */
  compareInputs(inputMap) {
    let compareObject = {};
    let isValid = false;

    Object.keys(inputMap).forEach((key) => {

      if (inputMap[key]['compareId']) {

        if (!compareObject[inputMap[key]['compareId']]) {
          compareObject[inputMap[key]['compareId']] = [];
        }
        compareObject[inputMap[key]['compareId']].push(inputMap[key].value);
      }
    });

    for (let key in compareObject) {
      isValid = this.validateEquality(compareObject[key])
    }
    return isValid
  }

  /**
   * checks if every value of an array is equal
   * @param inputValueArray
   * @returns {boolean}
   */
  validateEquality(inputValueArray) {
    return inputValueArray.every((value, index, arr) => {
      return value === arr[0]
    });
  }

  /**
   * use render prop to pass changeHandler to children
   * it gives its changeHandler, and fromIsValid if whole form is valid to enable/disable submit for example
   * changeHandler is needed to validate if the whole form has valid inputs
   * @return {*}
   */
  render() {

    return (
      <form className={this.props.additionalClasses} onSubmit={this.handleSubmit} noValidate={true}
            ref={this.formRef}>
        {this.props.render({changeHandler: this.changeHandler, formIsValid: this.state.formIsValid})}
      </form>
    )
  }
}

FormValidator.propTypes = {

  render: PropTypes.func.isRequired,

  onSubmit: PropTypes.func,

  /**
   * add additional classes for this component here.
   * Use this prop for special css-classes, which are not defined by default.
   */
  additionalClasses: PropTypes.string,
};

export {
  FormValidator,
}
