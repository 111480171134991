import React, {Component} from 'react';
import './PreloaderWrapper.scss'
import classNames from "classnames";
import PropTypes from "prop-types";

class PreloaderWrapper extends Component {

	render() {

		const classes = classNames(
			'preloader-wrapper show-content',
			this.props.classes,
		);

		return (
			<div className={classes}>
				{this.props.children &&
				this.props.children
				}
			</div>
		)
	}
}

PreloaderWrapper.propTypes = {
	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	classes:PropTypes.string,

	/**
	 * expecting one or more nodes (HTML)
	 */
	children:PropTypes.node
};

export {
	PreloaderWrapper as default,
	PreloaderWrapper
}
