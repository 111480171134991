import React, {useState} from 'react';
import './AuthenticationCheck.scss';
import {connect} from "react-redux";
import LoginModal from "../loginModal/LoginModal";
import PropTypes from "prop-types";

function AuthenticationCheck(props) {

	const [loginIsVisible, showLogin] = useState(false);
	const button = props.render({clickHandler: onClickHandler})

	/**
	 * clickHandler wich is used by the childComponent
	 */
	function onClickHandler() {
		if (!props.isAuthenticated && props.bookable) {
			showLogin(true)
		} else if (props.isAuthenticated && props.bookable) {
			callBackHandler()
		}
	}

	/**
	 * callbackHandler which is executed after successful login
	 */
	function callBackHandler() {
		if (props.onCallback) {
			props.onCallback();
		}
	}


	return (
		<div>
			{button}
			{(!props.isAuthenticated && loginIsVisible) &&
			<LoginModal onClose={(loginSuccess) => {
				showLogin(false);
				if (loginSuccess) {
					callBackHandler()
				}

			}}/>}

		</div>
	)
}

AuthenticationCheck.propTypes = {
	/**
	 * method which should be executed after successful login
	 */
	onCallback: PropTypes.func.isRequired,

	/**
	 * method that returns JSX
	 */
	render: PropTypes.func.isRequired,
};

const mapStateToProps = (state, ownProps) => {
	return {
		isAuthenticated: state.globalStateManagement.isAuthenticated,
		bookable: state.globalStateManagement.permissions && state.globalStateManagement.permissions.bookable,
		bookableReason: state.globalStateManagement.permissions && state.globalStateManagement.permissions.bookableReason,
	}
};

export default connect(mapStateToProps, null)(AuthenticationCheck);
