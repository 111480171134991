/**
 * Error Handling Class
 *
 * @param options {{reportUrl:string, additionalParams:object}}
 * @constructor
 */
let ErrorHandling = function (options) {
	this.errorCount = 0;
	this.options = this.objectMerge([this.defaultOptions, options]);
	this.restrictMsg = this.restrictMessage ? new RegExp(this.restrictMessageRegexp, "i") : false;
	this.restrictDomain = this.restrictDomain ? new RegExp(this.restrictDomain, "i") : false;
	window.onerror = this._onError.bind(this);
};

/**
 * Default-Options
 */
ErrorHandling.prototype.defaultOptions = {
	skipMsg: "Error loading script|FlashObject is not defined",
	additionalParams: {}
};

/**
 * Merge multiple objects together
 *
 * @param objects {Array.<object>}
 * @returns {object}
 */
ErrorHandling.prototype.objectMerge = function (objects) {
	let merged = {};
	for (var i = 0; i < objects.length; i++) {
		var object = objects[i];
		for (var key in object) if (object.hasOwnProperty(key)) {
			merged[key] = object[key];
		}
	}
	return merged;
};

/**
 * Error event handler
 *
 * @param msg {string|Event} Available as event (sic!) in HTML onerror="" handler and also as an event object when dispatching an Event to window in which case the other arguments will not be supplied (as opposed to ErrorEvent which does cause the full range of arguments to be supplied to window.onerror whereas the single error event object is supplied to window.addEventListener('error') handlers)
 * @param file {string} URL of the script where the error was raised
 * @param line {number} Line number where error was raised
 * @param col {number} Column number for the line where the error occurred
 * @param error {Error}
 * @returns {boolean}
 * @private
 */
ErrorHandling.prototype._onError = function (msg, file, line, col, error) {

	if (!window.StackTrace) {
		return false;
	}
	error = error || {};
	let self = this;
	window.StackTrace.fromError(error).then(function (trace) {

		self.submitError(msg, file, line, col, trace, error);
	}).catch(function (caughtError) {
		console.log(caughtError)
	});
	return true;
};

/**
 * Detect if this error should be reported
 * @param msg {string}
 * @param file {string}
 * @returns {boolean}
 */
ErrorHandling.prototype.isRestricted = function (msg, file) {
	if (this.restrictMsg && msg.match(this.restrictMsg)) {
		return true;
	} else if (this.restrictDomain && !file.match(this.restrictDomain)) {
		return true;
	}
	return false;
};

/**
 * Submit error
 * @param msg
 * @param file
 * @param line
 * @param col
 * @param bt
 */
ErrorHandling.prototype.submitError = function (msg, file, line, col, bt, error) {
	// Log exception to console (if existing)
	if (console && console.log) {
		console.log("Error occurred", msg, file, line, col, bt);
	}

	// Restrict what is reported
	if (this.isRestricted(msg, file)) {
		return;
	}

	if (this.errorCount <= 10) {
		// Build
		let req = [];
		req.push("url=" + encodeURIComponent(window.location));
		req.push("msg=" + encodeURIComponent(msg));
		req.push("file=" + encodeURIComponent(file));
		req.push("line=" + encodeURIComponent(line));
		req.push("col=" + encodeURIComponent(col));
		req.push("ua=" + encodeURIComponent(navigator.userAgent));

		// Provide any additional params configured
		let addParams = this.options.additionalParams || {};
		for (let paramName in addParams) if (addParams.hasOwnProperty(paramName)) {
			req.push(paramName + "=" + encodeURIComponent(addParams[paramName]));
		}

		// Add stack trace
		for (let step in bt) {
			if (!bt.hasOwnProperty(step)) {
				continue;
			}
			req.push("bt[]=" + encodeURIComponent(bt[step]));
		}

		if (error.name === 'AjaxResponseError') {
			for (let paramName in error.data) {
				if (error.data.hasOwnProperty(paramName)) {
					req.push(`info[${paramName}] = ${encodeURIComponent(error.data[paramName])}`);
				}
			}
		}

		// Send error
		new Image().src = this.options.reportUrl + "?" + req.join("&");
		this.errorCount++;
	}
};

export {
	ErrorHandling
}
