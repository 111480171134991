import {DataProviderService} from "../services/DataProviderService";
import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import PropTypes from "prop-types";

function withPdfDownload(WrappedComponent) {
	class WithPdfDownload extends Component {

		/**
		 * constructor
		 * default data is isLoading: true to pass a indicator for loading process to wrapped component
		 * @param props
		 */
		constructor(props) {
			super(props);

			this.state = {
				authFailed:false,
				isLoading:false
			};
			this.openPdfTimeout = null;

			this.clickHandler = this.clickHandler.bind(this);
			this.downloadCompleteHandler = this.downloadCompleteHandler.bind(this);
			this.downloadErrorHandler = this.downloadErrorHandler.bind(this);
		}

		componentDidMount() {
			this.dataProvider = new DataProviderService({endpoint:this.props.url});
		}

		componentWillUnmount() {
			if(this.dataProvider) {
				this.dataProvider.cancelRequest();
				this.dataProvider = null;
			}

			if(this.openPdfTimeout) {
				clearTimeout(this.openPdfTimeout);
			}
		}

		clickHandler(e) {
			this.fetchData();
			this.setState({isLoading:true});
			if(this.props.onClick){
				this.props.onClick(e);
			}
		}

		/**
		 * fetch data of given endpoint
		 * fetchData is called recursively if pollIntervall is set
		 *
		 */
		fetchData() {
			if (this.dataProvider) {
				this.dataProvider.getPdfBlob().then(
					response => {

						if (response) {
							if(response.isAxiosError) {
								console.warn("Error while datafetching: ", response);

								this.handleError(response.response.status);
							}else {
								this.showFile(response);
								this.downloadCompleteHandler();
							}
						}else{
							console.info('Response is empty. Request Canceled');
							this.downloadErrorHandler();
						}
					}
				)
			}
		}

		/**
		 * sets isLoading state to false when download is complete
		 */
		downloadCompleteHandler(){
			this.setState({isLoading:false});
			if(this.props.onDownloadComplete){
				this.props.onDownloadComplete();
			}
		}


		downloadErrorHandler(){
			this.setState({
				isLoading:false
			});

			if(this.props.onDownloadError){
				this.props.onDownloadError();
			}
		}


		showFile(blob){

			// It is necessary to create a new blob object with mime-type explicitly set
			// otherwise only Chrome works like it should
			let newBlob = new Blob([blob], {type: "application/pdf"});

			// IE doesn't allow using a blob object directly as link href
			// instead it is necessary to use msSaveOrOpenBlob
			if (window.navigator && window.navigator.msSaveOrOpenBlob) {
				window.navigator.msSaveOrOpenBlob(newBlob);
				return;
			}

			// For other browsers:
			// Create a link pointing to the ObjectURL containing the blob.
			const data = window.URL.createObjectURL(newBlob);

			let link = document.createElement('a');
			link.href = data;
			link.target = '_blank';
			if(this.props.download){
				link.download = this.props.download;
			}
			link.click();
			this.openPdfTimeout = setTimeout(() => {
				// For Firefox it is necessary to delay revoking the ObjectURL
				window.URL.revokeObjectURL(data);
			}, 100);
		}

		/**
		 * do something specific for different kinds of errors
		 * @param errorStatus
		 */
		handleError(errorStatus){

			switch (errorStatus) {

				case 401:
					this.setState({authFailed:true});
					break;

				default:
					console.warn("error with code ",errorStatus);
			}

		}


		render() {
			//filter props to pass
			const { download, onDownloadComplete, onDownloadError, url, loadingContent, refetchTrigger, ...passThroughProps } = this.props;

			if(this.state.authFailed){
				return <Redirect to={'/login'}/>
			}

			// if component isLoading and loadingContent is set
			if(this.state.isLoading && this.props.loadingContent) {
				return (this.props.loadingContent);
			}
			return <WrappedComponent {...passThroughProps} onClick={this.clickHandler}/>;
		}
	}

	WithPdfDownload.propTypes = {
		download: PropTypes.string,
		onDownloadComplete:PropTypes.func,
		url:PropTypes.string,
		loadingContent:PropTypes.element
	};

	WithPdfDownload.displayName = `WithDataProvider(${getDisplayName(WrappedComponent)})`;
	return WithPdfDownload;
}



function getDisplayName(WrappedComponent) {
	return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withPdfDownload;
