import React from 'react';
import {Redirect} from "react-router-dom";

function LegacyRouteTabRedirect(props) {

	const routeId = props.match.params.routeId !== 'default' ? props.match.params.routeId : '';
	const newPath = `/digitaloffer/${props.match.params.offertoken}/routes/${routeId}`;
	return <Redirect to={newPath}/>
}

export {
	LegacyRouteTabRedirect as default,
	LegacyRouteTabRedirect
}
