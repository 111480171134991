import React from 'react';
import './SocialMediaBlock.scss'
import PropTypes from "prop-types";
import Icon from "../icon/Icon";

function SocialMediaBlock({dispatchFetchData, ...props}) {

  return (
    <div className={"socialmedia-block socialmedia-block--icon socialmedia-block--dark socialmedia-block--footer-nav"}>
      <a className={"socialmedia-block__link"} target="_blank" rel="noopener noreferrer"
         href="https://www.youtube.com/channel/UCbNQejl_dvH_rMcSX6fuRcg?sub_confirmation=1">
        <div className={"socialmedia-block__icon"}>
          <Icon name={'youtube'}/>
        </div>
      </a>

      <a className={"socialmedia-block__link"} target="_blank" rel="noopener noreferrer"
         href="https://www.facebook.com/pages/Hamburg/Canusa/332194262578">
        <div className={"socialmedia-block__icon"}>
          <Icon name={'facebook'}/>
        </div>
      </a>
      <a className={"socialmedia-block__link"} target="_blank" rel="noopener noreferrer"
         href="https://www.instagram.com/canusatouristik/">
        <div className={"socialmedia-block__icon"}>
          <Icon name={'instagram'}/>
        </div>
      </a>
      <a className={"socialmedia-block__link"} target="_blank" rel="noopener noreferrer"
         href="https://www.pinterest.de/canusatouristik/">
        <div className={"socialmedia-block__icon"}>
          <Icon name={'pinterest'}/>
        </div>
      </a>
    </div>

  )
}

SocialMediaBlock.propTypes = {
  /**
   * OPTIONAL: add additional classes for this component here.
   * Use this prop for special css-classes, which are not defined by default.*
   */
  additionalClasses: PropTypes.string,
};


export {
  SocialMediaBlock as default,
  SocialMediaBlock
}
