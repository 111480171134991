import React, {Component} from 'react';
import './ItemHeaderOptionals.scss'
import PropTypes from "prop-types";

import {BUTTON_APPEARANCE, BUTTON_WIDTH, Button} from "../../../button/Button";
import {Link} from "../../../link/Link";
import {Modal} from "../../../modal/Modal";
import {ItemSelect} from "../../item-select/ItemSelect";
import {Tooltip} from "../../../tooltip/Tooltip";

import {renderCollapsible, renderHorizontalLine, renderNoteIcon, renderTypeIcon} from "../ItemHeaderUtils";
import {currencyFormat} from "../../../../../vendor/utils/Utils";
import classNames from "classnames";
import {mapLabelType} from "../../../../../vendor/utils/Mappings";
import {connect} from "react-redux";
import {Badge} from "../../../badge/Badge";

class ItemHeaderOptionals extends Component {

  constructor(props) {
    super(props);
    this.showSelectModal = this.showSelectModal.bind(this);
    this.state = {
      showSelectModal: false,
    }
  }

  /**
   * set showSelectModal state to show or hide modal
   *
   * @param {boolean} show
   */
  showSelectModal(show) {
    this.setState({
      showSelectModal: show
    })
  }

  /**
   * split at first colon, join all following items
   * temporary helper function
   * @param label
   * @param type
   * @returns {{description: string | *, label: T | *}}
   */
  splitItemLabel(label, type) {
    const splitList = label.split(': ');
    return {
      label: (splitList.length > 1 && splitList.shift()) || mapLabelType(type),
      description: splitList.join(': ') || label
    }
  }

  /**
   * render label (e.g.: excursion, flight, etc ) of optional header
   * @param label
   * @param type
   * @returns {*}
   */
  renderLabel(label, type) {
    const data = this.splitItemLabel(label, type);
    return <div className={'item-header-optionals__title-type ts-copy-small'}
                dangerouslySetInnerHTML={{__html: data.label}}/>
  }

  /**
   * render description of optional item
   * @param label
   * @returns {*}
   */
  renderDescription(label) {
    const data = this.splitItemLabel(label);
    return <div className={'item-header-optionals__title-description ts-bold'}
                dangerouslySetInnerHTML={{__html: data.description}}/>
  }

  /**
   * render price
   * CURRENTLY NOT USED
   * @return {*}
   */
  renderPrice() {
    return (
      <>
        {!!this.props.item.price &&
        <div className="item-header-optionals__price">
          <span className={'ts-bold'}>{currencyFormat(this.props.item.price)}</span>
        </div>
        }
      </>
    )
  }

  /**
   * render selection Button or Link and Modal
   * @return {*}
   */
  renderSelect() {
    if (this.props.item.options && this.props.item.options.length > 0) {
      return (
        <>
          <div className={'item-header-optionals__select'}>

            {this.props.isDesktop ?
              <Button label={'Auswahltermine'}
                      icon={'calendar'}
                      width={BUTTON_WIDTH.FULL_WIDTH}
                      appearance={BUTTON_APPEARANCE.GHOST}
                      additionalClasses={'item-header-optionals__select-button'}
                      onClick={(event) => {
                        event.preventDefault();
                        this.showSelectModal(true)
                      }}
              />
              :
              <Link label={'Auswahltermine'}
                    icon={'calendar'}
                    width={BUTTON_WIDTH.FULL_WIDTH}
                    size={'small'}
                    onClick={(event) => {
                      event.preventDefault();
                      this.showSelectModal(true);
                    }}
              />
            }
          </div>

          {this.state.showSelectModal &&
          <Modal
            size={this.props.isDesktop ? 'big' : 'fullsize'}
            onClose={() => this.showSelectModal(false)}>
            <ItemSelect options={this.props.item.options}
                        item={this.props.item}
                        isDesktop={this.props.isDesktop}
                        parentId={this.props.collapsibleId}
                        readOnly={this.props.readOnly}
                        showDisclaimer={this.props.readOnly}
                        permissions={this.props.permissions}
                        offerNumber={this.props.offerNumber}
            />
          </Modal>}
        </>
      )
    } else {

      return (
        <>
          <div className={'item-header-optionals__select'}>
            <Tooltip
              content={'Bitte kontaktieren Sie Ihren Reiseberater, um diese Leistung zu buchen.' /*TODO: add Values from reduxstore*/}
              direction={this.props.isDesktop ? 'left' : 'top-right'}
              inactive={true} /*TODO: add Values from reduxstore*/
            >
              {this.props.isDesktop ?
                <Button label={'hinzufügen'}
                        icon={'plus'}
                        width={BUTTON_WIDTH.FULL_WIDTH}
                        additionalClasses={'item-header-optionals__select-button'}
                        appearance={BUTTON_APPEARANCE.GHOST}
                        onClick={(event) => {
                          event.preventDefault();
                          return null
                        }}
                />
                :
                <Link label={'hinzufügen'}
                      icon={'plus'}
                      width={BUTTON_WIDTH.FULL_WIDTH}
                      size={'small'}
                      onClick={(event) => {
                        event.preventDefault();
                        return null
                      }}
                />
              }
            </Tooltip>
          </div>
        </>
      )
    }
  }

  /**
   * render select Button
   */
  renderButtons() {

    let content = <>
      {this.renderSelect()}
    </>;

    if (!this.props.isDesktop) {
      content = <div className={'item-header-optionals__button-wrapper'}>
        {content}
      </div>
    }
    return content;
  }

  /**
   * render Badge that shows selected Dates in itemHeader
   * @returns {*}
   */
  renderSelectedOptions() {

    const {item} = this.props;

    if (item.selected) {

        const date = item.date.split('-').reverse().join('.');
        const label = (item.time) ? date + ' | ' + item.time : date;

      return <div className={'item-header-optionals__badge-list'}>
        <Badge label={label}
               icon={'time'}
               size={'small'}
               type={'signal'}
               additionalClasses={'item-header-optionals__badge'}
        />
     </div>
    }
    return null;
  }

  render() {
    const classes = classNames(
      'item-header__grid'
    );

    return (
      <>
        {renderTypeIcon(this.props.item.type)}
        <div className={classes}>
          <label htmlFor={this.props.collapsibleId} className={'item-header__clickarea'}></label>
          <div className={'item-header__top'}>
            <div className={'item-header__top-left'}>
              {!this.props.isDesktop && this.renderLabel(this.props.item.label, this.props.item.type)}
            </div>

            <div className={'item-header__top-right'}>
              {!this.props.isDesktop && this.renderPrice()}
            </div>
          </div>

          {renderHorizontalLine()}

          <div className={'item-header__middle'}>
            <div className={'item-header__middle-left'}>
              {this.props.isDesktop && this.renderLabel(this.props.item.label, this.props.item.type)}
              <div className={'item-header-optionals__label-wrapper'}>
                {this.renderDescription(this.props.item.label, this.props.item.type)}
                {this.renderSelectedOptions()}
              </div>

            </div>
            <div className={'item-header__middle-right'}>
              {this.props.isDesktop && this.renderPrice()}
              {this.props.isDesktop && this.renderButtons()}
              {this.props.item.showNoticeIcon && renderNoteIcon(this.props.groupIndex, this.props.item.id, this.props.item.notice, 'item-header__notice', this.props.item.originId)}
              {this.props.isDesktop && renderCollapsible(this.props.isDesktop, this.props.isCollapsible, this.props.collapsibleId, 'Details ansehen')}

            </div>
          </div>
          {!this.props.isDesktop &&
          <div className={'item-header__bottom'}>
            <div className={'item-header__bottom-left'}>
              {this.renderButtons()}
            </div>
            <div className={'item-header__bottom-right'}>
              {renderCollapsible(this.props.isDesktop, this.props.isCollapsible, this.props.collapsibleId, 'Details ansehen')}
            </div>
          </div>
          }
        </div>
      </>
    );
  }
}

ItemHeaderOptionals.defaultProps = {
  isCollapsible: false,
  readOnly: false,
};

ItemHeaderOptionals.propTypes = {

  /**
   * OPTIONAL: add additional classes for this component here.
   * Use this prop for special css-classes, which are not defined by default.*
   */
  additionalClasses: PropTypes.string,

  /**
   * defines if header has collapsible funtionality
   *
   * @default: false
   */
  isCollapsible: PropTypes.bool,

  /**
   * id of parent collapsible. use for toggle the collapsible for nested elements
   *
   */
  collapsibleId: PropTypes.string.isRequired,

  /**
   * prevents changes in ItemHeader
   *
   * @default: false
   */
  readOnly: PropTypes.bool,

  /**
   * switch between tabs & modal
   */
  isDesktop: PropTypes.bool.isRequired,

  /**
   *
   * see **Item** for further information
   */
  item: PropTypes.shape({
    /**
     * template of ItemHeader
     * @example: 'main'
     *
     */
    contentType: PropTypes.oneOf(['main', 'extra', 'optional']),
    /**
     * label used in ItemHeader
     * @example: 'Mariner King Inn'
     */
    label: PropTypes.string,

    /**
     * location.label used in ItemHeader
     * @example: {label:'Lighthouse Trail, NS', lat:34.2618964, lng:-116.9194283}
     */
    location: PropTypes.shape(
      {
        /**
         * Location label
         * @example: 'Lighthouse Trail, NS'
         */
        label: PropTypes.string,

        /**
         * Latitude of location
         * @example: 34.2618964
         */
        lat: PropTypes.number,

        /**
         * Longitude of location
         * @example: -116.9194283
         */
        lng: PropTypes.number,
      }
    ),

    /**
     * OPTIONAL: an array with options inside
     *
     * @example:
     *       [
     *        {date: "2019-08-29",
     *			times: [
     *				{price: 118, time: "14:00-15:30"}
     *				{price: 118, time: "15:00-16:30"}
     * 				{price: 118, time: "16:30-18:00"}
     *				{price: 118, time: "20:30-22:00"}]},
     *            {date: "2019-08-30"
     *			times: [
     *				{price: 118, time: "14:00-15:30"}
     *				{price: 118, time: "15:00-16:30"}
     *				{price: 118, time: "16:30-18:00"}
     *				{price: 118, time: "20:30-22:00"}]}
     *        ]
     *
     */
    options: PropTypes.array,

  })
};

const mapStateToProps = (state) => {

  return {
    isDesktop: state.globalStateManagement.isDesktop,
    offerNumber: state.globalStateManagement.offer.offerNumber,
    permissions: state.globalStateManagement.permissions,
  }
};

export default connect(mapStateToProps)(ItemHeaderOptionals)
