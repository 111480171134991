import React, {useEffect, useRef} from "react";
import './Checkout.scss';
import classNames from "classnames";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import CheckoutProgress from "./CheckoutProgress";

import CheckoutParticipants from "./steps/CheckoutParticipants";
import CheckoutRenter from "./steps/CheckoutContact";
import CheckoutSummary from "./steps/CheckoutSummary";
import {CHECKOUT_BUTTON_CONFIG} from "./CheckoutNavigation";
import {
	trackCheckoutContact,
	trackCheckoutParticipants, trackCheckoutSummary,
} from "../../../vendor/utils/TrackingManager";
import {getPriceOfActiveItems, getPriceOfGroups} from "../../redux/selectors";

export const CHECKOUT_STEP_PARTICIPANTS = 1;
export const CHECKOUT_STEP_CONTACT = 2;
export const CHECKOUT_STEP_SUMMARY = 3;

function Checkout(props) {

	const classes = classNames(
		'checkout',
		props.additionalClasses
	);

	const initialFlagState = {
		[CHECKOUT_STEP_PARTICIPANTS]: false,
		[CHECKOUT_STEP_CONTACT]: false,
		[CHECKOUT_STEP_SUMMARY]: false
	}
	const checkoutStepVisited = useRef(initialFlagState);
	const maxCheckoutSteps = Object.keys(CHECKOUT_BUTTON_CONFIG).length;

	useEffect(() => {
		const selector = document.querySelector('.modal__layer');
		if (document.body.contains(selector)) { // use document.body to ensure as some browsers will not be able to execute .contains() on document
			selector.scrollTo({ top: 0 });
		}

		if(!checkoutStepVisited.current[props.checkoutStep]) {

			switch (props.checkoutStep) {
				case CHECKOUT_STEP_PARTICIPANTS:
					trackCheckoutParticipants(props.totalPrice);
					break;
				case CHECKOUT_STEP_CONTACT:
					trackCheckoutContact();
					break;
				case CHECKOUT_STEP_SUMMARY:
					trackCheckoutSummary();
					break;
				default:
					console.warn('unknown step: ',props.checkoutStep);
			}
			checkoutStepVisited.current[props.checkoutStep] =  true;
		}
	}, [props.checkoutStep, props.totalPrice]);


	return (
		<div className={classes}>
			<CheckoutProgress steps={maxCheckoutSteps} currentStep={props.checkoutStep}/>
			{props.checkoutStep === CHECKOUT_STEP_PARTICIPANTS &&
			<CheckoutParticipants parentId={props.parentId} checkoutStep={props.checkoutStep}/>}
			{props.checkoutStep === CHECKOUT_STEP_CONTACT &&
			<CheckoutRenter parentId={props.parentId} checkoutStep={props.checkoutStep}/>}
			{props.checkoutStep === CHECKOUT_STEP_SUMMARY &&
			<CheckoutSummary parentId={props.parentId} checkoutStep={props.checkoutStep}/>}
		</div>
	);
}

Checkout.propTypes = {
	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string
};

Checkout.defaultProps = {
	hasCheckoutButton: false
};

const mapStateToProps = (state, ownProps) => {
	return {
		checkoutStep: state.checkoutManagement.checkoutState,
		totalPrice: getPriceOfGroups ( state.offerContentManagement.offerItems ) + getPriceOfActiveItems ( state.itemManagement.itemMap ),
	}
};

export default connect(mapStateToProps, {})(Checkout);
