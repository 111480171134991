import {DataProviderService} from "../services/DataProviderService";
import React, {Component} from 'react';
import {Redirect} from "react-router-dom";
import {jsonEqual} from "../../vendor/utils/Utils";

import PropTypes from "prop-types";

function withDataProvider(WrappedComponent, {endpoint, useCache = false, useMock = false, value = null, pollInterval = null}) {
	class WithDataProvider extends Component {

		/**
		 * constructor
		 * default data is isLoading: true to pass a indicator for loading process to wrapped component
		 * @param props
		 */
		constructor(props) {
			super(props);
			this.state = {
				data: {isLoading: true},
				authFailed: false,
			};

			this.pollIntervallId = null;
			this.lastData = null;
			this.refetchHandler = this.refetchHandler.bind(this);

		}

		componentDidMount() {
			this.dataProvider = new DataProviderService({endpoint, useCache, useMock});
			this.fetchData();
		}

		componentWillUnmount() {
			if (this.dataProvider) {
				this.dataProvider.cancelRequest();
				this.dataProvider = null;
				if (this.pollIntervallId) {
					clearTimeout(this.pollIntervallId);
				}
			}
		}

		/**
		 * compare updateId props. if new updateId is set, fetch data
		 * @param prevProps
		 */
		componentDidUpdate(prevProps) {
			if (this.props.updateId !== prevProps.updateId) {
				this.fetchData();
			}
		}

		/**
		 * this handler is piped down to cta which might trigger refetching data
		 * especially for pdflinks which should trigger fetching of documents items
		 */
		refetchHandler() {
			this.fetchData();
		}

		/**
		 * fetch data of given endpoint
		 * fetchData is called recursively if pollIntervall is set
		 *
		 */
		fetchData() {
			if (this.dataProvider) {
				this.setData({isLoading: true});

				this.dataProvider.getData(value).then(

					response => {

						if (response) {
							if (!response.internalType) {

								if (Array.isArray(response)) {
									this.setData({list: response})
								} else {
									this.setData(response);
								}
								if (pollInterval) {
									clearTimeout(this.pollIntervallId);
									this.pollIntervallId = setTimeout(() => {
										this.fetchData();
									}, pollInterval);
								}
							} else if (response.internalType) {
								this.handleError(response.internalType)
							}
						}
					}
				)
			}
		}

		/**
		 * do something specific for different kinds of errors
		 * @param errorStatus
		 */
		handleError(errorStatus) {
			console.log("errorStatus", errorStatus);
			switch (errorStatus) {

				case 401:
					this.setState({authFailed: true});
					break;

				case "isCanceled":
					//do nothing here
					break;

				default:
					console.warn("error with code ", errorStatus);
					this.setErrorData();
			}
		}

		/**
		 * sets hasLoadingError to true
		 */
		setErrorData(){
			if (this.props.onLoadingError) {
				this.props.onLoadingError();

			}
			this.setData({hasLoadingError: true});
		}

		/**
		 * parsing json-response and store in states
		 * @param {json} [data]
		 */
		setData(data) {
			// if new Data is available
			if (!jsonEqual(this.lastData, data)) {
				this.setState({
					data: data
				});
			}
			this.lastData = data
		}

		render() {
			if (this.state.authFailed) {
				return <Redirect to={'/login'}/>
			}

			return <WrappedComponent {...this.state.data} {...this.props}/>;
		}
	}

	WithDataProvider.displayName = `WithDataProvider(${getDisplayName(WrappedComponent)})`;
	return WithDataProvider;
}

function getDisplayName(WrappedComponent) {
	return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

withDataProvider.propTypes = {
	/**
	 * Eventhandler if DataProvider has loading Error
	 */
	onLoadingError: PropTypes.func,
};

export default withDataProvider;
