import React, {Component} from 'react';
import PropTypes from 'prop-types';
import './Alternatives.scss'
import AlternativeTeaserItem from "./AlternativeTeaserItem";
import classNames from "classnames";
import {Icon} from "../icon/Icon";
import {Slider} from "../slider/Slider";
import {connect} from "react-redux";
import {dispatchActivateItem} from "../../redux/actions/itemManagementAction";
import {getActiveItem} from "../../redux/selectors";

class Alternatives extends Component {

	constructor(props) {
		super(props);
		this.selectedIdChangeHandler = this.selectedIdChangeHandler.bind(this);
		this.dragCompleteHandler = this.dragCompleteHandler.bind(this);
		this.nextClickHandler = this.nextClickHandler.bind(this);
		this.prevClickHandler = this.prevClickHandler.bind(this);
	}

	/**
	 * creating arrows by direction
	 * @param direction
	 * @returns {*}
	 */
	arrowFactory(direction) {
		const classes = classNames(
			`alternatives-slider__arrow alternatives-slider__arrow alternatives-slider__arrow--${direction}`
		);

		const innerClasses = classNames(
			'alternatives-slider__arrow-inner',
			{'alternatives-slider__arrow-inner--expanded':this.props.isExpanded}
		);

		return (
			<div className={classes}>
				<div className={innerClasses}>
					<Icon additionalClasses={'alternatives-slider__arrow-icon'} name={'arrow-dots-' + direction}/>
				</div>
			</div>
		)
	}

	/**
	 * create unique id by concating alternativesList as string
	 * @param list
	 * @returns {string}
	 */
	createUniqueGroupId(list) {
		return list.sort().join('_');
	}

	findSelectedItemIndex(alternativeList, selectedId) {
		if (alternativeList && alternativeList.length > 0 && selectedId) {
			return alternativeList.findIndex(alternativeId => alternativeId === selectedId)
		}
	}

	/**
	 * render Alternative Teaser Items
	 * @param alternativeList
	 * @param sliderId
	 * @returns {*}
	 */
	renderAlternativeItems(alternativeList, sliderId, isExpanded) {
		if (alternativeList.length <= 0) {
			return
		}

		const groupId = this.createUniqueGroupId([...alternativeList]);

		return alternativeList.map((itemId, index) => {

			let item = this.props.itemMap[itemId];
			return <AlternativeTeaserItem item={item}
																		key={item.id}
																		groupId={groupId}
																		appearance={'default'}
																		isExpanded={isExpanded}
																		onSelect={(itemId) => {
																			this.props.dispatchActivateItem(itemId)
																		}}
																		isSelected={item.id === this.props.selectedAlternativeId}
																		onClick={this.selectedIdChangeHandler}
																		isActive={item.active}
																		disabled={this.props.isSaving}
			/>
		});
	}

	/**
	 * handle changes on SliderItemsIndex
	 * -> item is selected
	 * @param selectedItemId
	 */
	selectedIdChangeHandler(selectedItemId) {
		if (this.props.onChange) {
			this.props.onChange(selectedItemId);
		}
	}

	dragCompleteHandler(index) {
		this.selectedIdChangeHandler(this.props.alternativeList[index]);
	}

	nextClickHandler() {
		const activeItemIndex = this.findSelectedItemIndex(this.props.alternativeList,  this.props.selectedAlternativeId);

		if(activeItemIndex < this.props.alternativeList.length) {
			this.selectedIdChangeHandler(this.props.alternativeList[activeItemIndex +1]);
		}

	}

	prevClickHandler() {
		const activeItemIndex = this.findSelectedItemIndex(this.props.alternativeList, this.props.selectedAlternativeId);

		if(activeItemIndex > 0) {
			this.selectedIdChangeHandler(this.props.alternativeList[activeItemIndex -1]);
		}
	}

	/**
	 * renders alternativeSlider
	 * @returns {*}
	 */
	renderAlternativeSlider() {
		const activeItemId = getActiveItem(this.props.itemMap, this.props.alternativeList).id;
		const activeItemIndex = this.findSelectedItemIndex(this.props.alternativeList, activeItemId);
		const selectedItemIndex = this.findSelectedItemIndex(this.props.alternativeList,  this.props.selectedAlternativeId);

		return (<>
			{!this.props.isExpanded && <div className={'alternatives__dotted-line'}></div>}

			<div className={'alternatives-slider'}>
				<Slider startAt={activeItemIndex}
								keyControl={true}
								prevBtn={this.arrowFactory('left')}
								nextBtn={this.arrowFactory('right')}
								additionalItemClass={'alternatives-slider__item'}
								indentButtons={!!this.props.isDesktop}
								snapSlides={true}
								centerMode={true}

				        showPrevBtn={selectedItemIndex > 0}
				        showNextBtn={selectedItemIndex < this.props.alternativeList.length-1}
				        onNextClick={this.nextClickHandler}
				        onPrevClick={this.prevClickHandler}
				        onDragComplete={this.dragCompleteHandler}
								classes={{
									slide: 'alternatives-slider__slide',
									navBtn: 'alternatives-slider__btn'
								}}
				>
					{this.props.alternativeList && this.renderAlternativeItems(this.props.alternativeList, this.props.sliderId, this.props.isExpanded)}
				</Slider>
			</div>
		</>)
	}

	render() {

		const classes = classNames(
			'alternatives',
			{
				'alternatives--expanded': this.props.isExpanded,
			},
			this.props.additionalClasses
		);

		return (
			<div className={classes}>
				{this.renderAlternativeSlider()}
			</div>
		)
	}
}

Alternatives.propTypes = {
	/**
	 * id of parent Item
	 */
	sliderId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	/**
	 * use small or extended view
	 */
	isExpanded: PropTypes.bool,
	/**
	 * expose selectedItemId to parent
	 */
	onChange: PropTypes.func.isRequired,
	/**
	 * required data for alternativesTeaser
	 */
	alternativeList: PropTypes.arrayOf(
		PropTypes.string
	),

	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string,
};

Alternatives.defaultProps = {};

const mapStateToProps = (state, ownProps) => {

	return {
		itemMap: state.itemManagement.itemMap,
		isSaving: state.checkoutManagement.isSaving,
	}
};

export default connect(mapStateToProps, {dispatchActivateItem: id => dispatchActivateItem(id)})(Alternatives)
