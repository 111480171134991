import React, {Component} from 'react';
import {Icon} from "../icon/Icon";
import './Radiobutton.scss'
import classNames from "classnames";
import PropTypes from "prop-types";

class Radiobutton extends Component {

	/**
	 * @param {string} [name]
	 * @param {string} [value]
	 * @param {boolean} [required=false]
	 * @param {boolean} [disabled=false]
	 * @param {function} [changeHandler]
	 * @param {string} [additionalClasses]
	 */

	constructor(props) {
		super(props);
		this.changeHandler = this.changeHandler.bind(this);
	}

	changeHandler(e) {
		this.props.changeHandler && this.props.changeHandler(e.currentTarget.value);
	}

	render() {

		const classes = classNames(
			'radiobutton',
			{[`radiobutton--${this.props.appearance}`]: this.props.appearance},
			this.props.additionalClasses,
		);

		return (
			<div className={classes}>
				<input type="radio"
							 name={this.props.name}
							 value={this.props.value}
							 required={this.props.required}
							 disabled={this.props.disabled}
							 onChange={this.changeHandler}
							 checked={!!this.props.checked}
				/>
				<label htmlFor={this.props.uid}>
					<Icon name='radio-check'/>
					<Icon name='radio'/>
				</label>
			</div>
		)
	}
}

Radiobutton.propTypes = {
	/**
	 * name of the radiogroup
	 */
	name: PropTypes.string,
	/**
	 * OPTIONAL
	 * input required
	 * @default: null
	 */
	required: PropTypes.bool,
	/**
	 * OPTIONAL
	 * input disabled
	 * @default: null
	 */
	disabled: PropTypes.bool,
	/**
	 * OPTIONAL
	 * input value default: null
	 */
	value: PropTypes.string,
	/**
	 * callbackhandler returns value of selected radiobutton
	 */
	changeHandler: PropTypes.func,
	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string,
	/**
	 * OPTIONAL:
	 */
	appearance: PropTypes.oneOf(['primary', 'signal', 'white']),
};


export {
	Radiobutton as default,
	Radiobutton
}
