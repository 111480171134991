import React, {Component} from 'react';
import './FAQPage.scss'
import withDataProvider from "../../hoc/WithDataprovider";
import Stage from "../../components/stage/Stage";
import Faq from "../../components/faq/Faq";
import Footer from "../../components/footer/Footer";
import {Header} from "../../components/header/Header";
import classNames from "classnames";
import {isDevEnvironment} from "../../../vendor/utils/Utils";
import DebugBar, {DEBUG} from "../../debug/DebugBar";
import {trackPageView} from "../../../vendor/utils/TrackingManager";

const StageWithDataProvider = withDataProvider(Stage, {
	useMock: false,
	endpoint: 'portal/stage',
	value: {topic: 'faq'}
});

class FAQPage extends Component {

	componentDidMount() {
		const trackingObj = {
			tabId:'faq', // generic
		};

		trackPageView(trackingObj);
	}

	render() {
		const classes = classNames(
			'faq-page',
			'page-full-height'
		);

		const showDebugBar = isDevEnvironment() && process.env.REACT_APP_GLOBAL_DEBUG === "true";

		return (
			<div className={classes}>
				{showDebugBar && <DebugBar show={[DEBUG.TRACKING]}/>}
				<Header/>
				<StageWithDataProvider
					withContent={false}
					join={true}
					imageOptions={{direction: 'South'}} // TODO: remove after stage images have been updated in DB
				/>
				<div className={'centered-content'}>
					<Faq additionalClasses={'box'}/>
				</div>
				<Footer/>
			</div>
		);
	}
}

export {
	FAQPage as default,
	FAQPage
}
