import React, {Fragment} from 'react';
import './AddonTab.scss'
import {Icon, ICON_SIZE} from "../../../icon/Icon";
import PropTypes from "prop-types";
import {Tooltip} from "../../../tooltip/Tooltip";
import ReactMarkdown from "react-markdown";
import classNames from 'classnames';
import {currencyFormat} from "../../../../../vendor/utils/Utils";
import ControlledSelect from "../../../select/ControlledSelect";
import ControlledCheckbox from "../../../checkbox/ControlledCheckbox";
import {connect} from "react-redux";
import {dispatchUpdateAddOn} from "../../../../redux/actions/itemManagementAction";
import rehypeRaw from "rehype-raw";
import rehypeSanitize from "rehype-sanitize";

function AddonTab(props) {

	const changeHandler = (addOnId, value) => {
		props.dispatchUpdateAddOn({itemId: props.itemId, addOnId, value})
	}

	/**
	 * creates itemContent with checkbox or select
	 * @param item
	 * @param itemPrice
	 * @returns {null|*}
	 */
	const renderSelectOrCheckbox = (addOn) => {
		const optionLength = Object.keys(addOn.price).length;

		const mandatory = !!parseInt(addOn.mandatory);

		const isMandatory = props.isDigitalOffer ? mandatory : false;
		const hasTooltip = props.isDigitalOffer ? mandatory : true;
		const isDisabled = props.isDigitalOffer ? (mandatory || props.isSaving) : true;

		if (!optionLength) {
			return null
		} else if (optionLength === 1) {

			let checkBox = <ControlledCheckbox name={addOn.name}
																				 onChange={checked => changeHandler(addOn.id, +checked)}
																				 checked={!!addOn.selected}
																				 disabled={!props.isBookable || isDisabled}
			/>;

			if (props.isBookable && !mandatory && !hasTooltip) {
				return checkBox
			} else {
				return wrapInTooltip(<div className={'touch-area'}>
					{checkBox}
				</div>, true)
			}

		} else {

			const zeroAddedOptions = {0: [0], ...addOn.price}
			const itemPrice = Object.keys(zeroAddedOptions).map((key) => {
				return createOptionObject(key, zeroAddedOptions);
			});

			return wrapInTooltip(<ControlledSelect name={addOn.name}
																				label={''}
																				size={'small'}
																				fullWidth={true}
																				disabled={isDisabled}
																				onChange={value => changeHandler(addOn.id, value)}
																				options={itemPrice}
																				value={addOn.selected.toString()}
			/>, hasTooltip, isMandatory);
		}
	}

	/**
	 * creates Object for select options
	 * @param key
	 * @param optionList
	 * @return {{disabled: boolean, label: string, value, extendedLabel: string}}
	 */
	const createOptionObject = (key, optionList) => {
		const label = key.toString();
		const extendedLabel = key + ' (' + currencyFormat(optionList[key]) + ')';
		return {value: key, label, extendedLabel, disabled: !props.isDigitalOffer}
	}

	/**
	 * wraps Checkbox or Select in a toolTip (conditionally)
	 * @param item
	 * @param useToolip
	 * @returns {JSX.Element|*}
	 */
	const wrapInTooltip = (item, useTooltip, isMandatory = false) => {
		const tooltipText = isMandatory ?
			'Dies ist eine Pflichtleistung, die nicht abgewählt werden kann.'
			:
			props.bookableReason || 'Bitte rufen Sie uns an, wenn Sie diese Leistung buchen möchten.'; //TODO: prüfen ob der String noch für das Portal benötigt wird

		return useTooltip ? <Tooltip content={tooltipText}
																 direction={props.isDesktop ? 'top-right' : 'right'}
																 color={'primary'}
																 parentContainerSelector={'#modal-addon-' + props.parentId}
			>
				{item}
			</Tooltip>
			:
			item
	}


	/**
	 * creates a list of addon-items
	 * @param item
	 * @param index
	 * @returns {*}
	 */
	const renderAddonDetailItem = (addOn, index) => {

		const price = {...addOn.price}

		const isSelect = Object.keys(price).length > 1;

		// prevent wrong br-tags
		addOn.help = addOn.help.split("</br>").join('<br/>');

		const infoContent = <div className={'item-details-addon__tooltip'}>
			<ReactMarkdown rehypePlugins={[rehypeRaw, rehypeSanitize]}>{addOn.help}</ReactMarkdown>
		</div>;

		const classes = classNames(
			'item-details-addon',
			'dotted',
			{'with-select': isSelect}
		);

		let defaultPrice;
		if (isSelect) {
			// defaultPrice  if it's select input
			defaultPrice = addOn.price.hasOwnProperty(addOn.selected) ? addOn.price[addOn.selected] : 0;
		} else {
			// defaultPrice  if it's checkbox input
			defaultPrice = addOn.price[Object.keys(addOn.price)[0]];
		}

		return (
			<div className={classes} key={index}>
				<div className={'item-details-addon__selection'}>
					{renderSelectOrCheckbox(addOn)}
				</div>

				<span className={'mobile-wrapper'}>

					<span className={'item-details-addon__price ts-bold'}>{currencyFormat(defaultPrice)}</span>
						<span className={'item-details-addon__name'}>{addOn.name}</span>

					{(addOn.help) &&
					<span className={'item-details-addon__info'}>
								<Tooltip content={infoContent}
												 direction={'left'}
												 color={'secondary'}
												 size={'large'}
												 parentContainerSelector={props.parentId && '#modal-addon-' + props.parentId}
								>
									<div className={'touch-area'}>
										<Icon name={'circle-info'} size={ICON_SIZE.S}/>
									</div>
								</Tooltip>
							</span>
					}
					</span>
			</div>
		)
	}

	const items = props.tab.content.map((addOn, index) => {
		return renderAddonDetailItem(addOn, index);
	});
	return (
		<Fragment key={1}>
			{items}
			<div className={'item-details-addon__summary'}>
				<span className={'ts-bold'}>{props.tab.summary}</span>
			</div>
		</Fragment>
	)
}

AddonTab.defaultProps = {};
AddonTab.propTypes = {
	/**
	 * selector of the wrapping container: passthrough the selector to the tooltip
	 * reason: when set tooltip position is fixed that it can be displayed inside of an overflow hidden container.
	 */
	parentId: PropTypes.string.isRequired,
	/**
	 * switch between tabs & modal
	 */
	isDesktop: PropTypes.bool.isRequired,
	/**
	 * content of ItemDetailTab.
	 * see **Item** for further information
	 */
	tab: PropTypes.shape().isRequired,
};

const mapStateToProps = (state, ownProps) => {
	return {
		isBookable: state.globalStateManagement.permissions?.bookable,
		bookableReason: state.globalStateManagement.permissions?.bookableReason,
		isSaving: state.checkoutManagement.isSaving,
	}
}

export default connect(mapStateToProps, {dispatchUpdateAddOn: dispatchUpdateAddOn})(AddonTab)
