import React from 'react';
import './PriceGuarantee.scss';
import {Icon} from "../icon/Icon";

function PriceGuarantee(props){

	return (
		<div className={'price-guarantee box'}>
			<div className={'price-guarantee__headline ts-handwriting h1'}>Garantiert unschlagbares Angebot</div>
			<div className={'price-guarantee__delimiter'}></div>

			<div className={'price-guarantee__card-wrapper'}>
				<div className={'price-guarantee__card'}>
					<div className={'price-guarantee__icon'}><Icon name={'check-match'}/></div>
					<div className={'price-guarantee__subheadline ts-copy-small ts-bold'}>Wir bieten Ihnen nur das
						Beste!
					</div>
					<div className={'price-guarantee__copy ts-copy-small'}>Mit dieser Garantie versichern wir Ihnen,
						jedes
						vergleichbare Angebot in Preis und Leistung zu schlagen. Wir garantieren Ihnen die günstigste
						Buchung.
					</div>
				</div>

				<div className={'price-guarantee__card'}>
					<div className={'price-guarantee__icon'}><Icon name={'check-match'}/></div>
					<div className={'price-guarantee__subheadline ts-copy-small ts-bold'}>Wie funktioniert diese
						Garantie?
					</div>
					<div className={'price-guarantee__copy ts-copy-small'}>Sie zeigen uns das vergleichbare Angebot. Wir
						prüfen Preis
						und Leistung und beweisen Ihnen, dass wir besser sind. Gültig für alle Angebote mit Flug und
						Landprogramm.
					</div>
				</div>

				<div className={'price-guarantee__card'}>
					<div className={'price-guarantee__icon'}><Icon name={'check-match'}/></div>
					<div className={'price-guarantee__subheadline ts-copy-small ts-bold'}>Fordern Sie uns heraus!</div>
					<div className={'price-guarantee__copy ts-copy-small'}>Sie meinen, es geht besser als von CANUSA
						TOURISTIK
						angeboten? Dann sagen Sie es uns jetzt – wir sind garantiert unschlagbar günstig!
					</div>
				</div>
			</div>
		</div>

	)
}

export {
	PriceGuarantee as default,
	PriceGuarantee
}

