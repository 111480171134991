import React, {Component, Fragment} from 'react';
import './Rating.scss'
import {Icon, ICON_SIZE} from "../icon/Icon";
import classNames from "classnames";
import PropTypes from "prop-types";

class Rating extends Component {

	getRatingIcons(value) {
		let iconElements = [];
		let maxStars = 5;

		// fallback for larger values
		if (value > maxStars) {
			maxStars = Math.round(value);
		}

		for (let i = 0; i < maxStars; i++) {
			const starValue = value - i;
			const iconName = ((starValue <= 0.5 && starValue > 0) ? 'star-full-half' : 'star-full');



			if (i >= value) {
				iconElements.push(
					<Fragment key={i}>
						{this.props.hasBackground &&
						<span className={"rating__star--background"}><Icon name={'star-full'} size={ICON_SIZE.S}/></span>
						}
					</Fragment>
				);
			} else {
				iconElements.push(
					<Fragment key={i}>
						<div className={'rating__star-wrapper'}>
						{this.props.hasBackground &&
						<span className={"rating__star--background"}><Icon name={'star-full'} size={ICON_SIZE.S}/></span>
						}
						<span className={"rating__star"}><Icon name={iconName}/></span>
						</div>
					</Fragment>
				);
			}
		}

		return iconElements;
	}

	render() {

		const classes = classNames(
			'rating',
			{'rating__star--background-filled': this.props.hasBackground},
			this.props.additionalClasses
		);

		return (
			<div className={classes}>
				{this.getRatingIcons(this.props.value, this.props.hasBackground)}
			</div>
		)
	}
}

Rating.defaultProps = {
	value: 0,
};


Rating.propTypes = {
	/**
	 * rating value. Expecting decimal numbers (floats) round accurate to 0.5
	 * @example:_ 2.5
	 */
	value: PropTypes.number,
	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	hasBackground: PropTypes.bool,
	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string,
};

export {
	Rating as default,
	Rating
}
