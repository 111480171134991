import React, { Component, Fragment } from "react";
import "./DALoginMask.scss";
import { Button, BUTTON_APPEARANCE } from "../button/Button";
import PropTypes from "prop-types";
import { StorageManager, storageManager } from "../../services/StorageManager";
import {
  dispatchIsAuthenticated,
} from "../../redux/actions/globalStateManagementAction";
import { connect } from "react-redux";
import Link from "../link/Link";
import PreloaderWrapper from "../preloader-wrapper/PreloaderWrapper";
import Icon, { ICON_SIZE } from '../icon/Icon';

class MagicLinkMask extends Component {

  constructor(props) {
    super(props);

    this.state = {
      isLoading: false,
      errorMessage: null,
    };

    this.doMagicLinkRequest = this.doMagicLinkRequest.bind(this);
    this.rerequestHandler = this.rerequestHandler.bind(this);
    this.dataProvider = this.props.dataProvider;

    this.interval = null;
  }

  doMagicLinkRequest() {

    this.setState({isLoading: true});
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
    this.dataProvider.getData().then((response) => {
      if (response.success) {
        this.interval = setInterval(() => {

          const newToken = StorageManager.getCookie("token");
          if (newToken) {
            clearInterval(this.interval);
            this.interval = null;
            storageManager.authToken = newToken;
            this.props.dispatchIsAuthenticated(true);
            this.setState({isLoading: false});
            if (this.props.onClose) {
              this.props.onClose(true); // logged in succesfully
            }
          }

        }, 500);
      }
    })
  }

  componentWillUnmount() {
    if (this.interval) {
      clearInterval(this.interval);
      this.interval = null;
    }
  }

  rerequestHandler() {
    this.doMagicLinkRequest();
  }

  render() {

    return (
      <>
        <div className={"da-login-wrapper"}>

          <h2 className={"da-login-wrapper__headline"}>Reisebüro-Login</h2>


          {!this.state.isLoading &&
            <>
              <p className={"da-login-wrapper__copy"}>Für den Login schicken
                wir
                Ihnen einen Link an Ihre Reisebüro-E-Mail-Adresse zu.
                Klicken Sie
                diesen einfach an, und Sie werden automatisch
                eingeloggt.</p>


              <Button onClick={this.rerequestHandler}
                      label={"Link anfordern"}
                      appearance={BUTTON_APPEARANCE.SECONDARY}/>
            </>
          }

          {this.state.isLoading &&
            <>
              <PreloaderWrapper>
                <div className={"da-login-wrapper__icon"}>
                  <Icon name={'paperplane'} size={ICON_SIZE.XL}/>
                </div>
                {/*<Preloader type={"logo"}/>*/}
              </PreloaderWrapper>
              <p className={"da-login-wrapper__copy"}>Wir haben Ihnen eine
                E-Mail an die mit diesem Angebot verknüpfte E-Mail-Adresse
                des Reisebüros geschickt!</p>
              <p className={"da-login-wrapper__copy"}>Sollten Sie keine Mail
                von
                uns erhalten haben, prüfen Sie bitte auch Ihren Spam-Ordner.
                Ansonsten können Sie hier einen neuen Link anfordern:</p>
              <Link withoutAnchor={true} onClick={this.rerequestHandler}
                    label={"Neu Anfordern"}/>
            </>
          }
        </div>
      </>
    );
  }
}

MagicLinkMask.propTypes = {
  /**
   * instance of LoginService
   */
  dataProvider: PropTypes.object.isRequired,
  /**
   * redirection Url after login
   */
  redirectTo: PropTypes.oneOfType([
    PropTypes.shape({
      pathname: PropTypes.string,
    }),
    PropTypes.string,
  ]),
};


export default connect(null, {
  dispatchIsAuthenticated: dispatchIsAuthenticated,
})(MagicLinkMask);
