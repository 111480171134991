import React, {Component} from 'react';
import './Collapsible.scss'
import {Icon, ICON_SIZE} from "../icon/Icon";
import PropTypes from "prop-types";
import classNames from "classnames";

class Collapsible extends Component {

  renderToggleButton() {

    let button = <>
      <span className="arrow"><Icon name={'arrow-dots-down'} size={ICON_SIZE.S}/></span>

      <span className="toggle ts-bold" htmlFor={this.props.id}>
						<span className="open dotted">Mehr anzeigen +</span>
						<span className="close dotted">verbergen -</span>
					</span>
    </>;

    if (this.props.forceToggleArrow) {
      button = <span className="arrow">
				<Icon name={'arrow-dots-down'} additionalClasses={'open'} size={ICON_SIZE.S}/>
				<Icon name={'arrow-dots-up'} additionalClasses={'close'} size={ICON_SIZE.S}/>
			</span>
    }
    return button
  }

  render() {
    const isCollapsible = !!this.props.children;
    let classes = classNames(
      'collapsible',
      {
        'collapsible--shadow-on-hover': this.props.hasShadowOnHover,
				'no-content': !this.props.children,
        'force-toggle-arrow': this.props.forceToggleArrow
      },
      this.props.additionalClasses
    );

    return (
      <div className={classes}>
        <input type='checkbox' id={this.props.id}/>

        <label className='head' htmlFor={this.props.id}>
          {this.props.headContent}

          {isCollapsible && this.renderToggleButton()}
        </label>
        {isCollapsible &&
        <div className="content">
          <div className="inner-content">
            {this.props.children}
          </div>
        </div>
        }
      </div>
    )
  }
}

Collapsible.propTypes = {
  /**
   * content of collapsible JSX / HTML
   */
  content: PropTypes.node,
  /**
   * headContent of collapsible JSX / HTML
   */
  headContent: PropTypes.node.isRequired,
  /**
   * id of collapsible
   */
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  /**
   * OPTIONAL: add additional classes for this component here.
   * *Use this prop for special css-classes, which are not defined by default.*
   */
  additionalClasses: PropTypes.string,
  /**
   * OPTIONAL: add a box shadow
   */
  hasShadowOnHover: PropTypes.bool
};

export {
  Collapsible as default,
  Collapsible
}
