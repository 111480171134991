import "./ResetPasswordMask.scss";
import React, {Component} from "react";
import {Redirect} from "react-router-dom";
import {Input} from "../input/Input";
import {Button} from "../button/Button";
import {FormValidator} from "../form/FormValidator";
import PropTypes from "prop-types";
import {trackSubmitAttempt} from "../../../vendor/utils/TrackingManager";

class ResetPasswordMask extends Component {

	constructor(props) {
		super(props);

		this.state = {
			loggedIn: false,
			isLoading: false,
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.dataProvider = this.props.dataProvider;
		this.resetHeadline = "Passwort zurücksetzen";
	}

	/**
	 * submit handler of Form
	 * send Form and set state depending on response
	 * if form was send for the first time then errors will be shown. not before
	 * @param form
	 * @param isValid
	 */
	handleSubmit({form}) {
		this.setState({
			isLoading: true,
		});

		this.dataProvider.setData(form).then(response => {
			// Reset password always returns success to prevent the possibility to abuse brute forcing in order to get the right password
			trackSubmitAttempt("reset_password", true);
			this.setState({
				loggedIn: true,
				isLoading: false,
			});
		});
	}

	render() {

		if (this.state.loggedIn) return <Redirect to={this.props.redirectTo}/>;

		return (
				<>
					<div className={"login_wrapper__mask-content"}>
						<h2 className="login_wrapper__headline">{this.resetHeadline}</h2>

						<FormValidator additionalClasses="login_wrapper__form"
						               onSubmit={this.handleSubmit}
						               render={() => (
								               <>
									               <div className="login_wrapper__inputs">

										               <Input name="password_new"
										                      label="neues Passwort"
										                      type="password"
										                      required={true}
										                      fullWidth={true}
										                      tooltipDirection={this.props.isDesktop ?
												                      "left" :
												                      "top"}
										               />

										               <Input name="password_new2"
										                      label="neues Passwort wiederholen"
										                      type="password"
										                      required={true}
										                      fullWidth={true}
										                      tooltipDirection={this.props.isDesktop ?
												                      "left" :
												                      "top"}
										               />

										               <Input name="reset_id"
										                      type="hidden"
										                      label=""
										                      initialValue={this.props.resetId}
										               />
									               </div>

										               <Button type="submit"
										                       label="Passwort bestätigen"
										                       additionalClasses="important login_wrapper__submit"
										                       isLoading={this.state.isLoading}/>

								               </>
						               )}/>
					</div>
					<div className={"login_wrapper__divider dotted"}/>
					<div className="login_wrapper__text">
						<div
								className="h2 login_wrapper__headline login_wrapper__headline--visible-on-desktop">
							{this.resetHeadline}
						</div>
						Bitte wählen Sie ein sicheres Passwort, welches Sie sich gut merken
						können. Tragen Sie dieses zunächst in das Feld „neues Passwort“ ein.
						Zur Verifizierung des Passworts wiederholen Sie das neue Passwort
						bitte im unteren Feld.
						Nach dem erfolgreichen Ändern des Passworts erhalten Sie eine
						entsprechende Meldung.
						Sollten Sie diese Meldung nicht erhalten, versuchen Sie es bitte
						erneut. Vielleicht hat sich beim ersten Versuch ein Tippfehler
						eingeschlichen.
					</div>
				</>
		);
	}
}

ResetPasswordMask.propTypes = {
	/**
	 * id reset api needs
	 */
	resetId: PropTypes.number,
	/**
	 * instance of LoginService
	 */
	dataProvider: PropTypes.object,
	/**
	 * add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.
	 */
	additionalClasses: PropTypes.string,
};

export {
	ResetPasswordMask,
};
