import React, {Component} from 'react';
import './Stage.scss'
import {FullWidthImage} from "../full-width-image/FullWidthImage";
import {TravelTime} from "../traveltime/TravelTime";
import ContactBoxContainer from "../contactbox/ContactBoxContainer";
import {BREAKPOINTS} from "../../../vendor/utils/BreakpointUtils";
import {ImageVO} from "../image/Image";
import PropTypes from 'prop-types';
import {connect} from "react-redux";
import {DESKTOP_RATIO, MOBILE_RATIO} from "../../../vendor/utils/Utils";

export const PROPORTION_LIST = [
	{
		ratio: MOBILE_RATIO,
		media: `(max-width: ${BREAKPOINTS.tablet - 1}px)`
	},
	{
		ratio: DESKTOP_RATIO,
		media: `(min-width: ${BREAKPOINTS.tablet}px)`
	}
];

class Stage extends Component {

	/**
	 * Return image value object with values from PROPOTION_LIST
	 *
	 * @param image {ImageObject}
	 * @return {any[]}
	 */
	getImages(image) {
		return PROPORTION_LIST.map((proportion) => {
			let imageVO = Object.assign(new ImageVO(), image);
			imageVO.ratio = proportion.ratio;
			imageVO.media = proportion.media;
			imageVO.imageOptions = this.props.imageOptions;
			return imageVO;
		})
	}

	/**
	 * get padding-bottom valu as string
	 * to prevent flashing images on pageChange
	 * @returns {string}
	 */
	getAspectRatioPadding() {
		const ratioList = PROPORTION_LIST.map((proportion) => {
			return proportion.ratio.height / proportion.ratio.width * 100 + '%';
		});

		if (!this.props.isDesktop) {
			return ratioList[0];
		} else {
			return ratioList[1];
		}
	}

	render() {
		const componentClass = 'stage';
		if (this.props.isLoading) {
			return (
				<div className={`${componentClass}`}>
					<div className={'stage__img full-w-img'}>
						<div className={'img img--is-loading'}
								 style={{'paddingTop': this.getAspectRatioPadding()}}></div>
					</div>
				</div>
			)
		}

		return (
			<div className={`${componentClass}`}>
				<FullWidthImage images={this.getImages(this.props.image)} fullwidth={false}
												additionalClasses={`${componentClass}__img`}
												join={this.props.join}/>
				{(this.props.withContent && this.props.journeyStart && this.props.journeyEnd) &&
				<div className={`${componentClass}__content`}>
					<div className={`${componentClass}__travel-time`}>

						<TravelTime
							journeyStart={this.props.journeyStart}
							journeyEnd={this.props.journeyEnd}
						/>

					</div>
					<div className={`${componentClass}__contact-box`}>
						<ContactBoxContainer appearance={'horizontal'}/>
					</div>
				</div>
				}
			</div>
		)
	}
}

Stage.propTypes = {
	/**
	 * Let content below go into stage
	 *
	 * @default false
	 */
	join: PropTypes.bool,

	/**
	 * stage can be rendered with TravelTime and ContactBox or without
	 *
	 * @default true
	 */
	withContent: PropTypes.bool,

	/**
	 * needs to be set if withContent is set. Will be passe through to Contactbox to ensure reload if needed
	 */
	updateId: PropTypes.string,

	/**
	 * needs to be set if withContent is set
	 */

	journeyStart: PropTypes.string,
	journeyEnd: PropTypes.string,

	/**
	 * image to be shown on stage
	 */
	image: PropTypes.shape({
		/**
		 * url to image
		 * @examples
		 * 		'https://images.canusa.de/img/regionen/bahamas/nassau/allgemein/17katalog-bahamas-nassau-haengematte.jpg'
		 *
		 */
		url: PropTypes.string,

		/**
		 * alternative text show image could not load and it is also important for accessibility and seo
		 */
		alt: PropTypes.string,
	})
};

Stage.defaultProps = {
	withContent: true,
	join: false
};

const mapStateToProps = (state, ownProps) => {

	return {
		isDesktop: state.globalStateManagement.isDesktop,
		journeyStart: state.globalStateManagement.offer.journeyStart || state.globalStateManagement.journeyStart,
		journeyEnd: state.globalStateManagement.offer.journeyEnd || state.globalStateManagement.journeyEnd
	}
};

export default connect(mapStateToProps)(Stage)


