import React, { useEffect } from 'react';
import './Participants.scss'
import { Preloader } from "../preloader/Preloader";
import classNames from "classnames";
import PropTypes from "prop-types";
import { ErrorMessage } from "../error/ErrorMessage";
import { connect } from "react-redux";
import {
  dispatchFetchData,
  FETCH_TYPE_PARTICIPANTS
} from "../../redux/actions/fetchAction";
import { Link } from "../link/Link";
import { Modal } from "../modal/Modal";
import ParticipantsForm from "../participantsForm/ParticipantsForm";
import { Tooltip } from "../tooltip/Tooltip";
import AuthenticationCheck from "../authenticationCheck/AuthenticationCheck";
import { dispatchCloseModal, dispatchOpenModal } from "../../redux/actions/globalStateManagementAction";
import { createTravellerItemsList } from "../../../vendor/utils/ParticipantsUtils";

function Participants({dispatchFetchData, ...props}) {

  useEffect(() => {
    dispatchFetchData(props.isDigitalOfferLegacy);
  }, [dispatchFetchData, props.isMainClient, props.isDigitalOfferLegacy]);

  /**
   * create modal with participantsForm
   * @param list
   * @returns {null|*}
   */
  function createEditModal(list) {

    const modalId = 'edit-participants-modal';

    if (props.modalOpenId === modalId && props.isMainClient) {
      return <Modal id={modalId} onClose={closeEditModal}
                    additionalClasses={'participant__edit-modal'}>
        <ParticipantsForm participantsList={list} parentId={modalId} onClose={closeEditModal}/>
      </Modal>
    } else {
      return null;
    }
  }

  /**
   * create pen-link (open modal)
   * @returns {*}
   */
  function createEditLink() {
    if (!props.hasEditIcon) {
      return null;
    }
    const link = <AuthenticationCheck onCallback={openEditModal} render={({clickHandler}) => (
      <Link withoutAnchor={true}
            onClick={clickHandler}
            icon={'pen'}
            size={'small'}
            disabled={!props.bookable}
      />
    )}/>

    if (!props.bookable) {
      return <div className={'participants__edit'}>
        <Tooltip content={props.bookableReason}
                 showDefault={false}
                 inactive={props.bookable}
                 direction={props.isDesktop ? 'left' : 'top-left'}
        >
          {link}
        </Tooltip>
      </div>
    }

    return <div className={'participants__edit'}>
      {link}
    </div>
  }

  /**
   * open modal
   */
  function openEditModal() {
    props.dispatchOpenModal();
  }

  /**
   * close modal
   */
  function closeEditModal() {
    props.dispatchCloseModal();
  }

  const classes = classNames(
    'participants box',
    props.additionalClasses,
    {'participants--loaded': props.list},
  );

  if (props.hasLoadingError) {
    return (
      <ErrorMessage type={'loadingError'}/>
    )
  } else if (props.isLoading) {
    return (
      <div className={classes}>
        <Preloader type={'text'}/>
      </div>
    )
  }
  return (
    <div className={classes}>
      {createEditLink()}

      {(props.list && props.bookable) && createEditModal(props.list)}

      <div className="participants__head">
        <div className="participant__name h4 tt-none">Reiseteilnehmer</div>
        <div className="participant__birthdate h4 tt-none">Geburtsdatum</div>
      </div>
      <div className='participants__list'>
        {props.list && createTravellerItemsList(props.list)}
      </div>
      <div className="participants__disclaimer ts-bold">
        Bitte überprüfen Sie die oben angegebenen Namen aller Reiseteilnehmer auf Richtigkeit und
        Vollständigkeit. Es müssen alle Namen laut Reisepass sowie das Geburtsdatum angegeben werden.
      </div>
    </div>
  )
}

Participants.propTypes = {

  /**
   * indicates if content is still loading if its used with DataProvider
   */
  isLoading: PropTypes.bool,

  loadingComplete: PropTypes.bool,

  isReadOnly: PropTypes.bool,
  /**
   * JSON-formatted array
   */
  list: PropTypes.arrayOf(
    PropTypes.shape({

      /**
       * Date format "YYYY-MM-DD"
       * @example: "1970-05-01"
       */
      birthdate: PropTypes.string,
      /**
       * firstname as string
       * @default null
       */
      firstname: PropTypes.string,
      /**
       * id as int or string
       */
      id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      /**
       * lastname as string
       * @default null
       */
      lastname: PropTypes.string,
      /**
       * fimiddlenamesrstname as string
       * @default null
       */
      middlenames: PropTypes.string,
      /**
       * Salutation
       * @default null
       */
      salutation: PropTypes.oneOf(['Herr', 'Frau']),

      /**
       * Salutation
       * @default null
       */
      gender: PropTypes.oneOf(['m', 'f', '', 'none'])
    })
  )
};

Participants.defaultProps = {
  hasEditIcon: false

}

const mapStateToProps = (state, ownProps) => {
  return {
    isMainClient: state.globalStateManagement.isMainClient,
    isDesktop: state.globalStateManagement.isDesktop,
    isDigitalOfferLegacy: state.globalStateManagement.environment === 'digitalOffer',
    bookable: state.globalStateManagement.permissions && state.globalStateManagement.permissions.bookable,
    bookableReason: state.globalStateManagement.permissions && state.globalStateManagement.permissions.bookableReason,
    modalOpenId: state.globalStateManagement.modalId,
    ...state.participantManagement
  }
};


const ParticipantsMapped = connect(mapStateToProps, {
  dispatchFetchData: (isLegacy) => dispatchFetchData(FETCH_TYPE_PARTICIPANTS, false, {}, isLegacy),
  dispatchOpenModal: () => dispatchOpenModal('edit-participants-modal'),
  dispatchCloseModal: dispatchCloseModal,
})(Participants);
export {
  ParticipantsMapped as default
}
