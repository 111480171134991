import React, {Component} from 'react';
import './LoginPage.scss';
import Footer from "../../components/footer/Footer";
import Stage from "../../components/stage/Stage";
import {AUTH_TYPES, LoginWrapper} from "../../components/login/LoginWrapper";
import withDataProvider from "../../hoc/WithDataprovider";
import {Header} from "../../components/header/Header";
import classNames from "classnames";
import Consent from "../../components/consent/Consent";
import {connect} from "react-redux";
import {dispatchConsent} from "../../redux/actions/globalStateManagementAction";
import {StorageManager} from "../../services/StorageManager";
import DebugBar, {DEBUG} from "../../debug/DebugBar";
import {isDevEnvironment} from "../../../vendor/utils/Utils";

const StageWithDataProvider = withDataProvider(Stage, {
	useMock: false,
	endpoint: 'portal/stage',
	value: {topic: 'login'}
});

class LoginPage extends Component {

	constructor(props) {
		super(props);

		this.widthChange = this.widthChange.bind(this);
		this.mediaQuery = window.matchMedia("(min-width: 1024px)");
		this.mediaQuery.addListener(this.widthChange);
		const consentCookie = StorageManager.getCookie('acceptedCookies');
		if(consentCookie) {
			props.dispatchConsent(JSON.parse(decodeURIComponent(consentCookie)));
		}


		this.state = {
			isDesktop: this.mediaQuery.matches
		}
	}

	widthChange() {

		this.setState({
			isDesktop: this.mediaQuery.matches
		});
	}

	componentWillUnmount() {
		if (this.mediaQuery) {
			this.mediaQuery.removeListener(this.widthChange);
		}
	}

	render() {
		let type = this.props.type || (this.props.match && this.props.match.params.variant) || "login";

		const classes = classNames(
			'login_page',
			'page-full-height'
		);

		const showDebugBar = isDevEnvironment() && process.env.REACT_APP_GLOBAL_DEBUG === "true";

		return (
			<div className={classes}>
				{showDebugBar && <DebugBar show={[DEBUG.TRACKING]}/>}
				<Header logoVariant={'portal'}/>
				<StageWithDataProvider withContent={false} join={true}/>
				<div className='centered-content'>
					<LoginWrapper type={type} location={this.props.location} isDesktop={this.state.isDesktop}/>
				</div>
				{!this.props.consent &&
				<Consent brandName={'CANUSA'}
								 brandUrl={'https://www.canusa.de'}
				/>
				}
				<Footer/>
			</div>
		)
	}
}

const mapStateToProps = (state, ownProps) => {
	return {
		consent: state.globalStateManagement.consent,
	}
};

const ConnectedLoginPage =  connect(mapStateToProps, {dispatchConsent,})(LoginPage)

export function LoginScreen(props){
	return <ConnectedLoginPage type={AUTH_TYPES.LOGIN} {...props}/>;
}

export function LogoutScreen(props){
	return <ConnectedLoginPage type={AUTH_TYPES.LOGOUT} {...props}/>;
}

export function ResetScreen(props){
	return <ConnectedLoginPage type={AUTH_TYPES.RESET} {...props}/>;
}

export function ForgotScreen(props){
	return <ConnectedLoginPage type={AUTH_TYPES.FORGOT} {...props}/>;
}

export function NewPasswordScreen(props){
	return <ConnectedLoginPage type={AUTH_TYPES.NEW_PASSWORD} {...props}/>;
}

export function VerificationScreen(props){
	return <ConnectedLoginPage type={AUTH_TYPES.VERIFICATION} {...props}/>;
}
