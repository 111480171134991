import {STORE_CLEAR} from "../actions/globalStateManagementAction";
import {FAQ_DATA_UPDATE} from "../actions/faqManagementAction";

export const faqManagementReducer = (state = {}, action) => {
	switch (action.type) {
		case FAQ_DATA_UPDATE:
			return {
				...state,
				...action.data,
			};

		case STORE_CLEAR:
			return {};

		default:
			return state;
	}
};
