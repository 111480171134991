import React from 'react';
import './LoginModal.scss'
import { Modal } from "../modal/Modal";
import classNames from "classnames";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import DALoginMask from "../login/DALoginMask";
import { LoginService } from "../../services/LoginService";
import MagicLinkMask from "../login/MagicLinkMask";
import DataProviderService from "../../services/DataProviderService";
import { extractOfferIdAndRevisionFromOfferNumber } from '../../../vendor/utils/Utils';


function LoginModal({dispatchFetchData, ...props}) {
  
  const classes = classNames(
    'user-profile',
    props.additionalClasses,
  );
  
  let from = window.location.pathname || {from: {pathname: "/"}};
  
  const extractedOfferNumber = extractOfferIdAndRevisionFromOfferNumber(props.offerNumber)
  // const endpoint = '/json/magiclinkresponse.json'
  const endpoint = `one-time-password?offerId=${extractedOfferNumber.offerId}&revision=${extractedOfferNumber.revisionNumber}`
  
  return (
    <Modal size='small' onClose={props.onClose}>
      <div className={classes}>
        {!props.isAgencyOffer
          ? <DALoginMask dataProvider={new LoginService({endpoint: 'login', useLegacyAPI: true})}
                         redirectTo={from}
                         isDektop={props.isDesktop}
                         onClose={props.onClose}
          />
          : <MagicLinkMask
            dataProvider={new DataProviderService({endpoint: endpoint, useDaRestAPI: true})}
            onClose={props.onClose}
          />}
      </div>
    </Modal>
  )
}

LoginModal.propTypes = {
  
  /**
   * additional css class to alter view if needed
   */
  additionalClasses: PropTypes.string,
  /**
   * title of person. e.g. Dr
   */
  title: PropTypes.string,
  /**
   * first name of a person
   */
  firstname: PropTypes.string,
  
  /**
   * persons middle names
   */
  middlenames: PropTypes.string,
  
  /**
   * persons last name
   */
  lastname: PropTypes.string,
  
  /**
   * offernumber e.g. HAM-a1b2c3d4-ABC
   */
  offerNumber: PropTypes.string,
  
  /**
   * indicates if content is still loading if its used with DataProvider
   */
  isLoading: PropTypes.bool,
  
  /**
   * closeHandler which is passed through to modal
   */
  onClose: PropTypes.func
};

const mapStateToProps = (state, ownProps) => {
  return {
    offerNumber: state.globalStateManagement.offer.offerNumber,
    isDesktop: state.globalStateManagement.isDesktop,
    isAuthenticated: state.globalStateManagement.isAuthenticated,
    isAgencyOffer: state.globalStateManagement.isAgencyOffer,
    ...state.userProfileManagement
  }
};

export default connect(mapStateToProps, {})(LoginModal);

