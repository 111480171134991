import {Route, Switch} from "react-router-dom";
import React from "react";
import {AUTH_TYPES} from "./portal/components/login/LoginWrapper";
import {ErrorPage} from "./portal/pages/error/ErrorPage";
import FAQPage from "./portal/pages/faq/FAQPage";
import MainPagePortal from "./portal/pages/MainPagePortal";
import MainPageDigitalOffer from "./portal/pages/MainPageDigitalOffer";
import {LegacyRouteTabRedirect} from "./portal/components/redirects/LegacyRouteTabRedirect";
import {
    ForgotScreen,
    LoginScreen,
    LogoutScreen,
    NewPasswordScreen,
    ResetScreen,
    VerificationScreen
} from "./portal/pages/login/LoginPage";
import withTitleChange from "./portal/hoc/WithTitleChange";


const mainPagePortal = withTitleChange(MainPagePortal);
const mainPageDigitalOffer = withTitleChange(MainPageDigitalOffer);
const faqPage = withTitleChange(FAQPage);
const loginPage = withTitleChange(LoginScreen);
const logoutPage = withTitleChange(LogoutScreen);
const resetPage = withTitleChange(ResetScreen);
const newPasswordPage = withTitleChange(NewPasswordScreen);
const verificationPage = withTitleChange(VerificationScreen);
const forgotPage = withTitleChange(ForgotScreen);
function InnerRouter() {
    return (
        <Switch>
            <Route path="/faq/" component={faqPage}/>
            <Route path={`/${AUTH_TYPES.LOGIN}`} component={loginPage}/>
            <Route path={`/${AUTH_TYPES.LOGOUT}`} component={logoutPage}/>
            <Route path={`/${AUTH_TYPES.RESET}`} component={resetPage}/>
            <Route path={`/${AUTH_TYPES.NEW_PASSWORD}`} component={newPasswordPage}/>
            <Route path={`/${AUTH_TYPES.FORGOT}`} component={forgotPage}/>
            <Route path={`/${AUTH_TYPES.VERIFICATION}`} component={verificationPage}/>
            <Route exact path={["/digitaloffer/routes/:offertoken/:routeId"]} component={LegacyRouteTabRedirect}/>
            <Route path={["/digitaloffer/:offertoken/:tab?/:hintId?"]} component={mainPageDigitalOffer}/>
            <Route exact path={["/", "/:offer/:tab/:hintId?"]} component={mainPagePortal}/>
            <Route component={ErrorPage}/>
        </Switch>
    );
}

export default InnerRouter;
