import { DataProviderService } from "./DataProviderService";

class CountryListService extends DataProviderService {
  constructor() {
    super({
      endpoint: '/digitaloffer/restapi/country/list',
      useCache: false,
      useDaRestAPI: false,
    });
    this.baseUrl = '';
    
  }
  
  getData(value = null) {
    return super.getData().then(response => {
      if (Array.isArray(response)) {
        return [].concat({value: '', label: 'Bitte auswählen'}, response.map(country => {
          return {value: country, label: country}
        }));
      } else {
        throw new Error('something went wrong with countrylist');
      }
      
    })
  }
}

export {
  CountryListService
}
