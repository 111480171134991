import React from 'react';
import './Checkbox.scss'
import PropTypes from "prop-types";
import classNames from "classnames";
import {Icon} from "../icon/Icon";

function ControlledCheckbox(props) {


	const classes = classNames(
		'checkbox',
		props.additionalClasses
	);

	return (
		<div className={classes}>
			<input type="checkbox"
					 id={props.uid}
					 name={props.name}
					 checked={props.checked}
					 required={props.required}
					 disabled={props.disabled}
					 onChange={e => props.onChange(e.nativeEvent.target.checked)}
			/>
			<label htmlFor={props.uid}>
				<Icon name={'box-check'}/>
				<Icon name={'box'}/>
			</label>
		</div>
	)
}

ControlledCheckbox.propTypes = {

	/**
	 * **REQUIRED**: set name of the component
	 */
	name: PropTypes.string.isRequired,
	/**
	 * OPTIONAL:  set checked state by default
	 */
	checked: PropTypes.bool,
	/**
	 * OPTIONAL: set required
	 */
	required: PropTypes.bool,
	/**
	 * OPTIONAL: set disabled by default
	 */
	disabled: PropTypes.bool,
	/**
	 * changeHandler returns checkbox-state
	 */
	onChange: PropTypes.func.isRequired,
	/**
	 * OPTIONAL: add additional classes for this component here.
	 * *Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string
};

export {
	ControlledCheckbox as default,
	ControlledCheckbox
}
