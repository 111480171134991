import React, {useEffect, useState, useRef} from 'react';
import './NotificationMessage.scss';
import {connect} from "react-redux";
import classNames from "classnames";
import {Icon} from "../icon/Icon";
import {getAllActiveItem} from "../../redux/selectors";
import {
  dispatchHiddenNotificationMessage
} from "../../redux/actions/globalStateManagementAction";

function NotificationMessage(props) {
  const [hideOnStart, setHideOnStart] = useState(true);
  const [showMessage, setShowMessage] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    if (props.notificationMessage.visible) {
      if (props.isMainClient && !hideOnStart) {
        clearTimeout(timerRef.current);
        setShowMessage(true);

        timerRef.current = setTimeout(() => {
          setShowMessage(false);

          props.dispatchHiddenNotificationMessage();

        }, 5000);
      }
      setHideOnStart(false)
    }
    return () => {
      clearTimeout(timerRef.current);
    };
  }, [props.notificationMessage, hideOnStart, props]);

  const classes = classNames(
    'notification-message',
    props.additionalClasses
  );

  const contentClasses = classNames(
    'notification-message__content',
    {
      [`notification-message__content--${props.notificationMessage && props.notificationMessage.type}`]: (props.notificationMessage && props.notificationMessage.type)
    },
    {'notification-message__content--active': showMessage}
  );

  return (
    <div className={classes}>
      <div className={contentClasses}>
        {props.notificationMessage.type === 'info' ?
          <Icon name={'selected'} additionalClasses={'notification-message__icon'}/>
          :
          <Icon name={'circle-info'} additionalClasses={'notification-message__icon'}/>
        }
        <span className='notification-message__message'>
					{props.notificationMessage && props.notificationMessage.message}
				</span>
      </div>
    </div>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    isDesktop: state.globalStateManagement.isDesktop,
    allItems: state.itemManagement.itemMap,
    activeItems: getAllActiveItem(state.itemManagement.itemMap),
    isMainClient: state.globalStateManagement.isMainClient,
    notificationMessage: state.globalStateManagement.notificationMessage,
  }
};

export default connect(mapStateToProps, {
  dispatchHiddenNotificationMessage: () => dispatchHiddenNotificationMessage()
})(NotificationMessage)

