import React, {Component} from 'react';
import './Navigation.scss'
import {Slider} from "../slider/Slider";

class Navigation extends Component{
	constructor(props){
		super(props);
		this.state = {
			selectedId: props.selectedId
		}
	}

	render(){
		return (
			<Slider additionalClasses={'main-navigation'}
					selectedId={this.state.selectedId}
					groupuid='digital-offer'
					classes={{
						slide: 'main-navigation__slide',
						navBtn: 'main-navigation__nav-button'
					}}
					// indentButtons={true}
					snapSlides={false}
					centerMode={true}
				>
				{this.props.children}
			</Slider>
		)
	}
}

export {
	Navigation as default,
	Navigation
}
