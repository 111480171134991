import React, {Component, lazy, Suspense} from 'react';
import './ItemGroup.scss'
import {RouteDescription} from "../routeDescription/RouteDescription";
import Item from "../item/Item";
import {Preloader} from "../preloader/Preloader";
import classNames from 'classnames';
import PropTypes from "prop-types";
import {getPriceOfActiveAlternatives} from "../../redux/selectors";
import {getConvertedItem} from "../../../vendor/utils/DigitalOfferContentUtils";
import Link from "../link/Link";

const ItemGroupHeader = lazy(() => import('../../components/itemGroup/itemGroupHeader/ItemGroupHeader'));
const ItemGroupExtrasHeader = lazy(() => import('../../components/itemGroup/itemGroupExtrasHeader/ItemGroupExtrasHeader'));

const SORTING_MAP = [
	{type: 'valuecard', label: 'Unsere Specials für Sie'},
	{type: 'info-package', label: 'Reisepaket'},
	{
		type: 'insurance',
		label: 'Versicherung',
		info: 'Die ausführlichen Informationen zu unseren Versicherungen und deren Leistungen finden Sie unter ##INFO-LINK##.',
		infoLink: 'https://www.canusa.de/service/reiseversicherung'
	}
]

class ItemGroup extends Component {

	constructor(props) {
		super(props);
		this.itemChangeHandler = this.itemChangeHandler.bind(this);
	}

	/**
	 * Checks if item has a dependency to another item and if it's active
	 *
	 * @param item
	 * @param itemMap
	 * @return {boolean}
	 */
	hasActiveDependency(item, itemMap) {

		if (item.parent_id) {
			return itemMap[item.parent_id].active
		}
		return true;
	}

	getAdditionalTextWithLink(intoText, infoLink) {
		if ((intoText && infoLink) && intoText.indexOf("##INFO-LINK##") !== -1) {
			const internalLink = <Link linkPath={infoLink} label={'www.canusa.de/service/reiseversicherung'} target={'_blank'}/>;
			const introList = intoText.split('##INFO-LINK##');
			return (
				<small>
					<span dangerouslySetInnerHTML={{__html: introList[0]}}/>
					{internalLink}
					<span dangerouslySetInnerHTML={{__html: introList[1]}}/>
				</small>
			)
		}
	}

	sortExtraItems(unsortedItems) {
		const itemList = SORTING_MAP.map((sortType, index) => {
			// sort items
			const subItemList = unsortedItems.filter((item) => {
				return (item?.props.sortType === sortType.type) && item;
			})
			//  return label & sorted items
			return subItemList.length > 0 &&
				[<div className={'item-divider'} key={index}>
					{SORTING_MAP[index].label}
					{this.getAdditionalTextWithLink(SORTING_MAP[index]?.info, SORTING_MAP[index]?.infoLink)}
				</div>
					, ...subItemList];
		});

		return [].concat.apply([], itemList);
	}

	getItems(items, context, callbackHandler) {
		const unsortedItems = items.map((itemObject, index) => {
			let {item, itemId} = getConvertedItem(this.props.itemMap, itemObject);

			if (!itemId) {
				itemId = 'item-' + index;
			}
			// its possible that id isn't in the itemmap because of racecondition and unpredictable change of optional ids
			if (!item || !this.hasActiveDependency(item, this.props.itemMap)) {
				return null;
			}

			if (item.contentType === 'route-description') {
				return (<RouteDescription item={item}
																	key={'route-description-' + itemId}
																	id={itemId}
				/>);
			} else {
				return (
					<Item item={item}
								groupIndex={this.props.groupIndex}
								itemMap={this.props.itemMap}
								sortType={item.type}
								key={itemId}
								context={context}
								callbackHandler={callbackHandler}
								selectedItemId={this.props.selectedItemId}
								alternatives={(item.alternatives && item.alternatives.length > 0) && item.alternatives}
								readOnly={this.props.readOnly}
					/>
				)
			}
		});

		return this.props.group.type === 'extras' ? this.sortExtraItems(unsortedItems) : unsortedItems;
	}

	itemChangeHandler(itemId) {
		if (this.props.callbackHandler) {
			this.props.callbackHandler(itemId);
		}
	}

	getPrice(itemMap) {
		let price = 0;
		price += getPriceOfActiveAlternatives(itemMap, this.props.group.items);
		price += this.props.group.price;

		return price;
	}

	render() {
		const classes = classNames(
			'item-group',
			`item-group--${this.props.context}`,
			this.props.additionalClasses)
		return (
			<div className={classes}>
				<Suspense fallback={<Preloader/>}>
					{this.props.group.type === 'extras' ?
						<ItemGroupExtrasHeader
							label={this.props.group.label}
							context={this.props.context}
						/>
						:
						<ItemGroupHeader
							label={this.props.group.label}
							price={this.getPrice(this.props.itemMap)}
							contentType={this.props.group.contentType}
							dayStart={this.props.group.dayStart}
							dayEnd={this.props.group.dayEnd}
							state={this.props.group.state}
							headerImage={this.props.group.headerImage}
							link={this.props.group.link}
							context={this.props.context}
							withBackground={this.props.withBackground}
							additionalClasses={'h3'}
						/>
					}
				</Suspense>
				{this.props.group.items && this.getItems(this.props.group.items, this.props.context, this.itemChangeHandler)}
			</div>
		)
	}
}

ItemGroup.defaultProps = {
	context: 'offer',
	isHidden: false,
	isOpen: false,
	readOnly: false
};

ItemGroup.propTypes = {
	/**
	 * context of Item
	 *
	 * @default: offer
	 */
	context: PropTypes.oneOf(['offer', 'optionals', 'documents', 'invoice']),

	/**
	 * Id of the selected Item
	 */
	selectedItemId: PropTypes.string,

	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string,

	/**
	 * prevents rendering of the Item
	 *
	 * @default: false
	 */
	isHidden: PropTypes.bool,

	/**
	 * default state of Item (collapsible)
	 *
	 * @default: false
	 */
	isOpen: PropTypes.bool,

	/**
	 * disables userInteraction in ItemHeader
	 *
	 * @default: true
	 */
	readOnly: PropTypes.bool,

	/**
	 * Group
	 */
	group: PropTypes.shape({
		/**
		 * template of ItemGroup
		 * @example: 'main'
		 */
		contentType: PropTypes.oneOf(['main', 'extra', 'document', 'optional']),
		/**
		 * Array of Items.
		 *@see Item for details
		 */
		items: PropTypes.array,
		/**
		 * Label for ItemGroupHeader
		 * @example: Autorundreise ab/bis Halifax
		 */
		label: PropTypes.string,
		/**
		 * Price for ItemGroupHeader
		 * @example: 6124
		 */
		price: PropTypes.number,

		/**
		 * **TODO: find all TYPE values**
		 * type of the Item has influence on the icon in ItemHeader
		 */
		type: PropTypes.string, // .oneOf(['car', '...'])
	})
};

export {
	ItemGroup as default,
	ItemGroup
}
