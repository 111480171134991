import React, {Component} from 'react';
import './RouteDescription.scss';
import PropTypes from "prop-types";
import {decodeHTMLEntities} from "../../../vendor/utils/HTMLUtils";

class RouteDescription extends Component {

	render() {

		const collapsibleId = this.props.id;

		const content = this.props.item.content;

		

		return (
			<div className="route-description">
				<input type="checkbox" id={collapsibleId}/>
				<label className="route-description__header" htmlFor={collapsibleId}>
					<div className="route-description__duration h4">
						Tag {this.props.item.dayStart}{this.props.item.dayEnd && this.props.item.dayEnd > this.props.item.dayStart && `–${this.props.item.dayEnd}`}
					</div>
					<div className="route-description__collapsible ts-bold dotted">
						<span>Routeninformation </span>
						<span className="route-description__collapsible--open">+</span>
						<span className="route-description__collapsible--close">-</span>
					</div>
					<div className="route-description__label h4 tt-none">{this.props.item.label}</div>
				</label>
				{content &&
				<div className="route-description__content">
					{content.map(function (section, index) {
						return (
							<div className={"route-description__section"}
								 key={`route-description-section-${index}`}>
								<div className={"route-description__headline ts-bold"}>
									{`${section.label}`}
									{(section.distance && section.unit) && ` (${section.distance}${section.unit})`}
								</div>
								<div>{decodeHTMLEntities(section.text)}</div>
							</div>
						)
					})}
				</div>}
			</div>
		)
	}
}


RouteDescription.propTypes = {
	/**
	 * id of the component
	 */
	id:PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
	/**
	 * content of the component
	 */
	item:PropTypes.shape({
		/**
		 * startDate
		 * @example: '1'
		 */
		dayStart: PropTypes.number.isRequired,
		/**
		 * endDate
		 * @example: '3'
		 */
		dayEnd: PropTypes.number.isRequired,
		/**
		 * label of header
		 * @example: HALIFAX, NS'
		 */
		label: PropTypes.string.isRequired,
		/**
		 * content / copy text
		 */
		content: PropTypes.arrayOf(PropTypes.shape({

			/**
			 * distance in KM
			 *
			 *@example:  100
			 */
			distance:PropTypes.number,
			/**
			 * label
			 *@example: "Halifax, NS - Lighthouse Trail, NS"
			 */
			label:PropTypes.string,

			/**
			 * text
			 * @example:  "Ganz in der Nähe von Peggy's Cove finden Sie fantastische Möglichkeiten zum Sea-Kayaking (z. B. in East Dover, nur vor Ort buchbar). Über Chester fahren Sie im Anschluss nach Mahone Bay. Sie kommen durch viele kleine Fischerorte. Über 300 Jahre lang war dieser Küstenabschnitt ein Versteck für Piraten und Schmuggler. In Lunenburg sollten Sie nicht versäumen, das hervorragende Fisheries Museum of the Atlantic zu besuchen. Ihre heutige Fahrstrecke beträgt 100 km (63 mls)."
			 */
			text: PropTypes.string,
			/**
			 * unit
			 *
			 * @example: "km"
			 */
			unit: PropTypes.oneOf(['km', 'mi', 'miles', 'm',])


		}))
	})
};

export {
	RouteDescription as default,
	RouteDescription
}

