import React, {Component} from 'react';
import './TravelCountdown.scss'
import {diffDateDays} from '../../../vendor/utils/DateUtils.js'
import classNames from "classnames";
import PropTypes from 'prop-types';
import {getUtcSafeDate} from "../../../vendor/utils/DateUtils";


class TravelCountdown extends Component {

	render() {

		if(!this.props.startDate) return null;
		const daysToStart = diffDateDays(getUtcSafeDate(new Date(this.props.startDate).getTime()), getUtcSafeDate(new Date()));

		// Do not show countdown if journey is over
		if( daysToStart < 0 ) return null;

		const classes = classNames(
			'travel-countdown',
			'h1 ts-handwriting ts-dynamic',
			this.props.additionalClasses,
		);

		return (
			<div className={classes}>Übrigens: nur noch {daysToStart} {daysToStart===1?'Tag':'Tage'} bis zu Ihrer Traumreise!</div>
		)
	}
}

TravelCountdown.propTypes = {
	/**
	 * REQUIRED: start date of the journey to calculate countdown from now. String needs to be in ISO-8601 standard
	 */
	startDate: PropTypes.string,

	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string
};


export default TravelCountdown;
