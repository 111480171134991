import React from 'react';
import './PortalConector.scss';
import classNames from "classnames";
import {BUTTON_WIDTH} from "../button/Button";
import ButtonAnchor from "../button/ButtonAnchor";
import {connect} from "react-redux";
import {getUrlByEnvironment} from "../../../vendor/utils/Utils";

function PortalConnector(props) {

  const classes = classNames(
    'portal-connector',
    props.additionalClasses
  );

  const createPortalUrl = (isAuthenticated, offerNumber) => {
    let url = `${getUrlByEnvironment().portalUrl}/login`;
    if (isAuthenticated) {
      url = `${getUrlByEnvironment().portalUrl}/${offerNumber}/reise`;
    }
    return url;
  }

  return (
    <>
      {(props.isCurrentVersion && props.isOfferAccepted && !props.isAgencyOffer) &&
      <div className={classes}>
        <div className={'portal-connector__columns'}>
          <div className={'portal-connector__hint-text'}>
            <p>Im CANUSA-Kundenportal finden Sie alles Wichtige zu Ihrer Reise an einem Ort: Ihre gebuchten Leistungen,
              Rechnung und Zahlungsmöglichkeiten, sowie Ihre Reiseunterlagen und Ausflugstipps.</p>

            {!props.isAuthenticated &&
            <p>Die Zugangsdaten sind die gleichen, die Sie für Ihr Angebot verwendet haben.</p>}
          </div>

          <ButtonAnchor href={createPortalUrl(props.isAuthenticated, props.offerNumber)}
                        target={'_blank'}
                        width={BUTTON_WIDTH.FIXED}
                        label={'Jetzt zum Portal wechseln'}/>
        </div>
      </div>
      }
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    isCurrentVersion: state.globalStateManagement.offer.currentVersion,
    isOfferAccepted: state.globalStateManagement.offer.status === 'angenommen',
    isAuthenticated: state.globalStateManagement.isAuthenticated,
    isAgencyOffer: state.globalStateManagement.isAgencyOffer,
    offerNumber: state.globalStateManagement.offer.offerNumber,
  }
};

export default connect(mapStateToProps)(PortalConnector)
