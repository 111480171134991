import React, {useState} from 'react';
import './ParticipantsForm.scss';
import classNames from "classnames";
import { Button } from "../button/Button";
import { FormValidator } from "../form/FormValidator";
import { Tooltip } from "../tooltip/Tooltip";
import { connect } from "react-redux";
import { dispatchUpdateParticipants } from "../../redux/actions/participantsAction";
import { createParticipants } from "../../../vendor/utils/ParticipantsUtils";

function ParticipantsForm(props) {

  const [firstTimeError, setFirstTimeError] = useState(false);
  const [showFormErrors, setShowFormErrors] = useState(false);

  const classes = classNames(
    'participants-form',
    props.additionalClasses
  );

  /**
   * Form submit handler
   *
   * @param form
   * @param isValid
   */
  function handleSubmit({form, isValid}) {

    if (isValid) {
      props.dispatchUpdateParticipants(form)
    } else {
      setShowFormErrors(true);
      setFirstTimeError(true);
    }
  }

  /**
   *  renders with submit button with tooltip depending on error state
   *
   * @param formIsValid
   * @return {*}
   */
  function renderSubmitButton(formIsValid) {
    const showSubmitError = firstTimeError && props.errorMessage && !formIsValid;

    const submitButton = <Button type="submit"
                                 label="Bestätigen"
                                 width={'fit'}
                                 additionalClasses="important"
                                 isLoading={props.isLoading}
                                 disabled={showSubmitError}/>;

    if (showSubmitError) {
      return <Tooltip content={props.errorMessage}
                      showDefault={!!props.errorMessage}
                      inactive={false}
                      direction={props.isDesktop ? 'left' : 'top-left'}
                      parentContainerSelector={'#' + props.parentId}
                      color={'important'}>
        {submitButton}
      </Tooltip>
    } else {
      return submitButton;
    }
  }

  const participantClass = classNames(
      'participants-form__content',
      {'participants-form--alone': props.participantsList.length === 1}
  )

  return (
    <>
      <div className={classes}>
        <div className={'h2'}>Reiseteil&shy;nehmerdaten</div>
        <div>Bitte vervollständigen Sie die Daten aller Reiseteilnehmer, um die Buchung durchführen zu
          können.
        </div>
        <FormValidator additionalClasses="login_wrapper__form"
                       onSubmit={handleSubmit}
                       render={({changeHandler, formIsValid}) => (
                         <>
                           <div className={participantClass}>
                             {props.participantsList &&
                             createParticipants(
                               props.participantsList,
                               changeHandler,
                               props.parentId,
                               showFormErrors
                             )
                             }
                           </div>
                           <>
                             <div className={'participants-form__mandatory-text ts-copy-small'}>*
                               Pflichtfelder
                             </div>
                             <div className={'participants-form__buttons'}>
                               {renderSubmitButton(formIsValid)}
                             </div>
                           </>
                         </>
                       )}
        />
      </div>
    </>
  )
}

const mapStateToProps = (state, ownProps) => {
  return {
    isDesktop: state.globalStateManagement.isDesktop,
    isLoading: state.participantManagement.isLoading,
    hasLoadingError: state.participantManagement.hasLoadingError,
    errorMessage: state.participantManagement.errorMessage,
  }
};

export default connect(mapStateToProps,
  {
    dispatchUpdateParticipants: (form) => dispatchUpdateParticipants(form, false)
  }
)(ParticipantsForm);
