class StorageManager {

	/**
	 * Local store available state
	 * @type {boolean}
	 */
	isLocalStorageAvailable = false;

	constructor() {
		// Evaluate if local storage is really available and can be changed
		if (window['localStorage']) {

			try {
				window.localStorage.setItem('testKey', '1');
				window.localStorage.removeItem('testKey');
				this.isLocalStorageAvailable = true;
			} catch (e) {
				// Safari throws an error in private mode, even if localStorage exists
				this.isLocalStorageAvailable = false;
			}
		}

		this._offerNumber = null;
		this._offerToken = null;
		this._authToken = StorageManager.getCookie('token');
		this._securityToken = '';
		this._cacheMap = {};
		this._disableNotifications = StorageManager.getCookie('disableNotificationReload');

	}

	/**
	 * Store Promise of api response
	 *
	 * @param key {string}
	 * @param value {Promise}
	 */
	setCacheValue(key, value) {
		this._cacheMap[key] = value;
	}

	/**
	 * get Promise of stored api response
	 *
	 * @param key {string}
	 * @return {Promise}
	 */
	getCacheValue(key) {
		return this._cacheMap[key];
	}

	/**
	 * Get securtityToken.
	 * @return securityToken
	 * @type string
	 */
	get securityToken() {
		return this._securityToken;
	}

	/**
	 * Set securtityToken.
	 * @param securtityToken securityToken
	 * @type string
	 */
	set securityToken(value) {
		this._securityToken = value;
	}

	/**
	 * Get offerNumber.
	 * @return offerNumber
	 * @type string
	 */
	get offerNumber() {
		return this._offerNumber;
	}

	/**
	 * Set offerNumber.
	 * @param offerNumber
	 * @type string
	 */
	set offerNumber(value) {

		if (value !== this._offerNumber) {
			this._cacheMap = {}; //clear serviceCacheMap
		}
		this._offerNumber = value;
	}

	/**
	 * Get hashedOfferNumbe.
	 * @return offerNumber
	 * @type string
	 */
	get offerToken() {
		return this._offerToken;
	}

	/**
	 * Set offerNumber.
	 * @param offerNumber
	 * @type string
	 */
	set offerToken(value) {

		if (value !== this._offerToken) {
			this._cacheMap = {}; //clear serviceCacheMap
		}
		this._offerToken = value;
	}

	/**
	 * Get authentification token.
	 * @return authToken
	 * @type string
	 */
	//TODO get auth token directly from cookie. Don't save it in app
	get authToken() {
		return this._authToken;
	}

	/**
	 * Set authentification token.
	 * @param authentification token authToken
	 * @type string
	 */
	set authToken(value) {
		this._authToken = value;
		if (value) {
			StorageManager.setCookie('token', value, 99)
		} else {
			StorageManager.setCookie('token', null, -1);
		}
	}

	/**
	 * Get offerNumber.
	 * @return offerNumber
	 * @type string
	 */
	get disableNotifications() {
		return this._disableNotifications;
	}

	/**
	 * Set offerNumber.
	 * @param offerNumber
	 * @type string
	 */
	set disableNotifications(value) {

		this._disableNotifications = value;
		if (value) {
			StorageManager.setCookie('disableNotificationReload', value, 99)
		} else {
			StorageManager.setCookie('disableNotificationReload', null, -1);
		}
	}

	/**
	 * Check if local storage is really available
	 *
	 * @returns {boolean}
	 */
	getLocalStorageAvailability() {
		return this.isLocalStorageAvailable;
	}

	/**
	 * Set already stringified data at local storage attribute
	 *
	 * @param attribute {string}
	 * @param data
	 */
	setItem(attribute, data) {
		if (this.isLocalStorageAvailable) {
			window.localStorage[attribute] = data;
		}
	}

	/**
	 * Get unparsed data directly from local storage
	 *
	 * @param attribute {string}
	 * @returns {string}
	 */
	getItem(attribute) {
		if (this.isLocalStorageAvailable && window.localStorage[attribute]) {
			return window.localStorage[attribute];
		}
		return null;
	}




	/**
	 * Stringifies the data object and sets it at local storage attribute
	 *
	 * @param attribute
	 * @param data
	 */
	setObject(attribute, data) {
		if (this.isLocalStorageAvailable) {
			this.setItem(attribute, JSON.stringify(data));
		}
	}

	/**
	 * Get already parsed data from storage
	 *
	 * @param attribute {string}
	 * @returns {*}
	 */
	getObject(attribute) {
		let data = this.getItem(attribute);
		if (data) {
			data = JSON.parse(data);
		}
		return data;
	}

	/**
	 * Get all objects with this prefix
	 *
	 * @param prefix {string}
	 * @returns {Array.<{key:string, data:{object}}>}
	 */
	getPrefixedObjects(prefix) {

		// Make sure prefix does not collide with longer prefix
		prefix += '_';

		// List of objects with matching prefix
		let objects = [];

		// Iterate over enumerable attributes if local storage is available
		if (this.isLocalStorageAvailable) {
			for (var i = 0; i < window.localStorage.length; i++) {

				// Store object if prefix is found
				let key = localStorage.key(i);
				if (key.indexOf(prefix) === 0) {
					objects.push({key, data: this.getObject(key)});
				}
			}
		}

		return objects;

	}

	/**
	 * Unset attribute on local storage
	 *
	 * @param attribute
	 */
	unset(attribute) {
		if (this.isLocalStorageAvailable) {
			delete window.localStorage[attribute];
		}
	}

	/**
	 * Set cookie
	 *
	 * @param name {string}
	 * @param value {string}
	 * @param expireDays {number}
	 * @param path {string}
	 */
	static setCookie(name, value, expireDays, path="/") {
		const domain = process.env.NODE_ENV === 'production' ? '.canusa.de' : 'localhost'
		// Create expiring data in cookie format
		let d = new Date();
		d.setTime(d.getTime() + (expireDays * 24 * 60 * 60 * 1000));
		// Apply cookie data
		document.cookie = `${name}=${value};path=${path};domain=${domain};expires=${d.toUTCString()}`;
	}

	/**
	 * Get cookie
	 *
	 * @param name
	 * @returns {string}
	 */
	static getCookie(name) {
		name += "=";
		let cookies = document.cookie.split(';');
		for (var i = 0; i < cookies.length; i++) {
			let cookie = cookies[i];
			while (cookie.charAt(0) === ' ') {
				cookie = cookie.substring(1);
			}
			if (cookie.indexOf(name) === 0) {
				return cookie.substring(name.length, cookie.length);
			}
		}
		return undefined;
	}
}

const storageManager = new StorageManager();

export {
	StorageManager, storageManager,
};
