import React, {Component} from 'react';
import {ButtonAnchor} from "../button/ButtonAnchor";

import './ErrorBoundary.scss'
import {BUTTON_APPEARANCE} from "../button/Button";

class ErrorBoundary extends Component {
	constructor(props) {
		super(props);
		this.state = {hasError: false};
	}

	componentDidCatch(error, errorInfo) {
		console.log("error, errorInfo", error, errorInfo);
	}

	render() {
		if (this.state.hasError) {
			return <div className={'error-boundary'}>
				<div className={'h1 error-boundary__headline'}>Hoopla!</div>
				<div className={'h4 error-boundary__copy'}>Da ist etwas schief gelaufen.</div>
				<div className={'tt-bold error-boundary__copy'}>Bitte laden Sie die Seite erneut, oder versuchen Sie es in ein paar Minuten noch einmal.</div>
				<ButtonAnchor additionalClasses={'error-boundary__button'}
							  href={window.location.href}
								appearance={BUTTON_APPEARANCE.IMPORTANT}
							  width={"fit"}
							  label={'Neu Laden'}/>
			</div>;
		}

		return this.props.children;
	}
}

export {
	ErrorBoundary as default,
	ErrorBoundary
}
