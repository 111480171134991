import React from 'react';
import './CurrentOffer.scss';
import classNames from "classnames";
import {Tooltip} from "../tooltip/Tooltip";
import {connect} from "react-redux";
import PropTypes from "prop-types";
import {
  dispatchCloseModal, dispatchOpenModal, dispatchRevisionChange
} from "../../redux/actions/globalStateManagementAction";
import {BUTTON_WIDTH, BUTTON_APPEARANCE, Button} from "../button/Button";
import AuthenticationCheck from "../authenticationCheck/AuthenticationCheck";
import {Modal} from "../modal/Modal";
import Checkout from "../checkout/Checkout";
import {dispatchCheckoutReset} from "../../redux/actions/checkoutManagementAction";
import {checkIfCurrentRevision} from "../../../vendor/utils/DigitalOfferContentUtils";

function CurrentOffer(props) {

  const isCurrentRevision = checkIfCurrentRevision(props.offerNumber, props.revisionList)

  const classes = classNames(
    'current-offer',
    props.additionalClasses
  );

  function openCheckoutModal() {
    props.dispatchOpenModal();
  }

  /**
   * rendering bookingButton
   * @returns {*}
   */
  function renderBookingButton() {

    const button = <AuthenticationCheck onCallback={openCheckoutModal} render={({clickHandler}) => (
      <Button label={'Jetzt Buchen'}
              appearance={BUTTON_APPEARANCE.IMPORTANT}
              width={BUTTON_WIDTH.FULL_WIDTH}
              disabled={!props.bookable}
              onClick={clickHandler}
      />
    )}/>

    if (props.bookable) {
      return button
    }

    return <Tooltip content={props.bookableReason} direction={'top'}>
      {button}
    </Tooltip>
  }

  /**
   * rendering currentRevisionButton
   * @returns {*}
   */
  function renderCurrentVersionButton(buttonWidth) {

    if (props.revisionList) {
      const currentOffer = props.revisionList.find((item) => {
        return item.currentVersion;
      })

      return <Button label={'Zur Aktuellen Version'}
                     appearance={BUTTON_APPEARANCE.IMPORTANT}
                     width={buttonWidth}
                     onClick={() => {
                       props.dispatchRevisionChange(currentOffer)
                     }
                     }/>
    }
  }

  /**
   * decides which buttons should be rendered
   * @returns {null|*}
   */
  function renderButtons(buttonWidth, isCurrentRevision) {

    if (!isCurrentRevision) {
      return renderCurrentVersionButton(buttonWidth);
    } else if (isCurrentRevision && props.hasCheckoutButton) {
      return renderBookingButton(buttonWidth);
    } else {
      return null;
    }
  }

  /**
   * create modal with checkout
   * @param hasCheckoutButton
   * @param isModalOpen
   * @returns {JSX.Element|null}
   */
  function createCheckoutModal(hasCheckoutButton, modalOpenId) {

    const modalId = 'checkout-modal';

    if (hasCheckoutButton && modalOpenId === modalId && props.isMainClient) {
      return <Modal
        additionalClasses={'modal--item__detailview'}
        id={modalId}
        uid={modalId}
        size={'fullscreen'}
        theme={'light'}
        onClose={() => {
          props.dispatchCloseModal();
          props.dispatchCheckoutReset();
        }}
        isOpen={true}
      >
        <Checkout parentId={modalId}/>
      </Modal>
    } else {
      return null;
    }
  }


  return (
    <div className={classes}>


      {(!isCurrentRevision && props.showRevisionHint) ?
        <div className={'current-offer__columns'}>
          <div className={'current-offer__hint-text'}>
            Bei diesem Angebot handelt es sich um eine frühere Version Ihrer persönlichen Traumreise. Sie können diese
            mit
            Ihrem aktuellen Angebot vergleichen. Bitte beachten Sie, dass Änderungen nur in der aktuellen Version
            vorgenommen werden können. Wenn Sie Leistungen aus dieser Version in Ihr aktuelles Angebot übernehmen
            möchten,
            kontaktieren Sie mich bitte telefonisch.
          </div>
          <div>
            {renderButtons(BUTTON_WIDTH.FIXED, isCurrentRevision)}
          </div>
        </div>
        :
        renderButtons(BUTTON_WIDTH.FULL_WIDTH, isCurrentRevision)
      }
      {createCheckoutModal(props.hasCheckoutButton, props.modalOpenId)}
    </div>
  )
}

CurrentOffer.propTypes = {
  /**
   * if true the checkoutButton appears
   */
  hasCheckoutButton: PropTypes.bool,


  /**
   * if true a revision hint appears
   */
  showRevisionHint: PropTypes.bool,


  /**
   * OPTIONAL: add additional classes for this component here.
   * Use this prop for special css-classes, which are not defined by default.*
   */
  additionalClasses: PropTypes.string
};

CurrentOffer.defaultProps = {
  hasCheckoutButton: false,
  showRevisionHint: false
};

const mapStateToProps = (state, ownProps) => {
  return {
    bookable: state.globalStateManagement.permissions && state.globalStateManagement.permissions.bookable,
    bookableReason: state.globalStateManagement.permissions && state.globalStateManagement.permissions.bookableReason,
    isMainClient: state.globalStateManagement.isMainClient,
    offerNumber: state.globalStateManagement.offer.offerNumber,
    revisionList: state.revisionListManagement.revisionList,
    modalOpenId: state.globalStateManagement.modalId,
  }
};

export default connect(mapStateToProps, {
  dispatchRevisionChange: dispatchRevisionChange,
  dispatchOpenModal: () => dispatchOpenModal('checkout-modal'),
  dispatchCloseModal: dispatchCloseModal,
  dispatchCheckoutReset:dispatchCheckoutReset,
})(CurrentOffer);
