import React, {Component} from 'react';
import './NavigationItem.scss'
import classNames from "classnames";
import {ButtonInternal} from "../button/ButtonInternal";
import {BUTTON_APPEARANCE} from "../button/Button";

class NavigationItem extends Component {
	constructor(props) {
		super(props);
		this.state = {
			selectedId: props.selectedId
		}
	}

	render() {
		const classes = classNames(
			'navigation-item',
			{
				'navigation-item--active': (this.props.selectedId === this.props.uid),
			}
		);

		return (
			<div className={classes}>
				<ButtonInternal uid={this.props.uid}
												linkPath={this.props.linkPath}
												label={this.props.label}
												appearance={BUTTON_APPEARANCE.MAIN_NAVIGATION}
												useRoundBorder={false}
												onClick={this.props.onClick}
												additionalClasses='ts-bold ts-copy-small navigation-item__navigation-button'/>
			</div>
		)
	}
}

export {
	NavigationItem as default,
	NavigationItem
}
