import React, {Suspense, useEffect, useState} from 'react';
import './RoutesContent.scss'
import ItemGroup from "../../components/itemGroup/ItemGroup";
import {Preloader} from "../../components/preloader/Preloader";
import PropTypes from 'prop-types';
import {ErrorMessage} from "../../components/error/ErrorMessage";
import ContactBoxContainer from "../../components/contactbox/ContactBoxContainer";
import {connect} from "react-redux";
import {dispatchFetchData, FETCH_TYPE_DIGITAL_OFFER_ROUTES} from "../../redux/actions/fetchAction";
import {ErrorBoundary} from "../../components/error/ErrorBoundary";
import CurrentOffer from "../../components/currentOffer/CurrentOffer";
import {Select} from "../../components/select/Select";
import {PreloaderWrapper} from "../../components/preloader-wrapper/PreloaderWrapper";
import {dispatchHintId} from "../../redux/actions/routeHintManagementAction";

// lazy connect
const GMap = React.lazy(() =>
	import('../../components/gmap/GMap').then(module => ({default: module.GMapInDA}))
);

function RoutesContent({dispatchFetchData, match, ...props}) {

	const [selectedOfferItemId, setSelectedOfferItemId] = useState(null);
	const [selectedMapId, setSelectedMapId] = useState(null);

	useEffect(() => {
		dispatchFetchData(match.params.hintId || '');
	}, [dispatchFetchData, match.params.hintId, props.offerNumber]);

	/**
	 * will be called from markerCallback by clicking a mapMarker.
	 * @param itemId
	 */
	function updateOfferItem(itemId) {
		setSelectedOfferItemId(itemId)
	}

	/**
	 * will be called from offerItemCallback by clicking an offerItem
	 * @param itemId
	 */
	function updateMapContent(itemId) {
		setSelectedMapId(itemId)
	}

	/**
	 * render offerItems in Itemgroup
	 * @param offerItems
	 * @returns {*}
	 */
	function renderOfferItems(offerItems) {
		return (
			offerItems.map((group, index) => {
				let groupKey = group.type + '-' + group.label.split(' ').join('_').toLowerCase();
				return (
					<ItemGroup group={group}
										 itemMap={props.itemMap}
										 key={groupKey}
										 groupIndex={index}
										 id={`group-${index}`}
										 context={'offer'}
										 readOnly={group.readOnly}
										 withBackground={true}
										 callbackHandler={updateMapContent}
										 selectedItemId={selectedOfferItemId}
					/>
				);
			})
		);
	}

	/**
	 * on routeProposal update
	 * @param hintId
	 */
	function routeProposalChangeHandler(hintId) {
		props.dispatchHintId(hintId.value);
	}

	/**
	 * render introtext & routeProposalSelect
	 * @param isLoading
	 * @returns {*}
	 */
	function renderIntroText(isLoading) {

		if (isLoading) {

			return <Preloader type={'text'} additionalClasses={'box'}/>;

		} else {

			return <div className={'routes-content__intro box'}>

				{props.headline && <div className={'routes-content__intro-headline h2 ts-handwriting'}>
					{props.headline}
				</div>
				}

				{props.text && <div className={'routes-content__intro-text'}>
					<span dangerouslySetInnerHTML={{__html: props.text}}></span>
				</div>
				}

				{(props.hintList && props.hintList.length > 1 && props.additionalText) &&
					<div className={'routes-content__intro-text'}>
						<span dangerouslySetInnerHTML={{__html: props.additionalText}}></span>
					</div>
				}

				{props.hintList && <Select label={'Routenvorschläge'}
																	 onChange={routeProposalChangeHandler}
																	 initialValue={match.params.hintId || ''}
																	 additionalClasses={'routes-content__intro-select'}
																	 options={props.hintList.map((item) => {
																		 return {
																			 label: item.name,
																			 placeholder: item.name,
																			 value: item.hintId.toString()
																		 }
																	 })}
				/>

				}

				<CurrentOffer showRevisionHint={true}/>

			</div>
		}

	}

	/**
	 * render fallback text if no route is given
	 * @param isLoading
	 * @returns {*}
	 */
	function renderFallbackText(isLoading) {

		if (isLoading) {

			return <Preloader type={'text'} additionalClasses={'box'}/>;

		} else {

			return <div className={'routes-content__intro box'}>

				<div className={'routes-content__intro-headline h2 ts-handwriting'}>
					Keine Routenvorschläge verfügbar.
				</div>

				<div className={'routes-content__intro-text'}>
					Für diese Version des Angebots ist kein Routenvorschlag verfügbar. Bitte wechseln Sie zur aktuellen
					Version des Angebots
				</div>

				<CurrentOffer additionalClasses={'routes-content__revision-btn'}/>

			</div>
		}

	}

	/**
	 * render block of offerItems
	 * @param isLoading
	 * @returns {*}
	 */
	function renderOfferBlock(isLoading) {

		if (isLoading) {
			return <Preloader type={'logo'}/>;
		} else {
			return <>
				{props.offerItems && renderOfferItems(props.offerItems)}
			</>
		}
	}

	/**
	 * render main content conditionally
	 * @returns {*}
	 */
	function renderContent() {

		if (props.hasLoadingError) {
			return <ErrorMessage type={'loadingError'}/>
		} else if (props.isLoading && !props.loadingComplete) {
			return <>
				<PreloaderWrapper classes={'routes-content'}>
					<Preloader type={'logo'}/>
				</PreloaderWrapper>
			</>
		} else if (props.loadingComplete && !props.hintList) {
			return <div className='routes-content__full'>
				{renderFallbackText(props.isLoading)}
			</div>
		} else {
			return <div className={'routes-content'}>

				<div className='routes-content__main'>

					{renderIntroText(props.isLoading)}

					<ContactBoxContainer/>

					{props.hasLoadingError ?
						<ErrorMessage type={'loadingError'}/>
						:
						renderOfferBlock(props.isLoading)
					}

				</div>

				<div className='routes-content__side'>
					<ErrorBoundary>
						<Suspense fallback={<Preloader/>}>
							<GMap callbackHandler={updateOfferItem}
										selectedItemId={selectedMapId}
										mapType={'routeMap'}
							/>
						</Suspense>
					</ErrorBoundary>
				</div>
			</div>
		}
	}

	return (
		<>
			{renderContent()}
		</>
	)
}

RoutesContent.propTypes = {
	/**
	 * @example: 'HAM-1339657-BSC'
	 */
	offerNumber: PropTypes.string.isRequired,
	/**
	 * id of routehint
	 * @example: 6500
	 */
	hintId: PropTypes.number,

	/**
	 * headline of introtext
	 */
	introductionHeadline: PropTypes.string,

	/**
	 * copy of introtext
	 */
	introductionText: PropTypes.string,

	/**
	 * list of offerItems
	 */
	offerItems: PropTypes.array,

	/**
	 * list of select options
	 */
	hintList: PropTypes.array,

	/**
	 * is loading
	 */
	isLoading: PropTypes.bool,

	/**
	 * is loading complete
	 */
	loadingComplete: PropTypes.bool,

};

const mapStateToProps = (state, ownProps) => {

	return {
		offerNumber: state.globalStateManagement.offer.offerNumber,
		hintId: state.globalStateManagement.hintId,

		headline: state.offerRoutesManagement.headline,
		text: state.offerRoutesManagement.text,
		additionalText: state.offerRoutesManagement.additionalText,
		offerItems: state.offerRoutesManagement.offerItems,
		hintList: state.offerRoutesManagement.hintList,
		itemMap: state.itemManagement.routeItemMap,

		isLoading: state.offerRoutesManagement.isLoading,
		loadingComplete: state.offerRoutesManagement.loadingComplete
	}
};

export default connect(mapStateToProps, {
	dispatchFetchData: (hintId) => dispatchFetchData(FETCH_TYPE_DIGITAL_OFFER_ROUTES, false, {hintId: hintId}),
	dispatchHintId: dispatchHintId

})(RoutesContent)
