import React, {Component} from 'react';
import './TabNavigation.scss'
import classNames from 'classnames';
import PropTypes from 'prop-types';
import {Tab} from "./tab/Tab";

class TabNavigation extends Component {

	constructor(props){
		super(props);
		this.changeHandler = this.changeHandler.bind(this);
	}

	changeHandler(event){
		if(this.props.onChange){
			this.props.onChange(event.target.id)
		}
	}

	render() {
		return (
			<div className={classNames('tab-navigation', this.props.additionalClasses)} onChange={this.changeHandler}>
				{this.props.children}
			</div>
		)
	}
}

TabNavigation.propTypes = {

	/**
	 * has to be an instance of tab component
	 */
	children: PropTypes.arrayOf(PropTypes.shape({
		type: PropTypes.oneOf([Tab])
	})).isRequired,

	/**
	 * change handler if value of tab radio group changes. returns id of changed input
	 */
	onChange: PropTypes.func,

	/**
	 * add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.
	 */
	additionalClasses: PropTypes.string,
};

export {
	TabNavigation as default,
	TabNavigation
}
