/**
 * Supported html entities
 *
 * @param encodedString {string|number}
 */

const entities = {
	'amp': '&',
	'apos': '\'',
	'#x27': '\'',
	'#x2F': '/',
	'#39': '\'',
	'#47': '/',
	'lt': '<',
	'gt': '>',
	'nbsp': ' ',
	'quot': '"',
	'Auml': 'Ä',
	'auml': 'ä',
	'Ouml': 'Ö',
	'ouml': 'ö',
	'Uuml': 'Ü',
	'uuml': 'ü',
	'szlig': 'ß',
	'euro': '€',
	'deg': '°',
};

/**
 * Decode html entities to utf8 char
 *
 * @param encodedString
 * @return {*}
 */
const decodeHTMLEntities = (encodedString) => {

	// remove leading & trailing empty ascii-characters
	encodedString = encodedString.trim();

	if (encodedString) {
		return encodedString.replace(/&([^;]+);/gm, function (match, entity) {
			return entities[entity] || match
		})
	}
};

/**
 * remove styling from markup-String
 * @param text
 * @returns {string}
 */
const removeUnusedStyles = (text) => {

	// in some offers the styleAttribute has no "quotes"
	let regex = /style="(.*?)"/gm || /style=[^"].*?[;]/ig;

	text = text.split('↵').join('');
	text = text.replace(regex, '');

	// remove style-tag
	text = text.replace(/<style([\s\S]+?)<\/style>/, '')
	return text
}

/**
 * remove styling from markup-String
 * @param text
 * @returns {string}
 */
const removeUnusedCharacters = (text) => {
	text = text.split('↵').join('');
	text = text.split('↵').join('');
	return text
}

/**
 * removes first and last <br>
 * @param text
 * @returns {string|null}
 */
const trimHtmlBreaks = (text) => {

	// remove leading <br>
	if (text.indexOf('<br>') === 0) {
		text = text.replace('<br>', '')
	}

	// remove trailing <br>
	if (text.lastIndexOf('<br>') === text.length - 4) {
		text = text.substring(0, text.length - 4)
	}

	return text;
}

/**
 * TODO: remove this after https://ticket.tegtmeier.net/browse/CPV-7738 is merged
 * @param text
 * @returns {*}
 */
const removeRatingStarsImage = (text) => {
	return text.replace(/<img[^>]*>/g, '')
}


const isValidJSON = (string) => {
	let testValue = string;
	if (typeof string !== 'string') {
		testValue = JSON.stringify(string)
	}
	try {
		JSON.parse(testValue)
	} catch (e) {
		if (e instanceof SyntaxError) return false;
	}
	return true;
}

const ROUTES_MAP = {
	TRAVEL: 'reise',
	INVOICE: 'rechnung',
	DOCUMENTS: 'dokumente',
	TRIPS: 'ausfluege',
	FAQ: 'faq'
}

const pathNameToTitle = (pathName) => {
	const pathToTitleMap = {
		[ROUTES_MAP.TRAVEL]: 'Ihre Reise',
		[ROUTES_MAP.INVOICE]: 'Ihre Rechnung',
		[ROUTES_MAP.DOCUMENTS]: 'Ihre Reiseunterlagen',
		[ROUTES_MAP.TRIPS]: 'Buchbare Ausflüge',
		[ROUTES_MAP.FAQ]: 'Häufig gestellte Fragen'
	};
	const route = pathName.split("/").pop();

	return pathToTitleMap[route] ? `${pathToTitleMap[route]} | CANUSA Kundenportal` : 'Kundenportal | CANUSA' ;
}

/**
 * Remove script tags, style tag and inline styling
 *
 * @param markup
 * @return {*}
 */
const saveMarkup = (markup) => {
	return markup.replace(/(<script\b[^>]*>([\s\S]*?)<\/script>)|(<style\b[^>]*>([\s\S]*?)<\/style>)|(style="([\s\S]*?);")/gm, '');
};

export {
	decodeHTMLEntities,
	trimHtmlBreaks,
	removeUnusedStyles,
	removeUnusedCharacters,
	removeRatingStarsImage,
	isValidJSON,
	saveMarkup,
	pathNameToTitle,
	ROUTES_MAP
}
