import React, {Component} from 'react';
import "./Item.scss"
import {ItemHeader} from "./item-header/ItemHeader";
import classNames from 'classnames';
import PropTypes from "prop-types";
import ItemHeaderOffer from "./item-header/item-header-offer/ItemHeaderOffer";
import {ItemHeaderDocuments} from "./item-header/item-header-documents/ItemHeaderDocuments";
import ItemHeaderOptionals from "./item-header/item-header-optionals/ItemHeaderOptionals";
import {connect} from "react-redux";
import Alternatives from "../alternatives/Alternatives";
import ItemDetailsContainer from "./item-details/ItemDetailsContainer";
import {getActiveItem} from "../../redux/selectors";

class Item extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: props.isOpen,
      selectedAlternativeId: props.item.id
    };

    this.handleChange = this.handleChange.bind(this);
    this.closeHandler = this.closeHandler.bind(this);

    this.alternativeItemIdChangeHandler = this.alternativeItemIdChangeHandler.bind(this);

    this.ref = React.createRef();
  }

  /**
   * changeHandler für Collapsible
   * @param event
   */
  handleChange(event) {
    if (!this.state.isOpen) {
      if (this.props.callbackHandler && this.props.item.mapKey) {
        this.props.callbackHandler(this.props.item.mapKey)
      }
    }

    this.setState({
      isOpen: event.target.checked
    })
  }

  /**
   * check if selectedItemId
   * @param prevProps
   */
  componentDidUpdate(prevProps, prevState) {
    if (this.props.selectedItemId !== prevProps.selectedItemId &&
      this.props.selectedItemId === this.props.item.mapKey) {
      this.setState({isOpen: true});

      const headerHeight = document.getElementsByClassName('header')[0]?.offsetHeight || 0
      const navigationHeight = document.getElementsByClassName('main-navigation')[0]?.offsetHeight || 0;
      const offset = headerHeight + navigationHeight;

      window.scrollTo({
        // scroll to top - headerHeight - radiogroupHeight
        top: this.ref.current.offsetTop - offset - 8,
        left: 0,
        behavior: 'smooth'
      });
    }

    if ((prevProps.isDesktop !== this.props.isDesktop) && this.state.isOpen) {
      this.setState({
        isOpen: false
      })
    }

  }

  /**
   * closeHandler für Modal (mobileDetailView)
   */
  closeHandler() {
    this.setState({
      isOpen: false
    })
  }

  checkAlternatives(item) {
    if (this.props.itemMap) {
      return getActiveItem(this.props.itemMap, this.props.alternatives || []) || item;
    }
    return item;
  }

  renderItemHeaderContent(isCollapsible, collapsibleId) {
    switch (this.props.context) {
      case'offer':
        return <ItemHeaderOffer item={this.checkAlternatives(this.props.item)}
                                isCollapsible={isCollapsible}
                                isDesktop={this.props.isDesktop}
                                collapsibleId={collapsibleId}
                                alternatives={this.props.item.alternatives}
                                alternativeTeaser={this.props.alternativeTeaser}
                                groupIndex={this.props.groupIndex}
                                items={this.props.items}
        />;

      case'documents':
        return <ItemHeaderDocuments item={this.props.item}
                                    isCollapsible={isCollapsible}
                                    isDesktop={this.props.isDesktop}
                                    collapsibleId={collapsibleId}
        />;

      case'optionals':
        return <ItemHeaderOptionals item={this.props.item}
                                    isCollapsible={isCollapsible}
                                    isDesktop={this.props.isDesktop}
                                    collapsibleId={collapsibleId}
                                    readOnly={this.props.readOnly}
        />;

      default:
        return <div>INVALID ITEM HEADER DATA</div>
    }
  }

  /**
   * render itemHeader
   * @param isCollapsible
   * @param collapsibleId
   * @returns {*}
   */
  renderItemHeader(isCollapsible, collapsibleId) {
    return <ItemHeader context={this.props.context}>
      {this.renderItemHeaderContent(isCollapsible, collapsibleId)}
    </ItemHeader>
  }

  /**
   * render itemDetails OR itemDetailsContainer
   * @param collapsibleId
   * @returns {*}
   */
  renderItemDetails(collapsibleId, alternativeItemId) {

    let item = this.props.itemMap ? this.props.itemMap[alternativeItemId] : this.props.item;
    return <ItemDetailsContainer item={item}
                                 tabs={JSON.stringify(item.tabs)} //pseudo prop to trigger rerender because of nested data
                                 parentId={collapsibleId}
                                 isOpen={!this.props.isDesktop && this.state.isOpen}
                                 isDesktop={this.props.isDesktop}
                                 closeHandler={this.closeHandler}
                                 alternativeList={this.props.alternatives}
                                 onAlternativeChange={this.alternativeItemIdChangeHandler}
    />
  }

  /**
   * render alternativesSlider
   * @param openCollapsible
   * @returns {null|*}
   */
  renderAlternatives(openCollapsible, isDesktop) {
    if (this.props.alternatives && this.props.alternatives.length > 0 && isDesktop) {
      return <Alternatives alternativeList={this.props.alternatives}
                           isExpanded={this.props.isDesktop && openCollapsible}
                           sliderId={'alternatives-' + this.props.item.id}
                           isDesktop={this.props.isDesktop}
                           onChange={(selectedItemId) => {
                             this.alternativeItemIdChangeHandler(selectedItemId)
                           }}
                           selectedAlternativeId={this.state.selectedAlternativeId}
      />
    }
  }

  /**
   *
   * @param selectedItemId
   */
  alternativeItemIdChangeHandler(selectedItemId) {
    this.setState({
      selectedAlternativeId: selectedItemId,
      isOpen: true
    })
  }

  render() {

    if (this.props.isHidden) {
      return null;
    }

    const isCollapsible = !!this.props.item.tabs && this.props.item.tabs.length >= 1;
    const isInteractive = isCollapsible || this.props.item.mapKey;

    const collapsibleId = ('collapsible-item-' + (this.props.item.id || this.props.item.mapKey + '-' + this.props.item.date));

    let openCollapsible = this.state.isOpen;

    let classes = classNames(
      'item', 'shadow',
      {
        'item--interactive': isInteractive,
        'item--open': openCollapsible,
        'item--highlight': openCollapsible
      },
      this.props.additionalClasses
    );

    return (
      <div className={classes} ref={this.ref}>

        {isCollapsible && <input className={'item__trigger'}
                                 type='checkbox'
                                 id={collapsibleId}
                                 onChange={this.handleChange}
                                 checked={openCollapsible}
        />
        }

        <span onClick={(e) => {
          if (!isCollapsible) {
            // enable mapUpdates even if there is no collapsible (e.g: rental-car return)
            if (this.props.item.mapKey) {
              this.handleChange(e)
            }
          }
        }}>

					{this.renderItemHeader(isCollapsible, collapsibleId)}

				</span>
        {this.renderAlternatives(openCollapsible, this.props.isDesktop)}

        {isCollapsible && this.renderItemDetails(collapsibleId, this.state.selectedAlternativeId)}
      </div>
    )
  }
}

Item.defaultProps = {
  isHidden: false,
  isOpen: false,
  context: 'offer',
};

Item.propTypes = {

  /**
   * OPTIONAL: add additional classes for this component here.
   * Use this prop for special css-classes, which are not defined by default.*
   */
  additionalClasses: PropTypes.string,

  /**
   * OPTIONAL: returns mapKey of selected item
   */
  callbackHandler: PropTypes.func,

  /**
   * context of Item
   *
   * @default: offer
   */
  context: PropTypes.oneOf(['offer', 'optionals', 'documents', 'invoice']),

  /**
   * prevents rendering of the Item
   *
   * @default: false
   */
  isHidden: PropTypes.bool,

  /**
   * default state of Item (collapsible)
   *
   * @default: false
   */
  isOpen: PropTypes.bool,

  /**
   * prevents changes in ItemHeader
   *
   * @default: true
   */
  readOnly: PropTypes.bool,

  /**
   * Id of the selected Item
   */
  selectedItemId: PropTypes.string, // null

  /**
   * content of the Item
   */
  item: PropTypes.shape({
    /**
     * template of ItemHeader
     * @example: 'main'
     *
     */
    contentType: PropTypes.oneOf(['main', 'extra', 'optional']),

    /**
     * date used in ItemHeader
     * @example: '2019-08-31' (format: YYY-MM-DD)
     */
    date: PropTypes.string,

    /**
     * duration used in ItemHeader
     */
    duration: PropTypes.shape({

      /**
       * label
       * @example: 'Nächte'
       */
      label: PropTypes.string,

      /**
       * num / amount
       * @example: 2
       */
      num: PropTypes.number,
    }),

    /**
     * unique id of the Item
     *
     * @example: 60230071
     */
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),

    /**
     * used to display notification-icon-state
     *
     * @default: false
     */
    notice: PropTypes.string,

    /**
     * label used in ItemHeader
     * @example: 'Mariner King Inn'
     */
    label: PropTypes.string,

    /**
     * location.label used in ItemHeader
     * @example: {label:'Lighthouse Trail, NS', lat:34.2618964, lng:-116.9194283}
     */
    location: PropTypes.shape(
      {
        /**
         * Location label
         * @example: 'Lighthouse Trail, NS'
         */
        label: PropTypes.string,

        /**
         * Latitude of location
         * @example: 34.2618964
         */
        lat: PropTypes.number,

        /**
         * Longitude of location
         * @example: -116.9194283
         */
        lng: PropTypes.number,
      }
    ),

    /**
     * referenceId to mapKey (GMap)
     * @example: '1-5'
     */
    mapKey: PropTypes.string,

    /**
     * range: 1-5 steps: 0.5
     * @example: 4.5
     *
     */
    rating: PropTypes.number,

    /**
     * Array of objects
     * @see ItemDetails
     */
    tabs: PropTypes.arrayOf(
      PropTypes.object
    ),

    /**
     * url of tesearImage
     * @example: https://images.canusa.de/img/hotel/kanada/atlantikkanada/novascotia/lunenburg/mariner-king-inn/exterior-front
     */
    teaserImage: PropTypes.string,

    /**
     * **TODO: find all TYPE values**
     * type of the Item has influence on the icon in ItemHeader
     */
    type: PropTypes.string, //oneOf(['hotel', 'flight', 'ferry', '']),// TODO find all TYPE values

    /**
     * **TODO find all CONTEXT values**
     * context of Item
     * @default: 'offer'
     */
    context: PropTypes.string, //oneOf(['offer', 'optionals']),// TODO find all CONTEXT values

    /**
     * prevents rendering of the Item
     *
     * @default: false
     */
    isHidden: PropTypes.bool,

    /**
     * default state of Item (collapsible)
     *
     * @default: false
     */
    isOpen: PropTypes.bool,

    /**
     * prevents changes in ItemHeader
     *
     * @default: true
     */
    readOnly: PropTypes.bool,
  })
};

const mapStateToProps = (state) => {

  return {
    isDesktop: state.globalStateManagement.isDesktop,
  }
};

export default connect(mapStateToProps)(Item)

