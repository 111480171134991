import React, {Component} from 'react';
import {DataProviderService} from "../services/DataProviderService";
import {BUTTON_APPEARANCE, Button} from "../components/button/Button";
import {storageManager} from "../services/StorageManager";

class CreateNotificationsButton extends Component {

  constructor(props) {
    super(props);

    this.dataProvider = new DataProviderService({endpoint: 'debug/notifications/reset'});

    this.clickHandler = this.clickHandler.bind(this);
  }

  /**
   * send debug post request
   */
  clickHandler() {

    storageManager.disableNotifications = false;
    this.dataProvider.setData({}).then((e) => {
      if (this.props.reloadAfterUpdate) {
        console.info("I just found ", e.updated, 'notifications. Prepare to reload!');
        window.location.reload()
      }
    });
  }

  render() {

    const label = this.props.reloadAfterUpdate ? 'Reset Notifications & Reload' : 'Reset Notifications';

    return (
      <>
        <Button label={label} onClick={this.clickHandler} width={'fit'}
                appearance={this.props.reloadAfterUpdate && BUTTON_APPEARANCE.IMPORTANT}/>
      </>
    );
  }
}

export {
  CreateNotificationsButton as default,
  CreateNotificationsButton
}
