import React, {Component} from 'react';
import './ItemGroupHeader.scss'
import classNames from 'classnames';
import {FullWidthImage} from "../../full-width-image/FullWidthImage";
import {BREAKPOINTS} from "../../../../vendor/utils/BreakpointUtils";
import {ImageVO} from "../../image/Image";
import PropTypes from "prop-types";
import {decodeHTMLEntities} from "../../../../vendor/utils/HTMLUtils";
import {currencyFormat} from "../../../../vendor/utils/Utils";
import Badge from "../../badge/Badge";

const PROPORTION_LIST = [
	{
		ratio: {width: 7, height: 1},
		media: `screen and (max-width: ${BREAKPOINTS.tablet - 1}px)`
	},
	{
		ratio: {width: 5, height: 1},
		media: `screen and (min-width: ${BREAKPOINTS.tablet}px) and (max-width: ${BREAKPOINTS.desktop - 1}px)`
	},
	{
		ratio: {width: 48, height: 13},
		media: `screen and (min-width: ${BREAKPOINTS.desktop}px)`
	}
];

class ItemGroupHeader extends Component {

	/**
	 * Return image value object with values from PROPORTION_LIST
	 *
	 * @param image
	 * @return {any[]}
	 */
	getHeaderImages(image) {
		return PROPORTION_LIST.map((proportion) => {
			let imageVO = Object.assign(new ImageVO(), image);
			imageVO.ratio = proportion.ratio;
			imageVO.media = proportion.media;

			return imageVO;
		})
	}

	render() {
		const classes = classNames(
			'item-group-header',
			{
				[`item-group-header--${this.props.context}`]: this.props.context,
				[`item-group-header--type-${this.props.contentType}`]: this.props.contentType,
				'item-group-header--with-bg': this.props.withBackground,
				'h4 tt-none': this.props.contentType === 'optional'
			},
			this.props.additionalClasses
		);

		return (
			<>
				{this.props.headerImage && <FullWidthImage images={this.getHeaderImages(this.props.headerImage)}
														   additionalClasses={'item-group-header__image'} fullwidth={true}/>}
				<div className={classes}>
					{this.props.dayStart &&
					<div className={'item-group-header__duration'}>
						Tag {this.props.dayStart}{this.props.dayEnd && ` - ${this.props.dayEnd}`}
					</div>}
					<div className={'item-group-header__label'}>
						<span className={'item-group-header__mainlabel'} dangerouslySetInnerHTML={{__html:decodeHTMLEntities(this.props.label)}}>
						</span>
						{ !!this.props.disclaimer &&
								<div className={'item-group-header__disclaimer-wrapper'}>
									<Badge label={'Hinweis'} type={"signal"} inverted={true} additionalClasses={'item-group-header__disclaimer-icon'}/>
									<span className="item-group-header__disclaimer-text ts-copy-medium">{this.props.disclaimer}</span>
								</div>
						}
					</div>

					{ !!this.props.price  &&
					<div className={'item-group-header__price'}>{currencyFormat(this.props.price)}</div>
					}
				</div>
			</>
		)
	}
}

ItemGroupHeader.propTypes = {
	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.
	 */
	additionalClasses: PropTypes.string,

	// TODO: reconsider sense of context and contenttype in ItemGroup Header
	/**
	 * context of header
	 * @default: offer
	 */
	context: PropTypes.oneOf(['offer', 'optionals', 'offer-price', 'documents']),

	/**
	 * template of header
	 * @example: 'main'
	 *
	 */
	contentType: PropTypes.oneOf(['main', 'extra', 'document', 'optional']),

	/**
	 * object with image
	 */
	headerImage: PropTypes.shape({
		/**
		 * url to image
		 * @examples
		 *        'https://images.canusa.de/img/regionen/bahamas/nassau/allgemein/17katalog-bahamas-nassau-haengematte.jpg'
		 *
		 */
		url: PropTypes.string.isRequired,

		/**
		 * alternative text show image could not load and it is also important for accessibility and seo
		 * @example: 'Ihre Traumreise'
		 */
		alt: PropTypes.string,

	}),
	/**
	 * startDay of journey
	 * @example: 1
	 */
	dayStart: PropTypes.number,
	/**
	 * endDay of journey
	 * @example: 3
	 */
	dayEnd: PropTypes.number,
	/**
	 * label of header
	 * @example: 'Ihre Flüge'
	 */
	label: PropTypes.string,
	/**
	 * Price for ItemGroupHeader
	 * @example: 6124
	 */
	price: PropTypes.number,
	/**
	 * use dotted image as background
	 */
	withBackground: PropTypes.bool

};

export {
	ItemGroupHeader as default,
	ItemGroupHeader
}
