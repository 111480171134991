import React, {Component} from 'react';
import Invoice from "../../components/invoice/Invoice";
import './InvoiceContent.scss'

class InvoiceContent extends Component {

	render() {
		return (
			<div className={'invoice-content'}>
				<Invoice {...this.props}/>
			</div>
		)
	}
}

export {
	InvoiceContent as default,
	InvoiceContent
}
