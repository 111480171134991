import React, {Component} from 'react';
import {CreateNotificationsButton} from "./CreateNotificationsButton";
import {Collapsible} from "../components/collapsible/Collapsible";
import './DebugBar.scss';
import classNames from "classnames";
import {Icon} from "../components/icon/Icon";
import {SwitchOfferListButton} from "./SwitchOfferListButton";
import {DisableNotificationsReload} from "./DisableNotificationsReload";
import EnableTrackingButton from "./EnableTrackingButton";

export const DEBUG = {
  TRACKING: 'tracking',
  NOTIFICATION: 'notification',
  MOCK: 'mock',
}

class DebugBar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isDebugBarHidden: false,
      isDebugBarPinned: true
    };
    this.unpinDebugBarHandler = this.unpinDebugBarHandler.bind(this);
    this.mouseEnterHandler = this.mouseEnterHandler.bind(this);
    this.mouseLeaveHandler = this.mouseLeaveHandler.bind(this);
    this.pinDebugBarHandler = this.pinDebugBarHandler.bind(this);
  };

  /**
   * hides the debug bar
   */
  unpinDebugBarHandler() {
    this.setState({
      isDebugBarHidden: true,
      isDebugBarPinned: false
    });
  }

  /**
   * the debug bar is visible when the mouse pointer "hovers" inside the area where the debug bar should be
   */
  mouseEnterHandler() {
    if (!this.state.isDebugBarPinned) {
      this.setState({
        isDebugBarHidden: false,
      });
    }
  }

  /**
   * the debug bar disappears when the mouse pointer leaves the area of the debug bar
   */
  mouseLeaveHandler() {
    if (!this.state.isDebugBarPinned) {
      this.setState({
        isDebugBarHidden: true,
      });
    }
  }

  /**
   * pins the debug bar in place, allowing the open content/close content feature
   */
  pinDebugBarHandler() {
    if (!this.state.isDebugBarPinned) {
      this.setState({
        isDebugBarHidden: false,
        isDebugBarPinned: true,
      });
    }
  }


  render() {

    const classes = classNames(
      'debug-bar',
      {'debug-bar--hide': this.state.isDebugBarHidden},
    );

    return (
      <div className={classes}
           onMouseEnter={this.mouseEnterHandler}
           onMouseLeave={this.mouseLeaveHandler}
           onClick={this.pinDebugBarHandler}
      >
        {!this.state.isDebugBarHidden &&
        <Collapsible
          headContent={
            <span>Debug</span>
          }
          id={'debug_bar'}
          additionalClasses={'debug-bar__collapsible'}
        >
          <>
          {this.props.show.includes(DEBUG.TRACKING) &&<EnableTrackingButton/>}
          {this.props.show.includes(DEBUG.NOTIFICATION) &&
              <>
            <CreateNotificationsButton/>
            <CreateNotificationsButton reloadAfterUpdate={true}/>
            <DisableNotificationsReload/>
              </>}
          {this.props.show.includes(DEBUG.MOCK) && <SwitchOfferListButton/>}
          </>
        </Collapsible>
        }
        {this.state.isDebugBarPinned &&
        <div className={'debug-bar__close-button'} onClick={this.unpinDebugBarHandler}>
          <Icon name={"close"}></Icon>
        </div>
        }
      </div>
    );
  }
}

export {
  DebugBar as default,
  DebugBar
}
