import React, {Component} from 'react';
import {Helmet} from "react-helmet";
import {pathNameToTitle, ROUTES_MAP} from "../../vendor/utils/HTMLUtils";
import {AUTH_TYPES} from "../components/login/LoginWrapper";

function withTitleChange(WrappedComponent) {
	const canonicalArray = [`/${AUTH_TYPES.LOGIN}`, `/${ROUTES_MAP.FAQ}`];
	class WithTitleChange extends Component {

		render() {
			const pathName = window.location.pathname;
			const title = pathNameToTitle(pathName);
			const canonical = canonicalArray.includes(pathName) ? `${window.location.origin}${pathName}`:window.location.origin;
			//filter props to pass
			return <>
				<Helmet>
					<title>{title}</title>
					<link rel="canonical" href={canonical} />
				</Helmet>
				<WrappedComponent {...this.props} />
			</>;
		}
	}

	WithTitleChange.propTypes = {
	};

	WithTitleChange.displayName = `WithTitleChange(${getDisplayName(WrappedComponent)})`;
	return WithTitleChange;
}



function getDisplayName(WrappedComponent) {
	return WrappedComponent.displayName || WrappedComponent.name || 'Component';
}

export default withTitleChange;
