import {STORE_CLEAR} from "../actions/globalStateManagementAction";
import {OFFER_MAP, ROUTE_MAP} from "../actions/mapManagementAction";

const mapManagementState = {
  offerMap: {},
  routeMap: {},
};

const mapManagementReducer = (state = mapManagementState, action) => {

  switch (action.type) {

    case OFFER_MAP:
      return {
        ...state,
        offerMap: {...action.data, loadingComplete: true}
      };

    case ROUTE_MAP:
      return {
        ...state,
        routeMap: {...action.data, loadingComplete: true}

      };

    case STORE_CLEAR:
      return {};

    default:
      return state
  }
};

export default mapManagementReducer;
