import {DataProviderService} from "./DataProviderService";
import {toGermanDateStr} from "../../vendor/utils/DateUtils";

class OfferService extends DataProviderService {

	parseAddons(addonTab, payload, counter){
		const list = addonTab[0].content;
		list.forEach(item => {
			const price = {0: [0], ...item.price}
			payload.append(`items[${counter}][addons][${item.id}][quantity]`, item.selected)
			payload.append(`items[${counter}][addons][${item.id}][price]`, price[item.selected])
		})
	}

	getPayload(itemMap, totalPrice, form){
		const payload = form || new FormData();
		let counter = 0;
		Object.keys(itemMap).forEach(key => {
			const item = itemMap[key];
			if (item && item.active && item.originId) {
				if (item.contentType !== 'optional') {
					payload.append(`items[${counter}][id]`, item.originId)
					const addonTab = item.tabs.filter(tab => tab.type === 'addons');
					(addonTab && addonTab.length !== 0) && this.parseAddons(addonTab, payload, counter)
				} else if (item.selected) {
					const date = toGermanDateStr(item.date)
					// can be 19:00 oder 19:00-19:30
					const isValidTime = (item.time && item.time.indexOf(':') !== -1);
					const dateString = isValidTime ? `${date.substring(0, 6)} ${item.time}` : date;
					payload.append(`items[${counter}][id]`, item.originId)
					payload.append(`items[${counter}][date][label]`, dateString)
					payload.append(`items[${counter}][date][price]`, item.price)
				}
				counter++;
			}

		});
		payload.append('price', totalPrice)

		return payload
	}

	setOffer(itemMap, totalPrice, form){
		const payload = this.getPayload(itemMap, totalPrice, form);

		return this.setData(payload).then(response => {
			return response;
		})
	}

}

export {
	OfferService
}
