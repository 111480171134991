import React, {Component} from 'react';
import './Button.scss'
import {Icon, ICON_SIZE} from "../icon/Icon";
import classNames from "classnames";
import PropTypes from 'prop-types';

export const BUTTON_WIDTH = {FULL_WIDTH: 'fullwidth', FIT: 'fit', FIXED: 'fixed'};

export const BUTTON_APPEARANCE = {
  PRIMARY: 'primary',
  IMPORTANT: 'important',
  SECONDARY: 'secondary',
  TERTIARY: 'tertiary',
  QUATERNARY: 'quaternary',
  MAIN_NAVIGATION: 'main-navigation',
  GHOST: 'ghost',
  LINK: 'link'
};


class Button extends Component {

  render() {
    const isLink = this.props.appearance === 'link'
    const classes = classNames(
      'button',
      {
        [`button--${this.props.width}`]: !isLink && this.props.width,
        [`button--${this.props.appearance}`]: this.props.appearance
      },
      {'button--is-loading': !isLink && this.props.isLoading},
      {'dotted': isLink},
      this.props.additionalClasses
    );

    const iconName = this.props.isLoading ? 'loading' : this.props.icon;
    const icon = (iconName && !isLink) &&
      <Icon additionalClasses={'button__icon'} name={iconName} size={ICON_SIZE.XS}/>;
    const label = this.props.label &&
      <span className={classNames('button__label', {'dotted': this.props.underline})}>{this.props.label}</span>;

    return <button
      className={classes}
      data-uid={this.props.uid}
      title={this.props.title}
      disabled={(this.props.disabled || this.props.isLoading) ? 'disabled' : null}
      onClick={this.props.onClick}
      type={this.props.type}
    >
      {icon}{label}
    </button>
  }
}

Button.propTypes = {
  /**
   * **REQUIRED**: for type: **submit** and **default**
   */
  onClick: PropTypes.func,
  /**
   * **REQUIRED**: Label of the button
   */
  label: PropTypes.string.isRequired,
  /**
   * OPTIONAL: unique id of the button
   */
  uid: PropTypes.string,
  /**
   * OPTIONAL: button title
   */
  title: PropTypes.string,
  /**
   * OPTIONAL: disables component
   */
  disabled: PropTypes.bool,
  /**
   * OPTIONAL: icon-string @see 'utils/Mappings.js (iconMap)'
   */
  icon: PropTypes.string,
  /**
   * OPTIONAL: Type of the button @see **Types Section** for more information
   */
  type: PropTypes.oneOf(['button', 'submit']),
  /**
   * OPTIONAL:
   */
  appearance: PropTypes.oneOf(['primary', 'important', 'secondary', 'tertiary', 'quaternary', 'main-navigation', 'ghost', 'link']),
  /**
   * OPTIONAL:
   * @default 'fullwidth'
   */
  width: PropTypes.oneOf(['fullwidth', 'fit', 'fixed']),
  /**
   * OPTIONAL: add additional classes for this component here.
   * Use this prop for special css-classes, which are not defined by default.*
   */
  additionalClasses: PropTypes.string,
  /**
   * use round borders on buttons
   * @default: true
   */
  useRoundBorder: PropTypes.bool,
  /**
   * adds an icon with loading animation and set button disabled
   * @default: false
   */
  isLoading: PropTypes.bool
};

Button.defaultProps = {
  label: 'please_enter_a_proper_label',
  appearance: 'primary',
  type: 'button',
  target: '_self',
  useRoundBorder: true,
  width: 'fullwidth',
  isLoading: false
};

export {
  Button as default,
  Button
}
