import {
	HAS_ROUTE_HINTS,
	ROUTE_HINT_ID,
} from "../actions/routeHintManagementAction";

const routeHintManagementState = {
	hasRouteHints:false,
	hintId:null,
};

const routeHintManagementReducer = (state = routeHintManagementState, action) => {
	switch (action.type) {
		case HAS_ROUTE_HINTS:
			return {
				...state,
				hasRouteHints: action.hasRouteHints,
			};

		case ROUTE_HINT_ID:
			return {
				...state,
				hintId: parseInt(action.hintId) || null
			};

		default:
			return state
	}
};

export default routeHintManagementReducer;
