import React, {lazy, Suspense} from "react";
import {Checkbox} from "../../checkbox/Checkbox";
import {mapTypeToIcon} from "../../../../vendor/utils/Mappings";
import {Icon, ICON_SIZE} from "../../icon/Icon";
import {Rating} from "../../rating/Rating";
import LinkPdf from "../../link/LinkPdf";
import Preloader from "../../preloader/Preloader";
import classNames from "classnames";
import {Badge} from "../../badge/Badge";
import {getSmallPaddedFormatDate, getWeekday} from "../../../../vendor/utils/DateUtils";
import {decodeHTMLEntities} from "../../../../vendor/utils/HTMLUtils";
import {Tooltip} from "../../tooltip/Tooltip";

const Notice = lazy(() => import('../../notice/Notice'));


/**
 *
 * @return {*}
 */
const renderCheckbox = (readOnly) => {
  if (readOnly) {
    return null;
  }

  return (
      <div className={'item-header__checkbox'}>
        <Checkbox name={'checkbox'}/>
      </div>
  );
};

/**
 * Return icon base on type
 *
 * @return {*}
 */
const renderTypeIcon = (type) => {
  const iconName = mapTypeToIcon(type);

  return (
      <div className={'item-header__type-icon'}>
        {iconName && <Icon name={iconName}/>}
      </div>
  );
};

/**
 * Return location with prefix 'Ab' based on type
 *
 * @return {string|null}    preLocation
 */
const getPreLocation = (location, type) => {
  let preLocation;

  if (location && location.label) {
    switch (type) {
      case 'flight':
        preLocation = 'Ab ' + location.label;
        break;
      default:
        break;
    }
  }

  return preLocation;
};

/**
 * Return location with prefixes 'ab' or 'in' based on type
 *
 * @return {string|null} postLocation
 */

/**
 * configVO
 */
class renderTitleConfigVO {

  constructor() {
    this._label = null;
    this._originLabel = null;
    this._rating = null;
    this._type = null;
    this._location = null;
  }

  /**
   * Get location.
   * @return {} location
   */
  get location() {
    return this._location;
  }

  /**
   * Set location.
   * @param location {}
   */
  set location(value) {
    this._location = value;
  }

  /**
   * Get type.
   * @return {} type
   */
  get type() {
    return this._type;
  }

  /**
   * Set type.
   * @param type {}
   */
  set type(value) {
    this._type = value;
  }

  /**
   * Get rating.
   * @return {} rating
   */
  get rating() {
    return this._rating;
  }

  /**
   * Set rating.
   * @param rating {}
   */
  set rating(value) {
    this._rating = value;
  }

  /**
   * Get label.
   * @return {} label
   */
  get label() {
    return this._label;
  }

  /**
   * Set label.
   * @param label {}
   */
  set label(value) {
    this._label = value;
  }

  /**
   * Get originLabel.
   * @return {} originLabel
   */
  get originLabel() {
    return this._originLabel;
  }

  /**
   * Set label.
   * @param label {}
   */
  set originLabel(value) {
    this._originLabel = value;
  }
}

/**
 *
 * @param withLocation
 * @return {*}
 */
const renderTitle = (renderTitleConfigVO, withLocation = true) => {

  const preLocation = withLocation && getPreLocation(renderTitleConfigVO.location, renderTitleConfigVO.type);

  const toolTip = renderTitleConfigVO.originLabel ?
      <Tooltip content={`Ehemals: ${renderTitleConfigVO.originLabel}`} direction={'right'}>
        <Icon name={'circle-info'} size={ICON_SIZE.S} additionalClasses={'item-header__origin-hint'}/>
      </Tooltip>
      :
      null;

  return (
      <>
        <div className="item-header__title ts-bold">
				<span className={'item-header__title-wrapper'}>
				{preLocation && <span>{preLocation} </span>}
          {<span dangerouslySetInnerHTML={{__html: renderTitleConfigVO.label}}></span>}
          {toolTip}
				</span>
        </div>
      </>
  );
};


/**
 *
 * @return {*}
 */
const renderCollapsible = (isDesktop, isCollapsible, collapsibleId, label = 'Mehr anzeigen', size = 'default') => {
  let collapsibleInner = null;

  if (isDesktop) {
    collapsibleInner = <Icon name={'arrow-dots-down'} size={ICON_SIZE.S}/>;
  } else {

    const classes = classNames(
        'item-header__collapsible item-header__collapsible--small ts-bold dotted'
    );

    collapsibleInner = <label htmlFor={collapsibleId}>
								<span className={classes}
                      key={'open'}>{label}
								</span>
    </label>;
  }
  return (
      <div className="item-header__collapsible">
        {isCollapsible && collapsibleInner}
      </div>
  );
};

/**
 *
 * @return {*}
 */
const renderDownloadLink = (link, isDesktop, optionalTooltipDirection, itemType) => {
  if (!link) {
    return;
  }

  return (
      <div className="item-header__link">
        <LinkPdf
            type={itemType}
            additionalClasses={'ts-bold'}
            label={isDesktop ? null : link.label}
            download={link.downloadName}
            url={link.url}
            icon={'file-pdf'}
            optionalTooltipDirection={optionalTooltipDirection}
        />
      </div>
  );
};

/**
 *
 * @return {*}
 */
const renderDuration = (duration) => {
  if (!duration) {
    return null;
  }

  return (
      <div className={'item-header__duration'}>
        <Icon name={'time'} size={ICON_SIZE.S}/>
        <span className={'ts-copy-small'}>
				{duration && `${duration.num} ${decodeHTMLEntities(duration.label)}`}
			</span>
      </div>
  );
};

const renderLocation = (configVO) => {
  const nonLocationTypes = ['valuecard', 'transfer', 'default', 'seat-reservation', 'flight'];
  if (!configVO?.location?.label || nonLocationTypes.includes(configVO._type)) {
    return null;
  }

  return (
      <div className={'item-header__location'}>
        <Icon name={'pin'} size={ICON_SIZE.S}/>
        <span className={'ts-copy-small'}>
				{configVO.location.label}
			</span>
      </div>
  );
};

/**
 *
 * @return {*}
 */
const renderRating = (rating) => {
  if (!rating) {
    return null;
  }

  return (
      <div className={'item-header__rating rating--small'}>
        <Rating value={rating}/>
      </div>
  );
};

/**
 *
 * @return {*}
 */
const renderNoteIcon = (groupIndex, id, hasNotice, additionalClasses, originId = null) => {

  if (id) {
    return (
        <Suspense fallback={<Preloader/>}>
          <div className={'item-header__note'}>
            <Notice hasNotice={hasNotice} label={'Ihre Notizen'} groupIndex={groupIndex} noticeId={id}
                    originId={originId} additionalClasses={additionalClasses}/>
          </div>
        </Suspense>
    );
  }
};

/**
 *
 * @return {*}
 */
const renderBadge = (state) => {
  if (state) {
    return (
        <div className={'item-header__badge'}>
          <Badge type={state.level} label={state.label} size={'small'}/>
        </div>
    );
  }
};


/**
 *
 *
 * @return {*}
 */
const renderDate = (date) => {
  if (!date) {
    return null;
  }

  return (
      <div className="item-header__date">
        <Icon name={'calendar'} size={ICON_SIZE.S}/>
        <span className={'ts-copy-small'}>
					{`${getWeekday(date)}, ${getSmallPaddedFormatDate(date)}`}
				</span>
      </div>
  );
};

/**
 *
 *
 * @return {*}
 */
const renderHorizontalLine = () => {
  return (
      <div className={'item-header__horizontal-line'}/>
  );
};

export {
  renderCheckbox,
  renderTypeIcon,
  renderNoteIcon,
  renderTitleConfigVO,
  renderTitle,
  renderCollapsible,
  renderDownloadLink,
  renderDuration,
  renderLocation,
  renderRating,
  renderBadge,
  renderDate,
  renderHorizontalLine


};
