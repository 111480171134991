import React, {Component} from 'react';
import './DALoginMask.scss';
import {Input} from "../input/Input";
import {Button, BUTTON_APPEARANCE} from "../button/Button";
import {LinkInternal} from "../link/LinkInternal";
import {FormValidator} from "../form/FormValidator";
import {Tooltip} from "../tooltip/Tooltip";
import {Redirect} from "react-router-dom";
import PropTypes from 'prop-types';
import {storageManager} from "../../services/StorageManager";
import {dispatchIsAuthenticated} from "../../redux/actions/globalStateManagementAction";
import {connect} from "react-redux";
import {trackSubmitAttempt} from "../../../vendor/utils/TrackingManager";

class DALoginMask extends Component {

	constructor(props) {
		super(props);

		this.state = {
			firstTimeError: false,
			errorMessage: null,
			loggedIn: false,
			isLoading: false,
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.dataProvider = this.props.dataProvider;
	}

	/**
	 * submit handler of Form
	 * send Form and set state depending on response
	 * if form was send for the first time then errors will be shown. not before
	 * @param form
	 * @param isValid
	 */
	handleSubmit({form, isValid}) {
		//the isValid prop is overwritten because is not used in this mask
		isValid = true;

		this.setState({
			isLoading: true,
			errorMessage: null
		});

		if (isValid) {

			this.dataProvider.setData(form).then(response => {

				if (response.err) {
					this.setState({
						loggedIn: false,
						isLoading: false,
						errorMessage: response.err
					})
					trackSubmitAttempt( 'login', false, response.err)
				} else {
					this.setState({
						loggedIn: true,
						isLoading: false,
					});

					this.props.dispatchIsAuthenticated(true);

					if (this.props.onClose) {
						this.props.onClose(true); // logged in succesfully
						trackSubmitAttempt( 'login', true);
					}
				}
			});
		}
	}

	render() {

		if (this.state.loggedIn) return <Redirect to={this.props.redirectTo}/>;

		return (
			<>
				<div className={"da-login-wrapper"}>

					<h2 className={'da-login-wrapper__headline'}>ANMELDEN</h2>
					<p className={'da-login-wrapper__copy'}>Bitte geben Sie hier das Passwort ein, welches Sie per E-Mail
						zugeschickt bekommen haben.</p>
					{/* changeHandler are passed to inputs, that form registers changes. Submit Button will be disabled if form has been submitted and is not valid*/}
					<FormValidator additionalClasses="login_wrapper__form"
												 onSubmit={this.handleSubmit}
												 render={({changeHandler}) => (
													 <>
														 <div className="login_wrapper__inputs">
															 <input type={'hidden'} name={'offer'} value={storageManager.offerToken}/>
															 <Input name="password"
																			label="Passwort"
																			type="password"
																			required={true}
																			onChange={changeHandler}
																			showError={!!this.state.errorMessage}
																			fullWidth={true}
																			autoFocus={true}
															 />
														 </div>
														 <Tooltip
															 content={this.state.errorMessage}
															 inactive={!this.state.errorMessage}
															 direction={this.props.isDesktop ? 'left' : 'top'}
															 color={'important'}>

															 <Button type="submit"
																			 label="Einloggen"
																			 additionalClasses="important login_wrapper__submit"
																			 appearance={BUTTON_APPEARANCE.SECONDARY}
																			 isLoading={this.state.isLoading}
																			 disabled={this.state.firstTimeError}
															 />
														 </Tooltip>
													 </>
												 )}/>
					<LinkInternal label='Passwort vergessen'
												linkPath={{pathname: '/forgot', state: {offerToken: storageManager.offerToken}}}
												additionalClasses="login_wrapper__link"
					/>
				</div>
			</>
		)
	}
}

DALoginMask.propTypes = {
	/**
	 * instance of LoginService
	 */
	dataProvider: PropTypes.object.isRequired,
	/**
	 * redirection Url after login
	 */
	redirectTo: PropTypes.oneOfType([
		PropTypes.shape({
			pathname: PropTypes.string
		}),
		PropTypes.string,
	])
};

const mapStateToProps = (state, ownProps) => {
	return {
		isAuthenticated: state.globalStateManagement.isAuthenticated,
	}
};

export default connect(mapStateToProps, {
	dispatchIsAuthenticated: dispatchIsAuthenticated
})(DALoginMask);
