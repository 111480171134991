import React, {Component} from 'react';
import './InfoHeader.scss'
import {Icon, ICON_SIZE} from "../../../icon/Icon";
import {Rating} from "../../../rating/Rating";
import {TravelDetails} from "../travel-details/TravelDetails";

class InfoHeader extends Component {

	render() {

		let showDetailBox = true;

		switch (this.props.type) {
			case 'seat-reservation':
			case 'insurance':
				showDetailBox = false;
				break;

			default:
				showDetailBox = true;
		}

		let detailHeader = (
			<div className={'info-header__location'}><Icon name={'pin'} size={ICON_SIZE.XS}/>
				{this.props.item.location && this.props.item.location.label}
			</div>
		);

		return (
			<div className={'info-header'} key={this.props.item.id}>
				{this.props.item.location && detailHeader}
				<div className={'info-header__label h2 tt-none'}>{this.props.item.label}</div>
				<div className={'info-header__rating'}>
					{this.props.item.rating && <Rating value={this.props.item.rating} hasBackground={true} additionalClasses={'rating__star--color-signal'}/>}
				</div>

				{showDetailBox &&
				<TravelDetails item={this.props.item} isDesktop={this.props.isDesktop}/>
				}
			</div>
		)
	}
}

InfoHeader.defaultProps = {};
InfoHeader.propTypes = {};

export {
	InfoHeader as default,
	InfoHeader
}
