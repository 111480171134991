/**
 * Timing Handling Class
 *
 * @param options {{reportUrl:string}}
 * @constructor
 */
var TimingHandling = function (options) {
	this.options = this.objectMerge([this.defaultOptions, options]);
	this.id = Math.round(Math.random() * 1000000);
};

/**
 * Merge multiple objects together
 *
 * @param objects {Array.<object>}
 * @returns {object}
 */
TimingHandling.prototype.objectMerge = function (objects) {
	var merged = {};
	for (var i = 0; i < objects.length; i++) {
		var object = objects[i];
		for (var key in object) if (object.hasOwnProperty(key)) {
			merged[key] = object[key];
		}
	}
	return merged;
};

TimingHandling.prototype.submitTiming = function (data) {

	// Build
	var req = [];
	req.push("id=" + this.id);

	// Provide any additional params configured
	var addParams = data || {};
	for (var paramName in addParams) if (addParams.hasOwnProperty(paramName)) {
		req.push(paramName + "=" + encodeURIComponent(addParams[paramName]));
	}

	new Image().src = this.options.reportUrl + "?" + req.join("&");
};

export {TimingHandling};
