import React, { Component } from 'react';
import './Tooltip.scss';
import PropTypes from 'prop-types';
import classNames from "classnames";

class Tooltip extends Component {
  /**
   * @param {string} [content]
   * @param {string} [direction='top']
   * @param {string} [color='primary']
   * @param {string} [parentContainerSelector]
   * @param {boolean} [showDefault=false]
   */
  constructor(props) {
    super(props);


    this.tooltipHandler = this.tooltipHandler.bind(this);
    this.tooltipToggleHandler = this.tooltipToggleHandler.bind(this);
    this.repositionTooltipInsideContainer = this.repositionTooltipInsideContainer.bind(this);
    this.containerHandler = this.containerHandler.bind(this);
    this.scrollHandler = this.scrollHandler.bind(this);
    this.oppositeConflictSolution = this.oppositeConflictSolution.bind(this);
    this.getOppositeDirection = this.getOppositeDirection.bind(this);

    this.scrollWrapper = null;
    this.tooltip = null;

    this.state = {
      showDefault: props.showDefault,
      // TODO: per Composition Tooltip und OverflowTooltip trennen
      // TODO: marked for refactoring
      useOverflowHelper: !!props.parentContainerSelector,
      top: null,
      left: null,
      width: null,
      height: null,
      direction: props.direction,
      hideTooltip: false,
    };

    this.tooltipRef = React.createRef();
    this.childWrapperRef = React.createRef();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    // TODO: UPDATE VIEW
    if (prevProps.showDefault !== this.props.showDefault) {
      this.setState({showDefault: this.props.showDefault});
    }
  }

  componentDidMount() {
    document.body.addEventListener('click', this.props.toggle ? this.tooltipToggleHandler : this.tooltipHandler);

    window.addEventListener('resize', this.containerHandler);
    window.addEventListener('scroll', this.scrollHandler);
    window.addEventListener('touchmove', this.containerHandler);

    // add scrollListener if required
    if (this.state.useOverflowHelper) {
      this.scrollWrapper = document.querySelectorAll(this.props.parentContainerSelector)[0] || document.getElementById('root');
      this.scrollWrapper.addEventListener('scroll', this.scrollHandler);
    }
    this.containerHandler();
  }

  componentWillUnmount() {
    document.body.removeEventListener('click', this.props.toggle ? this.tooltipToggleHandler : this.tooltipHandler);

    window.removeEventListener('resize', this.containerHandler);
    window.removeEventListener('scroll', this.scrollHandler);
    window.removeEventListener('touchmove', this.containerHandler);

    if (this.state.useOverflowHelper) {
      this.scrollWrapper.removeEventListener('scroll', this.scrollHandler, false);
    }
  }

  scrollHandler() {
    this.containerHandler();
    this.tooltipHandler();
  }

  /**
   * combining methods for listenerbinding
   */
  containerHandler() {
    this.repositionTooltipInsideContainer();
    this.oppositeConflictSolution();
  }

  /**
   * set default show-state to false
   * the showDefault-Property will only set once. after clicking the body, it will disappear
   */
  tooltipHandler() {
    this.setState({showDefault: false});
  }

  /**
   * toggles the tooltip
   */
  tooltipToggleHandler(){
    if(this.state.showDefault === false){
      this.setState({showDefault: true});
    }else{this.setState({showDefault: false})}
  }

  /**
   * returning the opposite direction as a string
   * @param direction
   */
  getOppositeDirection(direction) {
    const oppositeMap = {
      'top': 'bottom',
      'bottom': 'top',
      'right': 'left',
      'left': 'right'
    };

    return oppositeMap[direction];
  }

  /**
   * detect button position and try to avoid conflicts via opposite direction
   */
  oppositeConflictSolution() {

    const tooltipRect = this.tooltipRef.current.getBoundingClientRect();
    const button = this.childWrapperRef.current.getBoundingClientRect();
    const remValue = 16;

    const directionMap = {};
    directionMap.top = button.top - tooltipRect.height < remValue;
    directionMap.bottom = button.top + button.height + tooltipRect.height + remValue > window.innerHeight;
    directionMap.left = button.left < tooltipRect.width + remValue;
    directionMap.right = button.right + button.width + tooltipRect.width + remValue > window.innerWidth;

    let originDirection = this.props.direction;
    //split direction into array e.g.'bottom-left' into ['bottom','left']
    let directions = originDirection.split('-');
    let checkedDirection = originDirection;
    //iterate through all directions
    for (let key in directionMap) {
      // check if on
      if (directionMap.hasOwnProperty(key) && directionMap[key] && directions.indexOf(key) !== -1) {
        // get opposite of direction which is out of bounds
        let oppositeDirection = this.getOppositeDirection(key);
        //replace direction in array and join together to string
        let directionStringIndex = directions.indexOf(key);
        directions[directionStringIndex] = oppositeDirection;
        checkedDirection = directions.join('-');
        break;
      }
    }
    this.setState({direction: checkedDirection});
  }

  /**
   * find boundingRect of current TooltipContent (e.g.: Button) and set position to the "Portalized" tooltip
   */
  repositionTooltipInsideContainer() {

    if (this.state.useOverflowHelper) {

      const rect = this.childWrapperRef.current.getBoundingClientRect();
      const parentRect = this.scrollWrapper.getBoundingClientRect();

      if (((rect.top - parentRect.top) + rect.height < 0) || ((rect.top - parentRect.top) > parentRect.height)) {
        this.setState({
          hideTooltip: true
        })
      } else {
        this.setState({
          hideTooltip: false
        })
      }

      this.setState({
        top: rect.top,
        left: rect.left,
        width: rect.width,
        height: rect.height,
      });
    }
  }

  render() {

    const classes = classNames(
      'tooltip',
      this.props.color,
      this.state.direction,
      {'inactive': this.props.inactive},
      {'show-default': this.state.showDefault},
      {'overflow-helper': this.state.useOverflowHelper},
      {'hide-tooltip': this.state.hideTooltip},
      {[`tooltip--${this.props.size}}`]: this.props.size},
      this.props.additionalClasses
    );
    const style = {
      top: `${this.state.top}px`,
      left: `${this.state.left}px`,
      width: `${this.state.width}px`,
      height: `${this.state.height}px`,
    };

    return (
      <span className="tooltip-wrapper">
				<span className={'tooltip-wrapper__children'} ref={this.childWrapperRef}>
					{this.props.children}
				</span>
				<div className={classes} style={style}>
					<div className="tooltip-content round-border" ref={this.tooltipRef}>
						<div className="nested-components">
							<span>
								{this.props.content}
							</span>
						</div>
					</div>
				</div>
			</span>
    )
  }
}

Tooltip.propTypes = {
  /**
   * defines one of three specific colors
   */
  color: PropTypes.oneOf(['primary', 'secondary', 'important']),

  /**
   * currently there is only on size ('large') defined.
   */
  size: PropTypes.oneOf(['large']),
  /**
   * text or content which should be desplayed in Tooltip
   */
  content: PropTypes.oneOfType(
    [PropTypes.string, PropTypes.element]
  ),
  /**
   * set true to ignore mouse over of children
   */
  inactive: PropTypes.bool,
  /**
   * makes the Tooltip visible from the beginning
   */
  showDefault: PropTypes.bool,

  /**
   * makes the Tooltip able to be toggle
   */
  toggle: PropTypes.bool,
  /**
   * opens the Tooltip into a certain direction. If tooltip can't be opened into the given direction it opens into the opposite direction.
   * e.g. the direction is top-left, but its already on the left edge of the screen, it would change its-direction to top-right
   */
  direction: PropTypes.oneOf(['top', 'top-right', 'top-left', 'bottom', 'bottom-right', 'bottom-left', 'right', 'left']),

  /**
   * when set tooltip position is fixed that it can be displayed inside of an overflow hidden container. Needs the selector of the wrapping container
   */
  parentContainerSelector: PropTypes.string,

  /**
   * add additional classes for this component here.
   * Use this prop for special css-classes, which are not defined by default.
   */
  additionalClasses: PropTypes.string,
};

Tooltip.defaultProps = {
  direction: 'left',
  color: 'primary',
  showDefault: false,
  inactive: false,
  toggle: false,

};

export {
  Tooltip,
}
