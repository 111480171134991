import React, {Component} from 'react';
import './ForgotPasswordMask.scss';
import {Input} from "../input/Input";
import {Button, BUTTON_APPEARANCE} from "../button/Button";
import {FormValidator} from "../form/FormValidator";
import {Tooltip} from "../tooltip/Tooltip";
import PropTypes from 'prop-types';
import {LoginMask} from "./LoginMask";
import {formConfigMap, formErrorMessages} from "./FormUtils";
import Link from "../link/Link";
import {trackSubmitAttempt} from "../../../vendor/utils/TrackingManager";

class ForgotPasswordMask extends Component {

	constructor(props) {
		super(props);

		this.state = {
			firstTimeError: false,
			errorMessage: null,
			resetRequested: false,
			isLoading: false,
			emailSend:null,
		};
		this.handleSubmit = this.handleSubmit.bind(this);
		this.dataProvider = this.props.dataProvider;
		this.forgotHeadline = "Passwort vergessen?";
	}

	/**
	 * submit handler of Form
	 * send Form and set state depending on response
	 * if form was send for the first time then errors will be shown. not before
	 * @param form
	 * @param isValid
	 */
	handleSubmit({form, isValid}) {

		this.setState({
			isLoading: true,
		});

		if (isValid) {

			this.dataProvider.setData(form).then(response => {

				if (response.success === 1) {
					this.setState({
						resetRequested: true,
						errorMessage: null,
						isLoading: false,
						emailSend: form.get('email'),
					})
					trackSubmitAttempt( 'forgot_password', true)


				} else if (response.err) {
					this.setState({
						resetRequested: false,
						errorMessage: response.err,
						isLoading: false
					})
					trackSubmitAttempt( 'forgot_password', false, response.err)
				} else {
					this.setState({
						resetRequested: false,
						errorMessage: formErrorMessages['unknownError'],
						isLoading: false,
					})
					trackSubmitAttempt('forgot_password', false, formErrorMessages['unknownError']);
				}
			});
		} else {
			this.setState(
				{
					firstTimeError: true,
					isLoading: false,
				});
		}
	}

	render() {
		if (this.state.resetRequested) {
			return (
				<div className={"login_wrapper__success-mask-content"}>
					<h3 className='login_wrapper__success-headline'>E-Mail erfolgreich versendet!</h3>
					<p>Innerhalb kürzester Zeit sollten Sie eine E-Mail an <b>{this.state.emailSend}</b> von uns
						erhalten, sofern diese E-Mail-Adresse bei uns registriert ist. Bitte vergessen Sie nicht auch
						in Ihren Werbungsordner zu schauen, ob unsere E-Mail ggf. hier verloren gegangen ist.</p>
					<br/>
					<p>Haben Sie Ihre E-Mail-Adresse falsch angegeben? <Link withoutAnchor={true} label={'E-Mail erneut angeben.'} onClick={()=>this.setState({resetRequested:false})}/>
					</p>
				</div>
			)
		}

		return (
			<>
				<div className={"login_wrapper__mask-content"}>
					<h2 className='login_wrapper__headline'>{this.forgotHeadline}</h2>
					<FormValidator additionalClasses="login_wrapper__form"
								   onSubmit={this.handleSubmit}
								   render={({changeHandler, formIsValid}) => (
									   <>
										   <div className="login_wrapper__inputs">

											   <Input name="email"
													  label="E-Mail"
													  type="email"
													  placeholder={formConfigMap['forgotMask'].email.placeholder}
													  required={true}
													  onChange={changeHandler}
													  showError={this.state.firstTimeError && !formIsValid}
													  fullWidth={true}
													  tooltipDirection={this.props.isDesktop ? 'left' : 'top'}
													  initialValue={formConfigMap['forgotMask'].email.initialValue}
													  errorMessage={formConfigMap['forgotMask'].email.toolTipText}
													  validator={formConfigMap['forgotMask'].email.validationPattern}
											   />
												 {this.props.offerToken &&
													 <Input name="offer"
															type="hidden"
															label=""
															initialValue={this.props.offerToken}
												 />}
										   </div>
										   <Tooltip
											   content={this.state.errorMessage}
											   showDefault={false}
											   inactive={!this.state.errorMessage}
											   direction={this.props.isDesktop ? 'left' : 'top'}
											   color={'important'}>
											   <Button type="submit"
													   label="E-Mail abschicken"
													   additionalClasses="important login_wrapper__submit"
														 appearance={BUTTON_APPEARANCE.SECONDARY}
													   isLoading={this.state.isLoading}
													   disabled={this.state.firstTimeError && !formIsValid }/>
										   </Tooltip>
									   </>
								   )}/>
				</div>
				<div className={'login_wrapper__divider dotted'}/>
				<div className='login_wrapper__text'>
					<div className='h2 login_wrapper__headline login_wrapper__headline--visible-on-desktop'>
						{this.forgotHeadline}
					</div>
					Bitte geben Sie Ihre E-Mail-Adresse ein, mit der Sie bei uns registriert sind.
					Innerhalb kürzester Zeit erhalten Sie dann eine E-Mail mit Informationen zum weiteren Vorgehen.
				</div>
			</>
		)
	}
}

LoginMask.propTypes = {
	/**
	 * instance of LoginService
	 */
	dataProvider: PropTypes.object
};

export {
	ForgotPasswordMask
}
