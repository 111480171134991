import React from 'react';
import './BookingBox.scss';
import classNames from "classnames";
import TotalPrice from "../totalPrice/TotalPrice";

function BookingBox(props) {

	const classes = classNames(
		'booking-box perforation-top',
		props.additionalClasses
	);

	/**
	 * wrap children for better alignment if tooltip or anchoris used
	 * @returns {*}
	 */
	function wrapChildren() {
		return props.children.map((child, index) => {
			return <div className={'booking-box__wrapper'} key={'booking-box-'+index}>{child}</div>
		})
	}

	return (
		<>
			<TotalPrice label={'Gesamtpreis'}
						sublabel={'(für alle Reiseteilnehmer)'}
						price={props.totalPrice}
						optionalPrice={props.optionalPrice}
			/>

			<div className={classes}>
				{wrapChildren()}
			</div>

		</>
	)

}

export {
	BookingBox as default,
	BookingBox
}
