export const IS_DESKTOP = 'IS_DESKTOP';
export const OFFER_NUMBER = 'OFFER_NUMBER';
export const REVISION_NUMBER = 'REVISION_NUMBER';
export const OFFER_LIST = 'OFFER_LIST';
export const STAGE_IMAGE = 'STAGE_IMAGE';
export const JOURNEY_DATES = 'JOURNEY_DATES';
export const IS_BOOKED = 'IS_BOOKED';
export const STORE_CLEAR = 'STORE_CLEAR';
export const RESET_STORE = 'RESET_STORE';
export const IS_AUTHENTICATED = 'IS_AUTHENTICATED';
export const PERMISSIONS = 'PERMISSIONS';
export const IS_MAIN_CLIENT = 'IS_MAIN_CLIENT';
export const IS_AGENCY_OFFER = 'IS_AGENCY_OFFER';
export const ENVIRONMENT = 'ENVIRONMENT';
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';
export const NOTIFICATION_MESSAGE_SHOW = 'NOTIFICATION_MESSAGE_SHOW';
export const NOTIFICATION_MESSAGE_HIDDEN = 'NOTIFICATION_MESSAGE_HIDDEN';
export const CONSENT = 'CONSENT';

export const dispatchClearStore = () => ({
	type: STORE_CLEAR
});

export const dispatchResetStore = () => ({
	type: RESET_STORE
});

export const dispatchIsDesktop = viewport => ({
	type: IS_DESKTOP,
	isDesktop: viewport
});

export const dispatchUpdateOffer = offer => ({
	type: OFFER_NUMBER,
	offer
});

export const dispatchRevisionChange = revision => ({
	type: REVISION_NUMBER,
	revision
});

export const dispatchUpdateOfferList = offerList => ({
	type: OFFER_LIST,
	offerList
});

export const dispatchUpdateStageImage = stageImage => ({
	type: STAGE_IMAGE,
	stageImage
});

export const dispatchUpdateJourneyDates = (journeyStart, journeyEnd) => ({
	type: JOURNEY_DATES,
	journeyStart,
	journeyEnd
});

export const dispatchIsBooked = (isBooked) => ({
	type: IS_BOOKED,
	isBooked,
});

export const dispatchIsAuthenticated = isAuthenticated => ({
	type: IS_AUTHENTICATED,
	isAuthenticated
});

export const dispatchPermissions = permissions => ({
	type: PERMISSIONS,
	permissions
});

export const dispatchIsMainClient = isMainClient => ({
	type: IS_MAIN_CLIENT,
	isMainClient
});

export const dispatchIsAgencyOffer = isAgencyOffer => ({
	type: IS_AGENCY_OFFER,
	isAgencyOffer
});

export const dispatchEnvironment = environment => ({
	type: ENVIRONMENT,
	environment
});

export const dispatchOpenModal = modalId => ({
	type: OPEN_MODAL,
	modalId
});

export const dispatchCloseModal = () => ({
	type: CLOSE_MODAL
});


export const dispatchShowNotificationMessage = notificationMessage => ({
	type: NOTIFICATION_MESSAGE_SHOW,
	notificationMessage
});

export const dispatchHiddenNotificationMessage = () => ({
	type: NOTIFICATION_MESSAGE_HIDDEN
});

export const dispatchConsent = (consent) => ({
	type: CONSENT,
	consent,
})

