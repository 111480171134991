import React from 'react';
import './Teaser.scss';
import {Image} from "../../components/image/Image";
import {ButtonInternal} from "../button/ButtonInternal";
import {connect} from "react-redux";
import {BUTTON_APPEARANCE} from "../button/Button";

function Teaser(props) {
  const title = 'Ideen und Empfehlungen für Ihre Reise';
  const text = 'Wir haben für Sie eine Auswahl an sehenswerten und interessanten Ausflügen zusammengestellt.';

  return (
    <>
      <div className={'teaser'}>
        <div className={'teaser__wrapper'}>
          <div className={'teaser__content'}>
            <div className={'h3 ts-handwriting'}>{title}</div>
            <p className={'teaser__content-text'}>{text}</p>
            <ButtonInternal uid='optionals'
                            linkPath='ausfluege'
                            label='vorschläge anschauen'
                            appearance={BUTTON_APPEARANCE.SECONDARY}
                            width={'fit'}
                            additionalClasses={'teaser__button'}
            />
          </div>
          <div className={'teaser__delimiter'}>
            <div className={'teaser__delimiter-dots'}/>
          </div>
          <div className={'teaser__image-wrapper'}>
            <Image
              url={"//images.canusa.de/img/regionen/kanada/alberta/waterton-lakes-national-park/westkanada-alberta-waterton-lakes-nationalpark.png"}
              additionalClasses={'teaser__image-content'}
              ratio={props.isDesktop ? null : {width: 16, height: 9}}
              sizes={{
                ultraWideDesktop: '600px',
                wideDesktop: '27vw',
                desktop: '25vw',
                tablet: '40vw',
                default: '100vw'
              }}/>
          </div>
        </div>
        <div className='teaser__subcard'></div>
        <div className='teaser__subcard teaser__subcard-two'></div>
      </div>
    </>
  )

}

const mapStateToProps = (state, ownProps) => {
  return {isDesktop: state.globalStateManagement.isDesktop}
};

export default connect(mapStateToProps)(Teaser)

