import {STORE_CLEAR} from "../actions/globalStateManagementAction";
import {
    BOOKING_ERROR,
    BOOKING_SUCCESS,
    CHECKOUT_BACKWARD,
    CHECKOUT_FORWARD, CHECKOUT_GO_TO_INDEX, CHECKOUT_RESET, IS_BOOKING, IS_SAVING,
} from "../actions/checkoutManagementAction";
import {CHECKOUT_BUTTON_CONFIG} from "../../components/checkout/CheckoutNavigation";


const initialState = {
    checkoutState: 1, isSaving: false, bookingSuccess: false, bookingIsLoading: false,
    bookingError: null
};

export const checkoutStateManagementReducer = (state = initialState, action) => {
    switch (action.type) {
        case CHECKOUT_BACKWARD:
            return {
                ...state,
                checkoutState: Math.max(state.checkoutState - 1, 1),
                bookingSuccess: false,
                bookingIsLoading: false,
                bookingError: null,
            };
        case CHECKOUT_FORWARD:
            return {
                ...state,
                checkoutState: Math.min(state.checkoutState + 1, Object.keys(CHECKOUT_BUTTON_CONFIG).length),
            };
        case CHECKOUT_GO_TO_INDEX:
            return {
                ...state,
                checkoutState: action.index
            };
        case CHECKOUT_RESET:
            return initialState;

        case IS_BOOKING: {
            return {
                ...state,
                bookingIsLoading: true,
            }
        }

        case  IS_SAVING: {
            return {
                ...state,
                isSaving: action.isSaving,
            }
        }

        case  BOOKING_SUCCESS: {
            return {
                ...state,
                bookingSuccess: true,
                bookingIsLoading: false,
            }
        }

        case  BOOKING_ERROR: {
            return {
                ...state,
                bookingIsLoading: false,
                bookingError: action.bookingError,
            }
        }

        case STORE_CLEAR:
            return {
                ...initialState
            };

        default:
            return state;
    }
};
