import React, {Component} from 'react';
import './ItemHeaderOffer.scss'
import PropTypes from "prop-types";
import classNames from "classnames";
import {
	renderCollapsible,
	renderDate,
	renderDuration,
	renderLocation,
	renderHorizontalLine,
	renderNoteIcon,
	renderRating,
	renderTitle,
	renderTitleConfigVO,
	renderTypeIcon
} from "../ItemHeaderUtils";
import AlternativeTeaserList from "../../../alternatives/AlternativeTeaserList";
import Badge from "../../../badge/Badge";

class ItemHeaderOffer extends Component {

	constructor(props) {
		super(props);

		this.state = {
			showSelectModal: false,
		}
	}

	renderAlternativeTeaserList(alternativeTeaser, isDesktop, sliderId) {
		if (alternativeTeaser && alternativeTeaser.length > 0 && !isDesktop) {
			return <AlternativeTeaserList alternativeTeaser={alternativeTeaser}
																		sliderId={sliderId}/>
		}
		return null;
	}


	renderItemHeader() {

		const configVO = new renderTitleConfigVO();
		configVO.label = this.props.item.label;
		configVO.originLabel = this.props.item.originLabel;
		configVO.location = this.props.item.location;
		configVO.rating = this.props.item.rating;
		configVO.type = this.props.item.type;
		const exclusiveBadge = <Badge label={'Exklusiv bei CANUSA'} type={'important'} additionalClasses={'item-header__extra-badge'}/>;

		const classes = classNames(
			'item-header__grid'
		);

		if (this.props.item.date) {
			return (
				<>
					{renderTypeIcon(this.props.item.type)}
					<div className={classes}>
						<label htmlFor={this.props.collapsibleId} className={'item-header__clickarea'}></label>
						<div className={'item-header__top'}>

							<div className={'item-header__top-left'}>
								{renderDate(this.props.item.date)}
								{this.props.isDesktop && renderDuration(this.props.item.duration)}
								{this.props.isDesktop && renderLocation(configVO)}
							</div>

							<div className={'item-header__top-right'}>
								{(!this.props.isDesktop && this.props.alternatives?.length>1) && <Badge additionalClasses={'item-header__badge'} label={`+${this.props.alternatives.length-1} Alternativen`} type={'signal'}/>}
								{this.props.item.showNoticeIcon && renderNoteIcon(this.props.groupIndex, this.props.item.id, !!this.props.item.notice, 'item-header__notice')}
								{this.props.isDesktop && renderCollapsible(this.props.isDesktop, this.props.isCollapsible, this.props.collapsibleId)}
							</div>

						</div>

						{renderHorizontalLine()}
						<div className={'item-header__middle'}>
							<div className={'item-header__middle-left'}>
								{renderTitle(configVO)}
								{renderRating(this.props.item.rating)}
								{this.renderAlternativeTeaserList(this.props.alternatives, this.props.isDesktop, this.props.item.id)}
							</div>
						</div>

						{!this.props.isDesktop &&
						<div className={'item-header__bottom'}>
							<div className={'item-header__bottom-left'}>
								{renderDuration(this.props.item.duration)}
								{renderLocation(configVO)}
							</div>

							<div className={'item-header_bottom-right'}>
								{renderCollapsible(this.props.isDesktop, this.props.isCollapsible, this.props.collapsibleId)}
							</div>
						</div>}
					</div>
				</>
			);
		} else {


			return (
				<>
					{renderTypeIcon(this.props.item.type)}
					<div className={classes}>
						<label htmlFor={this.props.collapsibleId} className={'item-header__clickarea'}></label>
						<div className={'item-header__middle'}>
							<div className={'item-header__middle-left'}>
								{renderTitle(configVO)}
							</div>
							<div className={'item-header__middle-right'}>
								{(this.props.isDesktop && configVO.label?.match(/exklusiv/i)) && exclusiveBadge}
								{this.props.item.showNoticeIcon && renderNoteIcon(this.props.groupIndex, this.props.item.id, this.props.item.notice)}
								{this.props.isDesktop && renderCollapsible(this.props.isDesktop, this.props.isCollapsible, this.props.collapsibleId)}
							</div>
						</div>

						{(!this.props.isDesktop && this.props.isCollapsible) &&
						<div className={'item-header__bottom'}>
							<div className={'item-header__bottom-left'}>
								{(!this.props.isDesktop && configVO.label?.match(/exklusiv/i)) && exclusiveBadge}
							</div>
							<div className={'item-header_bottom-right'}>
								{!this.props.isDesktop && renderCollapsible(this.props.isDesktop, this.props.isCollapsible, this.props.collapsibleId)}
							</div>
						</div>
						}
					</div>
				</>
			)
		}
	}

	render() {
		return (
			this.renderItemHeader()
		);
	}
}

ItemHeaderOffer.defaultProps = {
	isCollapsible: false,
};

ItemHeaderOffer.propTypes = {

	/**
	 * OPTIONAL: add additional classes for this component here.
	 * Use this prop for special css-classes, which are not defined by default.*
	 */
	additionalClasses: PropTypes.string,

	/**
	 * defines if header has collapsible funtionality
	 *
	 * @default: false
	 */
	isCollapsible: PropTypes.bool,


	/**
	 * id of parent collapsible. use for toggle the collapsible for nested elements
	 *
	 */
	collapsibleId: PropTypes.string.isRequired,
	/**
	 *
	 * see **Item** for further information
	 */
	item: PropTypes.shape({
		/**
		 * template of ItemHeader
		 * @example: 'main'
		 *
		 */
		contentType: PropTypes.oneOf(['main', 'extra', 'optional']),
		/**
		 * label used in ItemHeader
		 * @example: 'Mariner King Inn'
		 */
		label: PropTypes.string,

		alternativeBadge: PropTypes.shape(
			{
				level: PropTypes.oneOf(['signal', 'default']),
				label: PropTypes.string
			}
		),

		/**
		 * location.label used in ItemHeader
		 * @example: {label:'Lighthouse Trail, NS', lat:34.2618964, lng:-116.9194283}
		 */
		location: PropTypes.shape(
			{
				/**
				 * Location label
				 * @example: 'Lighthouse Trail, NS'
				 */
				label: PropTypes.string,

				/**
				 * Latitude of location
				 * @example: 34.2618964
				 */
				lat: PropTypes.number,

				/**
				 * Longitude of location
				 * @example: -116.9194283
				 */
				lng: PropTypes.number,
			}
		),

		/**
		 * OPTIONAL: an array with options inside
		 *
		 * @example:
		 *       [
		 *        {date: "2019-08-29"
		 *			times: [
		 *				{price: 118, time: "14:00-15:30"}
		 *				{price: 118, time: "15:00-16:30"}
		 * 				{price: 118, time: "16:30-18:00"}
		 *				{price: 118, time: "20:30-22:00"}]},
		 *            {date: "2019-08-30"
		 *			times: [
		 *				{price: 118, time: "14:00-15:30"}
		 *				{price: 118, time: "15:00-16:30"}
		 *				{price: 118, time: "16:30-18:00"}
		 *				{price: 118, time: "20:30-22:00"}]}
		 *        ]
		 *
		 */
		options: PropTypes.array,
	})
};

export {
	ItemHeaderOffer as default,
	ItemHeaderOffer
}
