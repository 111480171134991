import React, {Component} from 'react';
import './ErrorPage.scss'
import withDataProvider from "../../hoc/WithDataprovider";
import Stage from "../../components/stage/Stage";
import Footer from "../../components/footer/Footer";
import {Header} from "../../components/header/Header";
import {ErrorMessage} from "../../components/error/ErrorMessage"
import classNames from "classnames";
import {isDevEnvironment} from "../../../vendor/utils/Utils";
import DebugBar, {DEBUG} from "../../debug/DebugBar";
import {trackPageView} from "../../../vendor/utils/TrackingManager";

const StageWithDataProvider = withDataProvider(Stage, {useMock: false, endpoint: 'portal/stage', value: {topic: 'not_found'}});

class ErrorPage extends Component {

	componentDidMount() {
		document.title = 'CANUSA Kundenportal - 404';
		const trackingObj = {
			tabId:'404', // generic
		};

		trackPageView(trackingObj);
	}


	render() {

		const classes = classNames(
			'error-page',
			'page-full-height'
		);
		const showDebugBar = isDevEnvironment() && process.env.REACT_APP_GLOBAL_DEBUG === "true";
		return (
			<div className={classes}>
				{showDebugBar && <DebugBar show={[DEBUG.TRACKING]}/>}
				<Header />

				<StageWithDataProvider
					withContent={false}
					join={true}
				/>

				<ErrorMessage additionalClasses={'centered-content error-page__message'} type={'404'}/>

				<Footer/>
			</div>
		);
	}
}

export {
	ErrorPage as default,
	ErrorPage
}
