import React, {Component} from 'react';
import {Image} from "../image/Image";
import {Rating} from "../rating/Rating";
import {Radiobutton} from "../radiobutton/Radiobutton";
import {currencyFormat} from "../../../vendor/utils/Utils";
import classNames from "classnames";
import PropTypes from "prop-types";
import './AlternativesTeaserItem.scss'
import {getSelectedAddonPrices} from "../../redux/selectors";
import {IMAGE_DB_PREFIX} from "../image/ImageDecoder";

class AlternativeTeaserItem extends Component {

	constructor(props) {
		super(props);
		this.activeItemClickHandler = this.activeItemClickHandler.bind(this);
	}

	/**
	 * format price with prefixes
	 * @param price
	 * @returns {string}
	 */
	formatPriceDifference(price) {
		if (price < 0) {
			return '- ' + currencyFormat(price * -1);
		} else {
			return '+ ' + currencyFormat(price);
		}
	}

	/**
	 * if AlternativeItem is clicked
	 * -> orange border appears
	 * @param event
	 */
	activeItemClickHandler(event) {
		if (this.props.onClick) {
			this.props.onClick(this.props.item.id);
		}
	}

	calculatePrice(item) {
		let price = item.priceDiff || 0;
		price += getSelectedAddonPrices(item);

		if(price === 0) {
			return null;
		}

		return  price;
	}

	/**
	 * render teaser depending on appearance
	 * @returns {*}
	 */
	renderTeaserContent() {

		// TODO: check why imageUrls are not well formatted
		const imageUrl = (this.props.item.teaserImage.indexOf(IMAGE_DB_PREFIX) !== -1) ? this.props.item.teaserImage : IMAGE_DB_PREFIX + this.props.item.teaserImage + '.jpg';
		const calculatedPrice = this.calculatePrice(this.props.item)

		if (this.props.appearance === 'minimal') {
			const classes = classNames(
				'alternative-teaser--minimal',
				{
					'alternative-teaser--active': !!this.props.isActive,
				}
			);
			// single/minimal teaser for alternativeTeaserList
			return (
				<div className={classes} key={this.props.item.id} onClick={this.activeItemClickHandler}>
					<div className={'alternative-teaser__item'}>
						<div className={'alternative-teaser-image__image-wrapper'}>
							<Image url={imageUrl}/>
						</div>
						<Radiobutton name={'fixed-' + this.props.item.id}
												 additionalClasses={'alternative-teaser__radiobutton'}
												 uid={this.props.item.id}
												 value={this.props.item.label}
												 appearance={'white'}
												 changeHandler={()=>this.props.onSelect(this.props.item.id)}
												 checked={this.props.isActive}
						/>
					</div>
				</div>
			);

		} else {

			const classes = classNames(
				{
					'alternative-teaser--active': !!this.props.isSelected,
					'alternative-teaser--expanded': this.props.isExpanded,
					'alternative-teaser--default': !this.props.isExpanded,
				}
			);

			return (
				<div className={classes} key={this.props.item.id} onClick={this.activeItemClickHandler}>
					<div className={'alternative-teaser__item'}>
						<div className={'alternative-teaser__image-wrapper'}>
							<Image url={imageUrl}/>
						</div>

						<div className={'alternative-teaser__label-wrapper'}>
							{this.props.item.rating &&
							<div className={'alternative-teaser__rating'}>
								<Rating value={this.props.item.rating}
												hasBackground={true}
												additionalClasses={'alternative-teaser__rating-star--color-signal'}
								/>
							</div>
							}
							<div className={'alternative-teaser__label ts-bold'}>
								{this.props.item.label}
							</div>
						</div>

						<div className={'alternative-teaser__selector-wrapper'}>

							<Radiobutton name={'checkedGroup-' + this.props.groupId}
													 additionalClasses={'alternative-teaser__radiobutton'}
													 uid={this.props.item.id}
													 value={this.props.item.label}
													 appearance={this.props.isExpanded ? 'white' : 'signal'}
													 changeHandler={()=>this.props.onSelect(this.props.item.id)}
													 checked={this.props.isActive}
													 disabled={this.props.disabled}
							/>
							{calculatedPrice &&
							<div className={'alternative-teaser__price ts-bold'}>
								{this.formatPriceDifference(calculatedPrice)}
							</div>
							}
						</div>
					</div>
				</div>
			);
		}
	}

	render() {
		return (
			this.renderTeaserContent()
		);
	}
}

AlternativeTeaserItem.defaultProps = {
	appearance: 'default'
};

AlternativeTeaserItem.propTypes = {
	appearance: PropTypes.oneOf(['minimal', 'default']),
	/**
	 * id of the parent group
	 */
	groupId: PropTypes.string,
	/**
	 * is Item active
	 */
	isActive: PropTypes.bool,
	/**
	 * is Item expanded
	 */
	isExpanded: PropTypes.bool,

	disabled: PropTypes.bool,

	/**
	 * values of the alternative item
	 */
	item: PropTypes.shape({
			id: PropTypes.string,
			label: PropTypes.string,
			teaserImage: PropTypes.string,
			priceDiff: PropTypes.number
		}
	),
};

export {
	AlternativeTeaserItem as default,
	AlternativeTeaserItem
}
