/**
 * ##############################################################
 * reformat responbse to legacy-format START
 * ##############################################################
 */
import {mapTypeToIcon} from "../../../vendor/utils/Mappings";

/**
 * turn the old dataModel into the current one
 * @param data
 * @returns {any}
 */
const reformatFromLegacyDataModel = (data) => {

	let jsonString = JSON.stringify(data);
	// renaming the params:
	jsonString = jsonString.replace(/leistung_id/g, "itemId");
	jsonString = jsonString.replace(/"lon"/g, '"lng"');

	let newData = JSON.parse(jsonString);

	Object.keys(newData.path).map((key) => {
		let item = newData.path[key];
		item.typ = getPathTypeFromMap(item.typ).type;// LEGACY_PATH_TYPE_MAP[item.typ] ? LEGACY_PATH_TYPE_MAP[item.typ].type : item.typ;
		return null;
	});
	return newData;
};


const getPathTypeFromMap = (typ) => {
	const defaultType = {
		"type": "rental-car",
		"strokeType": "driving",
		"legendName": "Mietwagen",
		"dashed": false,
	}

	return LEGACY_PATH_TYPE_MAP[typ] || defaultType;
}


/**
 * turn the old dataModel into the current one
 * @type {{"F&auml;hre": {dashed: boolean, strokeType: string, icon: string, type: string, legendName: string}, Mietwagen: {dashed: boolean, strokeType: string, icon: string, type: string, legendName: string}, Standard: {dashed: boolean, icon: string, type: string, legendName: string}, Kreuzfahrt: {dashed: boolean, strokeType: string, icon: string, type: string, legendName: string}, Rundflug: {dashed: boolean, icon: string, type: string, legendName: string}, Wohnmobil: {dashed: boolean, strokeType: string, icon: string, type: string, legendName: string}, Busreise: {dashed: boolean, strokeType: string, icon: string, type: string, legendName: string}, FlugHawaii: {dashed: boolean, strokeType: string, icon: string, type: string, legendName: string}, Motorrad: {dashed: boolean, strokeType: string, icon: string, type: string, legendName: string}, Bahnfahrt: {dashed: boolean, strokeType: string, icon: string, type: string, legendName: string}, Ausflug: {dashed: boolean, strokeType: string, icon: string, type: string, legendName: string}, Transfer: {dashed: boolean, strokeType: string, icon: string, type: string, legendName: string}, Flug: {dashed: boolean, strokeType: string, icon: string, type: string, legendName: string}, Fähre: {dashed: boolean, strokeType: string, icon: string, type: string, legendName: string}, Hotel: {dashed: boolean, icon: string, type: string, legendName: string}}}
 */
const LEGACY_PATH_TYPE_MAP = {
	"Ausflug":// "excursion"
		{
			"type": "excursion",
			"strokeType": "excursion",
			"legendName": "Ausflug",
			"dashed": true,
		}
	,
	"Bahnfahrt": // "rail_journey"
		{
			"type": "ride",
			"strokeType": "railway",
			"legendName": "Bahnfahrt",
			"dashed": true,
		}
	,
	"Busreise": //  "Busreise":
		{
			"type": "bustrip",
			"strokeType": "transfer",
			"legendName": "Busreise",
			"dashed": true,
		}
	,
	"F&auml;hre": // "ferry":// "F&auml;hre":
		{
			"type": "ferry",
			"strokeType": "sailing",
			"legendName": "Fähre",
			"dashed": true,
		}
	,
	"Fähre": // "ferry":// "F&auml;hre":
		{
			"type": "ferry",
			"strokeType": "sailing",
			"legendName": "Fähre",
			"dashed": true,
		}
	,
	"Flug": // "flight":// "Flug":
		{
			"type": "flight",
			"strokeType": "flying",
			"legendName": "Flug",
			"dashed": false,
		}
	,
	"Hotel": // "hotel":// "Hotel":
		{
			"type": "hotel",
			"legendName": "Hotel",
			"dashed": false,
		}
	,
	"Kreuzfahrt": // "cruise":// "Kreuzfahrt":
		{
			"type": "cruise",
			"strokeType": "sailing",
			"legendName": "Transfer",
			"dashed": true,
		}
	,
	"Mietwagen":// "rental-car":// "Mietwagen":
		{
			"type": "rental-car",
			"strokeType": "driving",
			"legendName": "Mietwagen",
			"dashed": false,
		}
	,
	"Motorrad": // "motorcycle":// "Motorrad":
		{
			"type": "motorcycle",
			"strokeType": "driving",
			"legendName": "Motorrad",
			"dashed": false,
		}
	,
	"Rundflug": // "sightseeing_flight":// "Rundflug":
		{
			"type": "sightseeing-flight",
			"legendName": "Rundflug",
			"dashed": false,
		}
	,
	"Transfer": // "transfer":// "Transfer":
		{
			"type": "transfer",
			"strokeType": "transfer",
			"legendName": "Transfer",
			"dashed": false,
		}
	,
	"Wohnmobil": // "camper":// "Wohnmobil":
		{
			"type": "camper",
			"strokeType": "driving",
			"legendName": "Route",
			"dashed": false,
		}
	,
	"Standard": // "standard":// "Standard":
		{
			"type": "standard",
			"legendName": "Standard",
			"dashed": false,
		}
};

/**
 * ##############################################################
 * reformat response to legacy-format END
 * ##############################################################
 */

/**
 * get markerMap
 * @param offerMapObj
 * @returns {*}
 */
const getMarkerMap = (markers) => {

	let markerMap = {};

	markers.map((item, index) => {

		const itemObj = {
			origin: latLngConverter([item.lat, item.lng]),
			icon: mapTypeToIcon(item.bubbleIcon),
			title: item.title,
			type: 'marker',
		};

		if (!markerMap[item.itemId]) {
			markerMap[item.itemId] = [];
		}

		markerMap[item.itemId].push(itemObj);

		return null;
	});

	return calculateMarkerBoundaries(markerMap);
};

/**
 * get pathMap
 * @param offerMapObj
 * @returns {*}
 */
const getPathMap = (pathList) => {

	let pathMap = {};

	for (let key in pathList) {
		const path = pathList[key].geos.map((flights) => {
			return flights.map((item) => {
				return latLngConverter(item)
			})
		});

		pathMap[key] = {
			positionList: path,
			type: pathList[key].typ,
		}
	}
	return createWayPointsList(pathMap);

};

/**
 * add waypoints to pathMap
 * @param mapObj
 * @returns {*}
 */
const createWayPointsList = (mapObj) => {

	Object.keys(mapObj).map((key, index) => {
		// copying the Array
		let list = [].concat.apply([], mapObj[key].positionList);
		let lastItem = null;
		const tempList = []
		list.forEach((item)=>{
			if (JSON.stringify(item) !== JSON.stringify(lastItem)) {
				lastItem = item;
				tempList.push(item);
			}
		})
		list = tempList
		// pop & shift origin/destination & waypoints
		let origin = list.shift();
		let destination = list.pop();
		const waypoints = waypointFormatter(list);

		mapObj[key].origin = origin;
		mapObj[key].destination = destination;
		if (waypoints.length !== 0) {
			mapObj[key].waypoints = waypoints;
		} else {
			mapObj[key].waypoints = [];
		}
		return null;
	});
	return mapObj;
};

/**
 * get markerboundaries
 * add all markerbounds that belong to the same group to boundsList OR add the last and the next markerBounds to boundsList
 *
 * @param markerMap
 * @returns {*}
 */
const calculateMarkerBoundaries = (markerMap) => {

	let markerKeyList = Object.keys(markerMap);
	let lastGroupId = null;
	let groupId = null;

	markerKeyList.map((key, index) => {

		// get boundaries of all item in the same group
		if (markerMap[key].length > 1) {

			// add all latLngs from the same group into boundaries
			markerMap[key].boundaries = markerMap[key].map((marker) => {
				return marker.origin
			})

		} else {

			groupId = key.split('-')[0];
			let itemList = [];
			// add the previous and next (if available) latLng into boundaries
			if (groupId !== lastGroupId) {

				markerKeyList[index] && itemList.push(markerMap[markerKeyList[index]][0].origin);
				markerKeyList[index + 1] && itemList.push(markerMap[markerKeyList[index + 1]][0].origin);
				lastGroupId = groupId;

			} else {

				markerKeyList[index - 1] && itemList.push(markerMap[markerKeyList[index - 1]][0].origin);
				markerKeyList[index] && itemList.push(markerMap[markerKeyList[index]][0].origin);
				markerKeyList[index + 1] && itemList.push(markerMap[markerKeyList[index + 1]][0].origin);

			}

			markerMap[key].boundaries = itemList
		}

		return null;
	});

	return markerMap;
};

/**
 * converts an array of two lat&lng values to a waypoint object
 * @param list
 * @returns {*}
 */
const waypointFormatter = (list) => {

	return list.map((item) => {
		return {location: item, stopover: true}
	})

};

/**
 * converts an array of two lat&lng values to a readable object
 * @param arrayOfStrings
 * @returns {{lng: number, lat: number}}
 */
const latLngConverter = (arrayOfStrings) => {
	return {lat: parseFloat(arrayOfStrings[0]), lng: parseFloat(arrayOfStrings[1])}
};

/**
 * @param points {array}
 * @param lineType {string}
 */
const polylineConfigFactory = (paths, lineType) => {

	const arrowSymbol = {
		path: 1,//window.google.maps.SymbolPath.FORWARD_CLOSED_ARROW,
		strokeOpacity: 1,
		scale: 3,
		fillOpacity: 1
	};

	const dashedSymbol = {
		path: 'M 0,-1 0,1',
		strokeOpacity: 1,
		scale: 4
	};

	let config = {};

	if (/driving/.test(lineType)) {

		config = {
			path: paths,
			geodesic: true,
			strokeColor: STROKE_COLORS_MAP[lineType],
			strokeOpacity: 0.7,
			strokeWeight: 6,
			icons: [{
				icon: arrowSymbol,
				offset: '25%',
				repeat: '25%'
			}]
		}

	} else if (/flying/.test(lineType)) {

		config = {
			path: paths,
			geodesic: true,
			strokeColor: STROKE_COLORS_MAP[lineType],
			strokeOpacity: 1,
			strokeWeight: 2,
			icons: [{
				icon: arrowSymbol,
				offset: '25%',
				repeat: '25%'
			}]
		}

	} else if (/sailing/.test(lineType)) {

		arrowSymbol.fillColor = "#ffffff";
		config = {
			path: paths,
			geodesic: true,
			strokeColor: STROKE_COLORS_MAP[lineType],
			strokeOpacity: 0,
			strokeWeight: 6,
			icons: [
				{
					icon: dashedSymbol,
					offset: '0',
					repeat: '20px',
				},
				{
					icon: arrowSymbol,
					offset: '50%',
					repeat: '0',
				}
			]
		}

	} else if (/transfer/.test(lineType)) {

		arrowSymbol.fillColor = "#ffffff";
		config = {
			path: paths,
			geodesic: true,
			strokeColor: STROKE_COLORS_MAP[lineType],
			strokeOpacity: 0,
			strokeWeight: 6,
			icons: [
				{
					icon: dashedSymbol,
					offset: '0',
					repeat: '20px',
				},
				{
					icon: arrowSymbol,
					offset: '50%',
					repeat: '0',
				}
			]
		}

	} else if (/excursion/.test(lineType)) {

		arrowSymbol.fillColor = "#ffffff";
		config = {
			path: paths,
			geodesic: true,
			strokeColor: STROKE_COLORS_MAP[lineType],
			strokeOpacity: 0,
			strokeWeight: 6,
			icons: [
				{
					icon: dashedSymbol,
					offset: '0',
					repeat: '20px',
				},
				{
					icon: arrowSymbol,
					offset: '50%',
					repeat: '0',
				}
			]
		}

	} else if (/railway/.test(lineType)) {

		dashedSymbol.strokeColor = "#ffffff";
		config = {
			path: paths,
			geodesic: true,
			strokeColor: STROKE_COLORS_MAP[lineType],
			strokeOpacity: 1,
			strokeWeight: 6,
			icons: [
				{
					icon: dashedSymbol,
					offset: '0',
					repeat: '20px',
				},
				{
					icon: arrowSymbol,
					offset: '50%',
					repeat: '0',
				}
			]
		}
	}

	return config;
};

/**
 * helper to provide a button element with default layout
 * @returns {HTMLDivElement}
 * @private
 */
const generateStandardButtonElement = () => {
	let controlDiv = document.createElement('div');
	controlDiv.style.backgroundColor = '#fff';
	controlDiv.style.borderRadius = '2px';
	controlDiv.style.height = '32px';
	controlDiv.style.width = '40px';
	controlDiv.style.margin = '10px';
	controlDiv.style.cursor = 'pointer';
	controlDiv.style.boxShadow = 'rgba(0, 0, 0, 0.3) 0px 1px 4px -1px';
	controlDiv.style.textAlign = 'center';
	controlDiv.style.color = '#676767';
	controlDiv.style.willChange = 'transform';
	return controlDiv;
};

const STROKE_COLORS_MAP = {
	flying: "#ff0000",
	driving: "#62A9E8",
	railway: "#000000",
	sailing: "#62A9E8",
	transfer: "green",
	excursion: "orange",
};

/**
 * helper: define which itemType should be able to use the directionsrenderer
 * @param type
 * @returns {boolean}
 */
const shouldUseDirectionsRenderer = (type) => {
	switch (type) {
		case 'rental-car':
		case 'camper':
		case 'motorcycle':
		case 'ferry':
			return true;
		default:
			return false;
	}
};

const PATH_TYPE_MAP = {
	"excursion": // "Ausflug":
		{
			"icon": "active",
			"strokeType": "excursion",
			"legendName": "Ausflug",
			"dashed": true,
		}
	,
	"ride":// "Bahnfahrt":
		{
			"icon": "train",
			"strokeType": "railway",
			"legendName": "Bahnfahrt",
			"dashed": true,
		}
	,
	"bustrip":// "Busreise":
		{
			"icon": "coach",
			"strokeType": "transfer",
			"legendName": "Busreise",
			"dashed": true,
		}
	,
	"ferry":// "Fähre":
		{
			"icon": "ferry",
			"strokeType": "sailing",
			"legendName": "Fähre",
			"dashed": true,
		}
	,
	"flight":// "Flug":
		{
			"icon": "plane",
			"strokeType": "flying",
			"legendName": "Flug",
			"dashed": false,
		}
	,
	"hotel":// "Hotel":
		{
			"icon": "hotel",
			"legendName": "Hotel",
			"dashed": false,
		}
	,
	"cruise":// "Kreuzfahrt":
		{
			"icon": "cruise",
			"strokeType": "sailing",
			"legendName": "Transfer",
			"dashed": true,
		}
	,
	"rental-car":// "Mietwagen":
		{
			"icon": "car",
			"strokeType": "driving",
			"legendName": "Mietwagen",
			"dashed": false,
		}
	,
	"motorcycle":// "Motorrad":
		{
			"icon": "biker",
			"strokeType": "driving",
			"legendName": "Motorrad",
			"dashed": false,
		}
	,
	"sightseeing-flight":// "Rundflug":
		{
			"icon": "plane",
			"legendName": "Rundflug",
			"dashed": false,
		}
	,
	"transfer":// "Transfer":
		{
			"icon": "coach",
			"strokeType": "transfer",
			"legendName": "Transfer",
			"dashed": false,
		}
	,
	"camper":// "Wohnmobil":
		{
			"icon": "motorhome",
			"strokeType": "driving",
			"legendName": "Route",
			"dashed": false,
		}
	,
	"standard":// "Standard":
		{
			"icon": "pin",
			"legendName": "Standard",
			"dashed": false,
		}
};

export {
	reformatFromLegacyDataModel,
	polylineConfigFactory,
	getMarkerMap,
	getPathMap,
	shouldUseDirectionsRenderer,
	latLngConverter,
	createWayPointsList,
	generateStandardButtonElement,
	PATH_TYPE_MAP,
	STROKE_COLORS_MAP,
}
