import React, {Component} from 'react';
import './Tab.scss'
import PropTypes from 'prop-types';

class Tab extends Component {
	render() {
		return (
			<label className={'tab'}>
				<input
					name={this.props.name}
					id={this.props.id}
					type={'radio'}
					onChange={() => {
						this.props.onChange(this.props.id)
					}}
					checked={this.props.isSelected}
				/>
				{this.props.children}
			</label>
		)
	}
}

Tab.propTypes = {
	/**
	 * name of radio group inputs should correspond to
	 */
	name: PropTypes.string,

	/**
	 * id for input
	 */
	id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),

	/**
	 * flag if tab should be selected
	 */
	selected: PropTypes.bool,

	/**
	 * can be anything
	 */
	children: PropTypes.node
};

export {
	Tab as default,
	Tab
}
