//IMAGE DB
const IMAGE_DB_PREFIX = '//images.canusa.de/img/';

// OPTIONS PREFIXES
const GAMMA_PREFIX = 'ga';
const QUALITY_PREFIX = 'q';
const BACKGROUND_PREFIX = 'bg';
const DIRECTION_PREFIX = 'gr';
const CROP_PREFIX = 'cr';

class ImageDecoder {

	//OBJECT TYPE DEFINITION FOR JSDOC
	/**
	 * @typedef  {Object}        SizeOption
	 *
	 * @property {Number}        [width]        - width of image
	 * @property {Number}        [height]       - height of imagegit s
	 * @property {String}        [type]         - valid cut type for size option: 'cut' | 'max' | 'fit'
	 */

	/**
	 * @typedef  {Object}        CropOption
	 *
	 * @property {Number}        width          - width of croping area
	 * @property {Number}        height        	- height of croping area
	 * @property {Number}        x              - x-cordinate of croping point
	 * @property {Number}        y              - y-cordinate of croping point
	 */

	/**
	 * @typedef  {Object}        ImageOptions
	 *
	 * @property {String}         srcPrefix        -
	 * @property {String}         basePath        -
	 * @property {String}        [direction]    - valid values: 'NorthWest' | 'North' | 'NorthEast' | 'West' | 'Center' | 'East' | 'SouthWest' | 'South' | 'SouthEast'
	 * @property {SizeOption}    [size]         - see SizeOptiong
	 * @property {CropOption}    [crop]         - see CropOption
	 * @property {String}        [background]   - hex value
	 * @property {String}        [colorSpace]   - valid values: 'srgb' | 'rgb' | 'gray' | 'cmyk'
	 * @property {String}        [gamma]        - 3 digits value between 001 - 999
	 * @property {Boolean}       [negate]        - negate image
	 * @property {String}        [quality]      - value between 1 - 99
	 * @property {Boolean}       [trim]            - remove unnecessary white space
	 * @property {String}        [fileFormat]   - valid values: 'jpg' | 'png'
	 */


	/**
	 * Return encoded source string
	 *
	 * @example
	 *        ImageDecoder.getEncodeSrc({
	 * 		 				srcPrefix: "https://images.canusa.de/img/"
	 * 		 				basePath: "hotel/usa/florida/golfkueste/stpetersburg-clearwater/alden-beach-resort/exterior-pool"
	 * 						background: "555555"
	 *						colorSpace: "gray"
	 *						direction: "North"
	 *						gamma: "200"
	 *						negate: true
	 *						quality: "10"
	 *						trim: true
	 * 					})
	 *
	 *        => returns "https://images.canusa.de/img/hotel/usa/florida/golfkueste/stpetersburg-clearwater/alden-beach-resort/exterior-pool.grNorth.trim.bg555555.ga200.negate.gray.q10.jpg"
	 *
	 * @param {ImageOptions} options
	 *
	 * @return {String}
	 */
	getEncodeSrc(options) {
		return options.srcPrefix + options.basePath + '.' + this.encodeOptions(options);
	}

	/**
	 *
	 * @example
	 *        ImageDecoder.encodeOptions({
	 *						background: "555555"
	 *						colorSpace: "gray"
	 *						direction: "North"
	 *						gamma: "200"
	 *						negate: true
	 *						quality: "10"
	 *						trim: true
	 *						fileFormat: 'jpg'
	 *					})
	 *
	 *        => return "grNorth.trim.bg555555.ga200.negate.gray.q10.jpg"
	 *
	 * @param {ImageOptions} options
	 *
	 * @return {String}
	 */
	encodeOptions(options) {
		let encodeOptions = [];

		options.direction && encodeOptions.push(DIRECTION_PREFIX + options.direction);

		options.crop && encodeOptions.push(this.encodeCropOption(options.crop));

		options.size && encodeOptions.push(this.encodeSizeOption(options.size));

		options.trim && encodeOptions.push('trim');

		options.background && encodeOptions.push(BACKGROUND_PREFIX + options.background);

		options.gamma && encodeOptions.push(GAMMA_PREFIX + options.gamma);

		options.negate && encodeOptions.push('negate');

		options.colorSpace && encodeOptions.push(options.colorSpace);

		options.quality && encodeOptions.push(QUALITY_PREFIX + options.quality);

		options.fileFormat && encodeOptions.push(options.fileFormat);

		return encodeOptions.join('.');
	}

	/**
	 * Split src to option strings and decode them
	 *
	 * @example
	 *        ImageDecoder.decodeOptions("https://images.canusa.de/img/hotel/usa/florida/golfkueste/stpetersburg-clearwater/alden-beach-resort/exterior-pool.cr2100x1399-0x100.1920x500.gray.q10.negate.ga200.bg555555.trim.grNorth")
	 *
	 *        => return {
	 *						background: "555555"
	 *						basePath: "hotel/usa/florida/golfkueste/stpetersburg-clearwater/alden-beach-resort/exterior-pool"
	 *						colorSpace: "gray"
	 *						direction: "North"
	 *						gamma: "200"
	 *						negate: true
	 *						quality: "10"
	 *						srcPrefix: "https://images.canusa.de/img/"
	 *						trim: true
	 *					}
	 *
	 * @param   {String}        src
	 *
	 * @returns {ImageOptions}    decodedOptions
	 * */
	decodeOptions(src) {

		let decodedOptions = {};
		decodedOptions.srcPrefix = IMAGE_DB_PREFIX;

		// split url with the image db prefix and and take 2 entry in array
		const optionsURL = src.split(IMAGE_DB_PREFIX)[1];
		const options = optionsURL.split('.');

		options.forEach((option, index) => {
			if (index === 0) {
				//  basePath is always the first attribute
				decodedOptions.basePath = option;
			} else {
				const decodedOption = this.decodeOption(option);
				Object.assign(decodedOptions, decodedOption);
			}
		});

		return decodedOptions;
	}

	/**
	 * Parse dynamic Attributes an store in configurationObject according to imageAPI
	 * @see http://images.canusa.de/doku.html
	 *
	 * @param {String} option
	 *
	 * @returns {*} decodedOption => size: | cut: | direction: {String} | crop: | trim: | background: {String} | gamma: {String} | negate: {Boolean} | colorSpace: | quality: | fileFormat: {*}
	 */
	decodeOption(option) {
		let decodedOption = {};

		if (option.match(/((^x\d*)|(^\d*x\d*)|(^\d*x)|(^\d*).*(max|fit|cut))$/g)) {
			decodedOption.size = this.decodeSizeOption(option);
		} else if (option.match(/(^cr\d*)/g)) {
			decodedOption.crop = this.decodeCropOption(option.replace(CROP_PREFIX, ''));
		} else if (option.match(/(^gr).*(N.*|W.*|C.*|E.*|S.*)/g)) {
			decodedOption.direction = option.replace(DIRECTION_PREFIX, '');
		} else if (option.match(/(trim)/g)) {
			decodedOption.trim = true;
		} else if (option.match(/(^bg.{1,6})/g)) {
			decodedOption.background = option.replace(BACKGROUND_PREFIX, '');
		} else if (option.match(/(^ga\d{1,3})/g)) {
			decodedOption.gamma = option.replace(GAMMA_PREFIX, '');
		} else if (option.match(/(negate)/g)) {
			decodedOption.negate = true;
		} else if (option.match(/(rgb|srgb|gray|cmyk)/g)) {
			decodedOption.colorSpace = option;
		} else if (option.match(/(^q).*(\d)/g)) {
			decodedOption.quality = option.replace(QUALITY_PREFIX, '');
		} else if (option.match(/(png|jpeg|jpg|gif|webp)/g)) {
			decodedOption.fileFormat = option;
		} else {
			console.warn('Non-valid option: ' + option);
		}

		return decodedOption;
	}

	/**
	 * Decode size option string into SizeOption object
	 *
	 * @param    {String}        encodedOption
	 *
	 * @returns {CropOption}    decodedOption
	 */
	decodeCropOption(encodedOption) {
		let decodedOption = {};

		const cropOptions = encodedOption.split('-', 2);
		const sizeOptions = cropOptions[0];

		if (cropOptions.length > 0) {

			decodedOption = this.decodeSizeOption(sizeOptions);

			if (cropOptions.length > 1) {
				let posCropOptions = cropOptions[1].split('x', 2);
				decodedOption.x = posCropOptions[0];
				decodedOption.y = posCropOptions[1];
			}
		}

		return decodedOption;
	}

	/**
	 * Encode CropOption object to valid string for imageDB
	 *
	 * @param   {CropOption}    decodedOption
	 *
	 * @returns {String}        encodedOption
	 */
	encodeCropOption(decodedOption) {
		let encodedOption = '';

		if (decodedOption.width && decodedOption.height && decodedOption.x && decodedOption.y) {
			encodedOption = CROP_PREFIX + decodedOption.width + 'x' + decodedOption.height + '-' + decodedOption.x + 'x' + decodedOption.y;
		} else {
			console.warn('Non-valid crop option: ' + decodedOption);
		}

		return encodedOption;
	}

	/**
	 * Decode size option string into SizeOption object
	 *
	 * @example
	 *        ImageDecoder.decodeSizeOption('400x300cut');
	 *        => return {width: 400, height: 300, type: 'cut'}
	 *
	 * @param   {String}        encodedOption
	 *
	 * @returns {SizeOption}    decodedOption
	 */
	decodeSizeOption(encodedOption) {
		let decodedOption = {};

		if (encodedOption.match(/(^\d+x)$/g)) {
			decodedOption.width = encodedOption.replace('x', '');
		} else if (encodedOption.match(/(^x\d+)$/g)) {
			decodedOption.height = encodedOption.replace('x', '');
		} else if (encodedOption.match(/(^\d*).*(max|fit|cut)$/g)) {
			let splitOptions = encodedOption.split(/(max|fit|cut)$/g, 2);
			decodedOption.type = splitOptions[1];
			Object.assign(decodedOption, this.decodeSizeOption(splitOptions[0]))
		} else if (encodedOption.match(/^(\d+x\d+)$/g)) {
			let splitOptions = encodedOption.split('x', 2);
			decodedOption.width = splitOptions[0];
			decodedOption.height = splitOptions[1];
		} else {
			console.warn('Non-valid size option: ' + encodedOption);
		}

		return decodedOption;
	}

	/**
	 * Encode SizeOption object to valid string for imageDB
	 *
	 * @example
	 *        ImageDecoder.encodeSizeOption({ width: 400, height: 300, type: 'cut'});
	 *        => return '400x300cut'
	 *
	 * @param   {SizeOption}    decodedOption
	 *
	 * @returns {String}        encodedOption
	 */
	encodeSizeOption(decodedOption) {
		const size = (decodedOption.width || '') + 'x' + (decodedOption.height || '');
		const type = decodedOption.type || '';
		return size + type;
	}

	/**
	 * Check if given url is from image database
	 *
	 * @param  {String} url
	 * @return {boolean}
	 */
	isImageDB(url){

		if(!url) return null;
		return url.includes(IMAGE_DB_PREFIX)
	}

}

export {
	ImageDecoder as default,
	ImageDecoder,
	IMAGE_DB_PREFIX
}
